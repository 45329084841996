import service from "../network/axios";

/**
 * 获取资产信息
 * @param {number} type 0-法币 1-数字货币
 */
const getMyAssets = (type) => {
  const o = { type };
  return service.get("/api/user/asset/list", { params: o });
};

/**
 * 获取汇率
 * @param {*} src 基准货币代码
 * @param {*} des 目标货币代码
 * @param {*} type 1-充值汇率 2-提款汇率
 */
const getCryptoRate = (data) => {
  const { src, des, type = 1 } = data;
  const o = { src, des, type };
  return service.get("/api/home/currency/rate", { params: o });
};

export default {
  getMyAssets,
  getCryptoRate,
};
