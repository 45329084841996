import Contract from "../contract";
import { Brand } from "../index";
import Quote from "@/library/network/quote";
import Item from "../item/item";
import Store from "@/store";
import service from "@/library/network/axios";
import { autorun } from "mobx";

export default class CFD extends Contract {
  //判断是否初始化
  #initial = false;
  //行情域名
  #domain = [];
  //socket类
  #socket = null;
  //所有商品的对象集合
  #total = {};
  //默认商品
  #index = "";
  #initialCodes = [];
  // ？？
  #queue = [];
  #market = [];

  constructor(...args) {
    super(...args);
  }

  async start(domain, data) {
    try {
      if (this.#initial) return true;
      await this.init(data);
      //this.#domain = domain;
      //todo 启动行情 判断是否是数组
      this.#domain = domain.split(";");
      let url;
      
      if (Array.isArray(this.#domain)) {
        url = this.#domain.map((url) => {
          url = url.replace("https://", "");
          url = url.replace("http://", "");
          return url;
        });
      } else {
        url =
          this.#domain.indexOf("https") !== -1
            ? this.#domain.replace("https://", "").split(";")
            : this.#domain.replace("http://", "").split(";");
      }
      this.#socket = new Quote(url);
      const { group, names, brand, zones } = this.cache;
      const { market, index } = await service.get("/api/setting/market-config");
      this.#market = market;
      //todo 保存品牌信息
      Brand.setData(brand);
      //todo 获取所有商品合约;
      for (let o of names) {
        if (o && o.indexOf(":") !== -1) {
          const [id, name] = o.split(":");
          this.#total[id] = new Item(id, name);
        } else {
          console.warn("CFD初始化发生错误");
        }
      }
      //todo 获取合约数据
      for (let { code, list } of zones) {
        list = list.split(":");
        for (let e of list) {
          if (this.#total[e] !== undefined) {
            this.#total[e].zone = code;
          }
        }
      }
      if (brand.code === "CX") {
        this.#handleGroup(group);
        this.#handleIndexGroup(index);
      } else if (brand.code === "FK") {
        this.#handleConfigGroup(market);
        this.#handleIndexGroup(index);
      } else {
        this.#handleGroup(group);
      }

      if (this.#index === "") console.warn("默认商品获取出错,我不想配置了");
      this.#initialCodes.unique();
      await this.getItemDetail(true);
      this.#finishInitial();
    } catch (err) {
      console.warn(err, "cfd start");
      setTimeout(() => this.start(domain, data), 5000);
    }
  }
  /**
   * 处理默认group数组
   */
  #handleGroup = (group) => {
    group.forEach(({ list, name }, key) => {
      list = list.split(";").filter((code, i) => {
        if (key === 0 && i === 0) {
          if (this.#index === "") {
            this.#index = code;
          }
        }
        this.#initialCodes.push(code);
        return this.#total[code] !== undefined;
      });
      if (list.length > 0) Store.Brief.defaultGroup(name, list, key + 1);
    });
  };

  /**
   * 处理 market-config 数组
   * @param {*} initial
   * @returns
   */
  #handleConfigGroup = (market) => {
    market.forEach(({ codes, name }, key) => {
      codes = codes.filter((item, i) => {
        if (key === 1 && i === 1) {
          if (this.#index === "") {
            this.#index = item;
          }
        }
        this.#initialCodes.push(item);
        return this.#total[item] !== undefined;
      });
      if (codes.length > 0) Store.Brief.addConfigGroup(name, codes, key);
    });
  };

  /**
   * 处理 index 数组
   * @param {*} initial
   * @returns
   */
  #handleIndexGroup = (index) => {
    index.forEach(({ codes, name }, key) => {
      if (codes.length > 0) Store.Brief.addHomeGroup(name, codes, key);
    });
  };

  /**
   * 获取商品合约
   */
  getItemDetail = async (initial = true) => {
    try {
      if (this.#initialCodes.length === 0) return;
      this.#getTradeFee();
      const compared = await this.#initialCodes.filter(async (c) => {
        // const updated = await this.#total[c].shouldUpdate();
        return this.#total[c];// !== undefined && updated;
      });
      if (compared.length > 0) {
        // this.#setItemDetailUpdating(compared);
        const { data } = await service.post("/api/trade/commodity/tradeList", {
          code: compared.join(";"),
        });
        for (let o of compared) {
          if (data[o] && data[o].contractCode) {
            this.#total[o].config = data[o];
            this.#total[o].config.priceUnit = data[o].priceChange.mul(data[o].priceUnit);
          }
        }
      }
      Store.Data.getAll(this.#total);
      initial ? Store.Data.create(this.#initialCodes) : Store.Data.create(compared);
      Store.Quote.getAll(this.#total);
      Store.Position.getAll(this.#total);
    } catch (e) {
      console.warn(e, "getItemDetail");
    }
  };

  #setItemDetailUpdating(compared) {
    for (let o of compared) {
      this.#total[o].contractUpdating = true;
    }
  }

  /**
   * 获取交易手续费
   */
  #getTradeFee = async () => {
    if (!Store.User.isLogin) return;
    try {
      if (this.#initialCodes.length === 0) return;
      const compared = await this.#initialCodes.filter(async (c) => {
        // const updated = await this.#total[c].shouldUpdateTrade();
        return this.#total[c];// !== undefined && updated;
      });

      if (compared.length > 0) {
        // this.#setItemTradeUpdating(compared);
        const { data } = await service.post("/api/trade/commodity/chargeUnit", {
          code: compared.join(","),
        });
        for (let o of compared) {
          if (data[o]) this.#total[o].charge = data[o];
        }
      }
    } catch (e) {
      console.warn(e, "getTradeFee");
    }
  };

  #setItemTradeUpdating(compared) {
    for (let o of compared) {
      this.#total[o].tradeUpdating = true;
    }
  }

  //todo 完成初始化
  #finishInitial() {
    if (!this.#initial) {
      this.#initial = true;
    }
    while (this.#queue.length > 0) {
      const [resolve, data] = this.#queue.shift();
      resolve(data);
    }
    //初始化完成后 传入数据在Data处理
    autorun(() => {
      if (this.#socket) Store.Data.processing(this.#socket.data);
    });
  }

  //todo 初始化完成后的回调
  #after() {
    return new Promise((resolve) => {
      if (this.#initial) {
        resolve();
      } else {
        this.#queue.push([resolve]);
      }
    });
  }

  //todo 抛出index total
  get total() {
    return this.#total;
  }
  get index() {
    return this.#index;
  }
  get initial() {
    return this.#initial;
  }
  get market() {
    return this.#market;
  }

  //todo 启动行情
  startData() {
    this.#after().then(() => this.#socket.subscribe(Store.Brief.getSymbol()));
  }

  //todo 关闭行情
  endData() {
    this.#after().then(() => this.#socket.unsubscribe());
  }

  //todo 启动单个商品行情
  startQuoteData(code) {
    this.#after().then(() => {
      let o = this.#total[code].config.contractCode;
      this.#socket.chartSubscribe(o);
      autorun(() => {
        if (this.#socket) Store.Quote.processing(this.#socket.chartData);
      });
    });
  }

  //todo 商品行情切换
  switchQuoteData() {
    this.#socket.chartUpdate(Store.Quote.code);
  }

  //todo 退出关闭行情
  quitQuoteData() {
    this.#after().then(() => this.#socket.chartUnsubscribe(Store.Quote.code));
  }
}
