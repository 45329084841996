import React, { useState, useEffect } from "react";
import "./index.scss";
import { Tabs } from "antd";
import { goodsImg } from "../components/tool";
import { message } from "antd";
import favorI from "../../assets/images/trade/favor.svg";
import favor_select from "../../assets/images/trade/favor_select.svg";
import favor_black from "../../assets/images/favor_black.png";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import FK from "@/core";
import { useNavigate } from "react-router";
import { toJS } from "mobx";

const { TabPane } = Tabs;

const Tab = observer((props) => {
  const navigate = useNavigate();
  const [favor, setFavor] = useState("");

  useEffect(() => {
    FK.Launcher.CFD.startData();
    Store.User.getFavor();
    getFavor();
    return () => FK.Launcher.CFD.endData();
  }, [Store.Brief.configData]);

  const changeQuote = (id) => {
    Store.Futures.switchOrderType(0);
    navigate(`/trade/${id}`);
  };

  //todo 获取添加的自选
  const getFavor = () => {
    let ary = [];
    if (Store.User.favor === undefined || Store.User.favor === null) return;
    Store.User.favor.forEach((i) => {
      // ary.push(Store.Brief.total[i]);
      if (Store.Brief.total[i] != undefined) {
        ary.push(Store.Brief.total[i]);
      }
    });
    setFavor(ary);
  };

  //todo 添加自选
  const addFavor = (code) => {
    Store.User.setFavor(code).then((e) =>
      e === 403
        ? message.info("操作太频繁，请稍后再试", 1)
        : message.info("添加成功", 1)
    );
    Store.User.getFavor();
    getFavor();
  };

  //todo 删除自选
  const removeFavor = (code) => {
    Store.User.setFavor(code).then((e) =>
      e === 403
        ? message.info("操作太频繁，请稍后再试", 1)
        : message.info("取消成功", 1)
    );
    Store.User.getFavor();
    getFavor();
  };

  let { array, type } = props,
    newAry = [];
  !!array &&
    array.map((item) => {
      if (item !== undefined) {
        if (item.isOpen) {
          newAry.unshift(item);
        } else {
          newAry.push(item);
        }
      }
    });

  return (
    <div className={"quote_box"}>
      <div className={"quotes_title"}>
        <div>商品名称</div>
        <div>最新价</div>
        <div>涨跌幅</div>
        <div className={"empty"} />
      </div>
      <div className={"quotes_list"}>
        {newAry.length === 0 && !!type ? (
          <div className={"empty"}>
            <div>
              <img src={favor_black} alt="" />
              <b>暂无自选</b>
            </div>
          </div>
        ) : (
          newAry.map((item, key) => {
            let n = item.id.indexOf("USDT") === -1 ? "futures" : "crypto";
            return (
              <div className="list" key={key}>
                <div
                  className={"left_info"}
                  onClick={() => {
                    changeQuote(item.id);
                  }}
                >
                  <div className={item.isOpen ? "name" : "name rest"}>
                    <img src={goodsImg[item.id]} />{" "}
                    {n === "futures" ? item.name : item.des}
                  </div>
                  <div
                    className={
                      item.isOpen ? (item.isUp ? "red" : "green") : "rest"
                    }
                  >
                    {item.isOpen ? item.price : "--"}
                  </div>
                  <div
                    className={
                      item.isOpen ? (item.isUp ? "red" : "green") : "rest"
                    }
                  >
                    {item.isOpen ? item.rate : "--"}
                  </div>
                </div>
                {Store.User.isLogin &&
                  (Store.User.isFavor(item.id) ? (
                    <div
                      className={"favor"}
                      onClick={() => {
                        removeFavor(item.id);
                      }}
                    >
                      <img src={favor_select} alt="" />
                    </div>
                  ) : (
                    <div
                      className={"favor"}
                      onClick={() => {
                        addFavor(item.id);
                      }}
                    >
                      <img src={favorI} alt="" />
                    </div>
                  ))}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
});

const Quotation = (props) => {
  const [self, setSelf] = useState([]);
  const [crypto, setCrypto] = useState([]);
  const [international, setInternational] = useState([]);
  const [stock, setStock] = useState([]);
  const [domestic, setDomestic] = useState([]);

  useEffect(() => {
    FK.Launcher.CFD.startData();
    return () => FK.Launcher.CFD.endData();
  }, []);

  useEffect(() => {
    let ary = [];
    if (Store.User.favor === undefined || Store.User.favor === null) return;
    Store.User.favor.forEach((i) => {
      // ary.push(Store.Brief.total[i]);
      if (Store.Brief.total[i] != undefined) {
        ary.push(Store.Brief.total[i]);
      }
    });

    setSelf(ary);
  }, [Store.User.favor]);

  const quote = Store.Brief.configData;
  useEffect(() => {
    quote.map(({ name, list }) => {
      if (name === "币圈") {
        setCrypto(list);
      }
      if (name === "国际") {
        setInternational(list);
      }
      if (name === "股指") {
        setStock(list);
      }
      if (name === "国内") {
        setDomestic(list);
      }
    });
  }, [toJS(quote)]);

  return (
    <div className={"quotes"}>
      <div className={"quotes_info"}>
        <div className="quotes_nav">
          <Tabs defaultActiveKey={`1`}>
            <TabPane key={"0"} tab={"自选"}>
              <Tab array={self} type={"self"} {...props} />
            </TabPane>
            <TabPane key={"1"} tab={"数字货币"}>
              <Tab array={crypto} {...props} />
            </TabPane>
            <TabPane key={"2"} tab={"国际期货"}>
              <Tab array={international} {...props} />
            </TabPane>
            <TabPane key={"3"} tab={"股指期货"}>
              <Tab array={stock} {...props} />
            </TabPane>
            <TabPane key={"4"} tab={"国内期货"}>
              <Tab array={domestic} {...props} />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default observer(Quotation);
