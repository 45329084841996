import React, { useState, useEffect } from "react";
import "./index.scss";
import TradeHead from "./head";
import TradeQuotation from "./quotation";
import TradeChart from "./chart";
import TradeOrder from "./tradeOrder/order";
import TradeLeverOrder from "./tradeOrder/leverOrder";

import TradePosition from './position/index';
import HomeHeader from "../components/homeHeader";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import FK from "@/core";
import { useLocation, useParams } from "react-router-dom";

const Trade = () => {
  const location = useLocation();
  const [currentSimulate, setCurrentSimulate] = useState("");
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    FK.Launcher.CFD.startQuoteData(id);
    Store.Quote.setQuoteId(id);
    
    return () => {
      FK.Launcher.CFD.quitQuoteData();
      Store.Quote.quoteInitial();
    };
  }, [id]);
  
  useEffect(() => {
    if (location.state) {
      Store.Quote.switchSimulate(true);
    }
  }, []);

  const swapSimulate = (simulate) => {
    setCurrentSimulate(simulate);
  };

  const {coins} = Store.Quote.quote
  
  return (
    <div id={"Trade"}>
      <HomeHeader />
      <TradeHead
        swapSimulate={() => {
          swapSimulate();
        }}
      />
      <div className="trade_main">
        <div className={"trade_info"}>
          <div className={"trade_top"}>
            <TradeQuotation/>
            <TradeChart/>
          </div>
          <TradePosition/>
        </div>
        {coins ?(
          <TradeLeverOrder/>
        ) : (
          <TradeOrder/>
        )}
      </div>
    </div>
  );
};

export default observer(Trade);
