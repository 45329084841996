import React, { useState, useEffect } from "react";
import "./index.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { Link } from "react-router-dom";
import { formatDate } from "@/utils";
import calender from "../../assets/images/college/calendar.svg";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import EVENT from "@/library/api";

function Page01() {
  const history = useNavigate();
  const [showDetail, setShowDetail] = useState(false);
  const [list, setList] = useState([]);
  const [detailInfo, setDetailInfo] = useState({});

  useEffect(() => {
    updateNews(0);
  }, []);

  const gotoDetail = (item) => {
    setShowDetail(true);
    EVENT.News.getDetail(item.id).then(({ news }) => {
      setDetailInfo(news);
    });
  };

  const handleBack = () => {
    if (showDetail) {
      setShowDetail(false);
    }
  };

  //获取热点新闻
  const updateNews = (type, date, device, page, size) => {
    EVENT.News.getCommodityNews(type, date, device, page, size).then(
      ({ newsList }) => {
        let arr = list;
        let data = arr.concat(newsList);
        setList(data);
      }
    );
  };

  //加载更多
  const getMore = () => {
    let dataLength = list.length,
      lastDate = list[dataLength - 1].date;
    updateNews(0, lastDate);
  };

  return (
    <div className="school page0">
      <HomeHeader />
      <div className="wrap">
        <div className="box">
          <div className="menu">
            <p onClick={() => history("/school")} className="title">
              致远学院
            </p>
            <dl>
              <dt>
                <h5>实时资讯</h5>
                <p>
                  <Link to="/page0">市场快讯</Link>
                </p>
                <p>
                  <Link to="/page1">热点新闻</Link>
                </p>
                <p>
                  <Link to="/page2">财经日历</Link>
                </p>
              </dt>
              <dt>
                <h5>新手入门</h5>
                <p>
                  <Link to="/page3" t="新手入门">
                    基础知识
                  </Link>
                </p>
                <p>
                  <Link to="/page4" t="新手入门">
                    投资介绍
                  </Link>
                </p>
              </dt>
              <dt>
                <h5>高手进阶</h5>
                <p>
                  <Link to="/page5" t="高手进阶">
                    基本面分析
                  </Link>
                </p>
                <p>
                  <Link to="/page6" t="高手进阶">
                    技术面分析
                  </Link>
                </p>
                <p>
                  <Link to="/page7" t="高手进阶">
                    综合技巧
                  </Link>
                </p>
                <p>
                  <Link to="/page8" t="高手进阶">
                    高手心态
                  </Link>
                </p>
              </dt>
            </dl>
          </div>
          <div className="main">
            <div className="article">
              <div className="school-header">
                <Link to="/school">致远学院</Link>
                &gt; <span onClick={handleBack}>热点新闻</span>
                &gt; {showDetail && <span>详细信息</span>}
              </div>
              <div className={"news_box"}>
                <div className="news_title">
                  <div>
                    <img src={calender} />
                    <span>{formatDate("y-m-d", { date: new Date() })}</span>
                  </div>
                  <i />
                </div>
                {showDetail ? (
                  <>
                    <div>
                      <h1>
                        {detailInfo?.title}{" "}
                        <i onClick={() => setShowDetail(false)}>返回</i>
                      </h1>
                      <p className={"titleDate"}>{detailInfo?.date}</p>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: detailInfo?.content }}
                    />
                  </>
                ) : (
                  <>
                    <ul>
                      {!!list &&
                        list.map((item, key) => {
                          return (
                            <li key={key} onClick={() => gotoDetail(item)}>
                              <label>{!!item && item.date.slice(5)}</label>
                              <p>{item.title}</p>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                              <p className={"detailInfo"}>详细信息</p>
                            </li>
                          );
                        })}
                    </ul>
                    <div
                      className={"more"}
                      onClick={() => {
                        getMore();
                      }}
                    >
                      点击加载更多
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
}

export default observer(Page01);
