import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
// import {CSvg} from "../../../../../components/common/svg";
import closeEyes from "../../../assets/images/userCenter/closeEyes.svg";
import openEyes from "../../../assets/images/userCenter/openEyes.svg";
import { getPlatform } from "../../../utils";
import { TEST } from "../../../library/test";
import { safeValue } from "../../../library/safety";
import { RESTRICT } from "../../../library/standard";

const notice_alert = {
  tel: "手机格式错误",
  email: "邮箱格式错误",
  password:
    "6-16位由字母、数字和符号组成的密码，不能为纯数字或字母或纯字母+符号或纯数字+符号",
  msgCode: "验证码格式不正确",
  cn_name: "姓名格式不正确",
  ID: "身份证号不正确",
};

const postList = [
  "qq.com",
  "163.com",
  "sina.com",
  "gmail.com",
  "icloud.com",
  "hotmail.com",
  "126.com",
  "yahoo.com",
  "outlook.com",
  "aol.com",
  "docomo.ne.jp",
  "me.com",
  "mac.com",
];

const testPwd = (str) => {
  const regEn = /[`~!@#$%^&*()_+<>?:"{},.\/;'[\]]/im;
  const regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;

  if (regEn.test(str) || regCn.test(str)) {
    return true;
  }
};

//todo 邮箱填充
class Post extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.val.indexOf("@") === -1) {
      return (
        <ul className={"email_address"}>
          {postList.map((e, key) => (
            <li key={key} onClick={() => this.send(`${this.props.val}@${e}`)}>
              {this.props.val}@{e}
            </li>
          ))}
        </ul>
      );
    }
  }

  send(v) {
    this.props.onClick(v);
  }
}

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      val: this.props.initialValue || "",
      error: false,
      focus: false,
    };
  }

  render() {
    const { val, error, focus } = this.state;
    let {
      label,
      placeholder,
      type,
      link,
      goTo,
      maxLength,
      value,
      onChange,
      onBlur,
      notice,
      disable,
      customClass = "",
    } = this.props;

    let len = 50;
    let disabled;
    if (!!disable) {
      disabled = true;
    } else {
      disabled = false;
    }
    /**
     * 以下都是根据input类型来设置长度 以及实际输入类型
     */
    let type_exact = type;
    if (type === "number" && getPlatform() === "pc") {
      type_exact = "text";
    }
    if (type === "email") {
      type_exact = "text";
    }
    if (type === "nickname") {
      len = 16;
    }
    if (type === "verify") {
      len = 6;
    }
    if (type === "verifyCode") {
      len = 6;
    }
    if (type === "tel") {
      len = 11;
    }
    if (type === "password") {
      len = 16;
    }
    if (type === "card") {
      len = 19;
    }
    if (type === "id") {
      len = 18;
    }
    if (maxLength !== undefined && maxLength !== 0) {
      len = maxLength;
    }

    if (type_exact === "password" && this.state.show) type_exact = "text";
    // if (type_exact === 'password' ) type_exact = 'text';
    let o = val;
    if (value !== undefined) {
      o = value;
    }

    return (
      <div className={`simple_input ${customClass}`}>
        <div className={"input_title"}>
          <label>{label}</label>
          {!!link && <Link to={`${!!goTo ? goTo : "#"}`}>{link}</Link>}
        </div>
        <div className="input_box">
          <input
            type={type_exact}
            placeholder={placeholder}
            value={o}
            // autoFocus={autoFocus}
            maxLength={len}
            onChange={(e) => {
              this._onChange(e);
            }}
            onInput={(e) => {
              this._onInput(e);
            }}
            // onFocus={() => {
            //   this.setState({ focus: true });
            //   if (onFocus) onFocus();
            // }}
            onBlur={() => {
              if (onBlur) onBlur();
              //    setTimeout(() => {
              //        this.verify();
              //        this.setState({focus: false})
              //    }, 100)
            }}
            disabled={disabled}
          />
          {type === "password" && (
            <div
              className={"eye"}
              onClick={() => this.setState({ show: !this.state.show })}
            >
              <img src={this.state.show ? openEyes : closeEyes} />
            </div>
          )}

          {type === "email" &&
            this.props.value !== undefined &&
            this.props.value.length > 0 && (
              <Post
                val={this.props.value}
                onClick={(e) => {
                  this.setState({ val: e }, () => {
                    this.verify();
                  });
                  if (onChange) {
                    const result = this.test(e);
                    onChange(e, result);
                  }
                }}
              />
            )}
        </div>
        {error && <span className={"error"}>{notice_alert[notice]}</span>}
      </div>
    );
  }

  _onChange(e) {
    let t, pass;
    let { type, onChange, force, free, min, empty, digit } = this.props;
    if (type === "number") {
      t = RESTRICT.float(e.target.value, digit);
    } else {
      t = e.target.value;
    }
    if (min !== undefined && t < min) {
      this.debounce(t !== "");
    }
    if (type === "password") {
      if (testPwd(t)) return;
    }
    this.setState({ val: t }, () => {
      if (
        (type === "password" || type === "nickname") &&
        force &&
        t.length > 3
      ) {
        this.verify();
      }
    });

    if (onChange && (!pass || free)) {
      const result = this.test(t);
      if (type === "number" && t !== 0 && t < 0.000001) {
        let len = t.toString().split("-")[1];
        if (len !== undefined) {
          t = t.toFixed(len);
        }
      }
      onChange(t, result);
    }
  }

  _onInput(e) {
    let { type } = this.props;
    if (type === "number") {
      e.currentTarget.value = e.currentTarget.value.replace(/[^\d.]/gi, "");
    }
    if (type === "tel" || type === "verify" || type === "verifyCode") {
      e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/g, "");
    }
    if (type === "password") {
      e.currentTarget.value = e.currentTarget.value.replace(
        /[^\w\.!@#$%^&*?\/]/gi,
        ""
      );
    }
    if (type === "nickname" || type === "email") {
      e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "");
    }
  }

  limit(v, focus) {
    const { min, max } = this.props;
    if (v === "") {
      if (focus) {
        if (min !== undefined) return min;
        return 0;
      } else {
        return v;
      }
    }
    if (min !== undefined && v < min) return min;
    if (max !== undefined && v > max) return max;
    return v;
  }

  verify(val) {
    let value = this.state.val;
    if (
      this.props.value !== undefined &&
      this.props.value !== "" &&
      this.props.free
    ) {
      value = this.props.value;
    }
    if (value === "") return this.setState({ error: false });
    const { format, type } = this.props;
    if (!!format) {
      let result;
      if (typeof format === "string" && TEST[format] !== undefined) {
        result = !TEST[format].test(value);
      } else if (typeof format === "function") {
        result = !format(value, this);
      }
      if (type === "password" && safeValue(val, true) && value !== val) {
        this.setState({ compare: true });
      } else {
        this.setState({ compare: false });
      }
      this.setState({ error: result });
    }
  }

  test(val) {
    let force = false;
    if (this.props.type === "verify") {
      force = true;
    }
    const { format, compare } = this.props;
    let result = false;
    if (typeof format === "string" && TEST[format] !== undefined) {
      if (force) {
        result = TEST[format].test(val) && this.hasVerify;
      } else {
        result = TEST[format].test(val);
      }
    } else if (typeof format === "function") {
      result = format(val, this);
    }
    if (
      this.props.type === "password" &&
      safeValue(compare, true) &&
      val !== compare
    ) {
      result = false;
    }
    return result;
  }

  debounce(run = true) {
    clearTimeout(this._timeout);
    if (!run) return;
    this._timeout = setTimeout(() => {
      let val = this.limit(this.state.val);
      if (val !== this.state.val) {
        this.setState({ val });
        if (this.props.onChange) {
          this.props.onChange(val);
        }
      }
    }, 2000);
  }

  componentDidMount() {
    if (safeValue(this.props.initialValue, true)) {
      if (this.props.onChange) {
        const result = this.test(this.props.initialValue);
        this.props.onChange(this.props.initialValue, result);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
    clearTimeout(this._timeout);
  }
}
