import { makeAutoObservable } from "mobx";
import Rest from "@/library/rest";
import { RESTRICT } from "@/library/standard";

class Brief {
  id = "";
  name = "";
  des = "";
  code = "";
  prev = 0;
  price = 0;
  isUp = true;
  rate = "0.00%";
  isOpen = false;
  gap = null;
  flux = null;
  _gap = 0;
  format = null;

  constructor(config, zone) {
    if (Object.keys(config).length === 0) return;
    const { code, name, contractCode } = config;
    if (name.indexOf("USDT") !== -1) {
      this.des = `${name.split("USDT")[0]}`;
      this.currency = "USDT";
    } else {
      this.des = code;
    }
    this.id = code;
    this.name = name;
    this.code = contractCode;
    this.format = ["isUp", "price", "prev", null, "buyVolume", null, "sellVolume", "max", "min", "total"];
    this.zone = zone;
    makeAutoObservable(this);
  }

  //todo 更新合约号
  updateCode(code) {
    this.code = code;
  }
  //计算单次价格波动
  _wave = (price) => {
    if (this.price === null || price === this.price) return (this.flux = null);
    price > this.price ? (this.flux = true) : (this.flux = false);
  };

  //插入数据
  insert() {
    this._wave(arguments[1]);
    let bridge = this;
    this.format.forEach((key, index) => {
      bridge[key] = arguments[index];
    });
    this.isUp = Number(this.price) > Number(this.prev);
  }
  // 计算百分比
  calculateRate = () => {
    if (this.prev === null) return;
    if (Number(this.prev) <= 0) return (this.rate = "0.00%");
    this.rate = `${this.isUp ? "+" : "-"}${this._gap.div(this.prev).mul(100).toFixed(2)}%`;
  };
  // 计算价差
  calculateGap = () => {
    if (this.prev === null) return;
    if (+this.price <= 0) return (this.gap = 0);
    this._gap = Math.abs(+this.price - +this.prev) || 0;
    let l;
    if (this.price.indexOf(".") !== -1) {
      l = this.price.split(".")[1].length;
    } else {
      l = 0;
    }
    this.gap = `${this.isUp ? "+" : "-"}${RESTRICT.float(this._gap, l)}`;
  };
  //涨跌幅状态
  getWave = (raise, fall) => {
    if (this.flux === null) return "";
    if (this.flux) return raise;
    return fall;
  };
  //计算休市状态
  renew = (contract) => {
    this.isOpen = Rest.isOpening(contract);
  };
}

export default Brief;
