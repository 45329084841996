import React, { useEffect, useState } from "react";
import Store from "@/store";
import { goodsImg } from "../components/tool";
import { observer } from "mobx-react-lite";
import TvChart from "../components/tvChart";
import { toJS } from "mobx";

const TradeChart = ({ tradeType }) => {
  const [data, setData] = useState([]);

  const {
    price,
    code,
    rate,
    percent,
    isUp,
    isOpen,
    max,
    min,
    name,
    id,
    totalVolume,
  } = Store.Quote.quote;

  let n = code && code.indexOf("USDT") === -1 ? "futures" : "crypto";
  return (
    <div className={"chart"}>
      <div className={"chart_info"}>
        <div className={"info"}>
          <div>
            <div className={"info_name"}>
              {id == "- -" ? "" : <img src={goodsImg[id]} />}
              <span>
                {name}({n === "futures" ? code : id})
              </span>
            </div>
            <b className={isOpen ? (isUp ? "red" : "green") : "rest"}>
              {code == "- -"
                ? "- -"
                : isOpen
                ? price
                : id !== "--"
                ? "休市"
                : "--"}
            </b>
          </div>
          <div className={"grid"}>
            <label>涨跌额</label>
            <div className={isOpen ? (isUp ? "red" : "green") : "rest"}>
              {isOpen ? rate : "--"}
            </div>
          </div>
          <div className={"grid"}>
            <label>涨跌幅</label>
            <div className={isOpen ? (isUp ? "red" : "green") : "rest"}>
              {isOpen ? percent : "--"}
            </div>
          </div>
          <div className={"grid"}>
            <label>最高</label>
            <div>{isOpen ? max : "--"}</div>
          </div>
          <div className={"grid"}>
            <label>最低</label>
            <div>{isOpen ? min : "--"}</div>
          </div>
          <div className={"grid"}>
            <label>24H成交量</label>
            <div>{isOpen ? totalVolume : "--"}</div>
          </div>
        </div>
      </div>
      <TvChart />
    </div>
  );
};

export default observer(TradeChart);
