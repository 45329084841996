import React, { useState, useEffect, useMemo } from "react";
import { notification } from "antd";

import EVENT from "@/library/api";
import { arrayToStr, isEmpty } from "@/utils";
import x from "../../../../assets/images/common/x.svg";
import styles from "./index.module.scss";


export default function CheckIn({ close }) {
	const [checkInInfo, setCheckInInfo] = useState({ days: 0, list: [] });
	const week = useMemo(() => ["周一", "周二", "周三", "周四", "周五"], []);
	useEffect(() => {
		getCheckInHistory();
	}, []);
	useEffect(() => {
		if (!isEmpty(checkInInfo.list)) {
			let today = new Date().getDay();
			if (checkInInfo.list[today - 1].status !== "1") {
				checkInDay();
			}
		}
	}, [checkInInfo.list]);
	// 檢查簽到
	const checkInDay = () => {
		EVENT.Account.checkIn()
			.then(() => {
				notification.success({ message: "签到成功" });
				getCheckInHistory();
			})
			.catch(({ message }) => notification.error({ message }));
	};
	// 取得簽到紀錄
	const getCheckInHistory = () => {
		EVENT.Account.getCheckInStatus()
			.then(({ data }) => {
				const status = data.pointsStatus.split(",");
				let count = 0;
				for (let o of status) {
					if (o === "1") {
						count++;
					}
				}
				setCheckInInfo({
					days: count,
					list: data.pointsArray.split(",").map((point, index) => ({ point, status: status[index] })),
				});
			})
			.catch(({ message }) => notification.error({ message }));
	};

	return (
		<div className={arrayToStr([styles.modal, styles.checkInModal])}>
			<div className={styles.ellipses} />
			<div className={styles.close} onClick={close}>
				<img src={x} />
			</div>
			<div className={styles.checkInContent}>
				<span className={styles.title}>今日已签到</span>
				<span className={styles.hint}>已经连续{checkInInfo.days}天</span>
				<ul className={styles.list}>
					{week.map((key, index) => (
						<li className={styles.item}>
							<div
								className={arrayToStr([styles.point, checkInInfo.list[index]?.status === "1" ? styles.checked : ""])}
							>
								+{checkInInfo.list[index]?.point || 0}
								{checkInInfo.list[index]?.status === "1" && <i />}
							</div>
							<span className={checkInInfo.list[index]?.status === "1" ? styles.active : ""}>{key}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}
