import React from "react";

import "./index.scss";
import CSvg from "@/utils/svg";
import { RESTRICT } from "@/library/standard";

import add from "../../../assets/images/trade/add.svg";
import minus from "../../../assets/images/trade/loss.svg";
import eye_close from "../../../assets/images/userCenter/closeEyes.svg";
import eye_open from "../../../assets/images/userCenter/openEyes.svg";
// import { INTER } from "../../../../../pro/international";
import { parallel, getPlatform, TEST } from "@/utils/index";
import { safeValue } from "../../../library/safety";
import { notification } from "antd";

const postList = [
  "qq.com",
  "126.com",
  "163.com",
  "outlook.com",
  "gmail.com",
  "hotmail.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "yahoo.com",
  "aol.com",
  "docomo.ne.jp",
  "icloud.com",
  "me.com",
  "mac.com",
  "msn.com",
  "sina.com",
];

class Post extends React.Component {
  render() {
    if (this.props.val.indexOf("@") === -1) {
      return (
        <ul className={"smart-ass"}>
          {postList.map((e, key) => (
            <li key={key} onClick={() => this.send(`${this.props.val}@${e}`)}>
              {this.props.val}@{e}
            </li>
          ))}
        </ul>
      );
    } else {
      const [v, e] = this.props.val.split("@");
      let list = postList;
      if (e !== "") {
        list = postList.filter((o) => o.indexOf(e) !== -1);
      }
      return (
        <ul className={"smart-ass"}>
          {list.map((e, key) => (
            <li key={key} onClick={() => this.send(`${v}@${e}`)}>
              {v}@{e}
            </li>
          ))}
        </ul>
      );
    }
  }

  send(v) {
    this.props.onClick(v);
  }
}

export class Input extends React.Component {
  _timer = null;

  add = false;
  addLoop = null;
  sub = false;
  subLoop = null;

  _timeout = null;

  hasVerify = false;
  _verifyClick = false;

  constructor(props) {
    super(props);
    this.state = {
      val: isNaN(this.props.initialValue) ? "" : this.props.initialValue,
      display: "",
      error: false,
      compare: false,
      focus: false,
      retry: 0,
      show: false,
    };

    this._onChange = this._onChange.bind(this);
    this._onInput = this._onInput.bind(this);
  }

  render() {
    const { val, error, focus, compare } = this.state;
    /**
     * @param force 配合密码和昵称类型使用,试输入值时就出现错误提示
     */
    let {
      className,
      placeholder,
      type = "text",
      onChange,
      onFocus,
      onBlur,
      step = 0.01,
      controller,
      value,
      notice,
      compare_notice,
      btn_control,
      min,
      max,
      digit = 2,
      free,
      force,
      float,
      maxLength,
      empty,
      getAdd,
      getSub,
      prefix,
      right = '38px'
    } = this.props;
    const compile = [
      float && "float",
      className || null,
      "emulate-input",
      (error || compare) && "error",
      parallel(this.state.focus, "focus"),
    ];
    let len = 50;
    let o;
    let disabled = false;
    o = val;
    if (value !== undefined && !free) {
      disabled = true;
    }
    if (value !== undefined) {
      o = value;
    }
    // if (o === '' && this.props.initialValue !== '' && min !== undefined && !focus) {
    //     o = min;
    // }
    /**
     * 以下都是根据input类型来设置长度 以及实际输入类型
     */
    let type_exact = type;
    if (type === "number" && getPlatform() === "pc") {
      type_exact = "text";
    }
    if (type === "email") {
      type_exact = "text";
    }
    if (type === "percent") {
      type_exact = "text";
      disabled = true;
    }
    if (type === "nickname") {
      len = 16;
    }
    if (type === "verify") {
      len = 6;
    }
    if (type === "tel") {
      len = 15;
    }
    if (type === "password") {
      len = 16;
    }
    if (maxLength !== undefined && maxLength !== 0) {
      len = maxLength;
    }
    if (type_exact === "password" && this.state.show) type_exact = "text";

    const btn_available = this.state.retry === 0;
    /**
     * 存储自定义加减号dom
     */
    const Add = getAdd || null;
    const Sub = getSub || null;
    return (
      <div className={compile.join(" ")}>
        {prefix && prefix()}
        {(o === "" || float) && !prefix && (
          <label
            className={`${parallel(o !== "" || this.state.focus, "fixed")}`}
          >
            {placeholder}
          </label>
        )}
        {type === "password" && (
          <input
            type="password"
            autoComplete={"new-password"}
            style={{ position: "absolute", top: "-999px" }}
          />
        )}
        <input
          type={type_exact}
          maxLength={len}
          value={type === "percent" ? `${o.mul(100)}%` : o}
          onChange={this._onChange}
          onInput={this._onInput}
          placeholder={prefix ? placeholder : ""}
          onFocus={() => {
            this.setState({ focus: true });
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            if (min !== undefined || max !== undefined) {
              let n = this.limit(this.state.val, true);
              if (n !== this.state.val) {
                this.setState({ val: n });
                if (onChange) {
                  onChange(n);
                }
              }
            }
            if (onBlur) onBlur();
            setTimeout(() => {
              this.verify();
              this.setState({ focus: false });
            }, 200);
          }}
          disabled={disabled}
        />
        {type === "email" && this.state.focus && this.state.val !== "" && (
          <Post
            val={this.state.val}
            onClick={(e) => {
              this.setState({ val: e }, () => this.verify());
              if (onChange) {
                const result = this.test(e);
                onChange(e, result);
              }
            }}
          />
        )}
        {/*{*/}
        {/*    type === 'verify' && (*/}
        {/*        <div className={`verify-btn ${(!btn_available || !btn_control) && 'disabled'}`}*/}
        {/*             onClick={this.verification}>{!btn_available ? `${this.state.retry}${Dom.common('unit.second')}` : Dom.common('btn.verification')}</div>*/}
        {/*    )*/}
        {/*}*/}
        {((controller === true && disabled === false) ||
          type === "percent") && (
            <div className={"controller"} style={{ right: right }}>
              {Add ? (
                <Add
                  onClick={this._addClick.bind(this, o)}
                  onMouseDown={this._addDown.bind(this)}
                  onMouseUp={() => {
                    if (this.addLoop !== null) {
                      clearTimeout(this.addLoop);
                      this.addLoop = null;
                    }
                    if (this.add) {
                      this.add = false;
                    }
                  }}
                />
              ) : (
                <div
                  onClick={this._addClick.bind(this, o)}
                  onMouseDown={this._addDown.bind(this)}
                >
                  <CSvg className={"add"} svgClassName={"btn"} src={add} />
                </div>
              )}
              {Sub ? (
                <Sub
                  onClick={this._subClick.bind(this, o)}
                  onMouseDown={this._subDown.bind(this)}
                  onMouseUp={() => {
                    if (this.subLoop !== null) {
                      clearTimeout(this.subLoop);
                      this.subLoop = null;
                    }
                    if (this.sub) {
                      this.sub = false;
                    }
                  }}
                />
              ) : (
                <div
                  onClick={this._subClick.bind(this, o)}
                  onMouseDown={this._subDown.bind(this)}
                >
                  <CSvg className={"minus"} svgClassName={"btn"} src={minus} />
                </div>
              )}
            </div>
          )}
        {type === "password" && (
          <div
            className={"eye"}
            onClick={() => this.setState({ show: !this.state.show })}
          >
            <CSvg src={this.state.show ? eye_open : eye_close} />
          </div>
        )}
        {/*{*/}
        {/*    error ?*/}
        {/*        <LocaleText id={`public.format.${notice}`} className={'emulate-input-notice'}/> : (compare &&*/}
        {/*        <LocaleText id={`public.format.${compare_notice}`} className={'emulate-input-notice'}/>)*/}
        {/*}*/}
        {this.props.children}
      </div>
    );
  }

  _loopAdd(next) {
    if (!this.add) return;
    const { step = 0.01, onChange } = this.props;
    const { val } = this.state;
    const t = this.limit(Number(val).add(step));
    this.setState({ val: t });
    if (onChange) {
      onChange(t);
    }
    setTimeout(() => {
      this._loopAdd(Math.max(next - 50, 50));
    }, Math.max(next - 50, 50));
  }

  _loopSub(next) {
    if (!this.sub) return;
    const { step = 0.01, onChange } = this.props;
    const { val } = this.state;
    const t = this.limit(Number(val).sub(step));
    this.setState({ val: t });
    if (onChange) {
      onChange(t);
    }
    setTimeout(() => {
      this._loopSub(Math.max(next - 50, 50));
    }, Math.max(next - 50, 50));
  }

  _addClick(o) {
    const { onChange, step = 1 } = this.props;
    
    if (this.addLoop !== null) {
      clearTimeout(this.addLoop);
      this.addLoop = null;
    }
    if (this.add) {
      this.add = false;
    } else {
      const t = this.limit(Number(o).add(step));
      this.setState({ val: t });
      if (onChange) {
        onChange(t);
      }
    }
  }

  _addDown() {
    this.addLoop = setTimeout(() => {
      this.add = true;
      this._loopAdd(500);
    }, 500);
  }

  _subClick(o) {
    const { onChange, step = 1 } = this.props;
    if (this.subLoop !== null) {
      clearTimeout(this.subLoop);
      this.subLoop = null;
    }
    if (this.sub) {
      this.sub = false;
    } else {
      const t = this.limit(Number(o).sub(step));
      this.setState({ val: t });
      if (onChange) {
        onChange(t);
      }
    }
  }

  _subDown() {
    this.subLoop = setTimeout(() => {
      this.sub = true;
      this._loopSub(500);
    }, 500);
  }

  _onChange(e) {
    let t, pass;
    let { type, onChange, force, free, min, empty, digit } = this.props;
    if (type === "number") {
      if (
        e.currentTarget.value.indexOf(".") ===
        e.currentTarget.value.length - 1 ||
        (e.currentTarget.value.indexOf(".") !== -1 &&
          e.currentTarget.value.toString().substr(-1, 1) === "0") ||
        e.currentTarget.value === "-"
      ) {
        t = e.currentTarget.value;
        pass = !(e.currentTarget.value === "" && empty);
      } else {
        t = RESTRICT.float(e.currentTarget.value, digit);
      }
    } else {
      t = e.currentTarget.value;
    }
    if (min !== undefined && t < min) {
      this.debounce(t !== "" && t !== "-");
    }
    this.setState({ val: t }, () => {
      if (
        (type === "password" || type === "nickname") &&
        force &&
        t.length > 3
      ) {
        this.verify();
      }
    });
    if (onChange && (!pass || free)) {
      const result = this.test(t);
      if (type === "number" && t !== 0 && t < 0.000001 && t > 0) {
        let len = t.toString().split("-")[1];
        if (len !== undefined) {
          t = t.toFixed(len);
        }
      }
      onChange(t, result);
    }
  }

  _onInput(e) {
    let { digit, type, max, min } = this.props;
    if (type === "number") {
      e.currentTarget.value = e.currentTarget.value.replace(/[^\-?\d.]/gi, "");
      if (
        e.currentTarget.value.length > 1 &&
        e.currentTarget.value.lastIndexOf("-") > 0
      ) {
        e.currentTarget.value = e.currentTarget.value.substring(
          0,
          e.currentTarget.value.length - 1
        );
      }
      if (e.currentTarget.value.toString().indexOf(".") !== -1) {
        if (digit === 0) {
          e.currentTarget.value = e.currentTarget.value.replace(".", "");
        } else {
          const [, float] = e.currentTarget.value.toString().split(".");
          if (float.length > digit) {
            e.currentTarget.value = RESTRICT.float(
              e.currentTarget.value,
              digit
            );
          }
        }
      }
    }
    if (type === "tel" || type === "verify") {
      e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/g, "");
    }
    if (type === "password") {
      e.currentTarget.value = e.currentTarget.value.replace(
        /[^\w\.!@#$%^&*?\/]/gi,
        ""
      );
    }
    if (type === "nickname" || type === "email") {
      e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "");
    }

    if (max !== undefined && max > 0 && e.currentTarget.value > max) {
      e.currentTarget.value = max;
    }
    if (
      min !== undefined &&
      min < 0 &&
      e.currentTarget.value < min &&
      !isNaN(e.currentTarget.value)
    ) {
      e.currentTarget.value = min;
    }
  }

  limit(v, focus) {
    const { min, max } = this.props;
    if (v === "") {
      if (focus) {
        if (min !== undefined) return min;
        return 0;
      } else {
        return v;
      }
    }
    if (min !== undefined && v < min) return min;
    if (max !== undefined && v > max) return max;
    return v;
  }

  test(val) {
    let force = false;
    if (this.props.type === "verify") {
      force = true;
    }
    const { format, compare } = this.props;
    let result = false;
    if (typeof format === "string" && TEST[format] !== undefined) {
      if (force) {
        result = TEST[format].test(val) && this.hasVerify;
      } else {
        result = TEST[format].test(val);
      }
    } else if (typeof format === "function") {
      result = format(val, this);
    }
    if (
      this.props.type === "password" &&
      safeValue(compare, true) &&
      val !== compare
    ) {
      result = false;
    }
    return result;
  }

  verify(val) {
    let value = this.state.val;
    if (
      this.props.value !== undefined &&
      this.props.value !== "" &&
      this.props.free
    ) {
      value = this.props.value;
    }
    if (value === "") return this.setState({ error: false });
    const { format, type, compare } = this.props;
    if (!val) val = compare;
    if (format !== undefined) {
      let result;
      if (typeof format === "string" && TEST[format] !== undefined) {
        result = !TEST[format].test(value);
      } else if (typeof format === "function") {
        result = !format(value, this);
      }
      if (type === "password" && safeValue(val, true) && value !== val) {
        this.setState({ compare: true });
      } else {
        this.setState({ compare: false });
      }
      this.setState({ error: result });
    }
  }

  verification = () => {
    if (this._verifyClick) return;
    this._verifyClick = true;
    setTimeout(() => (this._verifyClick = false));
    const max = 90;
    if (this.state.retry === 0 && this.props.btn_control) {
      this.props.onClick &&
        this.props
          .onClick()
          .then(() => {
            this.hasVerify = true;
            this.setState({ retry: max, val: "" });
            this._loop();
          })
          .catch((err) => {
            this.hasVerify = false;
            notification.error({ message: err })
          });
    }
  };

  debounce(run = true) {
    clearTimeout(this._timeout);
    if (!run) return;
    this._timeout = setTimeout(() => {
      let val = this.limit(this.state.val);
      if (val !== this.state.val) {
        this.setState({ val });
        if (this.props.onChange) {
          this.props.onChange(val);
        }
      }
    }, 2000);
  }

  _loop() {
    this._timer = setTimeout(() => {
      if (this.state.retry > 0) {
        this.setState({ retry: this.state.retry - 1 });
        this._loop();
      }
    }, 1000);
  }

  componentDidMount() {
    if (safeValue(this.props.initialValue, true)) {
      if (this.props.onChange) {
        const result = this.test(this.props.initialValue);
        this.props.onChange(this.props.initialValue, result);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
    clearTimeout(this._timeout);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.initialValue !== this.props.initialValue) {
      this.setState({ val: nextProps.initialValue });
    }
    if (nextProps.compare !== this.props.compare) {
      this.verify(nextProps.compare);
    }
    if (nextProps.update !== this.props.update) {
      this.setState({ val: "" });
    }
    return true;
  }
}
