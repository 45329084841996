import React, { Component,useState } from "react";
import "./index.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import fh from '../../assets/images/school/fh.png'
import jx01 from "../../assets/images/school/jx01.png";
import jx02 from "../../assets/images/school/jx02.png";
import jx03 from "../../assets/images/school/jx03.png";
import rs00 from "../../assets/images/school/gs-rs00.png";
import rs01 from "../../assets/images/school/gs-rs01.png";
import rs02 from "../../assets/images/school/gs-rs02.png";
import rs03 from "../../assets/images/school/gs-rs03.png";
import pd01 from "../../assets/images/school/pd01.jpg";
import pd02 from "../../assets/images/school/pd02.jpg";
import pd03 from "../../assets/images/school/pd03.jpg";
import pd04 from "../../assets/images/school/pd04.jpg";

function Page07() {
  const history = useNavigate();
  const [which, setWhich] = useState(null);
  return (
    <div className="school page3">
    <HomeHeader />
    <div className="wrap">
      <div className="box">
        <div className="menu">
          <p onClick={()=>history.push('/school')} className="title">致远学院</p>
          <dl>
            <dt>
              <h5>实时资讯</h5>
              <p>
                <Link to="/page0">市场快讯</Link>
              </p>
              <p>
                <Link to="/page1">热点新闻</Link>
              </p>
              <p>
                <Link to="/page2">财经日历</Link>
              </p>
            </dt>
            <dt>
              <h5>新手入门</h5>
              <p>
                <Link to="/page3" t="新手入门">
                基础知识
                </Link>
              </p>
              <p>
                <Link to="/page4" t="新手入门">
                  投资介绍
                </Link>
              </p>
            </dt>
            <dt>
              <h5>高手进阶</h5>
              <p>
                <Link to="/page5" t="高手进阶">
                  基本面分析
                </Link>
              </p>
              <p>
                <Link to="/page6" t="高手进阶">
                  技术面分析
                </Link>
              </p>
              <p>
                <Link to="/page7" t="高手进阶">
                  综合技巧
                </Link>
              </p>
              <p>
                <Link to="/page8" t="高手进阶">
                  高手心态
                </Link>
              </p>
            </dt>
          </dl>
        </div>
        {which === null ? (
          <div className="main">
            <div className="school-header">
              <Link to="/school">致远学院</Link> &gt;{" "}
              <span v-param="title">综合技巧</span>
            </div>
            <div className="school-list">
              <div className="school-list-body" id="投资介绍">
                <ul>
                  <li>
                    <label>1</label>
                    <div onClick={() => setWhich(0)}>
                      消除假突破
                    </div>
                  </li>
                  <li>
                    <label>2</label>
                    <div onClick={() => setWhich(1)}>
                      高手的均线交易
                    </div>
                  </li>
                  <li>
                    <label>3</label>
                    <div onClick={() => setWhich(2)}>
                      入市时间的选择
                    </div>
                  </li>
                  <li>
                    <label>4</label>
                    <div onClick={() => setWhich(3)}>
                      建立波段交易系统
                    </div>
                  </li>
                </ul>
              </div>
              <p className="back">
                <Link to="/school">
                  <img src={fh} width="90px" alt="返回" title="返回" />
                </Link>
              </p>
            </div>
          </div>
        ) : which === 0 ? (
          <div className="main">
            <div className="school-header">
              <Link to="/school">致远学院</Link> &gt;&nbsp;
              <span>高手进阶</span>
            </div>
            <div className="school-detail">
              <div
                onClick={() => setWhich(null)}
                className="fh"
              >
                <img src={fh} width="70px" alt="返回" title="返回" />
              </div>
              <div className="school-detail-title">消除假突破</div>
              <div className="school-detail-content">
                <p>
                  以下给出更多的例子，说明经常发生的自阶段性高点/低点大约28天内出现的周期性趋势突破。要确定在三角形内运行多久才会出现突破，可以从图表上当前的交易日倒数算起，直到达到近阶段的高点或低点而得出结论。当计数达到25天，密切注意并最终确定你的交易流程，准备进行交易。永远记住，这只是一个工具，并不是百分之百地正确。下面，我们将讨论如何消除“假突破”。
                </p>
                <p>
                  <img src={rs00} />
                </p>
                <p>
                  交易者用对称三角形进行交易，听到最多的抱怨是假突破和接踵而至的双边亏损。根据我的经验，假突破通常发生在“计数日”开始的第23或24天，而且这个提前到来的突破经常以返回三角形内部并最终向其他方向突破而告终。由前所述，我们知道了在28天周期突破的形式后，市场可能的走向。将在此之前可能发生的假突破与之结合，我们建立了一个自第25天至第27天上午的机会时间窗。通过等待这个时间窗，我们可以消除很多其他交易者可能陷入的假突破。
                </p>
                <p>
                  <img src={rs01} />
                </p>
                <p>
                  下图9月美元三角形态中有两个明显的警告标志，因而我没有在初期下方突破时进行交易。首先，请注意，正如先前讨论的，三角形顶点没有出现在原图形的1/3中心区域，反而是发生在1/3上方区域。其次，下跌突破发生在第24日，这也是假突破的一个迹象。两点警告的结合可以很好地防止错误的交易，将下单时间放在第25天至第27天上午之间，你会大大受益，并消除了很多虚假突破。
                </p>
                <p>
                  <img src={rs02} />
                </p>
                <p>
                  当9月木材期货在第24天发生假突破，我分析了更远的几个月，注意到11月合约也已形成了一个对称三角形，但没有执行假突破。我将此视为一个信号，交易11月合约而不是9月合约，以取得好的结果。正如所料，9月合约返回到三角形内，且在第26天发生了下跌。如果你已进行了9月合约的交易，那在第4天上午，你不得不根据盈亏平衡点平仓出局。我交易了11月合约，因为它没有出现虚假突破。选择哪个合约进行交易，我将其称为交易的“艺术”，且这不容易程序化。经验在这样的交易选择中发挥了很重要的作用。
                </p>
                <p>
                  <img src={rs03} />
                </p>
              </div>
            </div>
          </div>
        ) : which === 1 ? (
          <div className="main">
            <div className="school-header">
              <Link to="/school">致远学院</Link> &gt;&nbsp;
              <span>高手进阶</span>
            </div>
            <div className="school-detail">
              <div
                onClick={() => setWhich(null)}
                className="fh"
              >
                <img src={fh} width="70px" alt="返回" title="返回" />
              </div>
              <div className="school-detail-title">高手的均线交易</div>
              <div className="school-detail-content">
                <p>说起均线谁都知道，但是真正又能有几个人用的好的呢</p>
                <p>
                  <img src={jx01} />
                </p>{" "}
                <p>
                  上面的图只是举例
                  实际中可能有很多震荡的走势，没有经验的人是很难去判断的。但是只要遵守交易纪律就算碰到震荡行情也只是小亏而已。
                </p>
                <p>图形参数：</p> <p>以30分钟K线为标准</p>
                <p>
                  以8、21、65线来看趋势的形成，144线当做多空趋势分割线。
                </p>{" "}
                <p>我们先从基本的一条MA做起：</p>
                <p>1. 上升的MA，价位在MA之上，择机做多绝不做空。</p>{" "}
                <p>2. 上升的MA，价位在MA之下，做空宜短打。</p>{" "}
                <p>3. 下降的MA，价位在MA之下，择机做空绝不做多。</p>{" "}
                <p>4. 下降的MA，价位在MA之上，做多宜短打。</p>{" "}
                <p>
                  5.
                  开始弯曲的第一次碰线，接近线，多是好的出击时机，停损设在穿越MA。
                </p>{" "}
                <p>6. 打平的MA，双面巴的几率高，观望为主。</p>{" "}
                <p className="subtitle">（一）入场方法</p>
                <p>
                  MA144让你舍弃自我主观意识，接受最笨的方法就是最好的方法。
                </p>{" "}
                <p>1. 144开始转上升，价格第一次压回碰线买多。</p>{" "}
                <p>2. 144开始转下降，价格第一次回升碰线卖空。</p>
                <p>3. 144约略打平，表示可能双面巴，新手还是观察。</p>{" "}
                <p>
                  <img src={jx02} />
                </p>
                <p>
                  出场时间随你高兴，算是练习进场的方法，没来碰线就不要做。
                </p>
                <p>
                  如此，可以让你学习至少不会大赔，然后慢慢学习价格穿过144线的各种形态、或是跌破144线的各种方式，以及碰线后上涨或下跌波段的各种形态。多看看过去的图形，自然体会出来波浪。
                </p>
                <p>
                  <img src={jx03} />
                </p>
                <p>
                  新手不要尝试第二次、第三次碰线的出击时机，因为逆转变化的可能性越来越高，风险越来越大。
                </p>
                <p>整个系统的窍门在于：</p>{" "}
                <p>抓住一条线的方向，一个发起基准点。</p>
                <p>坚持一个信念，绝对的服从和相信信任自己的交易系统</p>
                <p>工具的精神就是：宫本武藏一刀流，切割两个结局。</p>{" "}
                <p>不是A就是B，不然就是混沌未明，喝茶看戏。</p>
                <p>每天要做的第一个准备工作就是先看清楚：河床的结构。</p>
                <p>
                  原理很简单，线切割多与空，144均线是切割主要多与空区域，主区域再依据65线切割成65线的多空区域。趋势线方向往上时，于65线做多的获利空间相对高于做空。趋势往下时，于65线做空的获利空间相对高于做多。
                </p>
                <p>
                  不是【趋势往上只能做多，趋势往下只能做空】，而是自我调整获利空间的敏感度。进出场动作要快，砍仓快，抢回仓位也要快，当机立断不能犹豫，否则其后必乱，千万不要逆势摊平，那样被双面巴有份，乐趣没有份了。
                </p>
                <p>
                  不要怕买不到，卖不到，出场后可以根据情况随时可以再进场，心理上要一定要自由自在，才能适应交易市场中的翻脸如翻书。经济数据多会影响，不管是美国或欧洲各国的，不要理会太多经济数据，要搞懂经济数据，天都亮了，不用做单了。数据公布后，价格来回甩动时，冷静以对，等市场方向走出来后，才出手攻击，不要于数据公布时与他共舞，有时候会急涨转急跌，急跌转急涨，被双面巴两次就会很惨了。
                </p>
                <p className="subtitle">（二）停利的方法</p>{" "}
                <p>MA8 – MA21 – MA65</p>
                <p>
                  时间切割的原理，你需要更提早知道出场点，就是使用更细的时间切割。
                </p>
                <p>
                  进场点当然找手臂硬朗一点的144线来靠，出场反过来要找敏感一点的小手指头来用。
                </p>
                <p>
                  请自行把这两条线加上去，看交叉形态，你会察觉到：出场的信号就是开始形成下交叉，就该脚底抹油陆续出场。出场不一定是最高，下交叉后还是有可能上交叉。但是，你不要管他，还是分批一直出场。千万不要把上交叉当做144线之下的提早进场。还是乖乖地只在144线之上进场。出场才是依据短的两条线的下交叉信号，当然会比破144还要早。取代21线和65线的下交叉，也可以使用：破21线平仓一点，破65线再平仓其余。
                </p>
                <p>
                  以144线做进场，也同样是以144线为停损。以21线、65线作为停利，所以必然是获利状态中以21线、65线为基准来停利。
                </p>
                <p>
                  当然会发生，以144线进场买入后，会发生21线下交叉65线，那是因为价格压回碰144线时，会有可能出现21线下交叉65线，此一信号不是出场信号，你还是先以144线作为停损，等144线成功了，此时应该会出现21线大于65线的形态，然后才会有所谓21线下交叉的形态。
                </p>
                <p>
                  假若你还有更敏感的手指甲，那么再多一条MA8，分三批出场吧！
                </p>
                <p>破MA8出一小批，破21再出一小批，破65全出清。了解了吧！</p>
                <p>
                  界线分割多空，是以144线及65线来切割，不是21线。当你用21线来切割阴阳多空，被巴叫正常，赚钱叫奇怪。
                </p>
                <p>
                  逻辑前后，因果关系，要建立好前后一贯的一致性，可不要跳跃式的逻辑，否则使用规则起来会很乱很乱了！
                </p>
                <p>均线交叉的逻辑（空头）：</p> <p>1. K线破MA8；</p>{" "}
                <p>2. K线破MA21；</p> <p>3. MA8下交叉MA21；</p>{" "}
                <p>4. K线破MA65；</p> <p>5. MA8下交叉MA65；</p>{" "}
                <p>6. MA21下交叉MA65。</p>
                <p>
                  但是演化过程，不一定照着顺序来，中间多可能逆转，例如第三步之后，可能又站上MA21，然后MA8来准备上交叉MA21，然后又可能交叉失败。。。，太多种排列组合了。
                </p>
                <p>
                  当65线穿越144线，往往是一种新趋势的正式宣告。每当65线穿越144线之后，代表方向的改变，往往会有回马枪。手臂被硬转回去，不是不可能，只是发生的次数不多而已。
                </p>
                <p>MA8是用来数波浪数目用的。</p>{" "}
                <p>也可当成是最基本的浪花，观看重点是交叉或撞墙。</p>
                <p>MA8上（下）交叉MA21或者没有交叉变成撞到墙壁折返。</p>
                <p>MA8上（下）交叉MA65或者没有交叉变成撞到墙壁折返。</p>
                <p>MA8上（下）交叉MA144或者没有交叉变成撞到墙壁折返。</p>
                <p>
                  你可以观察到，交叉或撞墙以后，往往多会有一个明显的浪潮出来。就像海浪冲到岩礁，撞击后会激起一个大浪花。狼够大时，盖过去（等于是交叉）就继续往那个方向冲过去。
                </p>
                <p>
                  MA21用来作为攻击发起线用的。至少的必要条件，来作为攻击的信号。
                </p>
                <p>
                  MA144就好比多空分界，MA8、MA21、MA65的收敛和发散正是指明了发动的时机。
                </p>
                <p>
                  等在适当的位置，做正确的动作，不要预期将发生何事，只要依据定义的买进和卖出信号动作就好了。当知道要等待的是什么时，只要保持耐心，安静的观察，时机成熟时，果断进场。空单出场不表示多单进场。中间有一个模糊地带，也就是混沌地带。没有多空的预估，维持人在在线跑，也就是你人在MA144在线看世界，不是线图框框外面看世界。对你来说，只有跟着线跑，哪有多空的方向？耐心等待，像一个冲浪的选手，呆在水里，看到浪来啦！赶快起步冲啊冲，浪小啦！就停下来。这个浪就是8线、21线、65线的多种形态结构。
                </p>
                <p>
                  当你不意图获取最大利润时，三线的各种出场信号，多可以用，没有盲点的问题，只有少赚的问题。其工具设计上当然就不会有矛盾与盲点了。
                </p>
                <p className="subtitle">（三）K线形态</p>
                <p>
                  K线的画法，是依据开盘价和收盘价，用实体的红黑线表现出来，并把最高价和最低价用虚体的影线表现出来，依据其股价的表现，可分为阳线，阴线和十字线，其中柱状体部分称为实体，实体上下方凸出的直线称为影线。
                </p>
                <p>确认多方：长下影、长红、短下影中红。</p>{" "}
                <p>确认空方：长上影、长黑、短上影中黑。</p>
                <p>底部：以脚代表</p> <p>头部：以角代表</p>
                <p>旗杆：升旗台同K实体棒，旗杆为上影线</p>{" "}
                <p>熊市：熊打人由上往下打</p>
                <p>牛市：牛打人由下往上打</p>{" "}
                <p>看一根没把握，就再看一根K线。</p>
                <p>
                  遇到长下影，假如下一根红K，才能确认，长上影K线后，下一根是黑K，才确认。
                </p>
                <p>
                  心境无法平衡，肉是长不出来的。专业操作就是【随他便吧！与狼共舞！】不要想暴利，不要在意倍数，可以连续押宝赚到与原始出发资金数百、数千倍的情况，千万不要指望。
                </p>
                <p>那是赌博的运气，总有一天会把赚的吐回去。</p>
                <p>
                  自然就是美，顺其自然，你自然会获利。不要自己怪自己，找理由！越简单越好！
                </p>
                <p>
                  工具是让人学习顺流操作，顺着河流泛舟，行情自由它的走向，工具不是去预估行情，工具是一种地图，让你熟悉辨识河道、河床的结构，让你泛舟避开激流、漩涡、石头，让你安全的随波逐流。不是让你测量河道宽广、曲折。
                </p>
                <p>总之，熟练工具之后，所学会的是：顺势操作，舍弃自我。</p>
              </div>
            </div>
          </div>
        ) : which === 2 ? (
          <div className="main">
            <div className="school-header">
              <Link to="/school">致远学院</Link> &gt;&nbsp;
              <span>高手进阶</span>
            </div>
            <div className="school-detail">
              <div
                onClick={() => setWhich(null)}
                className="fh"
              >
                <img src={fh} width="70px" alt="返回" title="返回" />
              </div>
              <div className="school-detail-title">入市时间的选择</div>
              <div className="school-detail-content">
                <p>
                  我对对称三角形的定义是，在每日价格波动图形中，连接穿越中间高点和低谷的两条趋势线产生的图形式样。根据形成的三角形的大小，所需的日线数量可能不同，但我要求三角形至少包含28天，这个要求非常重要。顶的位置也非常关键。记住，我们的目标是除去尽可能多的主观因素并定义能遵从这一个模式的交易规则。
                </p>
                <p>
                  要画出产生三角形的趋势线，在形成的每边至少需要两个点作参考。因此，一个有效的模式一定包含至少4点作为参考，2个在顶端、2个在交互的序列底部（上图为例）。第1个点一定在主要趋势的方向形成。有时，在交易期间会发生对趋势线的小幅突破，但如果当日收盘价回到三角形态内，那么趋势线就是有效的。
                </p>
                <p>
                  对称三角形顶点的位置与其他众所周知的图形有所区分，如升序和降序三角形以及楔形，这些图形需要单独学习巩固。要判断一个三角形是否对称，将三角形自底部均分为3部分。在距离底部上方1/3处画一条线，自顶部向下1/3处再画一条线，如果三角形的顶点处于两条线的中央，则认为这个三角形是“对称的”。我建议阅读John
                  Murphey的《期货市场技术分析》一书，对更详细地理解其他形式的三角形和楔形有所帮助。
                </p>{" "}
                <p>
                  <img src={rs00} />
                </p>
                <p>
                  如果顶点发生在上1/3部分，这可能是一个警告，因为市场在完成走势之前可能还有一个下移的动作。同样，如果顶点出现在下1/3部分，这可能是一个警告，因为市场完成走势之前还有一个上移的动作。如果你决定在危险地区交易三角形形态，并认为这是一个对称三角形，那么你可以考虑使用买入跨式期权straddle，而不是直接进行期货合约的交易，以减少双边亏损出现的可能性。
                </p>
                <p>
                  我发现三角形突破往往发生在一个阶段性高点/低点起的28天内，如咖啡期货日线图（下图）所示。
                </p>{" "}
                <p>
                  <img src={rs01} />
                </p>
                <p>
                  一般来说最终的突破是与早在28天前的阶段性顶部/底部的形成同一方向，但也有例外的时候，如下图的可可豆走势所示，早在28天前形成阶段性底部，但最终突破方向相反。
                </p>
                <p>
                  <img src={rs02} />
                </p>
                <p>
                  生牛期货三角形日线图显示突破发生在首个阶段性高点后的第29天，而图表中小三角的突破发生在高点后的第14天，这仅为28天周期的一半。这个规律为你提供了一种巨大的优势以超越其他交易者，而他们还不知道它的存在。
                </p>
                <p>
                  <img src={rs03} />
                </p>
              </div>
            </div>
          </div>
        ) : which === 3 ? (
          <div className="main">
            <div className="school-header">
              <Link to="/school">致远学院</Link> &gt;&nbsp;
              <span>高手进阶</span>
            </div>
            <div className="school-detail">
              <div
                onClick={() => setWhich(null)}
                className="fh"
              >
                <img src={fh} width="70px" alt="返回" title="返回" />
              </div>
              <div className="school-detail-title">建立波段交易系统</div>
              <div className="school-detail-content">
                <p>（一）选择机会品种</p>
                <p>
                  每个品种在走出一波行情之初会有以下共同特性：1.持仓量在某个价格区间放大，成交量相对温和放大或缩小。2.价格形态表现为对原有趋势的调整状态。3.往往从图形形态上出现典型旗形、矩形、头肩形、收缩形态等。4.国内外相关品种联动。
                </p>
                <p>
                  图1为沪铜指数和伦铜在2012年2月至4月走势对比。由图可见，持仓量、成交量均出现放大，价格处于对原有上涨趋势的收缩调整形态，内外盘走势基本一致。图2是2012年1月至6月的国内连豆粕指数和美豆指数走势对比，其走势也基本符合以上条件。
                </p>
                <p>图1 沪铜指数和伦铜走势对比</p>{" "}
                <p>
                  <img src={pd01} />
                </p>
                <p>图2连豆粕和美豆走势对比</p>{" "}
                <p>
                  <img src={pd02} />
                </p>
                <p>（二）找准进场时机</p>
                <p>
                  投资期货贵在找准廉价止损点和最佳进场点。止损幅度要小，一旦行情展开，利润空间较大。但行情调整时间没有严格界限，什么位置是好进场点，需要具备以下几个特点：1.原有的调整形态被突破，即重要的阻力位或支撑位被突破。2.突破时伴有足够量能。3.重要的均线同时被突破。4.基本面信息也给予配合。5.国内外相关品种互相配合。图3是沪铜的最佳进场点（止损点是期价重新回到原来支撑位现在阻力位以上）。图4揭示的是豆粕1301合约和美豆最佳进场点。止损均以期价重新回到原来阻力位现在支撑位以下。
                </p>
                <p>图3 沪铜突破最佳进场点</p>{" "}
                <p>
                  <img src={pd03} />
                </p>{" "}
                <p>图4 豆粕1301和美豆最佳进场点对比</p>{" "}
                <p>
                  <img src={pd04} />
                </p>
                <p>（三）坚守有利持仓</p>
                <p>
                  在期货市场，大多数人有一个赔钱的共性——敢于持有被套亏损头寸，而不能坚守有利的盈利头寸。这主要是心理问题，原来一直亏损，亏怕了，有利润就想落袋为安，生怕煮熟的鸭子飞了。被套单子总幻想不亏或少亏，最终酿成大错。这种表现归根结底是没有坚守有利仓位的策略和方法。此外，也没有一个支持持仓的环境。如果一个投资者周围10个朋友都做短线，其很难做成波段。若想建立一套波段交易系统，就需要和做波段及长线的投资者交朋友。人的成长需要好环境，投资也是这样。
                </p>
                <p>
                  坚守有利持仓的方法其实很简单，涨势或跌势陡峭的市场就用10天均线做持仓跟踪线，涨势或跌势趋缓的市场就用20天均线做持仓跟踪线。
                </p>
                <p>（四）择机适度加码</p>
                <p>
                  利润=盈利手数×盈利幅度×合约乘数－亏损手数×亏损幅度×合约乘数。由公式可以看出，由于合约乘数是固定的，要想让利润最大化，就需要尽量增加盈利幅度，减少亏损幅度，增加盈利单子手数，减少亏损单子手数。明确地说，就是让利润飞奔，把亏损限于最小，盈利时适量加大头寸，亏损时减少头寸。
                </p>
                <p>
                  加码的方法有两种：一是均线加码法。如果价格呈现比较陡峭走势，当价格经历一段运行后，期价重新回踩10天均线，未跌破，待价格重返5天均线以上时加码介入（坚持金字塔式右侧交易加码方法），一旦期价在加码后跌破10天均线则果断止损离场。走势趋缓的可参照20天均线。二是形态加码法。一般在趋势行情中会出现调整形态，当这个形态沿着原有趋势方向突破时（有量能配合更佳）进行加码（坚持金字塔式右侧交易加码方法），止损条件是期价重新收盘回至调整形态以内。
                </p>
                <p>（五）减仓利润保护</p>
                <p>
                  盈利减仓当然对于每个人都是特别惬意的事，这里也有几个原则：1.暴利减仓法，是指在进场3到5个交易日，保证金利润回报突破50%以上，当小时图表出现期价和指标背离(macd)时最少减仓三分之一。2.期价过于远离30天均线，幅度达到15%以上，且上涨时途中出现阴线就减仓或下跌时出现阳线就减仓。3.在强涨势中跌破10天均线减仓，急跌市中突破10天均线减仓。缓跌缓涨趋势以20天线作参考。
                </p>
                <p>（六）转势果断离场</p>
                <p>
                  俗话说，会买是徒弟，会卖是师傅。实际上减仓也是卖出的方法之一。转势果断离场，这里的转势是指原有趋势转向，短期不再延续，并不代表市场真正从涨势转为跌势或从跌势转为涨势。在一个强势上涨（下跌）过程中，期价跌破（突破）20天均线，果断平仓出局。一个趋缓的趋势以30天均线为准。
                </p>
                <p>
                  建立一套良好的波段交易系统的关键在于坚持，形成一种行为的潜意识，只有这样，才会逐渐从亏损走向不亏，从小盈走向大盈，最终稳定盈利。
                </p>
                <p>
                  其实做交易有点像修行成佛的历程，第一阶段，戒。交易者要知道什么不可以做，比如不可逆势，不要不设止损就开仓。第二阶段，定。交易者自觉地“淡定”了，知道什么是可以干的，什么是不能干的。行为上能够“如如不动”，有行情做，没行情不做;看得明白做，看不明白不做;有时间做，没时间不做。第三阶段，慧。经历了行为上的“戒”，又经历了“去我”的禅定，再步入认知自然法则的“我”。慧，就是识道，并依道而行而已。
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="main">
            <div className="school-header">
              <Link to="/school">致远学院</Link> &gt;&nbsp;
              <span>高手进阶</span>
            </div>
            <div>暂无数据</div>
          </div>
        )}
      </div>
    </div>
    <HomeFooter />
  </div>
  );
}

export default Page07;
