import React, { useState, useEffect } from "react";
import notice from "../../assets/images/trade/notice.svg";
import { Carousel, notification } from "antd";
import { useNavigate } from "react-router";
import Store from "@/store";
import { observer } from "mobx-react-lite";

const TradeHead = () => {
  const navigate = useNavigate();
  const [money, setMoney] = useState("");
  const [game, setGame] = useState("");
  const [prize, setPrize] = useState("");
  const [eagle, setEagle] = useState("");
  const [lucky, setLucky] = useState("");
  const [simulate, setSimulate] = useState("");
  const [noticeList, setNotice] = useState([]);

  useEffect(() => {
    const { money, game, prize, eagle, lucky } = Store.User.assets;
    setMoney(money);
    setGame(game);
    setPrize(prize);
    setEagle(eagle);
    setLucky(lucky);
  }, [Store.User.assets]);

  useEffect(() => {
    getNotice()
  }, []);

  const getNotice = () => {
    Store.News.getCarousel()
      .then(({ notices }) => {
        setNotice(notices);
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  return (
    <div className={"trade_blc"}>
      <div className={"notice_info"}>
        <img src={notice} alt="" />
        <Carousel
          className={"notice_box"}
          dots={false}
          swiping={false}
          autoplay
          infinite
        >
          {noticeList?.length > 0 && noticeList.map((item, key) => {
            return <p key={key}>{item.title}</p>;
          })}
        </Carousel>

        <a
          onClick={() => {
            navigate("/notice");
          }}
        >
          更多》
        </a>
      </div>
      <div className={"blc_info"}>
        {!Store.Quote.simulate ? (
          <div>
            <label>账户余额</label>
            <b className={"real"}>{money}</b>
          </div>
        ) : (
          <div>
            <label>模拟余额</label>
            <b>{game}</b>
          </div>
        )}
        <div>
          <label>礼金</label>
          <b>{prize}</b>
        </div>
        <div>
          <label>积分</label>
          <b>{eagle}</b>
        </div>
        
        <div className={"switch_btn"}>
          <div
            className={!Store.Quote.simulate ? "active" : ""}
            onClick={() => {
              setSimulate(false);
              Store.Quote.switchSimulate(false);
              Store.Futures.switchMoneyType(0)
            }}
          >
            实盘
          </div>
          <div
            className={Store.Quote.simulate ? "active" : ""}
            onClick={() => {
              setSimulate(true);
              Store.Quote.switchSimulate(true);
              Store.Futures.switchMoneyType(0)
            }}
          >
            模拟
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TradeHead);
