import React, { useState } from 'react';
import './index.scss';
import { Select } from 'antd';
import { Link } from 'react-router-dom';

const { Option } = Select;

const SimpleSelect = ({ label, defaultValue, options, link, goTo, type, onChange, onClick }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    onChange && onChange(value);
  }

  return (
    <div className="simple_select">
      <div className="select_title">
        <label>{label}</label>
        {link && <Link to={goTo}>{link}</Link>}
      </div>
      <Select
        className="select_box"
        value={selectedValue}
        onChange={handleSelectChange}
      >
        {options &&
          options.map((item, key) => (
            <Option
              onClick={() => {
                onClick && onClick(item);
              }}
              value={type === 'bank' ? item.bank : item.value}
              key={key}
            >
              {item.icon && 1} {type === 'bank' ? item.bank : item.value}
              {type === 'bank' && `[${item.cardNumber.substr(item.cardNumber.length - 4)}]`}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default SimpleSelect;
