import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "@/library/api";

export class News {
  banners = [];
  notices = [];

  constructor() {
    makeAutoObservable(this);
  }

  getCarousel() {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await EVENT.News.getCarousel();
        runInAction(() => {
          this.banners = result.carousels;
          this.notices = result.notices;
        });
        resolve(result);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
}
