import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { SimpleInput } from "../../components";
import { SimpleSelect } from "../../components";
import Linkage from "../../components/linkage";
import { bankName } from "../../components/tool";
import { notification } from "antd";
import { nameMask, removeAllSpace } from "../../../utils/index";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import Store from "@/store";

const AddBankCardV1 = (props) => {
  const [cardNumber, setCardNumber] = useState("");

  const { name } = Store.User.accountInfo;

  const submit = () => {
    EVENT.Account.addAlipay(cardNumber)
      .then(() => {
        notification.success({
          message: "支付宝绑定成功",
          onClose: () => Store.User.updateDetail(),
        });
        props.onCancel();
      })
      .catch(({ message }) => notification.error({ message }));
  };

  return (
    <Modal
      title={"添加支付宝"}
      visible={props.visible}
      className={"alertModal aboutPass addBankcard"}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={<div />}
    >
      <SimpleInput label={"开户姓名"} value={nameMask(name)} disable={true} />
      <SimpleInput
        label={"支付宝号"}
        placeholder={"请输入支付宝号"}
        type={"card"}
        onChange={(e) => {
          setCardNumber(e);
        }}
      />
      <div
        className={`confirm_btn ${cardNumber.length < 9 && "error"}`}
        onClick={submit}
      >
        添加
      </div>
    </Modal>
  );
};

export default observer(AddBankCardV1);
