import React,{useEffect,useState} from 'react';
import './index.scss';
import {Link} from 'react-router-dom';
import closeEyes from '../../../assets/images/userCenter/closeEyes.svg';
import openEyes from '../../../assets/images/userCenter/openEyes.svg';
import Store from "@/store";
import { useNavigate } from "react-router";
import { observer } from "mobx-react-lite";
import CSvg from '../../../utils/svg';

const MyAsset = () => {
    const isLogin = Store.User.isLogin;
    const navigate = useNavigate();
    const { username } = Store.User.accountInfo;
    const { game, prize, eagle, money } = Store.User.assets;
    const [show , setShow] = useState(false);

    useEffect(() => {
        if (!!localStorage.getItem('eye')) {
            //this.state.show = JSON.parse(localStorage.getItem('eye'))
            setShow(JSON.parse(localStorage.getItem('eye')))
        }
      }, []);
  
    return (
        <div className={'Asset'}>
                <div className="asset_title">
                    <h5>我的资产</h5>
                </div>
                <div className="asset_box">
                    <div className={'asset_list'}>
                        <div className={'left'}>
                            <p>
                                <label>账户余额</label>
                                <b onClick={()=>{
                                    setShow(!show)
                                    localStorage.setItem('eye', !show);
                                }}><CSvg src={show?openEyes:closeEyes} alt=""/><span>{show?'隐藏':'显示'}余额</span></b>
                            </p>
                            <b>{show?money:'*****'} <span>CNY</span></b>
                        </div>
                        <div className={'right'}>
                            <Link to={'/account/recharge'}>充值</Link>
                            <Link to={'/account/withdraw'}>提现</Link>
                        </div>
                    </div>
                    <div className={'asset_list'}>
                        <div className={'left'}>
                            <p><label>礼金余额</label></p>
                            <b>{show?prize:'*****'}</b>
                        </div>
                        <div className={'right'}>
                            <Link to={{pathname:'/account/fundsDetail'}} state={5}>查看明细</Link>
                        </div>
                    </div>
                    <div className={'asset_list'}>
                        <div className={'left'}>
                            <p><label>积分余额</label></p>
                            <b>{show?eagle:'******'}</b>
                        </div>
                        <div className={'right'}>
                            <Link to={{pathname:'/account/fundsDetail'}}  state={6}>查看明细</Link>
                        </div>
                    </div>
                </div>
            </div>
    );
  };
  
  export default observer(MyAsset);
  
