import React, { useState, useEffect } from "react";
import './detail.scss';

import { formatDate } from "@/utils";
import { Pagination, notification } from 'antd';
import { Select } from 'antd';
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import funds from '../../../assets/images/funds_light.png';
import { RESTRICT } from "@/library/standard";
const { Option } = Select;

const TradeDetail = (props) => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);

    useEffect(() => {
        getRecord(page);
    }, []);

    useEffect(() => {
        getRecord(page);
    }, [page]);

    const getRecord = (page) => {
        EVENT.History.getRecordHistory(2, 10, "", page)
            .then(({ data, total }) => {
                setList(data);
                total = Number(total)
                setTotal(total);
            })
            .catch((err) => {
                notification.error({ message: err });
            });
    };

    return (
        <div id={'Detail'}>
            <ol className={'trade_ol'}>
                <li className={'li_title'}>
                    <div className={'long'}>ID</div>
                    <div className={'income'}>金额(元)</div>
                    <div className={'longer'}>明细</div>
                    <div>账户余额</div>
                    <div className={'longer'}>时间</div>
                </li>
                {
                    !!list && list.length !== 0 ? (
                        list.length !== 0 && list.map((item, key) => {
                            return (
                                <li key={key}>
                                    <div className={'long'}>{item.id}</div>
                                    <div className={`${item.type === 100 ? 'red' : 'green'} income`}>{item.type === 100 ? '+' : '-'}{RESTRICT.float(item.money, 2)}</div>
                                    <div className={'longer'}>{item.explain}</div>
                                    <div>{RESTRICT.float(item.assetMoney, 2)}</div>
                                    <div className={'longer'}>{formatDate('y-m-d h:i:s', { date: item.createTime })}</div>
                                </li>
                            )
                        })
                    ) : (
                        <div className={'empty'}>
                            <div>
                                <img src={funds}  />
                                <b>暂无记录</b>
                            </div>
                        </div>
                    )
                }
            </ol>
            <div className={'pagination'}>
                <Pagination
                    onChange={(page) => {
                        setPage(page)
                    }}
                    current={page}
                    total={total}
                    defaultPageSize={10}
                />
            </div>
        </div>
    );
};

export default observer(TradeDetail);

