import React, { useState, useEffect } from 'react';
import './index.scss';
import { cityData, provinceData } from '../../../utils/address';
import { Select } from 'antd';

const { Option } = Select;

export default function Linkage(props) {
  const [provinceValue, setProvinceValue] = useState(props.defaultProvince || '选择省份');
  const [cityValue, setCityValue] = useState(props.defaultCity || '选择城市/地区');
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState('');

  useEffect(() => {
    if (provinceValue !== '选择省份') {
      setCityList(cityData(provinceValue, true));
    }
  }, [provinceValue]);

  const handleProvinceChange = (e) => {
    setProvinceValue(e);
    const cityList = cityData(e, true);
    setCityList(cityList);
    setCityValue(cityList[0].value);
    setCity(cityList[0].value);
    props.getProvince(e);
    props.getCity(cityList[0].value);
  };

  const handleCityChange = (e) => {
    setCityValue(e);
    props.getCity(e);
  };
  
  return (
    <div className="Zone">
      <div className="simple_select">
        <div className="select_title">
          <label>省份</label>
        </div>
        <Select
          className="select_box"
          value={provinceValue}
          onChange={handleProvinceChange}
        >
          {provinceData(true).map((item, key) => (
            <Option value={item.value} key={key}>
              {item.value}
            </Option>
          ))}
        </Select>
      </div>
      <div className="simple_select">
        <div className="select_title">
          <label>城市/地区</label>
        </div>
        <Select
          className="select_box"
          value={cityValue}
          disabled={!provinceValue}
          onChange={handleCityChange}
        >
          {cityList.map((item, key) => (
            <Option value={item.value} key={key}>
              {item.value}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
}
