import React, { useState, useEffect, useRef } from "react";
import "../changeLoginPass/index.scss";
import { Modal } from "antd";
import { SimpleInput } from "../../../components";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import EVENT from "@/library/api";
import { ImgValide } from "@/view/components/index";
import { Toast } from "antd-mobile";

const BindPhone = (props) => {
  const { handleModal } = props;

  const timer = useRef();
  const type = "BIND_PHONE";
  const [account, setAccount] = useState("");
  const [code, setCode] = useState("");
  const [countText, setCountText] = useState("获取验证码");
  const [countNumber, setCountNumber] = useState(0);
  const [codeShow, setCodeShow] = useState(false);

  const bindPhone = () => {
    EVENT.Account.verifySMS(account, code, type)
      .then(() => {
        EVENT.Account.bindPhone(account)
          .then(() => {
            Toast.show({
              content: "手机号绑定成功",
              afterClose: () => handleModal(),
            });
            Store.User.updateDetail();
          })
          .catch(({ message }) => Toast.show(message));
      })
      .catch(({ message }) => Toast.show(message));
  };

  useEffect(() => {
    if (countNumber < 0) {
      setCountText("获取验证码");
      clearInterval(timer.current);
    } else {
      countDown(countNumber);
    }
  }, [countNumber]);

  const showCode = (show) => {
    setCodeShow(show);
  };

  const sendCodeStatus = (val) => {
    if (val) setCountNumber(120);
  };

  //todo 倒计时
  const countDown = () => {
    timer.current = setTimeout(() => {
      setCountNumber((time) => time - 1);
      setCountText(`${countNumber}秒后重发`);
    }, 1000);
  };

  return (
    <>
      <Modal
        title={"绑定手机"}
        visible={props.visible}
        className={"alertModal aboutPass"}
        onCancel={props.onCancel}
        destroyOnClose={true}
        footer={<div />}
      >
        <SimpleInput
          label={"手机号码"}
          placeholder={"请输入手机号码"}
          type={"tel"}
          format={"PHONE"}
          notice={"tel"}
          maxLength={11}
          onChange={(e, format) => {
            setAccount(e);
          }}
        />
        <div className={"sendCode"}>
          <SimpleInput
            label={"验证码"}
            placeholder={"请输入验证码"}
            format={"GLOBAL_CAPTCHA"}
            notice={"msgCode"}
            type={"verifyCode"}
            onChange={(e, format) => {
              setCode(e);
            }}
          />
          <div className={"getCode"} onClick={() => showCode(true)}>
            {countText}
          </div>
        </div>
        <div
          className={`confirm_btn ${
            (account.length < 1 || code.length < 1) && "error"
          }`}
          onClick={() => {
            bindPhone();
          }}
        >
          确认
        </div>
      </Modal>
      {codeShow && (
        <ImgValide
          hide={() => setCodeShow(false)}
          account={account}
          sendCodeStatus={sendCodeStatus}
          type={type}
        />
      )}
    </>
  );
};

export default observer(BindPhone);
