import React,{useEffect,useState} from "react";
import { formatDate } from "@/utils";
import { Copy } from "../../components/copy/copy";
import position_bl from "../../../assets/images/position_black.png";
import { RESTRICT } from "@/library/standard";
import { notification } from "antd";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import EVENT from "../../../library/api";

const Settlement = ({ array }) => {
  const [data, setList] = useState([]);
	const isSimulate = Store.Quote.simulate;

	useEffect(() => {
		getHistory();
	}, [isSimulate]);

	const getHistory = () => {
		EVENT.History.settleHistory()
			.then(({ data }) => {
				setList(
					data
				);
			})
			.catch((message) => notification.error({ message }));
	};

  return (
    <div className="position_list settlement_list">
    <div className="title">
      <i />
      <div className={"settle_long"}>商品名称</div>
      <div>模式</div>
      <div>手数/杠杆</div>
      <div>保证金</div>
      <div>开仓价</div>
      <div>平仓价</div>
      <div>盈亏(元)</div>
      <div className={"deferDays"}>过夜天数</div>
      <div className={"settle_short"}>开仓时间</div>
      <div className={"settle_short"}>平仓时间</div>
      <div className={"settle_long id"}>订单号</div>
    </div>
    <div className="list_box">
      {!!data && data.length !== 0 ? (
        !!data &&
        data.map((item, key) => {
          let rate = item.income.div(item.margin).mul(100),
            n =
              item.commodityCode.indexOf("USDT") === -1
                ? "futures"
                : "crypto";
          return (
            <div className={"list"} key={key}>
              <i className={item.isBuy ? "redB" : "greenB"} />
              <div className={`settle_long`}>
                {item.commodity}(
                {n === "futures" ? item.contCode : item.commodityCode})
              </div>
              <div>{item.moneyType === 0 ? "元" : "角"}</div>
              <div>{n === "futures" ? item.volume : `${item.lever}X`}</div>
              <div>{item.margin}</div>
              <div>{item.opPrice}</div>
              <div>{item.cpPrice}</div>
              <div className={rate > 0 ? "red" : "green"}>
                {n === "futures"
                  ? Math.round(item.income)
                  : RESTRICT.float(item.income, 2)}
              </div>
              <div className={"deferDays"}>{item.deferDays}</div>
              <div className={"settle_short"}>
                {formatDate("m-d h:i:s", { date: item.time })}
              </div>
              <div className={"settle_short"}>
                {formatDate("m-d h:i:s", { date: item.tradeTime })}
              </div>
              <div className={"settle_long id"}>
                <p>{item.id}</p>
                <Copy text={item.id} />
              </div>
            </div>
          );
        })
      ) : (
        <div className={"empty"}>
          <div>
            <img src={position_bl} alt="" />
            <b>暂无交易记录</b>
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default observer(Settlement);

