import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { observer } from "mobx-react-lite";
import Store from "@/store";
import FK from "@/core";
import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import { HomeFooter } from "../components";

const HomeSec = () => {
  const [notice, jumpNotice] = useState(false);
  useEffect(() => {
    FK.Launcher.CFD.startData();
    console.warn("22222");
    return () => FK.Launcher.CFD.endData();
  }, []);

  return (
    <div className={styles.home} id={"Home"}>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <HomeFooter />
    </div>
  );
};

export default observer(HomeSec);
