import { makeAutoObservable, action, autorun } from "mobx";
import { getFullNum } from "@/utils";
import FK from "@/core";
import Store from "./index";
import { getTradeViewData } from "../view/components/tvChart/chart/udf/streaming";
import Rest from "../library/rest";
import { RESTRICT } from "../library/standard";
import Brand from "../core/brand";

export class QuoteStore {
  //页面数据
  quote = {
    code: "--",
    des: "--",
    id: "--",
    coins: "--",
    currency: "--",
    simulate: false,
    name: "--",
    isUp: false,
    price: 0,
    open: "--",
    close: "--",
    rate: 0,
    percent: "0%",
  };
  //单个商品信息
  commodity = null;
  //商品手续费相关信息
  charge = null;
  //所有商品数组
  check = {};
  id = "";
  code = "";
  simulate = false;
  waitData = null;
  initial = false;

  constructor() {
    makeAutoObservable(this, {
      processing: action,
      getDepthData: action,
      getTurnoverData: action,
    });
  }

  //获取所有商品
  getAll(total) {
    this.check = total;
  }

  //获取默认商品ID
  setQuoteId(id) {
    this.id = id;
    return new Promise(async (resolve) => {
      if (Object.keys(this.check).length !== 0 && this.check[id] !== null) {
        this.code = this.check[this.id].config.contractCode;
        this.commodity = this.check[this.id].config;
        if (Store.User.isLogin && Object.keys(this.check[this.id].charge).length !== 0) {
          this.charge = this.check[this.id].charge;
          if (Brand.code === "CX") {
            Store.Position.processList();
          }
          resolve(Store.Futures.getConfig(this.commodity, this.charge));
        } else {
          this.waitData = setTimeout(() => this.setQuoteId(this.id), 100);
        }
      } else {
        this.waitData = setTimeout(() => this.setQuoteId(this.id), 100);
      }
    });
  }

  //切换商品
  switch(id) {
    this.id = id;
    this.code = this.check[this.id].config.contractCode;
    this.commodity = this.check[this.id].config;
    if (Store.User.isLogin) {
      this.charge = this.check[this.id].charge;
      Store.Futures.getConfig(this.commodity, this.charge);
    }
    FK.Launcher.CFD.switchQuoteData();
  }

  //切换 true 实盘  false 模拟
  switchSimulate(status) {
    this.simulate = status;
  }

  //退出页面初始化参数
  quoteInitial() {
    this.quote = {
      code: "- -",
      des: "- -",
      id: "- -",
      coins: "- -",
      currency: "- -",
      simulate: false,
      name: "- -",
      isUp: false,
      price: 0,
      open: "- -",
      close: "- -",
      rate: 0,
      percent: "0%",
      isOpen: false,
    };
  }

  //trade 页面数据展示
  processing(data) {
    try {
      if (data === null) return;
      autorun(() => getTradeViewData(data));
      if (this.commodity === null) return;
      const o = JSON.parse(data);
      const { priceDigit, name, spread, contractCode, code, coins, currency } = this.commodity;
      if (o.symbol !== contractCode || o.price === undefined) return;
      //点差
      let sell_price, buy_price;
      if (spread === 0) {
        sell_price = o.sellPrice;
        buy_price = o.buyPrice;
      } else if (spread === -1) {
        sell_price = o.price;
        buy_price = o.price;
      } else {
        sell_price = o.price.add(spread);
        buy_price = o.price.sub(spread);
      }
      const prev = o.prev || o.settle_price_yes || o.close || o.c; //o.c = price
      let rate = getFullNum(o.c).sub(getFullNum(prev));
      const isUp = o.price > prev;
      let percent = rate.div(getFullNum(prev));
      rate = `${isUp ? "+" : ""}${rate.toFixed(priceDigit)}`;
      percent = `${isUp ? "+" : ""}${percent.mul(100).toFixed(2)}%`;
      const total = o.buyVolume.add(o.sellVolume);
// console.log("o ========>>>>>>", JSON.stringify(o))
      this.quote = {
        code: o.symbol,
        id: code,
        des: name.indexOf("USDT") !== -1 ? name.split("USDT")[0] : code,
        coins: coins,
        currency: currency,
        simulate: this.simulate,
        name: name,
        isUp: isUp,
        price: RESTRICT.float(getFullNum(o.c), priceDigit),
        open: o.open || o.price,
        close: o.close || o.price,
        rate: rate,
        percent: percent,
        max: o.max ? o.max.toFixed(priceDigit) : "--",
        min: o.min ? o.min.toFixed(priceDigit) : "--",
        high_limit: o.high_limit ? o.high_limit.toFixed(priceDigit) : "--",
        low_limit: o.low_limit ? o.low_limit.toFixed(priceDigit) : "--",
        settle_price: o.settle_price ? o.settle_price.toFixed(priceDigit) : "--",
        settle_price_yes: o.settle_price_yes ? o.settle_price_yes.toFixed(priceDigit) : "--",
        hold_volume: o.hold_volume || 0,
        totalVolume: o.volume,
        buyPrice: sell_price.toFixed(priceDigit),
        buyVolume: o.buyVolume,
        buyWidth: o.buyVolume ? o.buyVolume.div(total) || 1 : 0,
        sellPrice: buy_price.toFixed(priceDigit),
        sellVolume: o.sellVolume,
        sellWidth: o.sellVolume ? o.sellVolume.div(total) || 1 : 0,
        isOpen: Rest.isOpening(this.commodity),
      };
      this.initial = true;
      Store.Futures.update(this.quote);
      if (Brand.code === "CX") {
        Store.Spot.update(this.quote);
      }
    } catch (e) {
      console.log(e, "quoteStore");
    }
  }

  //depht 深度图
  getDepthData(data) {
    if (data === null) return;
    const o = JSON.parse(data);
    Store.Spot.getDepth(o.asks, o.bids);
  }

  getTurnoverData(data) {
    if (data === null) return;
    data = data.split(",");
    Store.Spot.getTurnover(data);
  }
}
