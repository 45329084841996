import React, { useState, useEffect, useRef } from "react";
import "../loginNew/index.scss";
import { Link, useNavigate } from "react-router-dom";
import { Input, Toast } from "antd-mobile";
import { Header } from "@/view/components/index";
import EVENT from "@/library/api";
import Store from "@/store";
import USA from "../../../assets/images/home/USA.svg";
import { ImgValide, SimpleInput } from "../../components";
//图片
import checkIcon from "../../../assets/images/common/check.svg";
import CSvg from "../../../utils/svg";
import { safeValue } from "@/library/safety";
import { getCookie } from "@/utils";
import { notification } from "antd";
import CsAlert from "../../components/CsAlert";

function RegisterNew() {
  const navigate = useNavigate();
  const timer = useRef();
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("MOBILE");
  const [error, setError] = useState(true);
  const [check, setCheck] = useState(false);
  const [count, setCount] = useState("获取验证码");
  const [code, setCode] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [countNumber, setCountNumber] = useState(0);
  const [codeShow, setCodeShow] = useState(false);

  useEffect(() => {
    if (countNumber < 0) {
      setCount("获取验证码");
      clearInterval(timer.current);
    } else {
      countDown(countNumber);
    }
  }, [countNumber]);

  const sendCodeStatus = (val) => {
    if (val) setCountNumber(120);
  };

  //todo 倒计时
  const countDown = () => {
    timer.current = setTimeout(() => {
      setCountNumber((time) => time - 1);
      setCount(`${countNumber}秒后重发`);
    }, 1000);
  };

  const submit = () => {
    if (account == "") {
      return Toast.show("请输入账号");
    }
    if (password == "") {
      return Toast.show("请输入密码");
    }
    if (code == "") {
      return Toast.show("请输入验证码");
    }
    if (check === false) {
      notification.error({ message: "请勾选并阅读《致远服务条款》" });
      return;
    }
    EVENT.Account[type === "MOBILE" ? "verifySMS" : "verifyEmail"](
      account,
      code,
      "REGISTER"
    )
      .then(() => register())
      .catch((e) => notification.error({ message: e.message, duration: 2 }));
  };

  const register = () => {
    const data = {
      account,
      password: password,
      countryCode: "",
      refer: inviteCode,
    };
    EVENT.Account.register(data)
      .then(() => {
        EVENT.Account.login({ account, password: password }).then(
          ({ user, message }) => {
            Store.User.loginCallback(user);
            notification.success({
              message,
              duration: 2,
              onClose: () => {
                navigate("/");
              },
            });
          }
        );
      })
      .catch((e) => notification.error({ message: e.message, duration: 2 }));
  };

  const verify = () => {
    return account != "" && code != "" && password != "" ? true : false;
  };

  return (
    <div id={"Login"}>
      <div className="switch_btn">
        <div
          className={type === "MOBILE" ? "active" : ""}
          onClick={() => {
            setType("MOBILE");
            setAccount("");
            setPassword("");
            setCode("");
          }}
        >
          手机注册
        </div>
        <div
          className={type !== "MOBILE" ? "active" : ""}
          onClick={() => {
            setType("EMAIL");
            setAccount("");
            setPassword("");
            setCode("");
          }}
        >
          邮箱注册
        </div>
      </div>
      {type === "MOBILE" ? (
        <MobileReg
          account={account}
          password={password}
          setAccount={setAccount}
          setPassword={setPassword}
          code={code}
          setCode={setCode}
          count={count}
          setCount={setCount}
          setInviteCode={setInviteCode}
          inviteCode={inviteCode}
          showCode={() => countNumber <= 0 && setCodeShow(true)}
        />
      ) : (
        <EmailReg
          account={account}
          password={password}
          setAccount={setAccount}
          setPassword={setPassword}
          code={code}
          setCode={setCode}
          count={count}
          setCount={setCount}
          setInviteCode={setInviteCode}
          inviteCode={inviteCode}
          showCode={() => countNumber <= 0 && setCodeShow(true)}
        />
      )}
      <div className={"link_box"}>
        <a>
          <div
            className={`check_box ${!check && "no_check"}`}
            onClick={() => {
              setCheck(!check);
            }}
          >
            {check && <CSvg src={checkIcon} />}
          </div>
          我已阅读并同意 <Link to={"/agreement"}>致远服务条款</Link>
        </a>
        <a href={"/aboutUs#qualification"}>
          <img src={USA} alt={""} /> 美国金融监管机构权威认证
        </a>
      </div>
      <div
        className={`confirm_btn ${!verify() && "error"}`}
        onClick={() => submit()}
      >
        注册
      </div>
      <div className={"switch_type"}>
        <p>
          已有账户？<Link to={"/CommonLogin"}>立即登录</Link>
        </p>
      </div>
      <CsAlert />

      {codeShow && (
        <ImgValide
          account={account}
          type={"REGISTER"}
          hide={() => setCodeShow(false)}
          sendCodeStatus={sendCodeStatus}
        />
      )}
    </div>
  );
}

//Mobile Login
const MobileReg = ({
  setAccount,
  setPassword,
  account,
  password,
  code,
  setCode,
  count,

  inviteCode,
  setInviteCode,
  showCode,
}) => {
  const changeAccount = (account, format) => {
    setAccount(account);
  };
  const changePassword = (password, format) => {
    setPassword(password);
  };

  const changeCode = (code, format) => {
    setCode(code);
  };

  return (
    <div className={"mobile"}>
      <SimpleInput
        value={account}
        label={"手机号码"}
        placeholder={"请输入手机号码"}
        type={"tel"}
        format={"PHONE"}
        notice={"tel"}
        maxLength={11}
        onChange={changeAccount}
      />

      <SimpleInput
        value={password}
        label={"登录密码"}
        placeholder={"请输入登录密码"}
        format={"SET_PASSWORD"}
        notice={"password"}
        type={"password"}
        onChange={changePassword}
      />

      <div className={"sendCode"}>
        <SimpleInput
          value={code}
          label={"验证码"}
          placeholder={"请输入短信验证码"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          type={"verifyCode"}
          onChange={changeCode}
        />
        <div className={"getCode"} onClick={() => showCode(true)}>
          {count}
        </div>
      </div>

      <SimpleInput
        value={inviteCode}
        label={"邀请码（选填）"}
        placeholder={"请输入邀请码"}
        type={"text"}
        onChange={(e, format) => {
          setInviteCode(e);
        }}
      />
    </div>
  );
};

const EmailReg = ({
  setAccount,
  setPassword,
  account,
  password,
  autoF,
  count,
  code,
  setCode,
  inviteCode,
  setInviteCode,
  showCode,
}) => {
  const changeAccount = (account, format) => {
    setAccount(account);
  };
  const changePassword = (password, format) => {
    setPassword(password);
  };

  const changeCode = (code, format) => {
    setCode(code);
  };

  return (
    <div className={"email"}>
      <SimpleInput
        value={account}
        label={"邮箱"}
        placeholder={"请输入邮箱地址"}
        type={"email"}
        format={"EMAIL"}
        notice={"email"}
        onChange={changeAccount}
        autoFocus={autoF}
      />
      <SimpleInput
        value={password}
        label={"登录密码"}
        placeholder={"请输入登录密码"}
        format={"PASSWORD"}
        notice={"password"}
        type={"password"}
        onChange={changePassword}
      />
      <div className={"sendCode"}>
        <SimpleInput
          value={code}
          label={"验证码"}
          placeholder={"请输入邮箱验证码"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          type={"verifyCode"}
          onChange={changeCode}
        />
        <div className={"getCode"} onClick={() => showCode(true)}>
          {count}
        </div>
      </div>
      <SimpleInput
        value={inviteCode}
        label={"邀请码（选填）"}
        placeholder={"请输入邀请码"}
        type={"text"}
        onChange={(e, format) => {
          setInviteCode(e);
        }}
      />
    </div>
  );
};

export default RegisterNew;
