import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./index.scss";
import Store from "@/store";
import FK from "@/core";
import center from "../../assets/images/home/center.svg";
import logo from "../../assets/images/home/logo.svg";
import banner1 from "../../assets/images/home/banner/mockgold.png";
import banner2 from "../../assets/images/home/banner/registerGift2.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { getEXImg, goodsImg } from "../components/tool";
import up from "../../assets/images/home/up.svg";
import down from "../../assets/images/home/down.svg";
import bg from "../../assets/images/home/quotation_bg.png";
import { observer } from "mobx-react-lite";

const Section2 = () => {
  if (Store.Brief.hot === null) return;
  const { hotV2 } = Store.Brief;
  const array = Object.entries(Store.Brief.total);

  return (
    <div className={"Section2"}>
      <img src={bg} alt="" className={"bg"} />
      <div className="main">
        <div className="title">热门实时行情</div>
        <div className={"list"}>
          {hotV2.map((item, key) => {
            return array.map((i, k) => {
              if (item === i[0]) {
                return (
                  <div className={"goods_info"} key={key}>
                    <img src={goodsImg[i[1].id]} className={"code"} />
                    <div className="name">{i[1].name}</div>
                    <div className={"contract"}>{i[1].code}</div>
                    <div className={"price"}>
                      <div>
                        <b className={i[1].isUp ? "red" : "green"}>
                          {i[1].price}
                        </b>{" "}
                        <img src={i[1].isUp ? up : down} />
                      </div>
                    </div>
                    {i[1].isOpen ? (
                      <div className={`rate ${i[1].isUp ? "red" : "green"}`}>
                        <div>{i[1].gap}</div>
                        <div>{i[1].rate}</div>
                      </div>
                    ) : (
                      <div className={`rate rest`}>
                        <div>休市</div>
                      </div>
                    )}
                    <div className={`go_trade ${i[1].isOpen ? "" : "rest"}`}>
                      <Link
                        to={
                          Store.User.isLogin
                            ? `/trade/${i[1].id}`
                            : "/CommonLogin"
                        }
                        onClick={() => {
                          Store.Quote.switchSimulate(false);
                        }}
                      >
                        立即交易
                      </Link>
                    </div>
                  </div>
                );
              }
            });
          })}
        </div>
        <div className={"more"}>
          <Link
            to={Store.User.isLogin ? "/trade/CL" : "/CommonLogin"}
            onClick={() => {
              Store.Quote.switchSimulate(false);
            }}
          >
            查看更多行情>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default observer(Section2);
