import React, { useEffect, useState, useMemo } from "react";
import styles from "./index.module.scss";
import { Chart } from "./chart/chart";
import { observer } from "mobx-react-lite";
import FK from "@/core";
import { useParams } from "react-router-dom";

const TvChart = (props) => {
	const { id } = useParams();
	const [quoteId, setQuoteId] = useState();

	useEffect(() => {
		FK.Launcher.CFD.startData();
		initChart();

		return () => {
			FK.Launcher.CFD.endData();
			Chart.exit();
		};
	}, []);

	useEffect(() => {
		if (!id) return;
		if (quoteId && quoteId !== id) {
			Chart.swap({ code: id });
		}
		setQuoteId(id);
	}, [quoteId, id]);

	const RAISE = useMemo(() => {
		return "#D33B4F";
	}, []);
	const FALL = useMemo(() => {
		return "#1DAF9A";
	}, []);

	const initChart = () => {
		let overrides = {
			"mainSeriesProperties.areaStyle.linewidth": 2,
			"paneProperties.legendProperties.showLegend": true,
			"paneProperties.vertGridProperties.color": "transparent",
			"paneProperties.horzGridProperties.color": "transparent",
		};
		Object.assign(overrides, {
			"paneProperties.background": "#202020",
			"mainSeriesProperties.areaStyle.color1": "rgba(255, 204, 128, 0.05)",
			"mainSeriesProperties.areaStyle.color2": "rgba(19, 23, 34, 0.05)",
			"mainSeriesProperties.areaStyle.linecolor": "rgb(255, 167, 38)",
			"scalesProperties.textColor": "#7F8AA1",
			"scalesProperties.lineColor": "#475575",
		});
		//设置tradingview 分辨率默认收藏项
		// const key = "tradingview.IntervalWidget.quicks";
		// let o = Storage.getItem(key);
		// if (o === null) {
		// 	Storage.setItem(key, JSON.stringify(["1", "5", "30", "60", "240", "1D", "1W", "1M"]));
		// }
		new Promise((resolve) => {
			try {
				Chart.init({
					dom: "tradingView",
					code: id,
					autosize: true,
					contract: FK.Launcher.CFD,
					preset: "web",
					FALL: FALL,
					RAISE: RAISE,
					initType: 3,
					volume: true,
					overrides: overrides,
					theme: "Dark",
					css: "css/yfpc.css",
					studies_overrides: {
						"volume.volume.color.0": FALL,
						"volume.volume.color.1": RAISE,
						"volume.volume.transparency": 0,
					},
					disabled_features: [
						"timeframes_toolbar",
						"display_market_status",
						"header_compare",
						"symbol_info",
						"header_symbol_search",
						"area_chart",
						"line_tool",
					],
					enabled_features: [
						// "study_templates",
						"side_toolbar_in_fullscreen_mode",
					],
				});
				resolve();
			} catch (e) {
				console.log(e);
			}
		});
	};
	return <div id="tradingView" className={styles.tradingView} />;
};

export default observer(TvChart);
