import React, { useState, useEffect, useRef } from "react";
import "../changeLoginPass/index.scss";
import { Modal } from "antd";
import { SimpleInput } from "../../../components";
import { mobileMask } from "../../../components/tool";
import { Toast } from "antd-mobile";
import Store from "@/store";
import EVENT from "@/library/api";
import { ImgValide } from "@/view/components/index";

const OneStep = (props) => {
  let { mobile, showCode, countText, changeStep, type } = props;
  const [code, setCode] = useState("");
  const nextStep = () => {
    EVENT.Account.verifySMS(mobile, code, type)
      .then(() => changeStep(true))
      .catch(({ message }) => Toast.show({ content: message }));
  };
  return (
    <>
      <SimpleInput
        label={"手机号码"}
        value={mobileMask(mobile)}
        disable={true}
      />
      <div className={"sendCode"}>
        <SimpleInput
          label={"验证码"}
          placeholder={"请输入验证码"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          type={"verifyCode"}
          onChange={(e, format) => {
            setCode(e);
          }}
        />
        <div className={"getCode"} onClick={() => showCode(true)}>
          {countText}
        </div>
      </div>
      <div
        className={`confirm_btn ${code.length < 1 && "error"}`}
        onClick={
          code.length < 1
            ? null
            : () => {
                nextStep();
              }
        }
      >
        下一步
      </div>
    </>
  );
};

const TwoStep = (props) => {
  let { showCode, countText, oldPhone, getAccount, type , changeStep} = props;
  const [account, setAccount] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    getAccount(account);
  }, [account]);

  const bindPhone = () => {
    EVENT.Account.verifySMS(account, code, type)
      .then(() => {
        EVENT.Account.updatePhone(oldPhone, account)
          .then(() => {
            Toast.show({
              content: "手机号修改成功",
              afterClose: () => changeStep(),
            });
            Store.User.updateDetail();
          })
          .catch(({ message }) => Toast.show(message));
      })
      .catch(({ message }) => Toast.show(message));
  };

  return (
    <>
      <SimpleInput
        label={"新手机号码"}
        placeholder={"请输入新手机号码"}
        type={"tel"}
        format={"PHONE"}
        notice={"tel"}
        maxLength={11}
        onChange={(e, format) => {
          setAccount(e);
        }}
      />
      <div className={"sendCode"}>
        <SimpleInput
          label={"验证码"}
          placeholder={"请输入验证码"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          type={"verifyCode"}
          onChange={(e, format) => {
            setCode(e);
          }}
        />
        <div className={"getCode"} onClick={() => showCode(true)}>
          {countText}
        </div>
      </div>
      <div
        className={`confirm_btn ${
          (account.length < 1 || code.length < 1) && "error"
        }`}
        onClick={
          code.length < 1
            ? null
            : () => {
                bindPhone();
              }
        }
      >
        确认
      </div>
    </>
  );
};

function ChangePhoneNumber(props) {
  const timer = useRef();
  const [account, setAccount] = useState("");
  const oldPhone = Store.User.accountInfo?.mobile;
  const [countText, setCountText] = useState("获取验证码");
  const [countNumber, setCountNumber] = useState(0);
  const [codeShow, setCodeShow] = useState(false);
  const [next, setNext] = useState(false);
  const type = next ? "BIND_PHONE" : "CHANGE_PHONE";

  useEffect(() => {
    setAccount(Store.User.accountInfo.mobile);
    if (countNumber < 0) {
      setCountText("获取验证码");
      clearInterval(timer.current);
    } else {
      countDown(countNumber);
    }
  }, [countNumber, Store.User.accountInfo?.mobile]);

  const getAccount = (account) => setAccount(account);
  const nextStep = () => {
    setCountNumber(0);
    setNext(true);
  };
  const sendCodeStatus = (val) => {
    if (val) setCountNumber(120);
  };
  //todo 倒计时
  const countDown = () => {
    timer.current = setTimeout(() => {
      setCountNumber((time) => time - 1);
      setCountText(`${countNumber}秒后重发`);
    }, 1000);
  };

  const closeModal = () => {
    props.onCancel()
  }

  return (
    <>
      <Modal
        title={"修改手机"}
        visible={props.visible}
        className={"alertModal aboutPass"}
        onCancel={props.onCancel}
        afterClose={() => setNext(false)}
        destroyOnClose={true}
        footer={<div />}
      >
        {!next ? (
          <OneStep
            type={type}
            mobile={oldPhone}
            countText={countText}
            showCode={() => countNumber <= 0 && setCodeShow(true)}
            changeStep={nextStep}
          />
        ) : (
          <TwoStep
            type={type}
            countText={countText}
            oldPhone={oldPhone}
            showCode={() => countNumber <= 0 && setCodeShow(true)}
            getAccount={getAccount}
            changeStep={closeModal}
          />
        )}
      </Modal>
      {codeShow && (
        <ImgValide
          account={account}
          type={type}
          hide={() => setCodeShow(false)}
          sendCodeStatus={sendCodeStatus}
        />
      )}
    </>
  );
}

export default ChangePhoneNumber;
