import Brief from "../core/cfd/brief";
import Store from "@/store";
import { makeObservable, action } from "mobx";

export class DataStore {
  //存放brief的 total
  total = {};
  //记录对比
  check = {};

  constructor() {
    makeObservable(this, {
      processing: action,
    });
  }

  getAll(data) {
    this.check = data;
  }

  create(list) {
    if (list.length > 0) {
      for (let o of list) {
        if(this.check[o] !== undefined){
          if (this.total[o]) {
            this.total[o].updateCode(this.check[o].config.contractCode);
          } else {
            this.total[o] = new Brief(this.check[o].config);
          }
        }
      }
    }
    //todo 添加至Store
    Store.Brief.addData(this.total);
  }

  processing(data) {
    if (data === null) return;
    data = data.split(";");
    data = data.map((e) => e.split(","));
    for (let [code, ...rest] of data) {
      try {
        let id;
        isNaN(code) ? (id = this.getIdByCode(code)) : (id = code.substring(0, code.length - 4));
        if (this.total[id] && this.total[id].code === code) {
          this.total[id].insert(...rest);
          //计算涨跌金额
          this.total[id].calculateGap();
          //计算涨跌百分比
          this.total[id].calculateRate();
          //看商品是否在交易时间
          this.total[id].renew(this.check[id].config);
        }
        Store.Position.addData(this.total);
      } catch (err) {
        console.warn(err);
      }
    }
  }

  //去除合约号
  getIdByCode(code) {
    return code.replace(/\d+./g, "");
  }

  //单个价格查询
  getCurrent(id) {
    return this.total[id].price;
  }
}
