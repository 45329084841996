import React, { useEffect, useState, useMemo } from "react";
import "./index.scss";
import { SimpleInput } from "../../components";
import { notification } from "antd";

import { Toast } from "antd-mobile";
import EVENT from "@/library/api";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import { idMask, nameMask } from "../../../utils";

const NameVerify = () => {
	const { kyc, identityNumberValid, name, identityNumber } = Store.User.accountInfo;
	const [hasID, setHasID] = useState("");
	const [nName, setNName] = useState("");
	const [format_n, setFormat_n] = useState(false);
	const [id, setID] = useState("");
	const [format_id, setFormat_id] = useState(false);
	const isNameVerify = useMemo(() => identityNumberValid || kyc, [identityNumberValid, kyc]);
	const isValid = useMemo(() => {
		return format_n && format_id;
	}, [format_n, format_id]);

	useEffect(() => {
		setHasID(identityNumberValid);
	}, [identityNumberValid]);

	const submit = () => {
		if (!isValid) {
			return notification.error({ message: "身份证号码有误" });
		}
		EVENT.Account.nameVerification(nName, id)
			.then(() => {
				setHasID(true);
				Store.User.updateDetail();
				notification.success({ message: "实名认证成功" });
			})
			.catch((err) => {
				Toast.show(err.message);
			});
	};

	return (
		<div className={"NameVerify"}>
			<div className="verify_title">
				<h5>实名认证</h5>
				<div>
					<p>为了方便您快速通过审核，请确保您填写的信息与您证件上的信息一致</p>
				</div>
			</div>
			<div className={"main"}>
				<div className={"main_title"}>身份信息</div>
				<div className={"main_info"}>
					{isNameVerify || hasID ? (
						<>
							<div className={"verify_info"}>
								<label>姓名</label>
								<p>{nameMask(name)}</p>
							</div>
							<div className={"verify_info"}>
								<label>身份证号</label>
								<p>{idMask(identityNumber)}</p>
							</div>
							<div className={`confirm_btn error`}>已认证</div>
						</>
					) : (
						<>
							<SimpleInput
								label={"姓名"}
								placeholder={"请输入姓名"}
								format={"CNNAME"}
								notice={"cn_name"}
								type={"text"}
								maxLength={20}
								onChange={(e, format) => {
									setNName(e);
									if (format !== format_n) {
										setFormat_n(format);
									}
								}}
							/>
							<SimpleInput
								label={"身份证号"}
								placeholder={"请输入身份证号码"}
								format={"ID"}
								notice={"ID"}
								type={"id"}
								onChange={(e, format) => {
									setID(e);
									if (format !== format_id) {
										setFormat_id(format);
									}
								}}
							/>
							<div
								className={`confirm_btn ${(!id || !nName) && "error"}`}
								onClick={() => {
									if (id || nName) {
										submit();
									}
								}}
							>
								认证
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default observer(NameVerify);
