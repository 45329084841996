    //todo 银行卡图片
    import ICBC from "../../assets/images/userCenter/bank/bankcard/ICBC.svg";
    import CMB from "../../assets/images/userCenter/bank/bankcard/CMB.svg";
    import CCB from "../../assets/images/userCenter/bank/bankcard/CCB.svg";
    import ABC from "../../assets/images/userCenter/bank/bankcard/ABC.svg";
    import BOC from "../../assets/images/userCenter/bank/bankcard/BOC.svg";
    import COMM from "../../assets/images/userCenter/bank/bankcard/COMM.svg";
    import CMBC from "../../assets/images/userCenter/bank/bankcard/CMBC.svg";
    import SPDB from "../../assets/images/userCenter/bank/bankcard/SPDB.svg";
    import CITIC from "../../assets/images/userCenter/bank/bankcard/CITIC.svg";
    import GDB from "../../assets/images/userCenter/bank/bankcard/GDB.svg";
    import SZPAB from "../../assets/images/userCenter/bank/bankcard/SZPAB.svg";
    import CIB from "../../assets/images/userCenter/bank/bankcard/CIB.svg";
    import HXB from "../../assets/images/userCenter/bank/bankcard/HXB.svg";
    import CEB from "../../assets/images/userCenter/bank/bankcard/CEB.svg";
    import PSBC from "../../assets/images/userCenter/bank/bankcard/PSBC.svg";
    import RCB from "../../assets/images/userCenter/bank/bankcard/RCB.svg";
    import BCS from "../../assets/images/userCenter/bank/bankcard/BCS.svg";
    import CB from "../../assets/images/userCenter/bank/bankcard/CB.svg";
    import Ali from "../../assets/images/userCenter/bank/bankcard/ALIPAY.svg";

    import wechat from '../../assets/images/userCenter/payment/wechat.svg';
    import alipay from '../../assets/images/userCenter/payment/alipay.svg';
    import unit from '../../assets/images/userCenter/payment/unit.svg';
    import yun from '../../assets/images/userCenter/payment/yun.svg';

    import AP from '../../assets/images/trade/goods/AP.svg';
    import AG from '../../assets/images/trade/goods/AG.svg';
    import BCH from '../../assets/images/trade/goods/BCH.svg';
    import BSV from '../../assets/images/trade/goods/BSV.svg';
    import BTC from '../../assets/images/trade/goods/BTC.svg';
    import CL from '../../assets/images/trade/goods/CL.svg';
    import CN from '../../assets/images/trade/goods/CN.svg';
    import CU from '../../assets/images/trade/goods/CU.svg';
    import DASH from '../../assets/images/trade/goods/DASH.svg';
    import DAX from '../../assets/images/trade/goods/DAX.svg';
    import DOT from '../../assets/images/trade/goods/DOT.svg';
    import EOS from '../../assets/images/trade/goods/EOS.svg';
    import ETH from '../../assets/images/trade/goods/ETH.svg';
    import ETC from '../../assets/images/trade/goods/ETC.svg';
    import ES from '../../assets/images/trade/goods/ES.svg';
    import FG from '../../assets/images/trade/goods/FG.svg';
    import GC from '../../assets/images/trade/goods/GC.svg';
    import HG from '../../assets/images/trade/goods/HG.svg';
    import HSI from '../../assets/images/trade/goods/HSI.svg';
    import IC from '../../assets/images/trade/goods/IC.svg';
    import IH from '../../assets/images/trade/goods/IH.svg';
    import IF from '../../assets/images/trade/goods/IF.svg';
    import JD from '../../assets/images/trade/goods/JD.svg';
    import Y from '../../assets/images/trade/goods/Y.svg';
    import LINK from '../../assets/images/trade/goods/LINK.svg';
    import LTC from '../../assets/images/trade/goods/LTC.svg';
    import M from '../../assets/images/trade/goods/M.svg';
    import MDAX from '../../assets/images/trade/goods/MDAX.svg';
    import MHI from '../../assets/images/trade/goods/MHI.svg';
    import NG from '../../assets/images/trade/goods/NG.svg';
    import NI from '../../assets/images/trade/goods/NI.svg';
    import NK from '../../assets/images/trade/goods/NK.svg';
    import NQ from '../../assets/images/trade/goods/NQ.svg';
    import P from '../../assets/images/trade/goods/P.svg';
    import TA from '../../assets/images/trade/goods/TA.svg';
    import RB from '../../assets/images/trade/goods/RB.svg';
    import RU from '../../assets/images/trade/goods/RU.svg';
    import SC from '../../assets/images/trade/goods/SC.svg';
    import SI from '../../assets/images/trade/goods/SI.svg';
    import SR from '../../assets/images/trade/goods/SR.svg';
    import TRX from '../../assets/images/trade/goods/TRX.svg';
    import XRP from '../../assets/images/trade/goods/XRP.svg';
    import YM from '../../assets/images/trade/goods/YM.svg';
    import USDT from '../../assets/images/trade/goods/USDT.svg';
    import I from '../../assets/images/trade/goods/I.svg';
    import AL from '../../assets/images/trade/goods/AL.svg';
    import LH from '../../assets/images/trade/goods/LH.svg';
    import ATOM from '../../assets/images/trade/goods/ATOM.svg';
    import AAVE from '../../assets/images/trade/goods/AAVE.svg';
    import FIL from '../../assets/images/trade/goods/FIL.svg';
    import ADA from '../../assets/images/trade/goods/ADA.svg';
    import UNI from '../../assets/images/trade/goods/UNI.svg';
    import HT from '../../assets/images/trade/goods/HT.svg';
    import XMR from '../../assets/images/trade/goods/XMR.svg';
    import ZEC from '../../assets/images/trade/goods/ZEC.svg';
    import XLM from '../../assets/images/trade/goods/XLM.svg';
    import ZRX from '../../assets/images/trade/goods/ZRX.svg';
    import BAT from '../../assets/images/trade/goods/BAT.svg';
    import KNC from '../../assets/images/trade/goods/KNC.svg';
    import YFI from '../../assets/images/trade/goods/YFI.svg';
    import MKR from '../../assets/images/trade/goods/MKR.svg';
    import XTZ from '../../assets/images/trade/goods/XTZ.svg';
    import OMG from '../../assets/images/trade/goods/OMG.svg';
    import CRV from '../../assets/images/trade/goods/CRV.svg';
    import SNX from '../../assets/images/trade/goods/SNX.svg';
    import REN from '../../assets/images/trade/goods/REN.svg';
    import SUSHI from '../../assets/images/trade/goods/SUSHI.svg';
    import ONT from '../../assets/images/trade/goods/ONT.svg';
    import NEO from '../../assets/images/trade/goods/NEO.svg';
    import PP from '../../assets/images/trade/goods/PP.svg';
    import HC from '../../assets/images/trade/goods/HC.svg';
    import SM from '../../assets/images/trade/goods/SM.svg';
    import DOGE from '../../assets/images/trade/goods/DOGE.svg';


    //日历重要性等级
    import star1 from "../../assets/images/college/1.svg";
    import star2 from "../../assets/images/college/2.svg";
    import star3 from "../../assets/images/college/3.svg";
    import star4 from "../../assets/images/college/4.svg";
    import star5 from "../../assets/images/college/5.svg";
    //日历 国家
    import xjp from '../../assets/images/college/NationalFlag/xjp.svg';
    import yd from '../../assets/images/college/NationalFlag/yd.svg';
    import xxl from '../../assets/images/college/NationalFlag/xxl.svg';
    import adly from '../../assets/images/college/NationalFlag/adly.svg';
    import xby from '../../assets/images/college/NationalFlag/xby.svg';
    import rs from '../../assets/images/college/NationalFlag/rs.svg';
    import hg from '../../assets/images/college/NationalFlag/hg.svg';
    import mg from '../../assets/images/college/NationalFlag/mg.svg';
    import jnd from '../../assets/images/college/NationalFlag/jnd.svg';
    import fg from '../../assets/images/college/NationalFlag/fg.svg';
    import hk from '../../assets/images/college/NationalFlag/hk.svg';
    import dg from '../../assets/images/college/NationalFlag/dg.svg';
    import ydl from '../../assets/images/college/NationalFlag/ydl.svg';
    import rb from '../../assets/images/college/NationalFlag/rb.svg';
    import zg from '../../assets/images/college/NationalFlag/zg.svg';
    import yg from '../../assets/images/college/NationalFlag/yg.svg';
    import tw from '../../assets/images/college/NationalFlag/tw.svg';
    import om from '../../assets/images/college/NationalFlag/om.svg';


    //交易所
    import USA from '../../assets/images/home/USA.svg';
    import UK from '../../assets/images/home/UK.svg';
    import JP from '../../assets/images/home/JP.svg';
    import SG from '../../assets/images/home/SG.svg';
    import ZH from '../../assets/images/home/CN.svg';
    import HK from '../../assets/images/home/HK.svg';
    import BMD from '../../assets/images/home/BMD.svg';

    /**
     * 选取交易所对应logo
     * */
    export const getEXImg = [
        {
            img:USA,
            ex:'NYMEX',
        },
        {
            img:USA,
            ex:'COMEX',
        },
        {
            img:USA,
            ex:'CME',
        },
        {
            img:UK,
            ex:'EUREX',
        },
        {
            img:SG,
            ex:'SGX',
        },
        {
            img:HK,
            ex:'HKEX',
        },
        {
            img:ZH,
            ex:'CFFEX',
        },
        {
            img:ZH,
            ex:'CFFEX',
        },

    ];

    export const goodsImg = {
        I:I,
        LH:LH,
        AL:AL,
        AG:AG,
        AP:AP,
        CL:CL,
        CN:CN,
        CU:CU,
        DAX:DAX,
        DOT:DOT,
        DOTUSDT:DOT,
        ES:ES,
        FG:FG,
        GC:GC,
        HG:HG,
        HSI:HSI,
        IC:IC,
        IH:IH,
        IF:IF,
        JD:JD,
        M:M,
        MDAX:MDAX,
        MHI:MHI,
        NG:NG,
        NI:NI,
        NK:NK,
        NQ:NQ,
        P:P,
        TA:TA,
        RB:RB,
        RU:RU,
        SC:SC,
        SI:SI,
        SR:SR,
        YM:YM,
        PP:PP,
        HC:HC,
        SM:SM,
        USDT:USDT,
        BCHUSDT:BCH,
        BCH:BCH,
        BSVUSDT:BSV,
        BSV:BSV,
        BTCUSDT:BTC,
        DASHUSDT:DASH,
        DASH:DASH,
        EOSUSDT:EOS,
        EOS:EOS,
        ETHUSDT:ETH,
        ETCUSDT:ETC,
        ETC:ETC,
        LINKUSDT:LINK,
        LINK:LINK,
        LTCUSDT:LTC,
        LTC:LTC,
        TRXUSDT:TRX,
        XRPUSDT:XRP,
        ATOMUSDT:ATOM,
        ATOM:ATOM,
        AAVEUSDT:AAVE,
        AAVE:AAVE,
        FILUSDT:FIL,
        FIL:FIL,
        XRP:XRP,
        Y:Y,
        TRX:TRX,
        ETH:ETH,
        BTC:BTC,
        ADAUSDT:ADA,
        ADA:ADA,
        UNIUSDT:UNI,
        UNI:UNI,
        HTUSDT:HT,
        HT:HT,
        XMRUSDT:XMR,
        XMR:XMR,
        ZECUSDT:ZEC,
        ZEC:ZEC,
        BATUSDT:BAT,
        BAT:BAT,
        KNCUSDT:KNC,
        KNC:KNC,
        YFIUSDT:YFI,
        YFI:YFI,
        MKRUSDT:MKR,
        MKR:MKR,
        XTZUSDT:XTZ,
        XTZ:XTZ,
        OMGUSDT:OMG,
        OMG:OMG,
        CRVUSDT:CRV,
        CRV:CRV,
        SNXUSDT:SNX,
        SNX:SNX,
        RENUSDT:REN,
        REN:REN,
        SUSHIUSDT:SUSHI,
        SUSHI:SUSHI,
        XLMUSDT:XLM,
        XLM:XLM,
        ZRXUSDT:ZRX,
        ZRX:ZRX,
        ONTUSDT:ONT,
        ONT:ONT,
        NEOUSDT:NEO,
        NEO:NEO,
        DOGE:DOGE,
        DOGEUSDT:DOGE

    };

    /**
     * 隐藏电话
     * */
    export function mobileMask(mobile) {
        if(mobile === undefined) return;
        return mobile.replace(/(\d{3}).+(\d{4})/, "$1****$2");
    }

    /**
     * 隐藏邮箱
     * */
    export function emailMask(email) {
        if(email === undefined) return;
        return email.replace(/(.{1}).+(.{1}@.+)/g,'$1****$2');
    }

    /**
     * 选取银行对应logo
     * */
    export function chooseBankLogo(name) {
        switch (name) {
            case "工商银行":
                return ICBC;
            case "招商银行":
                return CMB;
            case "建设银行":
                return CCB;
            case "农业银行":
                return ABC;
            case "中国银行":
                return BOC;
            case "交通银行":
                return COMM;
            case "民生银行":
                return CMBC;
            case "浦发银行":
                return SPDB;
            case "上海浦发银行":
                return SPDB;
            case "中信银行":
                return CITIC;
            case "广发银行":
                return GDB;
            case "平安银行":
                return SZPAB;
            case "兴业银行":
                return CIB;
            case "华夏银行":
                return HXB;
            case "光大银行":
                return CEB;
            case "邮政储蓄":
                return PSBC;
            case "农商银行":
                return RCB;
            case "长沙银行":
                return BCS;
            case "商业银行":
                return CB;
            case "支付宝":
                return Ali;
            default:
                return "";
        }
    }

    //todo 根据不同银行显示不同颜色
    export function color(name) {
        switch (name) {
            case '工商银行':
                return 'icbc';
            case '招商银行':
                return 'cmb';
            case '建设银行':
                return 'ccb';
            case '农业银行':
                return 'abc';
            case '中国银行':
                return 'boc';
            case '交通银行':
                return 'comm';
            case '民生银行':
                return 'cmbc';
            case '浦发银行':
                return 'spcb';
            case '中信银行':
                return 'citic';
            case '广发银行':
                return 'gbd';
            case '平安银行':
                return 'szpab';
            case '兴业银行':
                return 'cib';
            case '华夏银行':
                return 'hxb';
            case '光大银行':
                return 'ceb';
            case '邮政储蓄':
                return 'psbc';
            case '长沙银行':
                return 'bcs';
            case "支付宝":
                return "alipay";
            default:
                return "common";
        }
    }

    export function bankName() {
        return ([
            {
                label: "工商银行",
                value: "工商银行"
            },
            {
                label: "招商银行",
                value: "招商银行"
            },
            {
                label: "建设银行",
                value: "建设银行"
            },
            {
                label: "农业银行",
                value: "农业银行"
            },
            {
                label: "中国银行",
                value: "中国银行"
            },
            {
                label: "交通银行",
                value: "交通银行"
            },
            {
                label: "民生银行",
                value: "民生银行"
            },
            {
                label: "浦发银行",
                value: "浦发银行"
            },
            {
                label: "中信银行",
                value: "中信银行"
            },
            {
                label: "广发银行",
                value: "广发银行"
            },
            {
                label: "平安银行",
                value: "平安银行"
            },
            {
                label: "兴业银行",
                value: "兴业银行"
            },
            {
                label: "华夏银行",
                value: "华夏银行"
            },
            {
                label: "光大银行",
                value: "光大银行"
            },
            {
                label: "邮政储蓄",
                value: "邮政储蓄"
            },
            // {
            //     label: "农商银行",
            //     value: "农商银行"
            // },
            {
                label: "长沙银行",
                value: "长沙银行"
            },
            // {
            //     label: "商业银行",
            //     value: "商业银行"
            // }
        ])
    }

    export function paymentImg(name) {
        switch (name) {
            case '银行转账':
                return unit;
            case '支付宝转账':
                return alipay;
            case '云闪付':
                return yun;
            case '微信转账':
                return wechat;
                break
        }
    }

    /**
     * 隐藏银行卡或身份证
     * */
    export function bankNumMark(id) {
        if (!id) return;
        return id.replace(/(\d{4})(\d{5})(\d{6})(\d{4})/, `**** **** **** $4`);
    }

    //todo 国旗
    export function flag(name) {
        switch (name) {
            case '新加坡':
                return xjp;
            case '印度':
                return yd;
            case '新西兰':
                return xxl;
            case '澳大利亚':
                return adly;
            case '西班牙':
                return xby;
            case '瑞士':
                return rs;
            case '韩国':
                return hg;
            case '美国':
                return mg;
            case '加拿大':
                return jnd;
            case '法国':
                return fg;
            case '中国香港':
                return hk;
            case '德国':
                return dg;
            case '意大利':
                return ydl;
            case '日本':
                return rb;
            case '中国':
                return zg;
            case '英国':
                return yg;
            case '中国台湾':
                return tw;
            case '欧元区':
                return om;
        }
    }

    //todo 星
    export function star(num) {
        switch (num) {
            case '1':
                return star1;
            case '2':
                return star2;
            case '3':
                return star3;
            case '4':
                return star4;
            case '5':
                return star5;
                break;
        }
    }

    //获取状态
    export function statusText(status) {
        switch (status) {
            case -1:
                return '待处理';
            case 0:
                return '待处理';
            case 1:
                return '处理成功';
            case 2:
                return '处理失败';
            case 3:
                return '已取消';
            case 4:
                return '处理中';
            case 5:
                return '汇款中';
            case 6:
                return '已退款';
                break;
        }
    }

    //todo 拆分数组
    export function splitAry (arr,len){
        let arr_length = arr.length;
        let newArr = [];
        for(let i=0;i<arr_length;i+=len){
            newArr.push(arr.slice(i,i+len));
        }
        return newArr;
    }

    //todo 是否在提款时间
    export function checkTimeAvailable() {
        let start = new Date();
        let end = new Date();
        let now = new Date();
        //每日提现时间 09:00-21:00
        start.setHours(9);
        start.setMinutes(0);
        end.setHours(21);
        end.setMinutes(0);
        return (
            now.getTime() - start.getTime() > 0 && now.getTime() - end.getTime() < 0
        );
    }