let usd = 7;
export default {
  BP: {
    name: "英镑",
    code: "BP",
    currency: "美元(USD)",
    unit: "每手62,500英镑",
    volatility: "0.0001美元/英镑",
    volatilityIncome: "波动一次 = 6.25美元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午07:00:00 - 次日05:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午07:00:00 - 次日05:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "次日05:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所英镑期货，每手62,500英镑，最小波动6.25美元（相当于最小波动盈亏人民币${usd*(
      6.25
    )}}元），可买涨买跌。`,
    isTimeWinter: "false",
  },
  EC: {
    name: "欧元",
    code: "EC",
    currency: "美元(USD)",
    unit: "每手125,000欧元",
    volatility: "0.00005美元/欧元",
    volatilityIncome: "波动一次 = 6.25美元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午07:00:00 - 次日05:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午07:00:00 - 次日05:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "次日05:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所欧元期货，每手125,000欧元，最小波动6.25美元（相当于最小波动盈亏人民币${usd*(
      6.25
    )}元），可买涨买跌。`,
    isTimeWinter: "false",
  },
  ES: {
    name: "标普500",
    code: "ES",
    exchange: "芝加哥商品交易所(CME)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x50美元",
    volatility: "指数点x50美元",
    volatilityIncome: "0.25(指数点) = 12.5美元",
    buyTimeAMWinter:
      "冬令时：</br> 上午07:00:00 - 次日05:50:00",
    buyTimeAM:
      "夏令时：</br> 上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter:
      "冬令时：</br> 上午07:00:00 - 次日05:55:00",
    sellTimeAM:
      "夏令时：</br> 上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所小型道指期货，最小波动5美元（相当于最小波动盈亏人民币${usd*(
      5
    )}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  YM: {
    name: "小道指",
    code: "YM",
    exchange: "芝加哥期货交易所(CBOT)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x5美元",
    volatility: "指数点x5美元",
    volatilityIncome: "1(指数点) = 5美元",
    buyTimeAMWinter:
      "冬令时：</br> 上午07:00:00 - 次日05:50:00",
    buyTimeAM:
      "夏令时：</br> 上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter:
      "冬令时：</br> 上午07:00:00 - 次日05:55:00",
    sellTimeAM:
      "夏令时：</br> 上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所小型道指期货，最小波动5美元（相当于最小波动盈亏人民币${usd*(
      5
    )}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  NQ: {
    name: "小纳指",
    code: "NQ",
    exchange: "芝加哥商品交易所(CME)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x20美元",
    volatility: "指数点x20美元",
    volatilityIncome: "0.25(指数点) = 5美元",
    buyTimeAMWinter:
      "冬令时：</br> 上午07:00:00 - 次日05:50:00",
    buyTimeAM:
      "夏令时：</br> 上午06:00:00 - 次日04:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter:
      "冬令时：</br> 上午07:00:00 - 次日05:55:00",
    sellTimeAM:
      "夏令时：</br> 上午06:00:00 - 次日04:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所小纳指期货，最小波动5美元（相当于最小波动盈亏人民币${usd*(
      5
    )}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  HG: {
    name: "美铜",
    code: "HG",
    exchange: "纽约商品交易所(COMEX)",
    type: "金属期货",
    currency: "美元(USD)",
    unit: "25,000磅",
    volatility: "最新价x25,000磅",
    volatilityIncome: "0.0005(美元/磅) = 12.5美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所美铜期货，每手25000磅，最小波动12.5美元（相当于最小波动盈亏人民币${usd*(
      12.5
    )}元），可买涨买跌。`,
    isTimeWinter: "true",
  },
  MDAX: {
    name: "小德指",
    code: "MDAX",
    exchange: "欧洲期货交易所(EUREX)",
    type: "股指期货",
    currency: "欧元(EUR)",
    unit: "指数点x5欧元",
    volatility: "指数点x5欧元",
    volatilityIncome: "1(指数点) = 5欧元",
    buyTimeAMWinter: "冬令时：下午15:00:00 - 次日04:50:00",
    buyTimeAM: "夏令时：下午14:00:00 - 次日03:50:00",
    sellTimeAMWinter: "冬令时：下午15:00:00 - 次日04:55:00",
    sellTimeAM: "夏令时：下午14:00:00 - 次日03:55:00",
    clearTimeWinter: "冬令时：次日04:55:00",
    clearTime: "夏令时：次日03:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: "1欧元=8.0人民币",
    rate: "1欧元=8.0人民币（汇率波动较大时，将会进行调整）",
    introduce:
      "欧期所小型德指期货，每点5欧元，最小波动1点（相当于最小波动盈亏人民币40元），可买涨买跌。",
    isTimeWinter: "true",
  },
  CL: {
    name: "美原油",
    code: "CL",
    exchange: "纽约商品交易所(NYMEX)",
    type: "能源化工",
    currency: "美元(USD)",
    unit: "1000桶",
    volatility: "最新价x1,000美元",
    volatilityIncome: "0.01(美元/桶) = 10美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所美原油期货，每手1000桶，最小波动10美元（相当于最小波动盈亏人民币${usd*(
      10
    )}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  GC: {
    name: "美黄金",
    code: "GC",
    exchange: "纽约商品交易所(COMEX)",
    type: "金属期货",
    currency: "美元(USD)",
    unit: "100盎司",
    volatility: "最新价x100美元",
    volatilityIncome: "0.1(美元/盎司) = 10美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所美黄金期货，每手100盎司，最小波动10美元（相当于最小波动盈亏人民币${usd*(
      10
    )}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  SI: {
    name: "美白银",
    code: "SI",
    exchange: "纽约商品交易所(COMEX)",
    type: "金属期货",
    currency: "美元(USD)",
    unit: "5000盎司",
    volatility: "最新价x5,000美元",
    volatilityIncome: "0.005(美元/盎司) = 25美元",
    buyTimeAMWinter: "冬令时:上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时: 上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时: 上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时: 上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所美白银期货，每手5000盎司，最小波动25美元（相当于最小波动盈亏人民币${usd*(
      25
    )}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  HSI: {
    name: "恒指",
    code: "HSI",
    exchange: "香港交易所(HKEX)",
    type: "股指期货",
    currency: "港币(HKD)",
    unit: "指数点x50港币",
    volatility: "指数点x50港币",
    volatilityIncome: "1(指数点) = 50港币",
    buyTimeAM: "上午 09:15:00 - 12:00:00",
    buyTimePM: "下午 13:00:00 - 16:20:00  </br> 夜间 17:15:00 - 02:50:00",
    sellTimeAM: "上午 09:15:00 - 12:00:00",
    sellTimePM: "下午 13:00:00 - 16:25:00 </br> 夜间 17:15:00 - 02:55:00",
    clearTime: "白天 16:25:00 夜间 02:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: "1港币=0.88人民币",
    rate: "1港币=0.88人民币（汇率波动较大时，将会进行调整）",
    introduce:
      "港交所恒指期货，每点50港元，最小波动1点（相当于最小波动盈亏人民币44元 ，可买涨买跌。",
    isTimeWinter: "false",
  },
  MHI: {
    name: "小恒指",
    code: "MHI",
    exchange: "香港交易所(HKEX)",
    type: "股指期货",
    currency: "港币(HKD)",
    unit: "指数点x10港元",
    volatility: "指数点x10港元",
    volatilityIncome: "1(指数点) = 10港元",
    buyTimeAM: "上午 09:15:00 - 12:00:00",
    buyTimePM: "下午 13:00:00 - 16:20:00 </br> 夜间 17:15:00 - 02:50:00",
    sellTimeAM: "上午 09:15:00 - 12:00:00",
    sellTimePM: "下午 13:00:00 - 16:25:00 </br> 夜间 17:15:00 - 02:55:00",
    clearTime: "白天 16:25:00 夜间 02:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: "1港币=0.88人民币",
    rate: "1港币=0.88人民币（汇率波动较大时，将会进行调整）",
    introduce:
      "港交所小恒指期货，每点10港元，最小波动1点（相当于最小波动盈亏人民币8.8元 ，可买涨买跌。",
    isTimeWinter: "false",
  },
  CN: {
    name: "富时A50",
    code: "CN",
    exchange: "新加坡交易所(SGX)",
    type: "股指期货",
    currency: "美元(USD)",
    unit: "指数点x1美元",
    volatility: "指数点x1美元",
    volatilityIncome: "1(指数点) = 1美元",
    buyTimeAM: "白天 09:00:00 - 16:20:00",
    buyTimePM: "夜间 17:00:00 - 05:05:00",
    sellTimeAM: "白天 09:00:00 - 16:25:00",
    sellTimePM: "夜间 17:00:00 - 05:10:00",
    clearTime: "白天 16:25:00 夜间 05:10:00",
    highLimit: "9.5%",
    lowLimit: "9.5%",
    highClose: "9.8%",
    lowClose: "9.8%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `新交所富时A50期货，每点1美元，最小波动2.5点（相当于最小波动盈亏人民币${usd*(
      2.5
    )}元） ，可买涨买跌。`,
    isTimeWinter: "false",
  },
  DAX: {
    name: "德指",
    code: "DAX",
    exchange: "欧洲期货交易所(EUREX)",
    type: "股指期货",
    currency: "欧元(EUR)",
    unit: "指数点x25欧元",
    volatility: "指数点x25欧元",
    volatilityIncome: "1(指数点)=25欧元",
    buyTimeAMWinter: "冬令时：下午15:00:00 - 次日04:50:00",
    buyTimeAM: "夏令时：下午14:00:00 - 次日03:50:00",
    sellTimeAMWinter: "冬令时：下午15:00:00 - 次日04:55:00",
    sellTimeAM: "夏令时：下午14:00:00 - 次日03:55:00",
    clearTimeWinter: "冬令时：次日04:55:00",
    clearTime: "夏令时：次日03:55:00",
    highLimit: "8.5%",
    lowLimit: "8.5%",
    highClose: "9%",
    lowClose: "9%",
    rateDetail: "1欧元=8.0人民币",
    rate: "1欧元=8.0人民币（汇率波动较大时，将会进行调整）",
    introduce:
      "欧期所德指期货，每点25欧元，最小波动0.5点（相当于最小波动盈亏人民币100元） ，可买涨买跌。",
    isTimeWinter: "true",
  },
  RB: {
    name: "螺纹钢",
    code: "RB",
    currency: "人民币",
    unit: "每手10吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "7.8%",
    lowLimit: "7.8%",
    highClose: "8%",
    lowClose: "8%",
    rateDetail: "",
    rate: "",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    introduce:
      "上期所螺纹钢期货，每手10吨，最小波动1元（相当于最小波动盈亏10元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  PP: {
    name: "PP聚丙烯",
    code: "PP",
    currency: "人民币",
    exchange: "大连商品交易所(DCE)",
    unit: "每手5吨",
    volatility: "1元/吨",
    type: "能源化工",
    volatilityIncome: "波动一次 = 5元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间22:55:00",
    highLimit: "4.5%",
    lowLimit: "4.5%",
    highClose: "4.8%",
    lowClose: "4.8%",
    rateDetail: "",
    rate: "",
    introduce:
      "大商所PP聚丙烯期货，每手5吨，最小波动1元（相当于最小波动盈亏5元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  NI: {
    name: "沪镍",
    code: "NI",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    currency: "人民币(CNY)",
    unit: "每手1吨",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨)=10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 00:50:00",
    buyTimeNI: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 00:55:00",
    clearTime: "白天 14:55:00 夜间 00:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所沪镍期货，每手1吨，最小波动10元（相当于最小波动盈亏10元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  CU: {
    name: "沪铜",
    code: "CU",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "最新价x50元",
    volatilityIncome: "10(元/吨) = 50元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 00:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 00:55:00",
    clearTime: "白天 14:55:00 夜间 00:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所沪铜期货，每手5吨，最小波动10元（相当于最小波动盈亏50元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  AG: {
    name: "沪银",
    code: "AG",
    exchange: "上海期货交易所(SHFE)",
    type: "金属期货",
    currency: "人民币(CNY)",
    unit: "每手15千克",
    volatility: "最新价x15元",
    volatilityIncome: "1(元/千克) = 15元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 02:20:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 02:25:00",
    clearTime: "白天 14:55:00 夜间 02:25:00",
    highLimit: "5%",
    lowLimit: "5%",
    highClose: "5.5%",
    lowClose: "5.5%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所沪银期货，每手15千克，最小波动1元（相当于最小波动盈亏15元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  AU: {
    name: "沪金",
    code: "AU",
    currency: "人民币",
    unit: "每手1000克",
    volatility: "0.05元/克",
    volatilityIncome: "波动一次 = 50元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    buyTimeNI: "夜间 21:00:00 - 02:20:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 02:25:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间 02:25:00",
    highLimit: "4%",
    lowLimit: "4%",
    highClose: "4.5%",
    lowClose: "4.5%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所沪金期货，每手1000克，最小波动0.05元（相当于最小波动盈亏50元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  SR: {
    name: "白糖",
    code: "SR",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:50:00",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "3.5%",
    lowLimit: "3.5%",
    highClose: "3.8%",
    lowClose: "3.8%",
    rateDetail: "",
    rate: "",
    introduce:
      "郑商所白糖期货，每手10吨，最小波动1元（相当于最小波动盈亏10元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  RU: {
    name: "天然橡胶",
    code: "RU",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "5(元/吨) = 50元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所天然橡胶期货，每手10吨，最小波动5元（相当于最小波动盈亏50元） ，可买涨买跌。",
    isTimeWinter: "false",
  },
  IC: {
    name: "中证500",
    code: "IC",
    exchange: "中国金融期货交易所(CFFEX)",
    type: "股指期货",
    currency: "人民币(CNY)",
    unit: "指数点x200元",
    volatility: "指数点x200元",
    volatilityIncome: "0.2(指数点) = 40元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午 09:30:00 - 11:30:00",
    buyTimePM: "下午 13:00:00 - 14:50:00",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:30:00 - 11:30:00",
    sellTimePM: "下午 13:00:00 - 14:55:00",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "白天 14:55:00",
    highLimit: "8%",
    lowLimit: "8%",
    highClose: "9%",
    lowClose: "9%",
    rateDetail: "",
    rate: "",
    introduce:
      "中金所中证500股指期货，每点200元，最小波动0.2点（相当于最小波动盈亏40元） 可买涨买跌。",
    isTimeWinter: "false",
  },
  IH: {
    name: "上证50",
    code: "IH",
    exchange: "中国金融期货交易所(CFFEX)",
    type: "股指期货",
    currency: "人民币(CNY)",
    unit: "指数点x300元",
    volatility: "指数点x300元",
    volatilityIncome: "0.2(指数点) = 60元",
    buyTimeAM: "上午 09:30:00 - 11:30:00",
    buyTimePM: "下午 13:00:00 - 14:50:00",
    sellTimeAM: "上午 09:30:00 - 11:30:00",
    sellTimePM: "下午 13:00:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    highLimit: "8%",
    lowLimit: "8%",
    highClose: "9%",
    lowClose: "9%",
    rateDetail: "",
    rate: "",
    introduce:
      "中金所上证50股指期货，每点300元，最小波动0.2点（相当于最小波动盈亏60元） 可买涨买跌。",
    isTimeWinter: "false",
  },
  IF: {
    name: "沪深300",
    code: "IF",
    exchange: "中国金融期货交易所(CFFEX)",
    type: "股指期货",
    currency: "人民币(CNY)",
    unit: "指数点x300元",
    volatility: "指数点x300元",
    volatilityIncome: "0.2(指数点) = 60元",
    buyTimeAM: "上午 09:30:00 - 11:30:00",
    buyTimePM: "下午 13:00:00 - 14:50:00",
    sellTimeAM: "上午 09:30:00 - 11:30:00",
    sellTimePM: "下午 13:00:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    highLimit: "8%",
    lowLimit: "8%",
    highClose: "9%",
    lowClose: "9%",
    rateDetail: "",
    rate: "",
    introduce:
      "中金所沪深300股指期货，每点300元，最小波动0.2点（相当于最小波动盈亏60元） 可买涨买跌。",
    isTimeWinter: "false",
  },
  BU: {
    name: "沥青",
    code: "BU",
    currency: "人民币(CNY)",
    unit: "每手10吨",
    volatility: "2元/吨",
    volatilityIncome: "波动一次 = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAMWinter: "",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "2.8%",
    lowLimit: "2.8%",
    highClose: "3%",
    lowClose: "3%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所石油沥青期货，每手10吨，最小波动2元（相当于最小波动盈亏20元），可买涨买跌。",
    isTimeWinter: "false",
  },
  HC: {
    name: "热卷",
    code: "HC",
    currency: "人民币(CNY)",
    exchange: "上海期货交易所(SHFE)",
    unit: "每手10吨",
    type: "金属期货",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00 ",
    buyTimeNI: "夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    sellTimeNI: "夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "2.8%",
    lowLimit: "2.8%",
    highClose: "3%",
    lowClose: "3%",
    rateDetail: "",
    rate: "",
    introduce:
      "上期所热轧卷板期货，每手10吨，最小波动2元（相当于最小波动盈亏20元），可买涨买跌。",
    isTimeWinter: "false",
  },
  MA: {
    name: "甲醇",
    code: "MA",
    currency: "人民币(CNY)",
    unit: "每手10吨",
    volatility: "1元/吨",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    buyTimeNI: "夜间 21:00:00 - 23:20:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    sellTimeNI: "夜间 21:00:00 - 23:25:00",
    clearTime: "白天 14:55:00 夜间 23:25:00",
    highLimit: "4.8%",
    lowLimit: "4.8%",
    highClose: "5%",
    lowClose: "5%",
    rateDetail: "",
    rate: "",
    introduce:
      "郑商所甲醇期货，每手10吨，最小波动1元（相当于最小波动盈亏10元），可买涨买跌。",
    isTimeWinter: "false",
  },
  FG: {
    name: "玻璃",
    code: "FG",
    exchange: "郑州商品交易所(ZCE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手20吨",
    volatility: "最新价x20元",
    volatilityIncome: "1(元/吨)=20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 23:20:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 23:25:00",
    clearTime: "白天 14:55:00 夜间 23:25:00",
    highLimit: "4.8%",
    lowLimit: "4.8%",
    highClose: "5%",
    lowClose: "5%",
    rateDetail: "",
    rate: "",
    introduce:
      "郑商所玻璃期货，每手20吨，最小波动1元（相当于最小波动盈亏20元），可买涨买跌。",
    isTimeWinter: "false",
  },
  SM: {
    name: "锰硅",
    code: "SM",
    currency: "人民币(CNY)",
    type: "金属期货",
    unit: "每手5吨",
    volatility: "2元/吨",
    exchange: "郑州商品交易所(ZCE)",
    volatilityIncome: "波动一次 = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00 ",
    highLimit: "5.8%",
    lowLimit: "5.8%",
    highClose: "6%",
    lowClose: "6%",
    rateDetail: "",
    rate: "",
    introduce:
      "郑商所锰硅期货，每手5吨，最小波动1元（相当于最小波动盈亏10元），可买涨买跌。",
    isTimeWinter: "false",
  },
  NG: {
    name: "天然气",
    code: "NG",
    exchange: "纽约商品交易所(NYMEX)",
    type: "能源化工",
    currency: "美元(USD)",
    volatilityIncome: "0.001(美元/mmBtu)=10美元",
    unit: "10000mmBtu",
    volatility: "最新价x10,000美元",
    buyTimeAMWinter: "冬令时：上午07:00:00 - 次日05:50:00",
    buyTimeAM: "夏令时：上午06:00:00 - 次日04:50:00",
    sellTimeAMWinter: "冬令时：上午07:00:00 - 次日05:55:00",
    sellTimeAM: "夏令时：上午06:00:00 - 次日04:55:00",
    clearTimeWinter: "冬令时：次日05:55:00",
    clearTime: "夏令时：次日04:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所天然气期货，每手10000mmBtu，最小波动10美元（相当于最小波动盈亏人民币${usd*(
      10
    )}元） ，可买涨买跌。`,
    isTimeWinter: "true",
  },
  QM: {
    name: "小原油",
    code: "QM",
    currency: "美元(USD)",
    unit: "每手500桶",
    volatility: "0.025美元/桶",
    volatilityIncome: "波动一次=12.5美元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午07:00:00 - 次日05:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午07:00:00 - 次日05:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "次日05:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `纽交所小原油期货，每手500桶，最小波动12.5美元（相当于最小波动盈亏人民币${usd*(
      12.5
    )}元） ，可买涨买跌。`,
    isTimeWinter: "false",
  },
  RBB: {
    name: "汽油",
    code: "RBB",
    currency: "美元(USD)",
    unit: "每手42000美制加仑",
    volatility: "0.0001美元/加仑",
    volatilityIncome: "波动一次4.2美元",
    buyTimeAMWinter: "",
    buyTimeAM: "上午07:00:00 - 次日05:50:00",
    buyTimePM: "",
    buyTimeNI: "",
    sellTimeAMWinter: "",
    sellTimeAM: "上午07:00:00 - 次日05:55:00",
    sellTimePM: "",
    sellTimeNI: "",
    clearTimeWinter: "",
    clearTime: "次日05:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: `1美元=${usd}人民币`,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    introduce: `芝加哥商品交易所汽油期货，每手42,000美制加仑，最小波动4.2美元（相当于最小波动盈亏人民币${usd*(
      4.2
    )}元） ，可买涨买跌。`,
    isTimeWinter: "false",
  },
  SC: {
    name: "原油期货",
    code: "SC",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "1000桶",
    volatility: "最新价x1000元",
    volatilityIncome: "0.1(元/桶) = 100元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br>上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br>夜间 21:00:00 - 02:20:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br>上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br>夜间 21:00:00 - 02:25:00",
    clearTime: "白天 14:55:00 夜间 02:25:00",
    highLimit: "4.8%",
    lowLimit: "4.8%",
    highClose: "5%",
    lowClose: "5%",
    rateDetail: "",
    rate: "",
    introduce:
      "上海国际能源交易中心，每手1000桶，最小波动0.1元（相当于最小波动盈亏100元)，可买涨买跌。",
    isTimeWinter: "false",
  },
  NK: {
    name: "日经指数",
    code: "NK",
    exchange: "新加坡交易所(SGX)",
    type: "股指期货",
    currency: "日元(JPY)",
    unit: "指数点x500日元",
    volatility: "指数点x500日元",
    volatilityIncome: "5(指数点) = 25,00日元",
    buyTimeAM: "上午 07:30:00 - 下午14:20:00",
    buyTimePM: "下午 14:55:00 - 夜间04:35:00",
    sellTimeAM: "上午 07:30:00 - 下午14:25:00",
    sellTimePM: "下午 14:55:00 - 夜间04:40:00",
    clearTime: "白天 14:25:00 夜间 04:40:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
    rateDetail: "1日元=0.06元人民币",
    rate: "1日元=0.06元人民币（汇率波动较大时，将会进行调整）",
    introduce:
      "新交所日经指数期货，每点500日元，最小波动5个指数点（相当于最小波动盈亏人民币150元，可买涨买跌。",
    isTimeWinter: "false",
  },
  TA: {
    name: "PTA",
    code: "TA",
    exchange: "郑州商品交易所(ZCE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "最新价x2.5元",
    volatilityIncome: "2(元/吨) = 2.5元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br/> 夜间 21:00:00 - 22:55:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br/> 夜间 21:30:00 - 22:55:00",
    clearTime: "下午 14:55:00 夜间 22:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
  },
  I: {
    name: "铁矿",
    code: "I",
    exchange: "大连商品交易所(DCE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手100吨",
    volatility: "最新价x100元",
    volatilityIncome: "0.5(元/吨) = 50元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br/> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br/> 夜间 21:30:00 - 22:55:00",
    clearTime: "下午 14:55:00 夜间 22:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
  },
  AL: {
    name: "沪铝",
    code: "AL",
    exchange: "上海期货交易所(SHFE)",
    type: "能源化工",
    currency: "人民币(CNY)",
    unit: "每手5吨",
    volatility: "最新价x5元",
    volatilityIncome: "5(元/吨) = 25元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 <br/> 夜间 21:00:00 - 00:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 <br/> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 <br/> 夜间 21:30:00 - 00:55:00",
    clearTime: "下午 14:55:00 夜间 00:55:00",
    highLimit: "20%",
    lowLimit: "20%",
    highClose: "30%",
    lowClose: "30%",
  },
  P: {
    name: "棕榈油",
    code: "P",
    exchange: "大连商品交易所(DCE)",
    type: "能源化工",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "2(元/吨) = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce: "大连交易所期货，每手10吨，最小波动盈亏20元，可买涨买跌。",
    isTimeWinter: "false",
  },
  M: {
    name: "豆粕",
    code: "M",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce: "大连交易所期货，每手10吨，最小波动盈亏10元，可买涨买跌。",
    isTimeWinter: "false",
  },
  Y: {
    name: "豆油",
    code: "Y",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "2(指数点) = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 </br> 夜间 21:00:00 - 22:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00 </br> 夜间 21:00:00 - 22:55:00",
    clearTime: "白天 14:55:00 夜间 22:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce: "大连交易所期货，每手10吨，最小波动盈亏20元，可买涨买跌。",
    isTimeWinter: "false",
  },
  LH: {
    name: "生猪",
    code: "LH",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手16吨",
    currency: "人民币(CNY)",
    volatility: "最新价x16元",
    volatilityIncome: "5(元/吨)=80元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce: "大连交易所期货，每手10吨，最小波动盈亏20元，可买涨买跌。",
    isTimeWinter: "false",
  },
  JD: {
    name: "鸡蛋",
    code: "JD",
    exchange: "大连商品交易所(DCE)",
    type: "农业期货",
    unit: "每手5吨",
    currency: "人民币(CNY)",
    volatility: "最新价x20元",
    volatilityIncome: "1(元/500千克) = 20元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce: "大连交易所期货，每手5吨，最小波动盈亏20元，可买涨买跌。",
    isTimeWinter: "false",
  },
  AP: {
    name: "苹果",
    code: "AP",
    exchange: "郑州商品交易所(ZCE)",
    type: "农业期货",
    unit: "每手10吨",
    currency: "人民币(CNY)",
    volatility: "最新价x10元",
    volatilityIncome: "1(元/吨) = 10元",
    buyTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    buyTimePM: "下午 13:30:00 - 14:50:00 ",
    sellTimeAM: "上午 09:00:00 - 10:15:00 </br> 上午 10:30:00 - 11:30:00",
    sellTimePM: "下午 13:30:00 - 14:55:00",
    clearTime: "白天 14:55:00",
    highLimit: "6%",
    lowLimit: "6%",
    highClose: "6.5%",
    lowClose: "6.5%",
    rateDetail: "",
    rate: "",
    introduce: "大连交易所期货，每手5吨，最小波动盈亏20元，可买涨买跌。",
    isTimeWinter: "false",
  },
  BTCUSDT: {
    name: "BTC",
    code: "BTC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x1美元",
    // volatilityIncome: "0.1(美元/BTC) = 0.1美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "500,000 CNY",
    lever: "10X~100X",
    publicTime: "2008/11/01",
    publicTotal: "2100万",
    publicCurrent: "81.79%",
    publicPrice: "--",
    shu: "https://bitcoin.org/bitcoin.ptf",
    publicWeb: "https://bitcoin.org/en/",
    publicSearch: "https://blockchain.info/",
    dec:
      "\t比特币（Bitcoin，简称BTC）是目前使用最为广泛的一种数字货币，它诞生于2009年1月3日，是一种点对点（P2P）传输的数字加密货币，总量2100万枚。比特币网络每10分钟释放出一定数量币，预计在2140年达到极限。比特币被投资者称为“数字黄金”。比特币依据特定算法，通过大量的计算产生，不依靠特定货币机构发行，其使用整个P2P网络中众多节点构成的分布式数据库来确认并记录所有的交易行为，并使用密码学设计确保货币流通各个环节安全性，可确保无法通过大量制造比特币来人为操控币值。基于密码学的设计可以使比特币只能被真实拥有者转移、支付及兑现。同样确保了货币所有权与流通交易的匿名性。",
  },
  ETHUSDT: {
    name: "ETH",
    code: "ETH/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x40美元",
    // volatilityIncome: "0.01(美元/ETH) = 0.4美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "300,000 CNY",
    lever: "10X~100X",
    publicTime: "2014/07/24",
    publicTotal: "9631.15万",
    publicCurrent: "100%",
    publicPrice: "¥1.89",
    shu: "https://github.com/ethereum/wiki/…",
    publicWeb: "https://www.ethereum.org",
    publicSearch: "https://etherscan.io",
    dec:
      "\t以太坊（Ethereum）是下一代密码学账本，可以支持众多的高级功能，包括用户发行货币，智能协议，去中心化的交易和设立去中心化自治组织(DAOs)或去中心化自治公司（DACs）。以太坊并不是把每一单个类型的功能作为特性来特别支持，相反，以太坊包括一个内置的图灵完备的脚本语言，允许通过被称为“合同”的机制来为自己想实现的特性写代码。一个合同就像一个自动的代理，每当接收到一笔交易，合同就会运行特定的一段代码，这段代码能修改合同内部的数据存储或者发送交易。",
  },
  ETCUSDT: {
    name: "ETC",
    code: "ETC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x400美元",
    // volatilityIncome: "0.001(美元/ETC) = 0.4美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2015/11/01",
    publicTotal: "9833.66万",
    publicCurrent: "100%",
    publicPrice: "--",
    shu: "https://ethereumclassic.github.io/…",
    publicWeb: "https://ethereumclassic.github.io/",
    publicSearch: "https://ethereumclassic.github.io/",
    dec:
      "\tETC（Ethereum Classic）是以太坊在1,920,000个块后硬分叉出的分叉币种，功能和以太坊极为类似。ETC秉承去中心化理念，支持区块链保证的共识机制。ETC坚信，区块链一旦开始运行，它的发展方向就不被任何中心团队所左右，而是按照参与整个网络人员的共识和全网算力的共识所决定。",
  },
  XRPUSDT: {
    name: "XRP",
    code: "XRP/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x16000美元",
    // volatilityIncome: "0.0001(美元/XRP) = 1.6美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2011/04/18",
    publicTotal: "10000000万",
    publicCurrent: "39.37%",
    publicPrice: "--",
    shu: "https://ripple.com/files/ripple_co…",
    publicWeb: "https://ripple.com",
    publicSearch: "https://ripple.com/graph",
    dec:
      "\t瑞波币（Ripple/XRP）提供一个无阻碍的利用区块链技术的全球支付网络，是世界上第一个开放的支付网络，通过加入Ripple正在成长的全球支付网络可以转账任意一种货币，包括美元、欧元、人民币、日元或者比特币，简便易行快捷，交易确认在几秒以内完成，交易费用几乎是零，没有所谓的跨行异地以及跨国支付费用。 Ripple是开放源码的点到点支付网络，它可以使任何机构或个人轻松、廉价并安全的把金钱转账到互联网上的任何机构或个人，无论在世界的哪个地方。Ripple是p2p软件，没有任何个人、公司，或政府操控，任何人可以创建一个ripple账户。",
  },
  BCHUSDT: {
    name: "BCH",
    code: "BCH/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x20美元",
    // volatilityIncome: "0.01(美元/BCH) = 0.2美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017/07/24",
    publicTotal: "21000000",
    publicCurrent: "82.36%",
    publicPrice: "--",
    shu: "http://blockdozer.com/insight/",
    publicWeb: "https://www.bitcoincash.org",
    publicSearch: "https://blockchair.com/bitcoin…",
    dec:
      "\tBCH是根据Bitcoin ABC方案产生的区块链资产。Bitcoin ABC方案为保持协议稳定简单，去除了Segwit功能，支持将区块大小提升至8M，是链上扩容的技术路线。Bitcoin ABC代码基于比特币协议的稳定版本进行了改进，其认为不包含Segwit将具有更大的稳定性、安全性、鲁棒性，是现行比特币协议和比特币系统的备份，自2017年8月1日21:16:14(GMT+8)，即区块高度478,558之后，BTC和BCH两条链已经正式分离成功。",
  },
  LTCUSDT: {
    name: "LTC",
    code: "LTC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x80美元",
    // volatilityIncome: "0.01(美元/LTC) = 0.8美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2011/11/09",
    publicTotal: "8400万",
    publicCurrent: "68.89%",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://litecoin.com",
    publicSearch: "http://explorer.litecoin.net/chain/",
    dec:
      "\t莱特币诞生于2011年11月9日，被称为是“数字白银”。莱特币在技术上和比特币具有相同的实现原理。它是第一个基于Scrypt算法的网络数字货币，与比特币相比，莱特币拥有更快的交易确认时间，更高的网络交易容量和效率。莱特币现在拥有完整的产业链，充分的流动性，足以证明其是成熟、安全、稳定的商用金融系统。",
  },
  EOSUSDT: {
    name: "EOS",
    code: "EOS/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x1500美元",
    // volatilityIncome: "0.001(美元/EOS) = 1.5美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017/07/02",
    publicTotal: "10亿",
    publicCurrent: "96.06%",
    publicPrice: "￥7",
    shu: "https://github.com/EOSIO/Docume…",
    publicWeb: "https://eos.io",
    publicSearch: " https://etherscan.io/token/EOS",
    dec:
      "\tEOS (Enterprise Operation System)是由 Block.one公司主导开发的一种全新的基于区块链智能合约平台，旨在为高性能分布式应用提供底层区块链平台服务。EOS 项目的目标是实现一个类似操作系统的支撑分布式应用程序的区块链架构。该架构可以提供账户，身份认证，数据库，异步通信以及可在数以万计的 CPU/GPU群集上进行程序调度和并行运算。EOS最终可以支持每秒执行数百万个交易，同时普通用户执行智能合约无需支付使用费用。",
  },
  TRXUSDT: {
    name: "TRX",
    code: "TRX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x200000美元",
    // volatilityIncome: "0.00001(美元/TRX) = 2美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017/09/14",
    publicTotal: "100亿",
    publicCurrent: "66.41%",
    publicPrice: "￥0.01",
    shu: "https://dn-peiwo-web.qbox.m…",
    publicWeb: " https://tron.network",
    publicSearch: "https://etherscan.io/address…",
    dec:
      "\t波场TRON是基于区块链的开源去中心化内容娱乐协议。它致力于利用区块链与分布式存储技术，构建一个全球范围内的自由内容娱乐体系，这个协议可以让每个用户自由发布，存储，拥有数据，并通过去中心化的自治形式，以数字资产发行，流通。交易方式决定内容的分发、订阅、推送，赋能内容创造者，形成去中心化的内容娱乐生态。",
  },
  DASHUSDT: {
    name: "DASH",
    code: "DASH/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x100美元",
    // volatilityIncome: "0.01(美元/DASH) = 1美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2014/01/18",
    publicTotal: "18,900,000",
    publicCurrent: "8,850,289",
    publicPrice: "--",
    shu: " https://www.stellar.org/papers/…",
    publicWeb: "https://www.dash.org",
    publicSearch: "https://chainz.cryptoid.info/dash",
    dec:
      "\t达世币是一种可线上操作的数字货币，用户可以使用达世币进行安全的在线即时支付，商户则可以为店内添加由全球千万用户所建立的开源支付平台。\n" +
      "\n" +
      "\t达世币核心由独特的激励制P2P网络构成。 矿工们维护区块链安全得到奖励；而主节点持有者则是为用户验证交易、存储数据以及提供多种服务而获得奖励。\n" +
      "\n" +
      "\t主节点代表着新一层级的网络。 它们可组成高度安全的集群 – 仲裁链, 提供多种类的去中心化服务，如即时交易、匿名性、去中心化管理等等，同时它还可以防止低成本的网络攻击。\n" +
      "\n" +
      "\t得益于达世币的奖励机制，它的网络主节点自2014年发行以来已经增长到了4100个，这意味着达世币P2P网络已经成为全球最大的网络之一。\n" +
      "\n" +
      "\t更多的节点意味着更高的安全性能，达世币能为更多来自全球各地的终端用户提供全天候的数字货币服务。",
  },
  LINKUSDT: {
    name: "LINK",
    code: "LINK/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x600美元",
    // volatilityIncome: "0.0001(美元/DASH) = 0.06美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "--",
    publicTotal: "1,000,000,000",
    publicCurrent: "350,000,000",
    publicPrice: "--",
    shu: "https://link.smartcontract.com/",
    publicWeb: "--",
    publicSearch: "--",
    dec:
      "ChainLink的LINK网络是第一个分散的oracle网络，允许任何人安全地提供智能合同，访问关键的外部数据，脱机支付和任何其他API功能。任何拥有数据Feed，脱机服务（如本地付款）或任何其他API的用户均可以直接将其提供给智能合同，以换取LINK令牌。",
  },
  DOTUSDT: {
    name: "DOT",
    code: "DOT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x1500美元",
    // volatilityIncome: "0.0001(美元/DOT) = 0.15美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2019/05/05",
    publicTotal: "1,000,000,000",
    publicCurrent: "904,858,113",
    publicPrice: "--",
    shu: "https://polkadot.network/PolkaD",
    publicWeb: "https://polkadot.network/",
    publicSearch: "https://polkadot.js.org/apps/#/e",
    dec:
      "Polkadot是一个由多条区块链，异构组成的区块链集合。Polkadot主要的目的是将现在各自独立的区块链连接起来。通过Polkadot，不同区块链之间可以进行通信和数据的传递。 Polkadot这个项目成立于2015年。Polkadot项目的主要负责人是大名鼎鼎的Dr. Gavin Wood。Dr. Gavin Wood是以太坊的创始人之一，也是以太坊黄皮书的制作者。Dr. Gavin Wood离开以太坊之后成立了自己的科技公司Parity。目前Polkadot这个项目由Parity公司管理。 DOT是Polkadot平台的原生代币，主要用于：管理平台；运营网络；通过粘合DOT创建区块链；支付信息传送费用。\n",
  },
  BSVUSDT: {
    name: "BSV",
    code: "BSV/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018/11/09",
    publicTotal: "21,000,000",
    publicCurrent: "--",
    publicPrice: "--",
    shu: "--",
    publicWeb: " https://bitcoinsv.io/",
    publicSearch: "--",
    dec:
      "\t比特币 SV 是比特币现金（BCH）的全节点实现。应比特币现金（BCH）矿业巨头 CoinGeek 及其他矿工的要求，团队创建了比特币 SV，旨在为矿工提供明确的比特币现金（BCH）实现选择，并允许企业在其稳固可靠的基础上构建应用程序和网站。",
  },
  ATOMUSDT: {
    name: "ATOM",
    code: "ATOM/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2019-03-15",
    publicTotal: "237,928,231",
    publicCurrent: "--",
    publicPrice: "$0.1",
    shu: "https://cosmos.network/resources/whitepaper",
    publicWeb: " https://cosmos.network/",
    publicSearch: "--",
    dec:
      "Cosmos是Tendermint团队推出的一个支持跨链交互的异构网络，由许多独立的并行区块链组成，采用Tendermint共识算法，允许大量自主且易开发的区块链互相扩展和交互，解决区块链价值孤岛的问题，建立价值网络的桥梁。ATOM是Cosmos Hub的代币，可用于垃圾邮件防范机制、抵押分红以及社区管理投票机制。",
  },
  FILUSDT: {
    name: "FIL",
    code: "FIL/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "--",
    publicTotal: "2,000,000,000",
    publicCurrent: "--",
    publicPrice: "--",
    shu: "https://filecoin.io/filecoin.pdf",
    publicWeb: "https://filecoin.io",
    publicSearch:
      "https://stats.testnet.filecoin.io/d/z6FtI92Zz/chain?orgId=1&refresh=45s&from=now-30m&to=now&kiosk",
    dec:
      "Filecoin是一个存储文件的对等网络，具有内置的经济激励机制，可确保随着时间的推移可靠地存储文件。 在Filecoin中，用户付费将其文件存储在存储矿工上。存储矿工是负责存储文件并证明其已随时间正确存储文件的计算机。任何想要存储其文件或希望因存储其他用户的文件而获得报酬的人都可以加入Filecoin。可用存储空间以及该存储空间的价格不受任何一家公司的控制。相反，Filecoin促进了开放市场来存储和检索任何人都可以参与的文件。 Filecoin包括区块链和本机加密货币（FIL）。存储矿工通过存储文件赚取FIL单位。",
  },
  AAVEUSDT: {
    name: "AAVE",
    code: "AAVE/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-10-03",
    publicTotal: "16,000,000",
    publicCurrent: "10,642,338",
    publicPrice: "$1.7",
    shu: "https://github.com/aave/aave-protocol/blob/master/docs/A",
    publicWeb: " https://aave.com/",
    publicSearch: "https://www.oklink.com/eth/token/0x7fc66500c84a76ad7",
    dec:
      "2018年9月，ETHLend更名为Aave，ETHLend是一个分散的p2p借贷平台，允许世界各地的人们获得贷款或成为贷款人。作为一个去中心化的平台，使用区块链技术，我们可以让你在几分钟内以安全的方式获得贷款或融资。自第一次发布以来，加入了许多功能，如比特币作为抵押品，众筹，部分抵押品呼叫，新的货币，例如稳定的硬币，超过150+ ERC20抵押品和更多。“ Aave”是一个芬兰语的单词，它的意思是“幽灵”。“幽灵”代表Aave致力于为分散式金融创建透明、开放的基础架构而努力。Aave的总部位于英国伦敦，由18名创新创造性的业界佼佼者们组成。",
  },
  ADAUSDT: {
    name: "ADA",
    code: "ADA/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2010-02-17",
    publicTotal: "450亿",
    publicCurrent: "57.62%",
    publicPrice: "$0.0163",
    shu: " https://cardanofoundation.org",
    publicWeb: " https://cardanofoundation.org",
    publicSearch: "https://cardanoexplorer.com",
    dec:
      "卡尔达诺（艾达币）是一个分散且公开的区块链，也是ㄧ个加密货币项目，而且是完全开源的。卡尔达诺正在开发一个智能合约平台，旨在提供比以前开发的任何协议更先进的功能。它是第一个从科学哲学和以研究为首驱使导向，进而演变而来的区块链平台。开发团队由全球专业工程师和研究人员组成。",
  },
  UNIUSDT: {
    name: "UNI",
    code: "UNI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-09-17",
    publicTotal: "1000000000",
    publicCurrent: "24710190",
    publicPrice: "--",
    shu: "https://uniswap.org/whitepaper.pdf",
    publicWeb: "https://uniswap.org/",
    publicSearch:
      "https://www.oklink.com/eth/token/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    dec:
      "Uniswap是一种基于以太坊的协议，旨在促进ETH和ERC20 代币数字资产之间的自动兑换交易，在以太坊上自动提供流动性。Uniswap完全部署在链上，任何个人用户，只要安装了去中心化钱包软件都可以使用这个协议。Uniswap试图利用去中心化协议来让数字资产交易过程中彻底实现去中介化。",
  },
  HTUSDT: {
    name: "HT",
    code: "HT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-01-24",
    publicTotal: "24999.2万",
    publicCurrent: "19680.9万",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://www.huobitoken.com/zh-cn/",
    publicSearch:
      "https://etherscan.io/address/0x6f259637dcd74c767781e37bc6133cd6a68aa161",
    dec:
      "HT（火币全球通用积分）是基于区块链发行和管理的积分系统，为火币官方发行的唯一积分。HT将支持火币全球业务、全新产品，如：VIP手续费折扣、认证商家保证金、火币积分专享活动、与热门币种交易、参与火币业务等。",
  },
  XMRUSDT: {
    name: "XMR",
    code: "XMR/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2014-04-18",
    publicTotal: "17,497,471",
    publicCurrent: "17,497,471",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://getmonero.org",
    publicSearch: "https://getmonero.org",
    dec:
      "门罗币（Monero）使用加密来屏蔽发送和接收地址以及交易金额，与其他公共区块链像比特币和以太坊不同。\n" +
      "默认情况下，每个门罗币交易都会混淆发送和接收地址以及交易金额。门罗币是可以替代的。这意味着门罗币将永远被接收而没有审查风险。\n" +
      "门罗不是一家公司。它由来自世界各地的加密学和分布式系统专家开发，他们捐赠时间或由社区捐赠资助。这意味着门罗不能被任何一个国家关闭，也不受任何特定法律管辖区的限制。",
  },
  ZECUSDT: {
    name: "ZEC",
    code: "ZEC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2016-10-28",
    publicTotal: "21,000,000",
    publicCurrent: "9,552,481",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://z.cash/",
    publicSearch: "https://explorer.zcha.in/",
    dec:
      "Zcash是一种去中心化、开源的加密互联网货币，基于14年IEEE会议上发布的paper Zerocash Protocol开发，采取零知识证明及多重账户机制保障交易的匿名性",
  },
  XLMUSDT: {
    name: "XLM",
    code: "XLM/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2014-08-01",
    publicTotal: "1000亿",
    publicCurrent: "18,766,663,721",
    publicPrice: "--",
    shu: "https://www.stellar.org/papers/…",
    publicWeb: "https://www.stellar.org",
    publicSearch: "https://stellarchain.io",
    dec:
      "Stellar网络是一个开放的支付网络，连接不同的金融系统，让任何人都可以为他们的社区建立低成本的金融服务 - 支付，储蓄，贷款和保险。Stellar网络可以像电子邮件一样轻松地让人，公司和金融机构之间直接转移资金。这种互联性意味着更多的个人访问，更低的银行成本和更多的企业收入。",
  },
  ZRXUSDT: {
    name: "ZRX",
    code: "ZRX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-08-15",
    publicTotal: "10亿",
    publicCurrent: "539,638,687",
    publicPrice: "--",
    shu: "https://www.0xproject.com/pdfs/0x_white_paper.pdf",
    publicWeb: "https://0xproject.com/",
    publicSearch: "--",
    dec:
      "0x是一个以太坊上的去中心化交易的开源协议,以促进以太坊的资产,例如 ERC20 代币,的去信任和低摩擦交易。该协议旨在作为开放标准和通用模块,推动具有交易功能去中心化应用（DApps）之间的互通性。",
  },
  BATUSDT: {
    name: "BAT",
    code: "BAT/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "--",
    publicTotal: "15亿",
    publicCurrent: "1,338,750,672",
    publicPrice: "--",
    shu: "https://basicattentiontoken.org/BasicAttentionTokenWhitePaper-4.pdf",
    publicWeb: "https://basicattentiontoken.org/",
    publicSearch: "--",
    dec:
      "BAT(Basic Attention Token)是Javascript创始人，Mozilla和Firefox浏览器联合创始人Brendan Eich创办的一个用于在广告商和用户之间进行流通的数字资产，用于解决浏览器中的广告展示和用户激励问题。",
  },
  KNCUSDT: {
    name: "KNC",
    code: "KNC/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-09-15",
    publicTotal: "215,625,198",
    publicCurrent: "134,132,696.93",
    publicPrice: "1/600 ETH",
    shu: "https://home.kyber.network/assets/KyberNetworkWhitep",
    publicWeb: "https://home.kyber.network",
    publicSearch: "--",
    dec:
      "Kyber Network是一个提供即时转换和具有流动性保证的，去中心化和无需信任的加密资产交易协议。我们的协议将是促进区块链生态系统中加密资产流动性的关键，将推进大众对于加密代币作为可交易资产的认知。",
  },
  YFIUSDT: {
    name: "YFI",
    code: "YFI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-07-18",
    publicTotal: "30,000",
    publicCurrent: "29,962",
    publicPrice: "$34",
    shu: "https://github.com/iearn-finance",
    publicWeb: "https://yearn.finance/",
    publicSearch: "https://www.oklink.com/eth/address/0x0bc529c00c6401",
    dec:
      "Yearn.finance是一个去中心化金融(DeFi)平台，其旨在建立聚合流动性池，杠杆交易平台，自动做市，及其他功能型平台。 YFI是yearn.finance平台中的原生功能型代币。用户可以通过给平台的聚合流动性池，既ypool, 提供流动性来获得此代币。YFI代币可被用于平台治理。 yearn.finance 当前为借币方提供服务，并自动将其期望借出的代币与dYdX, Aave, and Compound之间自动分配划转以获得最高收益。 该平台也会陆续发布其他产品，包括yVault, 为Synthetix, mStable等其他项目提供更多套利策略。",
  },
  MKRUSDT: {
    name: "MKR",
    code: "MKR/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-01-30",
    publicTotal: "1,000,000",
    publicCurrent: "610,000",
    publicPrice: "--",
    shu: "https://www.okex.com/spot/trade/makerdao.com/whitepaper",
    publicWeb: "https://makerdao.com/",
    publicSearch:
      "https://www.oklink.com/eth/address/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
    dec:
      "MakerDAO 是以太坊上的去中心化自治组织和智能合约系统，提供以太坊上第一个去中心稳定货币 Dai。 DAI 是有数字资产抵押背书的硬通货，和美元保持1:1锚定。 MKR 是 Maker系统的管理型代币和效用代币，用来支付借 Dai 的稳定费用以及参与管理系统。与 Dai 稳定货币不同，由于其独特的供给机制和在Maker平台上的作用，MKR的价值和整个系统的表现息息相关。",
  },
  XTZUSDT: {
    name: "XTZ",
    code: "XTZ/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-01-30",
    publicTotal: "763,306,930",
    publicCurrent: "607,489,041",
    publicPrice: "--",
    shu:
      "https://tezos.com/static/position_paper-841a0a56b573afb28da16f6650152fb4.pdf",
    publicWeb: "tezos.com",
    publicSearch: "--",
    dec:
      "Tezos是一个具有图灵完备智能合约的区块链网络。主网络已经与2018年9月正式上线。Tezos早于2014年就已经注意到了区块链网络的治理缺陷，最早提出了链上自治的解决方案。Tezos的协议被经过精心设计，以便于更加方便地进行参数修正与协议迭代更新。通过链上自治系统，代币持有者可以决定系统的升级路线与优先级，这将有助于解决争端，告别网络硬分叉。此外，Tezos采用特有的LPOS机制，目前已经有超过470多位来自世界各地的验证者参与保护网络，再加之代码层面的形式验证设计，最大限度保证了整个网络的安全性。",
  },
  OMGUSDT: {
    name: "OMG",
    code: "OMG/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2017-06-27",
    publicTotal: "14024.54万",
    publicCurrent: "100%",
    publicPrice: "$0.27",
    shu: "https://cdn.omise.co/omg/whit…",
    publicWeb: "https://etherscan.io/token/Omis…",
    publicSearch: "--",
    dec: "基于以太坊实现实时的点对点价值交换和支付服务。",
  },
  CRVUSDT: {
    name: "CRV",
    code: "CRV/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-08-14",
    publicTotal: "1,374,214,451",
    publicCurrent: "130,669,518",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://curve.finance/",
    publicSearch: "https://www.oklink.com/eth/address/0xD533a949740bb3",
    dec:
      "Curve是一个以太坊上的去中心化流动池交易所，提供极其高效的稳定币交易。Curve于2020年1月上线，它能够让用户以低滑点、低手续费交易稳定币。用户可以在提供流动性的时候捕获其平台的交易费用，同时其接入了DeFi协议iearn和Compound，为流动性提供者提供额外收益，自动化兑换的方式也对用户使用更加友好。",
  },
  SNXUSDT: {
    name: "SNX",
    code: "SNX/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-03-12",
    publicTotal: "212,424,133",
    publicCurrent: "110,519,345",
    publicPrice: "$0.9",
    shu: "https://docs.synthetix.io/litepaper/",
    publicWeb: "https://www.synthetix.io/",
    publicSearch: "https://www.oklink.com/eth/address/0xc011a73ee8576fb",
    dec:
      "Synthetix是2018年基于以太坊搭建的衍生品流动性协议，旨在实现合成资产的发行和交易。Synthetix目前支持发行的合成资产包括法币、加密货币、大宗商品。原则上，该系统可以对无限的现实资产提供链上敞口。 Synths由Synthetix Network Token（SNX）抵押产生，参与staking可以收取由Synth交易量产生的费用。",
  },
  RENUSDT: {
    name: "REN",
    code: "REN/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-02-22",
    publicTotal: "999,999,633",
    publicCurrent: "881,153,018",
    publicPrice: "$0.56",
    shu:
      "https://republicprotocol.github.io/whitepaper/republic-whitepaper.pdf",
    publicWeb: "https://republicprotocol.com/",
    publicSearch:
      "https://www.oklink.com/eth/address/0x408e41876cCCDC0F92210600ef50372656052a38",
    dec:
      "Republic Protocol（REN）是一个BTC/ETH，BTC/ERC20和ETH/ERC20之间大量加密资产在分布式暗池交易的协议，是目前所知唯一拥有此类分布式加密资产暗池交易技术的区块链企业。它使用原子交换交易比特币，以太币以及其他代币。交易被收录在隐式订单簿上，通过多方计算协议引擎进行匹配，这既保证了订单在执行前都不会暴露其具体细节,同时也为其他交易者带来便利。 Republic Protocol系统依靠精心设计的激励系统来保证玩家遵守规则。RenEx“Dark Nodes”运行撮合引擎来保证暗池的运行，同时多个暗池的存在将减轻交易对手的风险。为了防止恶意攻击，规定网络节点（Darknodes）必须支付100000个Ren，这个也就是Republic Protocol的Token。",
  },
  SUSHIUSDT: {
    name: "SUSHI",
    code: "SUSHI/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    // volatility: "最新价x50美元",
    // volatilityIncome: "0.0001(美元/BSV) = 0.005美元",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2020-08-27",
    publicTotal: "170,875,267",
    publicCurrent: "127,244,443",
    publicPrice: "--",
    shu: "--",
    publicWeb: "https://sushiswap.org/",
    publicSearch: "https://www.oklink.com/eth/address/0x6b3595068778dd5",
    dec:
      "SushiSwap是一个Uniswap的分叉，它采用了UniSwap的核心流动池设计，但是加入了代币挖矿的超强社区属性。可以说Sushiswap = UniSwap + YAM。",
  },
  NEOUSDT: {
    name: "小蚁(NEO)",
    code: "NEO/USDT",
    exchange: "",
    type: "数字货币",
    lever: "10X~100X",
    unit: "250个",
    currency: "美元(USD)",
    min: "50 CNY",
    max: "50,000 CNY",
    charge: "保证金*杠杆*0.2%",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    num: "USDT",
    type: 2,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    clearTime: "次日05:55 ",
    publicTime: "2014-06-01",
    publicTotal: "100,000,000",
    publicCurrent: "70,538,831 ",
    publicPrice: "$0.159（¥1）",
    shu: "https://github.com/AntShares/...",
    publicWeb: " https://neo.org",
    publicSearch: " https://neotracker.io/ ",
    dec:
      "NEO，原名小蚁，2014年正式立项，2015年6月于Github实时开源。成立以来，NEO团队亲历了区块链行业的高潮与低谷，数字货币市场的狂热与冷却，各国监管与政府态度的模糊与清晰。我们相信，科技是这个时代变迁的原动力，在这股动力的推动下，我们将迈入新的“智能经济”时代。 \n" +
      " \n" +
      "NEO区块链通过将点对点网络、拜占庭容错、数字证书、智能合约、超导交易、跨链互操作协议等一系列技术相结合，让你快速、高效、安全、合法地管理你的智能资产",
  },
  ONTUSDT: {
    name: "ONT",
    unit: "2000个",
    code: "ONT/USDT",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10x~100x",
    currency: "美元(USD)",
    charge: "保证金*杠杆*0.2%",
    type: 2,
    rate: `1美元=${usd}人民币（汇率波动较大时，将会进行调整）`,
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "次日05:55 ",
    publicTime: "2018/02/26",
    publicTotal: "1,000,000,000",
    publicCurrent: "53.75%",
    publicPrice: "--",
    shu: "https://ont.io/wp/Ontology-Introd…",
    publicWeb: "https://ont.io/",
    publicSearch: " https://explorer.ont.io/",
    dec:
      "\t本体是新一代公有基础链项目 & 分布式信任协作平台。 本体提供新一代高性能基础公有链，包括完整的分布式账本、智能合约体系支持。 同时本体架构支持公有链网体系，除了提供基础公有链服务，通过本体区块链框架支持不同应用的公有链定制，并通过不同协议群支持链网协作。 在基础层之上，本体将持续提供各类分布式应用中的通用性模块，如分布式身份框架、分布式数据交换协议等支持分布式信任协作组件，并会根据应用需求持续扩展新的通用模块。",
  },
  DOGEUSDT: {
    name: "DOGE",
    code: "DOGE/USDT",
    exchange: "",
    type: "数字货币",
    currency: "美元(USD)",
    buyTimeAM: "06:00:00 - 05:50:00",
    sellTimeAM: "06:00:00 - 05:55:00",
    clearTime: "05:55:00",
    rate: `1USD=${usd}CNY（汇率波动较大时，将会进行调整）`,
    charge: "保证金*杠杆*0.2%",
    overnight: "保证金*杠杆*0.08%",
    min: "50 CNY",
    max: "50,000 CNY",
    lever: "10X~100X",
    publicTime: "2018-03-12",
    publicTotal: "无限，每个区块10000DOGE",
    publicCurrent: "118,933,137,118",
    publicPrice: "$0.9",
    shu: "--",
    publicWeb: "https://dogecoin.com",
    publicSearch: "https://dogechain.info/chain/Dogecoin",
    dec:
      "Dogecoin是一个致力于像货币一样有真正实用价值的加密货币。我们有更快的区块间隔和极低的费率，使得Dogecoin适用于小额支付和在线购物。Dogecoin已经被在线商家所采用，并且可以很容易地被用作消费者之间的转账手段。",
  },
};
