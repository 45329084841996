import React, {Component,useState} from 'react';
import './index.scss'
import {Link} from 'react-router-dom';
import fh from '../../assets/images/school/fh.png'
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { useNavigate } from "react-router-dom";

function Page04() {
    const history = useNavigate();
    const [which, setWhich] = useState(null);
    return (
        <div className="school page3">
        <HomeHeader/>
        <div className="wrap">
            <div className="box">
                <div className="menu">
                    <p onClick={()=>history('/school')} className="title">致远学院</p>
                    <dl>
                        <dt>
                            <h5>实时资讯</h5>
                            <p><Link to="/page0">市场快讯</Link></p>
                            <p><Link to="/page1">热点新闻</Link></p>
                            <p><Link to="/page2">财经日历</Link></p>
                        </dt>
                        <dt>
                            <h5>新手入门</h5>
                            <p><Link to="/page3" t="新手入门">基础知识</Link></p>
                            <p><Link to="/page4" t="新手入门">投资介绍</Link></p>
                        </dt>
                        <dt>
                            <h5>高手进阶</h5>
                            <p><Link to="/page5" t="高手进阶">基本面分析</Link></p>
                            <p><Link to="/page6" t="高手进阶">技术面分析</Link></p>
                            <p><Link to="/page7" t="高手进阶">综合技巧</Link></p>
                            <p><Link to="/page8" t="高手进阶">高手心态</Link></p>
                        </dt>
                    </dl>
                </div>
                {
                    which===null?(
                        <div className="main">
                            <div className="school-header">
                                <Link to="/school">致远学院</Link> &gt; <span v-param="title">投资介绍</span>
                            </div>
                            <div className="school-list">
                                <div className="school-list-body" id="投资介绍">
                                    <ul>
                                        <li><label>1</label><div onClick={()=>setWhich(0)}>期货投机交易</div></li>
                                        <li><label>2</label><div onClick={()=>setWhich(1)}>期货套利的方法</div></li>
                                        <li><label>3</label><div onClick={()=>setWhich(2)}>期货交易的风险</div></li>
                                        <li><label>4</label><div onClick={()=>setWhich(3)}>投资者如何控制风险</div></li>
                                    </ul>
                                </div>
                                <p className="back">
                                    <Link to="/school">
                                        <img src={fh} width="90px" alt="返回" title="返回"/>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    ):(
                        which===0?(
                            <div className="main">
                                <div className="school-header">
                                    <Link to="/school">致远学院</Link> &gt;
                                    <span>新手入门</span>
                                </div>
                                <div className="school-detail">
                                    <div onClick={()=>setWhich(null)} className="fh">
                                        <img src={fh} width="70px" alt="返回" title="返回"/>
                                    </div>
                                    <div className="school-detail-title">期货投机交易</div>
                                    <div className="school-detail-content">
                                        <p>期货投机交易指在期货市场上以获取价差收益为目的的期货交易行为。投机者根据自己对期货价格走势的判断，作出买进或卖出的决定，如果这种判断与市场价格走势相同，则投机者平仓出局后可获取投机利润；如果判断与价格走势相反，则投机者平仓出局后承担投机损失。由于投机的目的是赚取差价收益，所以，投机者一般只是平仓了结持有的期货合约，而不进行实物交割。投机交易分为两种：价差投机和套利交易。</p>
                                        <p>所谓价差投机是指投机者通过对价格的预期，在认为价格上升时买进、价格下跌时卖出，然后待有利时机再卖出或买进原期货合约，以获取利润的活动。进行价差投机的关键在于对期货市场价格变动趋势的分析预测是否准确，由于影响期货市场价格变动的因素很多，特别是投机心理等偶然性因素难以预测，因此，正确判断难度较大，所以这种投机的风险较大。套利交易是期货投机交易中的一种特殊方式，它利用期货市场中不同月份、不同市场、不同商品之间的相对价格差，同时买入和卖出不同种类的期货合约，来获取利润。正如一种商品的现货价格与期货价格经常存在差异，同种商品不同交割月份的合约价格变动也存在差异；同种商品在不同的期货交易所的价格变动也存在差异。由于这些价格差异的存在，使期货市场的套利交易成为可能。</p>
                                        <p>套利交易丰富和发展了期货投机交易的内容，并使期货投机不仅仅局限于期货合约绝对价格水平变化，更多地转向期货合约相对价格水平变化。套利交易对期货市场的稳定发展有积极的意义，具体地讲，套利的作用主要表现在两个方面：一方面，套利提供了风险对冲的机会；另一方面，套利有助于合理价格水平的形成。</p>
                                    </div>
                                </div>
                            </div>
                        ):(
                            which===1?(
                                <div className="main">
                                    <div className="school-header">
                                        <Link to="/school">致远学院</Link> &gt;
                                        <span>新手入门</span>
                                    </div>
                                    <div className="school-detail">
                                        <div onClick={()=>setWhich(null)} className="fh">
                                            <img src={fh} width="70px" alt="返回" title="返回"/>
                                        </div>
                                        <div className="school-detail-title">期货套利的方法</div>
                                        <div className="school-detail-content"><p
                                            className="subtitle">跨交割月份套利(跨月套利)</p>
                                            <p>投机者在同一市场利用同一种商品不同交割期之间的价格差距的变化，买进某一交割月份期货合约的同时，卖出另一交割月份的同类期货合约以谋取利润的活动。其实质，是利用同一商品期货合约的不同交割月份之间的差价的相对变动来获利。这是最为常用的一种套利形式。比如：如果你注意到
                                                5 月份的大豆和 7 月份的大豆价格差异超出正常的交割、储存费，你应买入 5 月份的大豆合约而卖出 7 月份的大豆合约。过后，当
                                                7 月份大豆合约与 5
                                                月份大豆合约更接近而缩小了两个合约的价格差时，你就能从价格差的变动中获得一笔收益。跨月套利与商品绝对价格无关，而仅与不同交割期之间价差变化趋势有关。</p>
                                            <p>具体而言，这种套利又可细分为三种：牛市套利，熊市套利及蝶式套利。</p>        <p
                                                className="subtitle">跨市场套利(跨市套利)</p>
                                            <p>投机者利用同一商品在不同交易所的期货价格的不同，在两个交易所同时买进和卖出期货合约以谋取利润的活动。</p>
                                            <p>当同一种商品在两个交易所中的价格差额超出了将商品从一个交易所的交割仓库运送到另一交易所的交割仓库的费用时，可以预计，它们的价格将会缩小并在未来某一时期体现真正的跨市场交割成本。比如说小麦的销售价格，如果芝加哥交易所比堪萨斯城交易所高出许多而超过了运输费用和交割成本，那么就会有现货商买入堪萨斯城交易所的小麦并用船运送到芝加哥交易所去交割。</p>
                                            <p className="subtitle">跨商品套利</p>
                                            <p>所谓跨商品套利，是指利用两种不同的、但是相互关联的商品之间的期货价格的差异进行套利，即买进(卖出)某一交割月份某一商品的期货合约，而同时卖出(买入)另一种相同交割月份、另一关联商品的期货合约。</p>
                                        </div>
                                    </div>
                                </div>
                            ):(
                               which===2?(
                                    <div className="main">
                                        <div className="school-header">
                                            <Link to="/school">致远学院</Link> &gt;
                                            <span>新手入门</span>
                                        </div>
                                        <div className="school-detail">
                                            <div onClick={()=>setWhich(null)} className="fh">
                                                <img src={fh} width="70px" alt="返回" title="返回"/>
                                            </div>
                                            <div className="school-detail-title">期货交易的风险</div>
                                            <div className="school-detail-content"><p
                                                className="subtitle">（一）经纪委托风险</p>
                                                <p>即客户在选择和期货经纪公司确立委托过程中产生的风险。客户在选择期货经纪公司时，应对期货经纪公司的规模、资信、经营状况等对比选择，确立最佳选择后与该公司签订《期货经纪委托合同》。
                                                    投资者在准备进入期货市场时必须仔细考察、慎重决策，挑选有实力、有信誉的公司。</p>        <p
                                                    className="subtitle">（二）流动性风险</p>
                                                <p>即由于市场流动性差，期货交易难以迅速、及时、方便地成交所产生的风险。这种风险在客户建仓与平仓时表现得尤为突出。如建仓时，交易者难以在理想的时机和价位入市建仓，难以按预期构想操作，套期保值者不能建立最佳套期保值组合；平仓时则难以用对冲方式进行平仓，尤其是在期货价格呈连续单边走势，或临近交割，市场流动性降低，使交易者不能及时平仓而遭受惨重损失。因此，要避免遭受流动性风险，重要的是客户要注意市场的容量，研究多空双方的主力构成，以免进入单方面强势主导的单边市。</p>
                                                <p className="subtitle">（三）强行平仓风险</p>
                                                <p>期货交易实行由期货交易所和期货经纪公司分级进行的每日结算制度。在结算环节，由于公司根据交易所提供的结算结果每天都要对交易者的盈亏状况进行结算，所以当期货价格波动较大、保证金不能在规定时间内补足的话，交易者可能面临强行平仓风险。除了保证金不足造成的强行平仓外，还有当客户委托的经纪公司的持仓总量超出一定限量时，也会造成经纪公司被强行平仓，进而影响客户强行平仓的情形。因此，客户在交易时，要时刻注意自己的资金状况，防止由于保证金不足，造成强行平仓，给自己带来重大损失。</p>
                                                <p className="subtitle">（四）交割风险</p>
                                                <p>期货合约都有期限，当合约到期时，所有未平仓合约都必须进行实物交割。因此，不准备进行交割的客户应在合约到期之前将持有的未平仓合约及时平仓，以免于承担交割责任。
                                                    这是期货市场与其他投资市场相比，较为特殊的一点，新入市的投资者尤其要注意这个环节，尽可能不要将手中的合约，持有至临近交割，以避免陷入被"逼仓"的困境。</p>
                                                <p className="subtitle">（五）市场风险</p>
                                                <p>客户在期货交易中，最大的风险来源于市场价格的波动。这种价格波动给客户带来交易盈利或损失的风险。因为杠杆原理的作用，这个风险因为是放大了的，投资者应时刻注意防范。</p>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    which===3?(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;
                                                <span>新手入门</span>
                                            </div>
                                            <div className="school-detail">
                                                <div onClick={()=>setWhich(null)} className="fh">
                                                    <img src={fh} width="70px" alt="返回" title="返回"/>
                                                </div>
                                                <div className="school-detail-title">投资者如何控制风险</div>
                                                <div className="school-detail-content">
                                                    <p>作为期货市场的投资者，特别是新进入期货市场的新手，在进行期货交易时，最主要的要注意几个方面：</p>
                                                    <p>①严格遵守期货交易所和期货经纪公司的一切风险管理制度。如若违反这些制度，将使您处于非常被动的地位。</p>
                                                    <p>②投资的资金、规模必须正当、适度。如果资金渠道有问题，一旦抽紧，势必影响交易；而交易规模如果失当，盲目下单、过量下单，就会使您面临超越自己财力、能力的巨大风险。切记，期货市场是风险投资市场，决不是赌场，不要把自己降格为一个赌徒。</p>
                                                    <p>③要有良好的投资战略。根据自己的条件(资金、时间、健康等)，培养良好的心理素质，不断充实自己，逐步形成自己的投资战略。</p>
                                                    <p>④关注信息、分析形势，注意期货市场风险的每一个环节。期货市场是一个消息满天飞的地方，要逐步培养分析能力，充分掌握有价值的信息。同时，时刻注意市场的变化，提高自己反应的灵敏度。记住，市场永远是对的。</p>
                                                    <p>市场风险是不可预知的，但又是可以通过分析，加以防范的。在这方面，投资者要做的工作很多，最主要的就是，在入市投资时，首先要从自己熟悉的品种做起，做好基础工作，从基本面分析做起，辅之以技术分析，能从套期货保值做起更为稳妥。千万不能逆势而为，初期一定要设好"止损点"，以免损失不断扩大，难以全身而退。</p>
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;
                                                <span>新手入门</span>
                                            </div>
                                            <div>暂无数据</div>
                                        </div>
                                    )
                                )
                            )
                        )
                    )
                }

            </div>
        </div>
        <HomeFooter/>
    </div>
    );
  }
  
  export default Page04;

