import React, { useState, useEffect } from "react";
import { formatDate } from "@/utils";
import "./notice.scss";
import { HomeFooter } from "../components";
import banner from "../../assets/images/notice/notice-banner.jpg";
import fh from "../../assets/images/school/fh.png";
import HomeHeader from "../components/homeHeader";
import { useNavigate, useParams } from "react-router";
import EVENT from "../../library/api";

const NoticeDetails = ({}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [content, setContent] = useState("");

  useEffect(() => {
    notice();
  }, []);

  const notice = async () => {
    EVENT.News.getWealth().then(({ notices }) => {
      const content = notices.filter((item) => item.id === id);
      setList(notices.reverse());
      setContent(content[0]);
    });
  };

  const goDetails = (id) => {
    navigate(`/noticeDetails?id=${id}`);
  };

  return (
    <div className={"notice noticeDetails"}>
      <HomeHeader />
      <div className="banner">
        <img src={banner} alt="" />
      </div>
      <div className="body">
        <div className="left">
          <div className="title">浏览排行</div>
          <div className="rank">
            <ul>
              {!!list && list.length > 0
                ? list.map((item, index) => {
                    if (item.content.indexOf("%%") !== -1) {
                      const start = item.content.indexOf("%%");
                      const end = item.content.lastIndexOf("%%");
                      item.img = item.content.slice(start + 2, end);
                      item.content = item.content.replace(item.img, "");
                      item.content = item.content.replace(
                        new RegExp("%%", "gm"),
                        ""
                      );
                      item.img = item.img.replace(
                        new RegExp("<wbr>", "gm"),
                        ""
                      );
                      item.img = item.img.replace(/&lt;/, "<");
                      item.img = item.img.replace(/&gt;/, ">");
                    }
                    return (
                      <li key={index} onClick={() => goDetails(item.id)}>
                        {index + 1}、{item.title}
                      </li>
                    );
                  })
                : ""}
            </ul>
          </div>
        </div>
        <div className="right">
          {!!content ? (
            <div className="wrap">
              <div className="title0">
                <h5>{content.title}</h5>
                <p>
                  <span className="icon icon-clock" />
                  <span className={"time"}>
                    {formatDate("y-m-d h:i:s", {
                      date: content.time,
                    })}
                  </span>
                </p>
                <img
                  src={fh}
                  className="fh"
                  width="70px"
                  alt="返回"
                  title="返回"
                  onClick={() => navigate("/notice")}
                />
              </div>
              <div
                className={"content"}
                dangerouslySetInnerHTML={{
                  __html: content.content,
                }}
              />
              <div
                className={"content"}
                dangerouslySetInnerHTML={{
                  __html: content.img,
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default NoticeDetails;
