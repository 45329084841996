import React, { useEffect, useState } from "react";
import Store from "@/store";
import "./index.scss";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Popover, notification, Tooltip } from "antd";
import logo from "../../../assets/images/home/logo.svg";
import mine from "../../../assets/images/userCenter/head/mine.svg";
import app from "../../../assets/images/userCenter/head/app.svg";
import cs from "../../../assets/images/userCenter/head/cs.svg";
import notice from "../../../assets/images/userCenter/head/notice.svg";
import message from "../../../assets/images/common/message.svg";
import x from "../../../assets/images/common/x.svg";
import download_qr from "../../../assets/images/download_qrV2.png";

import { Brand } from "@/core";
import { observer } from "mobx-react-lite";

import MessageBox from "./message";

//todo 打开客服
const openCs = () => {
  let w = 600,
    h = 670;
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const user = Store.User.accountInfo;
  const link = `${Brand.cusServiceUrl}&metadata={"name":"${
    user?.username || "游客"
  }"}`;
  const feature = `
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
        `;
  window.open(link, "客服", feature);
};

const HomeHeader = () => {
  const [msgData, setMsgData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const unread = Store.User.accountInfo?.unread || 0;

  useEffect(() => {
    if (Store.User.isLogin) {
      setIsLogin(true);
    }
    Store.User.updateDetail();
  }, []);

  const content = (
    <div className={"nav"}>
      <div>
        <b>{Store?.User?.accountInfo?.username}</b>
        <p>UI:{Store?.User?.accountInfo?.userId}</p>
      </div>
      <Link to={"/account/userCenter"}>账户安全</Link>
      <Link to={"/account/myAsset"}>资产管理</Link>
      <Link to={"/account/fundsDetail"}>资金明细</Link>

      <a
        onClick={() => {
          Store.User.setLogout().then(({ message }) =>
            notification.success({ message: "账户退出成功" })
          );

          navigate("/");
        }}
      >
        退出登录
      </a>
    </div>
  );

  const nav = (
    <div className={"nav"}>
      <Link to={"/cooperation/invite"}>邀请好友</Link>
    </div>
  );

  const download = (
    <div className={"download"}>
      <img src={download_qr} alt="" />
      <div>
        <b>扫码下载APP</b>
        <p>IOS + Android</p>
        <Link to={"/download"}>查看全部</Link>
      </div>
    </div>
  );

  return (
    <div id={"Header"}>
      <div className={"head_left"}>
        <Link to={"/"}>
          <img src={logo} alt="" />
        </Link>
        {isLogin ? (
          <NavLink
            to={"/trade/CL"}
            activeClassName={"active"}
            onClick={() => {
              Store.Quote.switchSimulate(false);
            }}
          >
            行情
          </NavLink>
        ) : (
          ""
        )}
        <NavLink to={"/activity"} activeClassName={"active"}>
          活动专区
        </NavLink>
        <NavLink to={"/school"} activeClassName={"active"}>
          致远学院
        </NavLink>
        <NavLink
          to={"/cooperation/invite"}
          isActive={(match, location) =>
            location.pathname.startsWith("/cooperation/")
          }
          activeClassName={"active"}
        >
          推广合作
        </NavLink>
        <NavLink to={"/download"} activeClassName={"active"}>
          移动端下载
        </NavLink>
      </div>
      {isLogin ? (
        <div className={"head_right"}>
          <div className={"btn_box"}>
            <Link to={"/account/recharge"}>充值</Link>
            <Link to={"/account/withdraw"}>提现</Link>
          </div>
          <Popover content={content} placement="bottom">
            <a>
              <img
                src={mine}
                style={{ width: "20px", height: "20px", marginTop: "15px" }}
              />
            </a>
          </Popover>

          <Tooltip
            placement="bottom"
            title={"在线客服"}
            overlayClassName={"navgation-tooltip"}
          >
            <a
              onClick={() => {
                openCs();
              }}
            >
              <img
                src={cs}
                style={{ width: "20px", height: "20px", marginTop: "15px" }}
              />
            </a>
          </Tooltip>

          <Tooltip
            placement="bottom"
            title={"通知"}
            overlayClassName={"navgation-tooltip"}
          >
            <a
              onClick={() => {
                navigate("/notice");
              }}
            >
              <img
                src={notice}
                style={{ width: "20px", height: "20px", marginTop: "15px" }}
              />
            </a>
          </Tooltip>

          <Tooltip
            placement="bottom"
            title={"留言箱"}
            overlayClassName={"navgation-tooltip"}
          >
            <a
              onClick={() => {
                if (!Store.User.isLogin) return;
                setShowMessage(true);
              }}
            >
              <img
                src={message}
                style={{ width: "20px", height: "20px", marginTop: "15px" }}
              />
              {unread > 0 && <span className={"unread"}>{unread}</span>}
            </a>
          </Tooltip>
          <Popover content={download} placement={"bottom"}>
            <a>
              <img
                src={app}
                style={{ width: "20px", height: "20px", marginTop: "15px" }}
              />
            </a>
          </Popover>
        </div>
      ) : (
        <div className="head_right">
          <div className={"btn_box"}>
            <Link to={"/CommonLogin"}>登录</Link>
            <Link to={"/CommonRegister"}>注册</Link>
          </div>
        </div>
      )}

      {showMessage && (
        <div className={"message_box"}>
          <div className={"message_main"}>
            <div className={"message_head"}>
              <b>留言箱</b>{" "}
              <img
                onClick={() => {
                  setShowMessage(false);
                }}
                src={x}
                alt=""
              />
            </div>
            <div className={"message_ul"}>
              <MessageBox />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(HomeHeader);
