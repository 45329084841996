import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { SimpleInput } from "../../components";
import { SimpleSelect } from "../../components";
import Linkage from "../../components/linkage";
import { bankName } from "../../components/tool";
import { notification } from "antd";
import { nameMask, removeAllSpace } from "../../../utils/index";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import Store from "@/store";

const AddBankCardV1 = (props) => {
  const [bank, setBank] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [subbranch, setSubbranch] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cfmCardNumber, setCfmCardNumber] = useState("");

  const { name } = Store.User.accountInfo;

  let verify =
    bank.length !== 0 &&
    province.length !== 0 &&
    city.length !== 0 &&
    subbranch.length !== 0 &&
    cardNumber.length !== 0 &&
    cardNumber.length > 14 &&
    cfmCardNumber.length !== 0 &&
    cfmCardNumber.length > 14;

  const submit = (ve) => {
    if (ve) return;
    if (cardNumber !== cfmCardNumber)
      return notification.error({
        message: "卡号输入不一致，请确认后重新输入",
      });
    let cN = removeAllSpace(cardNumber);
    let cfmN = removeAllSpace(cfmCardNumber);

    EVENT.Account.addBankCard({
      bankType: bank,
      province,
      city,
      cardNumber: cN,
      cfmCardNumber: cfmN,
      subbranch,
    })
      .then(() => {
        Store.User.updateDetail();
        notification.success({ message: "银行卡绑定成功" });
        props.onCancel();
        props.bindAfter();
      })
      .catch((err) => {
        notification.success({ message: err.message });
      });
  };

  return (
    <Modal
      title={"添加银行卡"}
      visible={props.visible}
      className={"alertModal aboutPass addBankcard"}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={<div />}
    >
      <SimpleInput label={"开户姓名"} value={nameMask(name)} disable={true} />
      <SimpleSelect
        label={"选择银行"}
        options={bankName()}
        onChange={(e) => {
          setBank(e);
        }}
        defaultValue={"选择银行"}
      />
      <Linkage
        getProvince={(e) => {
          setProvince(e);
        }}
        getCity={(e) => {
          setCity(e);
        }}
      />
      <SimpleInput
        label={"开户支行"}
        placeholder={"请输入银行卡开户支行"}
        type={"text"}
        maxlength={30}
        onChange={(e) => {
          setSubbranch(e);
        }}
      />
      <SimpleInput
        label={"银行卡号"}
        placeholder={"请输入银行卡号"}
        type={"card"}
        onChange={(e) => {
          setCardNumber(e);
        }}
      />
      <SimpleInput
        label={"确认卡号"}
        placeholder={"请输入确认银行卡号"}
        type={"card"}
        onChange={(e) => {
          setCfmCardNumber(e);
        }}
      />
      <div
        className={`confirm_btn ${!verify && "error"}`}
        onClick={() => {
          submit(!verify);
        }}
      >
        添加
      </div>
    </Modal>
  );
};

export default observer(AddBankCardV1);
