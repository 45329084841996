import React, { useState, useEffect } from "react";
import { formatDate } from "@/utils";
import { Pagination, notification } from "antd";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import { RESTRICT } from "@/library/standard";
import funds from "../../../assets/images/funds_light.png";

const MyUser = (props) => {
  const [data, setdata] = useState([]);
  const [total, settotal] = useState(1);
  const [subName, setsubName] = useState("");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    update();
  }, []);

  const update = (page = 1) => {
    setLoading(true);

    EVENT.History.updateUserList(page, 10, subName)
      .then(({ data, page, total }) => {
        setdata(data);
        setPage(page);
        settotal(total);
        setLoading(false);
      })
      .catch((err) => {
        notification.error({ message: err });
        setLoading(false);
      });
  };

  return (
    <div className={"user_box"}>
      <div className="title">
        <div className={"title_section"}>
          <label>被邀请人</label>
          <input
            type="text"
            placeholder={"请输入昵称"}
            maxLength={20}
            onChange={(e) => {
              setsubName(e.target.value);
            }}
          />
        </div>
        <div
          className={"search"}
          onClick={() => {
            update();
          }}
        >
          搜索
        </div>
      </div>
      <ol>
        <li className={"user_title"}>
          <div>被邀请人</div>
          <div>今日交易量</div>
          <div>累计交易量</div>
          <div>累计交易次数</div>
          <div>注册时间</div>
          <div>最后登录</div>
        </li>
        {!!data && data.length !== 0 ? (
          data.map((item, key) => {
            return (
              <li key={key}>
                <div>{item.username}</div>
                <div>{item.tradeAmountDay}</div>
                <div>{RESTRICT.float(item.tradeAmount, 2).toFixed(2)}</div>
                <div>{item.tradeCount}</div>
                <div>
                  {formatDate("y-m-d h:i:s", { date: item.registerTime })}
                </div>
                <div>{formatDate("y-m-d h:i:s", { date: item.loginTime })}</div>
              </li>
            );
          })
        ) : (
          <div className={"empty"}>
            <div>
              <img src={funds} />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </ol>
      <div className={"pagination"}>
        <Pagination
          onChange={(page) => {
            setPage(page);
          }}
          current={page}
          defaultPageSize={10}
          total={total}
        />
      </div>
    </div>
  );
};

export default observer(MyUser);
