import React, { useEffect, useMemo } from "react";
import { Modal, Slider, notification } from "antd";
import CSvg from "../../../utils/svg";
import loss from "../../../assets/images/trade/loss.svg";
import add from "../../../assets/images/trade/add.svg";
import { RESTRICT } from "@/library/standard";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import "./index.scss";

const SPSLSet = ( props ) => {
  const { list = [] } = Store.Position;
  const stopLossStepMin = Store.FuturesSPSL.stopLossStepMin;
  useEffect(() => {
    if (Store?.FuturesSPSL?.SPSL?.stopLossIndex < stopLossStepMin) {
      Store.FuturesSPSL.setStopLoss(
        stopLossStepMin <= 0 ? stopLossStepMin + 1 : stopLossStepMin
      );
    }
  }, [stopLossStepMin]);
  const orderInfo = useMemo(
    () => list.find((item) => item.id === props.id),
    [list, props.id]
  );
  
  if (Store.FuturesSPSL.SPSL === null) return;

  const {
    name,
    contractCode,
    stopProfit,
    stopProfitPrice,
    stopProfitStep,
    stopProfitIndex,
    stopLoss,
    stopLossPrice,
    pair,
    stopLossStep,
    stopLossIndex,
    _tempStopLossPrice,
    _tempStopProfitPrice,
  } = Store.FuturesSPSL.SPSL;

  const updateProfit = (isAdd) => {
    if (isAdd) {
      Store.FuturesSPSL.setStopProfit(stopProfitIndex + 1);
    } else {
      Store.FuturesSPSL.setStopProfit(stopProfitIndex - 1);
    }
  };
  const updateLoss = (isAdd) => {
    if (isAdd) {
      Store.FuturesSPSL.setStopLoss(stopLossIndex + 1);
    } else {
      if (stopLossIndex - 1 === stopLossStepMin) return;
      Store.FuturesSPSL.setStopLoss(stopLossIndex - 1);
    }
  };

  // 設置止盈止損
  const submit = () => {
    props.onCancel();
    Store.FuturesSPSL.setSPSL()
      .then(() => {
        notification.success({ message: "设置止盈止损成功" });
        Store.Position.start(false);
        props.onCancel();
      })
      .catch(({ message }) => {
        notification.error({ message });
        props.onCancel();
      });
  };

  return (
    <Modal
      title={"设置止盈止损"}
      visible={props.visible}
      className={"spsl theme_dark"}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={<div />}
    >
      <div className="title">
        <div>
          {name}({contractCode})
        </div>
        <div>
          <b className={orderInfo?.income > 0 ? "red" : "green"}>
            {orderInfo?.income.toFixed(2)}
          </b>
          <span>元</span>
        </div>
      </div>
      <div className={"slider_box"}>
        <div className={"slider_title"}>
          <div>止盈金额</div>
          <div>
            <b className={"red"}>
              {!!pair
                ? RESTRICT.float(stopProfit, 2)
                : Math.round(stopProfit)}
            </b>
            元 ({stopProfitPrice})
          </div>
        </div>
        <div className={"controller"}>
          <div
            className={"btn"}
            onClick={() => {
              updateProfit(false);
            }}
          >
            <CSvg src={loss} />
          </div>
          <Slider
            className={"stopProfit stopLoss"}
            defaulatValue={stopProfit}
            step={1}
            min={1}
            value={stopProfitIndex}
            max={stopProfitStep}
            onChange={(val) => Store.FuturesSPSL.setStopProfit(val)}
          />
          <div
            className={"btn"}
            onClick={() => {
              updateProfit(true);
            }}
          >
            <CSvg src={add} />
          </div>
        </div>
      </div>
      <div className={"slider_box"}>
        <div className={"slider_title"}>
          <div>止损金额</div>
          <div>
            <b className={stopLoss > 0 ? "red" : "green"}>
              {!!pair
                ? RESTRICT.float(stopLoss, 2)
                : Math.round(stopLoss)}
            </b>
            元 ({stopLossPrice})
          </div>
        </div>
        <div className={"controller"}>
          <div
            className={"btn"}
            onClick={() => {
              updateLoss(false);
            }}
          >
            <CSvg src={loss} />
          </div>
          <Slider
            className={"stopLoss"}
            defaulatValue={stopLoss}
            step={1}
            min={stopLossStepMin}
            value={stopLossIndex}
            max={stopLossStep}
            onChange={(val) => Store.FuturesSPSL.setStopLoss(val)}
          />
          <div
            className={"btn"}
            onClick={() => {
              updateLoss(true);
            }}
          >
            <CSvg src={add} />
          </div>
        </div>
      </div>
      <div className="btn_box">
        <div
          onClick={() => {
            props.onCancel();
          }}
        >
          取消
        </div>
        <div
          className={"confirm"}
          onClick={() => {
            submit();
          }}
        >
          确定
        </div>
      </div>
    </Modal>
  );
};

export default observer(SPSLSet);
