import service from "../network/axios";

/**
 * 获取深度图初始历史数据可用可不用
 */
const getSpotHistory = (url, info) => {
  return new Promise(async (resolve, reject) => {
    try {
      let str = "?",
        res;
      for (let [n, v] of Object.entries(info)) {
        str += `${n}=${v}&`;
      }
      res = await fetch(`${url}mkpai/depth-v2${str}`, {
        method: "GET",
        mode: "cors",
        cors: true,
      });
      if (res.status !== 200) return;
      const { data } = await res.json();
      resolve([data.asks, data.bids]);
    } catch (e) {}
  });
};

/**
 * @param {*} data 下单数据
 * @returns
 */
const order = (data) => {
  const o = data;
  return service.post("/api/order/open", o);
};

/**
 * @param {*} _ 当前时间戳
 * @returns
 */
const position = () => {
  const o = {
    _: new Date().getTime(),
  };
  return service.get("/api/order/position", { params: o });
};

/**
 * @param {string} 订单ID
 */
const cancel = (orderId) => {
  const o = {
    orderId,
  };
  if (orderId.indexOf(",") !== -1) {
    o.source = "一键撤单";
  } else {
    o.source = "撤单";
  }
  return service.post("/api/order/revoke", o);
};

export default {
  getSpotHistory,
  position,
  order,
  cancel,
};
