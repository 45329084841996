import React, { useState, useEffect } from "react";
import "./index.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import { flag, star } from "../components/tool";

import { useNavigate } from "react-router-dom";
import EVENT from "@/library/api";

const { TabPane } = Tabs;

const Page02 = () => {
  const history = useNavigate();
  const [calendarData, setCalenderData] = useState([]);
  const [localArr, setLocalArr] = useState([]);
  const [defaultDay, setDefaultDay] = useState("");

  useEffect(() => {
    let day = new Date().getDate() - 1;
    setDefaultDay(day);
    updateCalender();
  }, []);

  const updateCalender = (v) => {
    getMonthTime();
    EVENT.News.getCalendar(getToday(v)).then((data) => {
      setCalenderData(data.news.newsData);
    });
  };

  const getToday = (v) => {
    let now = new Date(),
      year = now.getFullYear(),
      month =
        now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1,
      day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();

    if (!!v) {
      let d = Number(v) + 1 < 10 ? `0${Number(v) + 1}` : Number(v) + 1;
      return year + month.toString() + d;
    } else {
      return year + month.toString() + day.toString();
    }
  };

  // 获取当前月
  const mGetDate = () => {
    let date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth(),
      d = new Date(year, month, 1);
    return d.getTime();
  };

  const nGetDate = () => {
    let date = new Date(),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      d = new Date(year, month, 1);
    return d.getTime();
  };

  const diff = (v) => {
    let obj = {},
      week = ["日", "一", "二", "三", "四", "五", "六"],
      year = v.getFullYear(),
      month = v.getMonth() + 1 < 10 ? `0${v.getMonth() + 1}` : v.getMonth(),
      day = v.getDate() < 10 ? `0${v.getDate()}` : v.getDate();
    obj.year = v.getFullYear();
    obj.month = v.getMonth() + 1;
    obj.day = v.getDate();
    obj.week = "星期" + week[v.getDay()];
    obj.today = new Date().getDate();
    obj.getTime = year + month + day;
    obj.val = month.toString() + "-" + day.toString();
    return obj;
  };

  //todo 获取一月内数据
  const getMonthTime = () => {
    let ary = [],
      now = new Date(),
      day = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate(),
      s = 0;
    for (let i = mGetDate(); i < nGetDate(); i += 24 * 60 * 60 * 1000) {
      s = s + 1;
      let d3 = new Date(i);
      ary.push(diff(d3));
      if (s === day) break;
    }

    if (localArr.length < 1) {
      setLocalArr(ary);
    }
  };

  const handleDay = (v) => {
    const date = localArr[v]["getTime"];
    EVENT.News.getCalendar(date).then((data) => {
      setCalenderData(data.news.newsData);
    });
  };

  return (
    <>
      {localArr !== [] && (
        <div className="school page0">
          <HomeHeader />
          <div className="wrap">
            <div className="box">
              <div className="menu">
                <p onClick={() => history("/school")} className="title">
                  致远学院
                </p>
                <dl>
                  <dt>
                    <h5>实时资讯</h5>
                    <p>
                      <Link to="/page0">市场快讯</Link>
                    </p>
                    <p>
                      <Link to="/page1">热点新闻</Link>
                    </p>
                    <p>
                      <Link to="/page2">财经日历</Link>
                    </p>
                  </dt>
                  <dt>
                    <h5>新手入门</h5>
                    <p>
                      <Link to="/page3" t="新手入门">
                        基础知识
                      </Link>
                    </p>
                    <p>
                      <Link to="/page4" t="新手入门">
                        投资介绍
                      </Link>
                    </p>
                  </dt>
                  <dt>
                    <h5>高手进阶</h5>
                    <p>
                      <Link to="/page5" t="高手进阶">
                        基本面分析
                      </Link>
                    </p>
                    <p>
                      <Link to="/page6" t="高手进阶">
                        技术面分析
                      </Link>
                    </p>
                    <p>
                      <Link to="/page7" t="高手进阶">
                        综合技巧
                      </Link>
                    </p>
                    <p>
                      <Link to="/page8" t="高手进阶">
                        高手心态
                      </Link>
                    </p>
                  </dt>
                </dl>
              </div>
              <div className="main">
                <div className="article">
                  <div className="school-header">
                    <Link href="/school">致远学院</Link> &gt;
                    <span>财经日历</span>
                  </div>
                  <div className={"calender_box"}>
                    <Tabs
                      defaultActiveKey={defaultDay}
                      onTabClick={(e) => {
                        handleDay(e);
                      }}
                    >
                      {localArr.map((item, key) => (
                        <TabPane key={key} tab={item.val + "/" + item.week}>
                          <ul>
                            <li className={"ul_title"}>
                              <div>时间</div>
                              <div className={"country"}>国/区</div>
                              <div className={"info_main"}>指标名称</div>
                              <div className={"important"}>重要性</div>
                              <div>前值</div>
                              <div>预测值</div>
                              <div>公布值</div>
                              <div>影响</div>
                            </li>
                            {!!calendarData &&
                              calendarData.map((item, key) => {
                                return (
                                  <li key={key}>
                                    <div>{item.time_show}</div>
                                    <div className={"country"}>
                                      <img src={flag(item.country)} alt="" />
                                    </div>
                                    <div
                                      className={`info_main ${
                                        item.star > 3 && "red"
                                      }`}
                                    >
                                      {item.title}
                                    </div>
                                    <div className={"important"}>
                                      <img src={star(item.star)} alt="" />
                                    </div>
                                    <div className={item.star > 3 && "red"}>
                                      {item.unit === "%"
                                        ? item.previous + item.unit
                                        : item.previous}
                                    </div>
                                    <div className={item.star > 3 && "red"}>
                                      {item.consensus == null
                                        ? "--"
                                        : item.unit === "%"
                                        ? item.consensus + item.unit
                                        : item.consensus}
                                    </div>
                                    <div className={item.star > 3 && "red"}>
                                      {item.actual == null
                                        ? "未公布"
                                        : item.unit === "%"
                                        ? item.actual + item.unit
                                        : item.actual}
                                    </div>
                                    <div>
                                      <b
                                        className={
                                          item.status_name === "未公布"
                                            ? ""
                                            : item.status_name === "利空"
                                            ? "green"
                                            : "red"
                                        }
                                      >
                                        {item.status_name}
                                      </b>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeFooter />
        </div>
      )}
    </>
  );
};

export default Page02;
