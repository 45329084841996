import React from 'react';
import './index.scss';
import {Link,Navigate} from 'react-router-dom';
import {Route, Routes} from 'react-router';

import logo from '../../../assets/images/common/LOGO.svg';

import title from '../../../assets/images/common/title.svg';
import ResetLoginPassword from '../resetLoginPwd';

function CommonResetLoginPassword() {
    return(
        <div id={'Common_template'}>
        <main>
            <Link to={'/'} className={'logo'}><img src={logo} alt=""/></Link>
            <div className={'info'}>
                <div className={'description'}>
                    <img src={title} alt=""/>
                    <p>客户端全渠道覆盖 <b>·</b> 交易商品种类多样</p>
                    <p>精尖团队风险管控 <b>·</b> 行情精准专业高效</p>
                    <p>一站式服务更贴心 <b>·</b> 一个账户直连世界</p>
                </div>
               <div className={'switch_box'}>
                   <div className={'common'}>
                       <ResetLoginPassword/>
                   </div>
               </div>
            </div>
            <div className="foot">Copyright© 2015-2021 Zhiyuan Capital Group. All Rights Reserved.</div>
        </main>
     </div>
    )
}

export default CommonResetLoginPassword;


