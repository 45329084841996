import React, { useEffect, useState } from "react";
import "./index.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";

import safety from "../../assets/images/userCenter/safety.svg";
import asset from "../../assets/images/userCenter/asset.svg";
import partner from "../../assets/images/userCenter/partner.svg";
import Store from "@/store";
import LoginHistory from "./loginHistory";
import NameVerify from "./nameVerify";
import UserCenter from "./userCenter";
import MyAsset from "./myAsset";
import Recharge from "./recharge";
import BankCard from "./bankcard";
import AliPay from "./alipay";
import FundsDetail from "./fundsDetail";
import InviteHistory from "./inviteHistory";
import Withdraw from "./withdraw";

const Account = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Store.User.isLogin === false) {
      navigate("/CommonLogin");
    }
    if (window.location.pathname === "/account/userCenter") {
      setPage(1);
    }
    if (window.location.pathname === "/account/loginHistory") {
      setPage(3);
    }
    if (window.location.pathname === "/account/nameVerify") {
      setPage(2);
    }
    if (window.location.pathname === "/account/myAsset") {
      setPage(4);
    }
    if (window.location.pathname === "/account/recharge") {
      setPage(5);
    }
    if (window.location.pathname === "/account/withdraw") {
      setPage(6);
    }
    if (window.location.pathname === "/account/bankcard") {
      setPage(7);
    }
    if (window.location.pathname === "/account/fundsDetail") {
      setPage(8);
    }
    if (window.location.pathname === "/account/inviteFriends") {
      setPage(9);
    }

    if (window.location.pathname === "/account/alipay") {
      setPage(10);
    }
  }, [window.location.pathname]);
  return (
    <div id={"Account"}>
      <HomeHeader />
      <div className="box">
        <div className="main">
          <div className={"account_nav"}>
            <div className={"title"}>
              <img src={safety} style={{ marginRight: "10px" }} />
              <b>账户安全</b>
            </div>

            <NavLink
              to={"/account/userCenter"}
              activeClassName={"active"}
              onClick={() => {
                setPage(1);
              }}
            >
              个人中心
            </NavLink>
            <NavLink
              to={"/account/nameVerify"}
              activeClassName={"active"}
              onClick={() => {
                setPage(2);
              }}
            >
              实名认证
            </NavLink>
            <NavLink
              to={"/account/loginHistory"}
              activeClassName={"active"}
              onClick={() => {
                setPage(3);
              }}
            >
              账户活动
            </NavLink>
            <div className={"title"}>
              <img src={asset} style={{ marginRight: "10px" }} />
              <b>资金管理</b>
            </div>
            <NavLink
              to={"/account/myAsset"}
              activeClassName={"active"}
              onClick={() => {
                setPage(4);
              }}
            >
              我的资产
            </NavLink>
            <NavLink
              to={"/account/recharge"}
              activeClassName={"active"}
              onClick={() => {
                setPage(5);
              }}
            >
              充值
            </NavLink>
            <NavLink
              to={"/account/withdraw"}
              activeClassName={"active"}
              onClick={() => {
                setPage(6);
              }}
            >
              提现
            </NavLink>
            <NavLink
              to={"/account/bankcard"}
              activeClassName={"active"}
              onClick={() => {
                setPage(7);
              }}
            >
              银行卡
            </NavLink>
            <NavLink
              to={"/account/alipay"}
              activeClassName={"active"}
              onClick={() => {
                setPage(10);
              }}
            >
              支付宝
            </NavLink>
            <NavLink
              to={"/account/fundsDetail"}
              onClick={() => {
                setPage(8);
              }}
            >
              资金明细
            </NavLink>
            <div className={"title"}>
              <img src={partner} style={{ marginRight: "10px" }} />
              <b>合伙人</b>
            </div>
            <NavLink
              to={"/account/inviteFriends"}
              activeClassName={"active"}
              onClick={() => {
                setPage(9);
              }}
            >
              邀请记录
            </NavLink>
            <NavLink
              to={"/cooperation/invite"}
              activeClassName={"active"}
              onClick={() => {
                setPage(10);
              }}
            >
              邀请好友
            </NavLink>
          </div>
          <div className={"myAsset"}>
            {page === 1 && <UserCenter />}
            {page === 2 && <NameVerify />}
            {page === 3 && <LoginHistory />}
            {page === 4 && <MyAsset />}
            {page === 5 && <Recharge />}
            {page === 6 && <Withdraw />}
            {page === 7 && <BankCard />}
            {page === 8 && <FundsDetail />}
            {page === 9 && <InviteHistory />}
            {page === 10 && <AliPay />}
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Account;
