import React, { useState, useEffect } from "react";
import "./invite.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import Store from "../../../store";
import { formatDate } from "@/utils";
import { RESTRICT } from "../../../library/standard";
import bg from "../../../assets/images/partner/invite/bg.png";
import step1 from "../../../assets/images/partner/invite/1.png";
import step2 from "../../../assets/images/partner/invite/2.png";
import step3 from "../../../assets/images/partner/invite/3.png";
import level1 from "../../../assets/images/partner/invite/level01.png";
import level2 from "../../../assets/images/partner/invite/level02.png";
import level3 from "../../../assets/images/partner/invite/level03.png";
import level4 from "../../../assets/images/partner/invite/level04.png";
import level5 from "../../../assets/images/partner/invite/level05.png";
import funds from "../../../assets/images/funds_light.png";
import { useNavigate, Link } from "react-router-dom";

import EVENT from "@/library/api";
import { CopyText } from "../../components/copy/copy";

const InvitePartner = () => {
  const navigate = useNavigate();
  const [info, setInfo] = useState({});
  const [levName, setLevName] = useState("");
  const [address, setAddress] = useState("");
  const [total, setTotal] = useState("");
  const [data, setData] = useState([]);

  const { refer = "", commRatio = 0 } = Store?.User?.accountInfo;
  useEffect(() => {
    EVENT.Account.updatePromotionInfo().then(({ union, unionTotal }) => {
      let address = `${window.location.origin}/home/?ru=${union.userId}`;
      setAddress(address);
      setInfo(union);
      setTotal(unionTotal);
    });

    EVENT.Account.updateCommission({ page: 1, biz: 1, rows: 10 }).then(
      ({ data }) => {
        setData(data);
      }
    );
  }, []);

  const getLevName = (key) => setLevName(getLevel[key]);
  const getLevel = (ratio) => {
    let o = ratio * 100;
    switch (o) {
      case 5:
        return "青铜";
      case 20:
        return "白银";
      case 25:
        return "黄金";
      case 30:
        return "铂金";
      case 50:
        return "钻石";
        break;
    }
  };

  const getLevelImg = (ratio) => {
    let o = ratio * 100;
    switch (o) {
      case 5:
        return level1;
      case 20:
        return level2;
      case 25:
        return level3;
      case 30:
        return level4;
      case 50:
        return level5;
        break;
    }
  };

  return (
    <div id={"Invite"}>
      <HomeHeader />
      <div className="banner_box">
        <img src={bg} alt="" />
        <div>
          <h5>邀请好友共同赚钱</h5>
          <p>
            最高可获<b>50%</b>交易手续费返佣
          </p>
        </div>
      </div>
      <main>
        <div className="section">
          <h6>如何0成本致富</h6>
          <div className={"step_box"}>
            <div>
              <img src={step1} alt="" />
              <b>生成专属推荐链接</b>
              <p>设置返现比例，生成专属链接</p>
            </div>
            <div>
              <img src={step2} alt="" />
              <b>邀请好友</b>
              <p>分享推荐链接或二维码给您的好友</p>
            </div>
            <div>
              <img src={step3} alt="" />
              <b>共同致富</b>
              <p>当您的好友完成交易，最高可获得50%实时返佣</p>
            </div>
          </div>
        </div>
        {Store.User.isLogin && (
          <>
            <div className="invite_box">
              <div className={"left"}>
                <div className="title">
                  <div>立即邀请</div>
                  <p>
                    复制您专属的邀请码或邀请链接给朋友，也可将邀请二维码截屏分享或面对面分享给朋友
                  </p>
                </div>
                <div className={"invite_link"}>
                  <label>您的专属邀请码：</label>
                  <div className={"code_box"}>
                    <div className={"link"}>{refer}</div>
                    <CopyText
                      title={"复制邀请码"}
                      className={"copy"}
                      text={refer}
                    />
                  </div>
                </div>
                <div className={"invite_link"}>
                  <label>您的邀请链接：</label>
                  <div className={"code_box"}>
                    <div className={"link"}>
                      {window.location.origin}/?ru={refer}
                    </div>
                    <div />
                  </div>
                </div>
                <CopyText
                  title={"复制专属链接"}
                  className={"copy_link"}
                  text={window.location.origin + "/?ru=" + refer}
                />
              </div>
            </div>
            <div className={"proxy_box"}>
              <div className="title">您邀请好友的佣金收入</div>
              <div className={"info"}>
                <div>
                  <label>当前等级</label>
                  <div>
                    <img src={getLevelImg(info.commRatio)} alt="" />
                    {getLevel(info.commRatio)}
                  </div>
                </div>
                <div>
                  <label>基础返佣比例</label>
                  <div>{info.commRatio * 100}%</div>
                </div>
                <div>
                  <label>邀请好友数</label>
                  <div>{info.userCount}人</div>
                </div>
                <div>
                  <label>累计返佣</label>
                  <div>{RESTRICT.float(total, 2).toFixed(2)}元</div>
                </div>
              </div>
            </div>
            <div className={"invite_trade"}>
              <div className="title">
                <h5>您的返佣记录</h5>{" "}
                <Link to={"/account/inviteFriends"} state={"2"}>
                  查看更多记录
                </Link>
              </div>
              <ol>
                <li className={"li_title"}>
                  <div>序号</div>
                  <div>邀请用户</div>
                  <div>交易手续费</div>
                  <div>佣金收入(元)</div>
                  <div>返佣时间</div>
                </li>
                {data.length !== 0 ? (
                  data.map((item, key) => {
                    return (
                      <li key={key}>
                        <div>{key + 1}</div>
                        <div>{item.subName}</div>
                        <div>{RESTRICT.float(item.charge, 2)}</div>
                        <div>{RESTRICT.float(item.money, 2)}</div>
                        <div>
                          {formatDate("m-d h:i:s", { date: item.createTime })}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <div className={"empty"}>
                    <div>
                      <img src={funds} alt="" />
                      <b>暂无记录</b>
                    </div>
                  </div>
                )}
              </ol>
            </div>
          </>
        )}
        <div className="level_box">
          <div className="title">
            <div className={"left"}>
              <div>邀请返佣高达50%</div>
              <p>完善高额的佣金体系，助您不断提升</p>
            </div>
          </div>
          <div className={"level"}>
            <div>
              <b>青铜</b>
              <img src={level1} alt="" />
              <span>5%</span>
              <p>永久</p>
              <p>下级交易人数：1人+</p>
            </div>
            <div>
              <b>白银</b>
              <img src={level2} alt="" />
              <span>20%</span>
              <p>永久</p>
              <p>下级交易人数：10人+</p>
            </div>
            <div>
              <b>黄金</b>
              <img src={level3} alt="" />
              <span>25%</span>
              <p>永久</p>
              <p>下级交易人数：30人+</p>
            </div>
            <div>
              <b>铂金</b>
              <img src={level4} alt="" />
              <span>30%</span>
              <p>永久</p>
              <p>下级交易人数：80人+</p>
            </div>
            <div>
              <b>钻石</b>
              <img src={level5} alt="" />
              <span>50%</span>
              <p>永久</p>
              <p>下级交易人数：150人+</p>
            </div>
          </div>
        </div>
      </main>
      <HomeFooter />
    </div>
  );
};

export default InvitePartner;
