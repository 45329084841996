import { Routes, Route } from "react-router-dom";
import routes from "./routes/index";
import { RouterGurad } from "./routes/auth";
import "@/library/prototype";

function MyApp() {

  return (
    <Routes>
      {routes.map((route, i) => (
        <Route key={i} path={route.path} element={RouterGurad(route)} />
      ))}
    </Routes>
  );
}

export default MyApp;
