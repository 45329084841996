import axios from "axios";
import Store from "@/store";
import FK from "@/core";

const service = axios.create({
  transformRequest: [
    // 对 data 进行任意转换处理
    function (data) {
      let str = "";
      data = data || {};
      Object.keys(data).forEach((item) => {
        str += `${item}=${data[item]}&`;
      });
      str = str.replace(/&$/, "");
      return str;
    },
  ],
});

//设置默认超时
service.defaults.timeout = 10000;
service.defaults.withCredentials = true;
//设置默认请求头
service.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
service.defaults.headers.common["Accept"] = "*/*";
service.defaults.headers.common["Accept-Language"] = "zh-CN";
service.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8;";

//请求拦截器
service.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
//响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.status === 200 && res.data.code === 200) {
      return Promise.resolve(res.data);
    } else if (res.status === 200 && res.data.code === 401) {
      //todo 401 登录过期
      Store.User.setLogout(true);
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },
  (err) => {
    console.log(err);
    console.log("响应：`系统繁忙`");
    return Promise.reject("请求超时");
  }
);

export default service;
