import React from "react";
import { Navigate } from "react-router-dom";
import Live800 from "@/view/common/live800";
import WithdrawHistory from "@/view/withdraw/withdrawHistory";
import HomeSec from "../view/homeSec/index";
import CommonRegister from "../view/common/template/index";
import CommonLogin from "../view/common/LoginTemplate/index";
import Download from "../view/download";
//路由都写在上面
import NoMatch from "./404";
import InvitePartner from "../view/partner/invite/invite";
import School from "../view/school";
import Page0 from "../view/school/page0";
import Page1 from "../view/school/page1";
import Page03 from "../view/school/page3";
import Page04 from "../view/school/page4";
import Page05 from "../view/school/page5";
import Page06 from "../view/school/page6";
import Page07 from "../view/school/page7";
import Page08 from "../view/school/page8";
import Page2 from "../view/school/page2";
import Activity from "../view/activity";
import Account from "../view/account/index";
import TradeNew from "../view/tradeNew";
import RechargeTips from "../view/account/recharge/rechargeTips";
import Agreement from "../view/bot/agreement";
import Notice from "../view/notice/notice";
import NoticeDetails from "../view/notice/noticeDetails";
import ResetLoginPassword from "../view/common/resetLoginPwd";
import CommonResetLoginPassword from "../view/common/resetLoginTemplate";
import AboutUs from "../view/aboutUs";
import Warning from "../view/warning";
import Private from "../view/private";
import Disclaimer from "../view/disclaimer";

const routes = [
  {
    path: "/home",
    element: <Navigate to="/" />,
  },
  {
    path: "/",
    element: <HomeSec />,
  },
  {
    path: "/trade/:id",
    element: <TradeNew />,
    auth: true,
  },
  // {
  //   path: "/download",
  //   element: <Download />,
  //   auth: true,
  // },

  {
    path: "/live800",
    element: <Live800 />,
    auth: true,
  },

  {
    path: "/withdrawHistory",
    element: <WithdrawHistory />,
    auth: true,
  },

  {
    path: "/CommonRegister",
    element: <CommonRegister />,
  },
  {
    path: "/CommonLogin",
    element: <CommonLogin />,
  },
  {
    path: "/CommonResetLoginPassword",
    element: <CommonResetLoginPassword />,
  },
  {
    path: "/download",
    element: <Download />,
  },
  {
    path: "/cooperation/invite",
    element: <InvitePartner />,
    auth: true,
  },
  {
    path: "/school",
    element: <School />,
  },
  {
    path: "/page0",
    element: <Page0 />,
  },
  {
    path: "/page1",
    element: <Page1 />,
  },
  {
    path: "/page2",
    element: <Page2 />,
  },
  {
    path: "/page3",
    element: <Page03 />,
  },
  {
    path: "/page4",
    element: <Page04 />,
  },
  {
    path: "/page5",
    element: <Page05 />,
  },
  {
    path: "/page6",
    element: <Page06 />,
  },
  {
    path: "/page7",
    element: <Page07 />,
  },
  {
    path: "/page8",
    element: <Page08 />,
  },
  {
    path: "/activity",
    element: <Activity />,
  },
  {
    path: "/account/inviteFriends",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/userCenter",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/nameVerify",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/loginHistory",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/fundsDetail",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/myAsset",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/recharge",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/withdraw",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/bankcard",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/aliPay",
    element: <Account />,
    auth: true,
  },
  {
    path: "/rechargeTips",
    element: <RechargeTips />,
    auth: true,
  },
  {
    path: "/agreement",
    element: <Agreement />,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer />,
  },
  {
    path: "/private",
    element: <Private />,
  },
  {
    path: "/warning",
    element: <Warning />,
  },
  {
    path: "/notice",
    element: <Notice />,
    auth: true,
  },
  {
    path: "/noticeDetails/:id",
    element: <NoticeDetails />,
    auth: true,
  },
  {
    path: "/CommonRegister",
    element: <CommonRegister />,
    auth: true,
  },
  {
    path: "/CommonLogin",
    element: <CommonLogin />,
  },
  {
    path: "/CommonResetLoginPassword",
    element: <CommonResetLoginPassword />,
  },
  {
    path: "/download",
    element: <Download />,
  },
  {
    path: "/cooperation/invite",
    element: <InvitePartner />,
    auth: true,
  },
  {
    path: "/school",
    element: <School />,
  },
  {
    path: "/page0",
    element: <Page0 />,
  },
  {
    path: "/page1",
    element: <Page1 />,
  },
  {
    path: "/page2",
    element: <Page2 />,
  },
  {
    path: "/page3",
    element: <Page03 />,
  },
  {
    path: "/page4",
    element: <Page04 />,
  },
  {
    path: "/page5",
    element: <Page05 />,
  },
  {
    path: "/page6",
    element: <Page06 />,
  },
  {
    path: "/page7",
    element: <Page07 />,
  },
  {
    path: "/page8",
    element: <Page08 />,
  },
  {
    path: "/activity",
    element: <Activity />,
  },
  {
    path: "/account/inviteFriends",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/userCenter",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/nameVerify",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/loginHistory",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/fundsDetail",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/myAsset",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/recharge",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/withdraw",
    element: <Account />,
    auth: true,
  },
  {
    path: "/account/bankcard",
    element: <Account />,
    auth: true,
  },
  {
    path: "/rechargeTips",
    element: <RechargeTips />,
    auth: true,
  },
  {
    path: "/agreement",
    element: <Agreement />,
    auth: true,
  },
  {
    path: "/warning",
    element: <Warning />,
  },
  {
    path: "/notice",
    element: <Notice />,
    auth: true,
  },
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },

  //路由写上面
  {
    path: "*",
    element: <NoMatch />,
  },
];

export default routes;
