import React, { useState, useEffect } from "react";
import "./index.scss";

import { RESTRICT } from "@/library/standard";
import { Modal, notification } from "antd";
import Store from "@/store";
import FK from "@/core";
import { observer } from "mobx-react-lite";
import Position from "./position";
import Pending from "./pending";
import Settlement from "./settlement";

const TradePosition = () => {
  const [active, setActiveIndex] = useState(1);
  const [pending_data, setPending_data] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [confirmClose, setConfirmClose] = useState(false);

  const isSimulate = Store.Quote.simulate;
  const { limitList = [], list = [], total } = Store.Position;

  useEffect(() => {
    if (Store.User.isLogin) Store.Position.startLimit();
    return () => Store.Position.endLimit();
  }, []);

  useEffect(() => {
    if (Store.User.isLogin) {
      FK.Launcher.CFD.startData();
      Store.Position.start();
    }
    return () => Store.Position.end();
  }, [Store.Quote.simulate]);

  useEffect(() => {
    if (list !== undefined) {
      setListCount(list.length);
    }
  }, [list && list.length]);

  useEffect(() => {
    if (limitList !== undefined) {
      setPending_data(limitList);
    }
  }, [limitList && limitList.length]);

  // 一鍵平倉
  const sellAll = async () => {
    if (list.length === 0) {
      return;
    }
    const { confirmClose } = Store.Futures.orderSetting;
    console.log("confirmClose ===>>> ", confirmClose);

    if (!confirmClose) {
      setConfirmClose(true);
      return;
    }
    // if (FK.PositionClose.switch === "true") {
    //   setConfirmClose(true);
    //   return;
    // }
    classAll();
  };

  const classAll = () => {
    // 只取該品類的訂單id
    const idList = list.map((item) => item.id).join(",");
    Store.Position.closePosition(idList)
      .then(({ successNumber, failNumber }) => {
        notification.success({
          message: `订单已平仓，成功${successNumber}单， 失败${failNumber}单`,
        });
        Store.Position.start(false);
        Store.Wallet.updateWallet();
      })
      .catch(({ message }) => notification.error({ message }));
  };
  return (
    <div className={"position"}>
      <div className={"position_head"}>
        <div className={"nav"}>
          <div
            className={active === 1 ? "active" : ""}
            onClick={() => {
              setActiveIndex(1);
            }}
          >
            {isSimulate ? "模拟持仓" : "当前持仓"}({listCount})
          </div>
          {!isSimulate && (
            <div
              className={active === 2 ? "active" : ""}
              onClick={() => {
                setActiveIndex(2);
              }}
            >
              当前委托({pending_data.length})
            </div>
          )}
          <div
            className={active === 3 ? "active" : ""}
            onClick={() => {
              setActiveIndex(3);
            }}
          >
            交易记录
          </div>
        </div>
        <div className={"sellAll"}>
          <div className={"profit"}>
            <label>盈亏</label>
            <b className={total > 0 ? "red" : "green"}>{total.toFixed(2)}</b>
            <i>元</i>
          </div>
          <div
            className={"sell"}
            onClick={() => {
              sellAll();
            }}
          >
            一键平仓
          </div>
        </div>
      </div>
      {active === 1 && <Position array={list} />}
      {active === 2 && <Pending array={pending_data} />}
      {active === 3 && <Settlement />}

      <Modal
        title={"温馨提示"}
        visible={confirmClose}
        className={"theme_dark"}
        onCancel={() => setConfirmClose(false)}
        footer={<div />}
      >
        <div className={"confirm_info"}>确定是否平仓</div>
        <div className={"btn_box"}>
          <div onClick={() => setConfirmClose(false)}>取消</div>
          <div
            className={"confirm"}
            onClick={() => {
              classAll();
              setConfirmClose(false);
            }}
          >
            确定
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(TradePosition);
