import { Navigate, useLocation } from "react-router-dom";
import Storage from "../library/storage";

export const RouterGurad = (route) => {
  const location = useLocation();
  if (!!route.auth && route.auth) {
    const isLogin = window.localStorage.getItem("isLogin");
    if (isLogin) {
      return route.element;
    } else {
      return (
        <Navigate to={"/CommonLogin"} replace state={{ from: location }} />
      );
    }
  } else {
    return route.element;
  }
};
