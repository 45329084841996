import React, { useState, useEffect, useRef, useCallback } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import { Input, Toast } from "antd-mobile";
import { Header } from "@/view/components/index";
import EVENT from "@/library/api";
import Store from "@/store";
import USA from "../../../assets/images/home/USA.svg";
import { SimpleInput } from "../../components";
//图片
import { notification } from "antd";
import { safeValue } from "@/library/safety";
import CsAlert from "../../components/CsAlert";

function LoginNew() {
  const navigate = useNavigate();
  const [type, setType] = useState("MOBILE");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(true);

  const handleMobileChange = useCallback(
    (account, format) => {
      setPhone(account);
      const correct =
        safeValue(account, true) && safeValue(password, true) && format;
      setError(!correct);
    },
    [password]
  );

  const handleEmailChange = useCallback(
    (account, format) => {
      setEmail(account);
      const correct =
        safeValue(account, true) && safeValue(password, true) && format;
      setError(!correct);
    },
    [password]
  );

  const handlePassChange = useCallback(
    (account, format) => {
      setPassword(account);
      const correct =
        safeValue(account, true) && safeValue(password, true) && format;
      setError(!correct);
    },
    [password]
  );

  const toLogin = () => {
    let acc = type === "MOBILE" ? phone : email;
    EVENT.Account.login({ account: acc, password })
      .then(({ user, message }) => {
        Store.User.loginCallback(user);
        notification.success({ message: message });
        navigate("/trade/CL");
      })
      .catch((e) => {
        Toast.show({ icon: "fail", content: e.message });
      });
  };

  const rest = (val) => {
    setType(val);
    setPhone("");
    setEmail("");
    setPassword("");
    setError("");
  };

  return (
    <div id="Login">
      <div className="switch_btn">
        <div
          className={type === "MOBILE" ? "active" : ""}
          onClick={() => rest("MOBILE")}
        >
          手机登录
        </div>
        <div
          className={type !== "MOBILE" ? "active" : ""}
          onClick={() => rest("EMAIL")}
        >
          邮箱登录
        </div>
      </div>
      {type === "MOBILE" ? (
        <div className="mobile">
          <SimpleInput
            value={phone}
            label="手机号码"
            placeholder="请输入手机号码"
            type="tel"
            format="PHONE"
            notice="tel"
            onChange={handleMobileChange}
          />
          <SimpleInput
            value={password}
            label="登录密码"
            placeholder="请输入登录密码"
            type="password"
            format="SET_PASSWORD"
            notice="password"
            onChange={handlePassChange}
          />
        </div>
      ) : (
        <div className="mobile">
          <SimpleInput
            value={email}
            label="邮箱"
            placeholder="请输入邮箱地址"
            type="email"
            format="EMAIL"
            notice="email"
            onChange={handleEmailChange}
          />
          <SimpleInput
            value={password}
            label="登录密码"
            placeholder="请输入登录密码"
            type="password"
            format="SET_PASSWORD"
            notice="password"
            onChange={handlePassChange}
          />
        </div>
      )}
      <div className="link_box">
        <Link className="forgot" to="/CommonResetLoginPassword">
          忘记密码
        </Link>
        <a href="/aboutUs#qualification">
          <img src={USA} alt="" /> 美国金融监管机构权威认证
        </a>
      </div>
      <div className={`confirm_btn ${error && "error"}`} onClick={toLogin}>
        登录
      </div>
      <div className="switch_type">
        <p>
          没有账户？
          <Link to="/CommonRegister">立即注册</Link>
        </p>
      </div>
      <CsAlert />
    </div>
  );
}

export default LoginNew;
