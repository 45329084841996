import _header from "./header";
import _footer from "./footer";
import _imgValide from "./imgValid";
import _alert from "./alert";
import _simpleInput from "./simpleInput/index";
import _homeFooter from "./homeFooter/index";
import _homeHeader from "./homeHeader/index";
import _simpleSelect from "./simpleSelect/index";
import _simpleSelect2 from "./simpleSelect2/index";

export const Header = _header;
export const HomeFooter = _homeFooter;
export const Footer = _footer;
export const SimpleInput = _simpleInput;
export const ImgValide = _imgValide;
export const Alert = _alert;
export const HomeHeader = _homeHeader;
export const SimpleSelect = _simpleSelect;
export const SimpleSelect2 = _simpleSelect2;
