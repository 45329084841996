import React, { useState, useEffect } from "react";
import "./notice.scss";
import banner from "../../assets/images/notice/notice-banner.jpg";
import { HomeFooter } from "../components";
// import { formatDate } from "@/utils";
import { formatDate, isEmpty } from "@/utils";
import { Pagination } from "antd";
import HomeHeader from "../components/homeHeader";
import { useNavigate } from "react-router";
import EVENT from "../../library/api";

const Notice = ({}) => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [amount, setAmount] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [activeArr, setActiveArr] = useState([]);
  const [data, setData] = useState([]);

  const [eagleInfo, setEagleInfo] = useState({ list: [], total: 0 });
  const [page, setPage] = useState(1);

  useEffect(() => {
    notice();
  }, []);

  const notice = () => {
    EVENT.News.getWealth().then(({ notices }) => {
      setList(notices);
      setEagleInfo({ list: splitAry(notices, 10), total: notices.length });
    });
  };

  const splitAry = (arr, len) => {
    let arr_length = arr.length;
    let newArr = [];
    for (let i = 0; i < arr_length; i += len) {
      newArr.push(arr.slice(i, i + len));
    }
    return newArr;
  };

  const goDetails = (id) => {
    navigate(`/noticeDetails/${id}`);
  };

  const onChangePage = (page) => {
    if (page - 1 >= eagleInfo.list.length) return;
    setPage(Number(page));
  };

  return (
    <div className="notice">
      <HomeHeader />
      <div className="banner">
        <img src={banner} alt="" />
      </div>
      <div className="body">
        <div className="left">
          <div className="title">浏览排行</div>
          <div className="rank">
            <ul>
              {list.map((item, index) => {
                return (
                  <li key={index} onClick={() => goDetails(item.id)}>
                    {index + 1}、{item.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="right">
          <div className="title">动态公告</div>
          <div className="conn">
            <ul>
              {!isEmpty(eagleInfo.list) &&
                eagleInfo.list[page - 1].map((item, index) => {
                  return (
                    <li key={index} onClick={() => goDetails(item.id)}>
                      <div className="date">
                        <h5>{formatDate("d", { date: item.time })}</h5>
                        <p>{formatDate("y-m", { date: item.time })}</p>
                      </div>
                      <div className="content">{item.title}</div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={"pagination"}>
            <Pagination
              onChange={onChangePage}
              current={page}
              defaultPageSize={10}
              total={eagleInfo.total}
              showSizeChanger={false}
            />
          </div>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Notice;
