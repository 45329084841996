import React, { useState, useEffect } from "react";
import "./index.scss";
import { notification, message, Switch, Modal, Tooltip } from "antd";
import Rule from "../../markets/rule";
import help from "../../../assets/images/trade/help.svg";
import ruleJson from "../../components/tradeRule";
import { RESTRICT } from "@/library/standard";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Input } from "../../components/tradeInput/index";
import add from "../../../assets/images/trade/add.svg";
import minus from "../../../assets/images/trade/loss.svg";
import CSvg from "../../../utils/svg";

const Order = () => {
  const isSimulate = Store.Quote.simulate;
  const { quote = {} } = Store.Quote || {};
  const tradeInfo = Store.Futures;
  const { id } = useParams();
  const [targetPrice, setTargetPrice] = useState(Store.Futures.price);
  const [isFocus, setIsFocus] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [marketClose, setMarketClose] = useState(false);

  const [buy, setBuy] = useState("");
  const [order_show, setOrder_show] = useState(false);
  const [rule, setRule] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Store.Quote.quote === null) return;
    setData(Store.Quote.quote);
  }, [Store.Quote.quote]);

  useEffect(() => {
    setTargetPrice(Store.Futures.price);
    setRule(ruleJson[id]);
  }, [id]);

  if (Object.keys(Store.Futures.tradeData).length === 0) return;
  const {
    volumeIndex,
    priceDigit,
    volumeList,
    orderType,
    moneyType,
    moneyTypeList,
    stopLoss,
    select,
    stopProfitList,
    margin,
    marginPrize,
    deductCharge,
    total,
    eagle,
    eagleRatio,
    useEagle,
    usableEagle,
    deductEagle,
    deductMoney,
  } = Store.Futures.tradeData;

  const updatePrice = (isAdd) => {
    if (!isAdd) {
      if (targetPrice) {
        const val = targetPrice
          .sub(Math.pow(0.1, priceDigit))
          .toFixed(priceDigit);
        setTargetPrice(val);
        Store.Futures.setTargetPrice(val);
      } else {
        const val = targetPrice.sub(0.1).toFixed(priceDigit);
        Store.Futures.setTargetPrice(val);
      }
    } else {
      if (targetPrice) {
        const val = targetPrice
          .add(Math.pow(0.1, priceDigit))
          .toFixed(priceDigit);
        setTargetPrice(val);
        Store.Futures.setTargetPrice(val);
      } else {
        const val = targetPrice.add(0.1).toFixed(priceDigit);
        Store.Futures.setTargetPrice(val);
      }
    }
  };

  const order = (isBuy) => {
    Store.Futures.order(isBuy)
      .then(() => {
        setOrder_show(false);
        notification.success({ message: "买入委托已提交" });
        Store.User.updateDetail();
        Store.Position.start(Store.Position.keepUpdate ? false : true);
        Store.Position.startLimit(
          Store.Position.keepUpdateLimit ? false : true
        );
        Store.Wallet.updateWallet();
      })
      .catch((ret) => {
        message.error(
          <div
            style={{
              position: "relative",
              top: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: ret.message }}></div>
          </div>
        );
      });
  };

  const confirmOrder = (isBuy) => {
    if (Store.Futures.orderSetting.confirmPlace) {
      setOrder_show(true);
    } else {
      order(isBuy);
    }
  };

  return (
    <div className={"tradeOrder"}>
      <div className={"order_box"}>
        <div className={"nav_box"}>
          <div className={"title"}>
            <div
              onClick={() => Store.Futures.switchOrderType(0)}
              className={tradeInfo.orderType === 0 ? "active" : ""}
            >
              市价
            </div>
            {!isSimulate && (
              <div
                onClick={() => {
                  Store.Futures.switchOrderType(1);
                }}
                className={tradeInfo.orderType === 1 ? "active" : ""}
              >
                限价
              </div>
            )}
          </div>
        </div>
        <div className="order_info">
          <div className={"dynamic"}>
            <div className={"dynamic_info"}>
              <div>
                <div>
                  <span>买盘</span>
                  <b>{quote.buyPrice}</b>
                </div>
                <div>{quote.buyVolume}</div>
              </div>
              <div>
                <div>
                  <span>卖盘</span>
                  <b>{quote.sellPrice}</b>
                </div>
                <div>{quote.sellVolume}</div>
              </div>
            </div>
            <div className={"volume_info"}>
              <div
                className={"buy"}
                style={{ width: `${quote.buyWidth * 100}%` }}
              />
              <div
                className={"sell"}
                style={{ width: `${quote.sellWidth * 100}%` }}
              />
            </div>
          </div>
          <div className={"line one"}>
            <label>交易模式</label>
            {isSimulate ? (
              <div className={"volume"}>
                <div className={"active"}>元</div>
              </div>
            ) : (
              <div className={"volume"}>
                {!!moneyTypeList &&
                  moneyTypeList.map((item, key) => {
                    return (
                      <div
                        key={key}
                        className={moneyType === key ? "active" : ""}
                        onClick={() => Store.Futures.switchMoneyType(item)}
                      >
                        {item === 0 ? "元" : "角"}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          {tradeInfo.orderType === 1 && (
            <div className="line">
              <label>限价金额</label>
              <div className="volume">
                <div className={`input_box ${isFocus && "focus"}`}>
                  <Input
                    className={`label-input zh-cn`}
                    type={"number"}
                    empty={true}
                    free={true}
                    initialValue={targetPrice || ""}
                    value={Store.Futures.targetPrice || ""}
                    onChange={(e) => {
                      let value = e.toString();
                      if (value.indexOf("-") !== -1) return;
                      if (value.indexOf(".") !== -1) {
                        if (value.split(".")[0].length > 6) return;
                        if (value.split(".")[1].length > priceDigit) return;
                      } else {
                        if (value.length > 6) return;
                      }
                      Store.Futures.setTargetPrice(value);
                    }}
                    controller={true}
                    right={"0"}
                    min={0}
                    step={Math.pow(0.1, priceDigit)}
                    digit={priceDigit}
                    getAdd={(props) => (
                      <div
                        onClick={() => updatePrice(true)}
                        onMouseDown={props.onMouseDown}
                        onMouseUp={props.onMouseUp}
                        className={"btn-control btn-plus"}
                      >
                        <CSvg src={add} />
                      </div>
                    )}
                    getSub={(props) => (
                      <div
                        onClick={() => updatePrice(false)}
                        onMouseDown={props.onMouseDown}
                        onMouseUp={props.onMouseUp}
                        className={"btn-control btn-minus"}
                      >
                        <CSvg src={minus} />
                      </div>
                    )}
                  ></Input>
                </div>
              </div>
            </div>
          )}
          <div className={"line two"}>
            <label>手数(手)</label>
            <div className={"volume"}>
              {!!volumeList &&
                volumeList.map((val, key) => {
                  return (
                    <div
                      key={key}
                      className={`${volumeIndex === key ? "active" : ""}`}
                      onClick={() => Store.Futures.switchVolume(key)}
                    >
                      {val}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={"line two"}>
            <label>保证金(元)</label>
            <div className={"volume stopLoss"}>
              {!!stopLoss &&
                stopLoss.map((val, key) => {
                  return (
                    <div
                      key={key}
                      className={`${select === key ? "active" : ""}`}
                      onClick={() => Store.Futures.switchStopLoss(key)}
                    >
                      {Math.abs(val)}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={"line"}>
            <label>
              止盈{" "}
              <Tooltip placement={"right"} title={"下单后可在持仓修改止盈止损"}>
                <img src={help} alt="" />
              </Tooltip>
            </label>
            <div className={"red"}>
              {margin * (!!stopProfitList && stopProfitList[0])}
              <span>元</span>
            </div>
          </div>
          <div className={"total"}>
            <div>
              <label>手续费</label>
              <div className={"green"}>
                {deductCharge}
                <span>元</span>
              </div>
            </div>
            {!isSimulate && (
              <div>
                <label>礼金抵扣</label>
                <div>{marginPrize}元</div>
              </div>
            )}
            <div>
              <label>履约保证金</label>
              <div>{margin}元</div>
            </div>
            <div>
              <label>总交易费用</label>
              <div>{!!total && RESTRICT.float(total, 2)}元</div>
            </div>
          </div>
          {!isSimulate && (
            <div className={"line"}>
              <label>积分抵扣</label>
              <div className={"volume"}>
                {useEagle && usableEagle && (
                  <div className={"deduct"}>
                    消耗
                    <span className={"green"}>
                      {RESTRICT.float(deductEagle, 2)}
                    </span>
                    积分，抵扣
                    <span className={"green"}>
                      ¥{RESTRICT.float(deductMoney, 2)}
                    </span>
                  </div>
                )}
                <Switch
                  className={"Switch"}
                  checked={useEagle}
                  onChange={() => {
                    console.log("!usableEagle", !usableEagle);
                    if (!useEagle && !usableEagle)
                      message.warning("积分不足，无法抵扣");
                    console.log("当前 !useEagle ===> ", !useEagle);
                    Store.Futures.switchEagle(!useEagle);
                  }}
                />
              </div>
            </div>
          )}
          <div className="btn_box">
            <div
              className="btn red_bg"
              onClick={() => {
                confirmOrder(true);
                setBuy(true);
              }}
            >
              <span>买涨</span>
              <b>{quote.buyPrice}</b>
            </div>
            <div
              className="btn green_bg"
              onClick={() => {
                confirmOrder(false);
                setBuy(false);
              }}
            >
              <span>买跌</span>
              <b>{quote.sellPrice}</b>
            </div>
          </div>
        </div>
      </div>
      {rule !== {} && data !== [] && (
        <Rule rule={rule} data={data} orderType={tradeInfo.orderType} />
      )}
      {order_show && (
        <Modal
          title={"订单详情"}
          visible={order_show}
          className={"order_confirm"}
          onCancel={() => {
            setOrder_show(false);
          }}
          destroyOnClose={true}
          footer={<div />}
        >
          <div>
            <label>方向：</label>
            <b className={buy ? "red" : "green"}>{buy ? "买涨" : "买跌"}</b>
          </div>
          <div>
            <label>保证金：</label>
            <b>{margin}元</b>
          </div>
          <div>
            <label>手续费：</label>
            <b>{deductCharge}元</b>
          </div>
          {!isSimulate && (
            <>
              <div>
                <label>积分抵扣：</label>
                <b>
                  {useEagle && usableEagle ? RESTRICT.float(deductMoney, 2) : 0}
                  元
                </b>
              </div>
              <div>
                <label>礼金抵扣：</label>
                <b>{marginPrize}元</b>
              </div>
            </>
          )}

          <div>
            <label>合计：</label>
            <b className={"total"}>{!!total && RESTRICT.float(total, 2)}元</b>
          </div>
          <div className={"btn_box"}>
            <div
              onClick={() => {
                setOrder_show(false);
              }}
            >
              取消
            </div>
            <div
              onClick={() => {
                order(buy);
                setOrder_show(false);
              }}
            >
              确认
            </div>
          </div>
        </Modal>
      )}
      <Modal
        title={"温馨提示"}
        visible={marketClose}
        className={"order_confirm"}
        onCancel={() => {
          setMarketClose(false);
        }}
        destroyOnClose={true}
        footer={<div />}
      >
        <div
          className={"errorMessage"}
          dangerouslySetInnerHTML={{ __html: errMessage }}
        ></div>
      </Modal>
    </div>
  );
};

export default observer(Order);
