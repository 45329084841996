import React, { useState, useEffect } from "react";
import CSvg from "../../../utils/svg";
import { message, Switch, Slider, Modal, notification, Checkbox } from "antd";
import Rule from "../../markets/rule";
import TradeSettingModal from "./tradeSettingModal";
import set from "../../../assets/images/trade/setting.svg";
import { Input } from "../../components/tradeInput/index";
import add from "../../../assets/images/trade/add.svg";
import minus from "../../../assets/images/trade/loss.svg";

import ruleJson from "../../components/tradeRule";
import { RESTRICT } from "@/library/standard";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

const LeverOrder = () => {
  const isSimulate = Store.Quote.simulate;
  const { quote = {} } = Store.Quote || {};
  const { accountInfo } = Store.User;

  const tradeInfo = Store.Futures;
  const [activeMargin, setActiveMargin] = useState("");
  const [activeStopLoss, setActiveStopLoss] = useState(0);
  const [activeStopProfit, setActiveStopProfit] = useState(0);
  const [order_show, setOrder_show] = useState(false);
  const [marks, setMarks] = useState([]);
  const [marginList] = useState([25, 50, 75, 100]);

  const { id } = useParams();
  const [rule, setRule] = useState({});
  const [data, setData] = useState([]);
  const [setting, setSetting] = useState(false);
  const [buy, setBuy] = useState("");

  const quoteId = quote?.id || "--";
  const quotePrice = quote?.price || "--";
  const isLogin = Store.User.isLogin;
  const { tp, sl, confirmClose, confirmPlace, overnight } =
    Store.Futures.orderSetting || {};

  useEffect(() => {
    if (quoteId !== "--" && isLogin && accountInfo) {
      Store.Futures.setTargetPrice(quotePrice);
    }
  }, [quoteId]);

  useEffect(() => {
    if (Store.Quote.quote === null) return;
    setData(Store.Quote.quote);
  }, [Store.Quote.quote]);

  useEffect(() => {
    setRule(ruleJson[id]);
  }, [id]);

  useEffect(() => {
    if (Store.Futures.leverShowList) {
      let list = Store.Futures.leverShowList;
      let markInfo = [];
      list.forEach((e) => {
        markInfo[e] = `${e}X`;
      });
      setMarks(markInfo);
      Store.Futures.switchLever(50);
    }
  }, [Store.Futures.leverShowList]);

  useEffect(() => {
    if (activeStopLoss == 0) {
      Store.Futures.switchStopLossRate(sl.mul(100));
    }
  }, [activeStopLoss, sl]);

  useEffect(() => {
    if (activeStopProfit == 0) {
      Store.Futures.switchStopProfitRate(tp.mul(100));
    }
  }, [activeStopProfit, tp]);

  // if (Object.keys(Store.Futures.tradeData).length === 0) return;
  // if (Object.keys(Store.Futures.orderSetting).length === 0) return;
  const {
    priceDigit,
    orderType,
    margin,
    marginPrize,
    deductCharge,
    total,
    useEagle,
    usableEagle,
    deductEagle,
    deductMoney,
    marginArea,
    // deferFee,
    defer,
  } = Store.Futures.tradeData;
  const deferFee = Store.Futures.deferFee;
  // console.log("total ===> ", total);
  // console.log("useEagle : ", useEagle, " ; usableEagle : ", usableEagle);
  const updatePrice = (isAdd) => {
    let price = Store.Futures.targetPrice || Store.Futures.price;
    if (!isAdd) {
      const val = Number(price).sub(
        Math.pow(0.1, priceDigit).toFixed(priceDigit)
      );
      Store.Futures.setTargetPrice(val);
    } else {
      const val = Number(price).add(
        Math.pow(0.1, priceDigit).toFixed(priceDigit)
      );
      Store.Futures.setTargetPrice(val);
    }
  };

  const setMargin = (val, key) => {
    const { money, game } = Store.User.assets;
    setActiveMargin(key);
    const m = RESTRICT.float(
      !isSimulate ? money.mul(val.div(100)) : game.mul(val.div(100)),
      0
    );

    if (m < marginArea[0]) return Store.Futures.switchMarginFree(marginArea[0]);
    if (m > marginArea[1]) return Store.Futures.switchMarginFree(marginArea[1]);
    Store.Futures.switchMarginFree(m);
  };

  const confirmOrder = (isBuy) => {
    if (!margin) {
      return;
    }
    if (Store.Futures.orderSetting.confirmPlace) {
      setOrder_show(true);
    } else {
      order(isBuy);
    }
  };

  const order = (isBuy) => {
    Store.Futures.order(isBuy)
      .then(() => {
        setOrder_show(false);
        notification.success({ message: "委托提交成功" });
        Store.User.updateDetail();
        Store.Position.start(Store.Position.keepUpdate ? false : true);
        Store.Position.startLimit(
          Store.Position.keepUpdateLimit ? false : true
        );
        Store.Wallet.updateWallet();
      })
      .catch(({ message }) => notification.error({ message }));
  };

  // 保证金*杠杆*tradelist里边的deferFee
  const checkDerFee = () => {
    const num = margin.mul(tradeInfo.lever).mul(deferFee);
    console.log(num);
    return RESTRICT.float(num, 2);
  };

  return (
    <div className={"tradeLeverOrder"}>
      <div className={"order_box"}>
        <div className={"nav_box"}>
          <div className={"title"}>
            <div
              onClick={() => Store.Futures.switchOrderType(0)}
              className={tradeInfo.orderType === 0 ? "active" : ""}
            >
              市价
            </div>
            {!isSimulate && (
              <div
                onClick={() => Store.Futures.switchOrderType(1)}
                className={tradeInfo.orderType === 1 ? "active" : ""}
              >
                限价
              </div>
            )}
          </div>
        </div>
        <div className="order_info">
          <div className={"lever_box"}>
            <div className={"lever_title"}>
              <label>
                杠杆倍率：
                <b>{tradeInfo.lever}X</b>
              </label>
              <div
                onClick={() => {
                  setSetting(true);
                }}
              >
                <i>交易设置</i> <CSvg src={set} />
              </div>
            </div>
            <div>
              <Slider
                marks={marks}
                step={1}
                defaultValue={50}
                min={tradeInfo.leverList[0]}
                max={tradeInfo.leverList[1]}
                onChange={(e) => {
                  Store.Futures.switchLever(e);
                }}
              />
            </div>
          </div>
          {tradeInfo.orderType === 1 && !isSimulate && (
            <div className={"profit_box"}>
              <label>限价</label>
              <div>
                <Input
                  className={`label-input zh-cn`}
                  type={"number"}
                  empty={true}
                  free={true}
                  value={Store.Futures.targetPrice || ""}
                  onChange={(e) => {
                    let value = e.toString();
                    if (value.indexOf("-") !== -1) return;
                    if (value.indexOf(".") !== -1) {
                      if (value.split(".")[0].length > 6) return;
                      if (value.split(".")[1].length > priceDigit) return;
                    } else {
                      if (value.length > 6) return;
                    }

                    Store.Futures.setTargetPrice(value);
                  }}
                  controller={true}
                  min={0}
                  step={Math.pow(0.1, priceDigit)}
                  digit={priceDigit}
                  getAdd={(props) => (
                    <div
                      onClick={() => updatePrice(true)}
                      onMouseDown={props.onMouseDown}
                      onMouseUp={props.onMouseUp}
                      className={"btn-control btn-plus"}
                    >
                      <CSvg src={add} />
                    </div>
                  )}
                  getSub={(props) => (
                    <div
                      onClick={() => updatePrice(false)}
                      onMouseDown={props.onMouseDown}
                      onMouseUp={props.onMouseUp}
                      className={"btn-control btn-minus"}
                    >
                      <CSvg src={minus} />
                    </div>
                  )}
                >
                  <span className={"unit"}>USDT</span>
                </Input>
              </div>
            </div>
          )}

          <div className={"profit_box"}>
            <label>保证金</label>
            <div>
              <Input
                placeholder={`${marginArea ? marginArea[0] : 0} ~ ${
                  marginArea ? marginArea[1] : 0
                }`}
                // initialValue={margin || ""}
                className={`label-input zh-cn`}
                type={"number"}
                free={true}
                step={marginArea ? marginArea[0] : 0}
                min={marginArea ? marginArea[0] : 0}
                max={marginArea ? marginArea[1] : 0}
                onChange={(e) => {
                  let value = e;
                  Store.Futures.switchMarginFree(value);
                }}
                value={margin || ""}
              >
                <span className={"unit"}>CNY</span>
              </Input>

              <div className={"proportion"}>
                {marginList.map((val, index) => (
                  <div
                    key={val}
                    className={index === activeMargin ? "active" : ""}
                    onClick={() => setMargin(val, index)}
                  >
                    {val !== 100 ? `${val}%` : "ALL"}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={"choose_box"}>
            <label>止损比例</label>
            <div className={"proportion"}>
              <div
                className={activeStopLoss === 1 ? "active" : ""}
                onClick={() => {
                  setActiveStopLoss(1);
                  Store.Futures.switchStopLossRate(30);
                }}
              >
                30%
              </div>
              <div
                className={activeStopLoss === 2 ? "active" : ""}
                onClick={() => {
                  setActiveStopLoss(2);
                  Store.Futures.switchStopLossRate(50);
                }}
              >
                50%
              </div>
              <div
                className={activeStopLoss === 3 ? "active" : ""}
                onClick={() => {
                  setActiveStopLoss(3);
                  Store.Futures.switchStopLossRate(70);
                }}
              >
                70%
              </div>
              <div
                className={activeStopLoss === 4 ? "active" : ""}
                onClick={() => {
                  setActiveStopLoss(4);
                  Store.Futures.switchStopLossRate(90);
                }}
              >
                90%
              </div>
              <div
                className={activeStopLoss === 0 ? "active" : ""}
                onClick={() => {
                  setActiveStopLoss(0);
                  // Store.Futures.switchStopLossRate(
                  //   Store.Futures.orderSetting.sl.mul(100)
                  // );
                }}
              >
                默认
              </div>
            </div>
          </div>
          <div className={"choose_box"}>
            <label>止盈比例</label>
            <div className={"proportion"}>
              <div
                className={activeStopProfit === 1 ? "active" : ""}
                onClick={() => {
                  setActiveStopProfit(1);
                  Store.Futures.switchStopProfitRate(50);
                }}
              >
                50%
              </div>
              <div
                className={activeStopProfit === 2 ? "active" : ""}
                onClick={() => {
                  setActiveStopProfit(2);
                  Store.Futures.switchStopProfitRate(100);
                }}
              >
                100%
              </div>
              <div
                className={activeStopProfit === 3 ? "active" : ""}
                onClick={() => {
                  setActiveStopProfit(3);
                  Store.Futures.switchStopProfitRate(150);
                }}
              >
                150%
              </div>
              <div
                className={activeStopProfit === 4 ? "active" : ""}
                onClick={() => {
                  setActiveStopProfit(4);
                  Store.Futures.switchStopProfitRate(200);
                }}
              >
                200%
              </div>
              <div
                className={activeStopProfit === 0 ? "active" : ""}
                onClick={() => {
                  setActiveStopProfit(0);
                  // Store.Futures.switchStopProfitRate(
                  //   Store.Futures.orderSetting.tp.mul(100)
                  // );
                }}
              >
                默认
              </div>
            </div>
          </div>
          <div className={"total"}>
            <div>
              <label>手续费</label>
              <div className={"green"}>
                {!!deductCharge && deductCharge !== ""
                  ? RESTRICT.float(deductCharge, 2)
                  : 0}
                <span>元</span>
              </div>
            </div>
            {!isSimulate && (
              <div>
                <label>礼金抵扣</label>
                <div>{marginPrize}元</div>
              </div>
            )}
            <div>
              <label>履约保证金</label>
              <div>{margin === "" ? 0 : margin}元</div>
            </div>
            <div>
              <label>总交易费用 </label>
              <div>{!!total && RESTRICT.float(total, 2)}元</div>
            </div>
          </div>
          {!isSimulate && (
            <>
              <div className={"line"}>
                <label>持仓过夜</label>
                <Checkbox
                  checked={tradeInfo.defer}
                  onChange={(e) => Store.Futures.switchDefer(e.target.checked)}
                />
              </div>

              <div className={"line"}>
                <label>积分抵扣</label>
                <div className={"volume"}>
                  {useEagle && usableEagle && (
                    <div className={"deduct"}>
                      消耗
                      <span className={"green"}>
                        {RESTRICT.float(deductEagle, 2)}
                      </span>
                      积分，抵扣
                      <span className={"green"}>
                        ¥{RESTRICT.float(deductMoney, 2)}
                      </span>
                    </div>
                  )}
                  <Switch
                    checked={useEagle && usableEagle}
                    onChange={() => {
                      if (!useEagle && !usableEagle)
                        return message.warning("积分不足，无法抵扣");
                      Store.Futures.switchEagle(!useEagle);
                    }}
                  />
                </div>
              </div>
            </>
          )}
          <div className="btn_box">
            <div
              className="btn red_bg"
              onClick={() => {
                confirmOrder(true);
                setBuy(true);
              }}
            >
              <span>买涨</span>
              <b>{quote.buyPrice}</b>
            </div>
            <div
              className="btn green_bg"
              onClick={() => {
                confirmOrder(false);
                setBuy(false);
              }}
            >
              <span>买跌</span>
              <b>{quote.sellPrice}</b>
            </div>
          </div>
        </div>
      </div>
      <Rule rule={rule} data={data} orderType={tradeInfo.orderType} />
      {setting && (
        <TradeSettingModal
          visible={setting}
          onCancel={() => {
            setSetting(false);
          }}
        />
      )}

      {order_show && (
        <Modal
          title={"订单详情"}
          visible={order_show}
          className={"order_confirm"}
          onCancel={() => {
            setOrder_show(false);
          }}
          destroyOnClose={true}
          footer={<div />}
        >
          <div>
            <label>方向：</label>
            <b className={buy ? "red" : "green"}>{buy ? "买涨" : "买跌"}</b>
          </div>
          <div>
            <label>保证金：</label>
            <b>{margin}元</b>
          </div>
          <div>
            <label>手续费：</label>
            <b>{RESTRICT.float(deductCharge, 2)}元</b>
          </div>
          {!isSimulate && (
            <>
              <div>
                <label>递延费：</label>
                <b>{defer ? checkDerFee() : 0}元/天</b>
              </div>
              <div>
                <label>积分抵扣：</label>
                <b>{useEagle && usableEagle ? deductMoney : 0}元</b>
              </div>
              <div>
                <label>礼金抵扣：</label>
                <b>{marginPrize}元</b>
              </div>
            </>
          )}
          <div>
            <label>合计：</label>
            <b className={"total"}>{!!total && RESTRICT.float(total, 2)}元</b>
          </div>
          <div className={"btn_box"}>
            <div
              onClick={() => {
                setOrder_show(false);
              }}
            >
              取消
            </div>
            <div
              onClick={() => {
                // 全配置好要删掉
                order(buy);
                setOrder_show(false);
              }}
            >
              确认
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default observer(LeverOrder);
