import React from 'react';
import CopyToClipboard from "react-copy-to-clipboard";
import {message} from "antd";
import './copy.scss';
import copy from "../../../assets/images/copy.svg";

const Copy = ({text, className}) => {
  const handleCopy = () => {
    if (text !== '' && text !== undefined) {
      message.success('复制成功')
    }
  }

  return (
    <CopyToClipboard text={text}>
      <span className={`common-copy ${className}`} onClick={handleCopy}>
        <img src={copy} className={'copy'} alt={''}/>
      </span>
    </CopyToClipboard>
  )
}

const CopyText = ({text, title, className}) => {
  const handleCopy = () => {
    if (text !== '' && text !== undefined) {
      message.success('复制成功')
    }
  }

  return (
    <CopyToClipboard text={text}>
      <span className={`common-copy ${className}`} onClick={handleCopy}>
        {title}
      </span>
    </CopyToClipboard>
  )
}

export { Copy, CopyText };
