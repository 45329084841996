import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { SimpleInput, SimpleSelect2 } from "../../components";
import ForgotWithdraw from "./forgotWithdraw";
import WithdrawPass from "../userCenter/withdrawPass/index";
import { formatDate, mobileMask, emailMask } from "@/utils";
import { statusText } from "../../components/tool";
import { message } from "antd";
import funds from "../../../assets/images/funds_light.png";
import Store from "@/store";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { Toast } from "antd-mobile";
import { TEST } from "@/utils/test";

const Withdraw = (props) => {
  const [balance, setBalance] = useState(0);
  const [user, setUser] = useState("");
  const [money, setMoney] = useState("");
  const [selectBank, setSelectBank] = useState("");
  const [password, setPassword] = useState("");
  const [list, setList] = useState([]);
  const [bank, setBank] = useState("");
  const [forgot, setForgot] = useState(false);
  const [set, setSet] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [defaultBank, setDefaultBank] = useState("请选择银行卡");
  const [defaultCard, setDefaultCard] = useState("");
  const [verify, setVerify] = useState(false);
  const navigate = useNavigate();
  const { name, bankCardCount, identityNumberValid } = Store.User.accountInfo;

  useEffect(() => {
    const { money } = Store.User.assets;
    setBalance(money);
    setUser(Store.User.accountInfo);
    updateWithdrawRecord();
  }, [Store.User.assets, Store.User.accountInfo]);

  useEffect(() => {
    if (identityNumberValid === false) {
      message.warn("请先实名认证");
      return navigate("/account/nameVerify", { state: "unbind" });
    } else {
      getAllBankcard();
    }
  }, [identityNumberValid, name]);

  useEffect(() => {
    if (identityNumberValid && bankCardCount === 0) {
      message.warn("请先绑定收款账户");
      return navigate("/account/bankcard", { state: "unbind" });
    }
  }, [bankCardCount]);

  useEffect(() => {
    if (defaultBank !== "请选择银行卡" && money >= 100 && password !== "") {
      setVerify(true);
    }
  }, [defaultBank, money, password]);
  //输入金额
  const onChangeAmount = (e) => {
    setMoney(e);
    if (e > balance) {
      setMoney(Math.floor(balance));
    }
    if (e === 0) {
      setMoney("");
    }
  };

  //todo 获取银行卡
  const getAllBankcard = async () => {
    try {
      let result = await EVENT.Account.getAllBankCard();
      if (result.bankCards[0].length === 0) {
      } else {
        const { bankCards } = result;
        bankCards.forEach((item) => {
          let { bank, cardNumber } = item;
          item.bankWithNum =
            bank + "(" + getLastNumbers(bank, cardNumber) + ")";
        });
        setBankList(bankCards);
        setSelectBank(result.bankCards[0]);
        setDefaultBank(result.bankCards[0].name);
        setDefaultCard(result.bankCards[0].cardNumber.length - 4);
      }
    } catch (err) {
      //Toast.show(err.message)
    }
  };

  //todo 提款
  const withdraw = async (verify) => {
    if (user.pw_w === 0) return message.warn("请先设置提款密码");
    if (money < 100) return message.warn("单笔最低提款100元");
    if (verify) return;

    EVENT.Account.withdraw({
      money,
      password,
      bankCard: selectBank.id,
    })
      .then((data) => {
        setMoney("");
        setPassword("");
        Toast.show("提款成功");
        updateWithdrawRecord();
      })
      .catch((err) => {
        message.error({ message: err });
        Toast.show(err.message);
      });
  };

  //todo 提款记录
  const updateWithdrawRecord = () => {
    EVENT.History.depositWithdraw({ type: 200, currencyType: 0 }).then(
      ({ data }) => {
        setList(data.slice(0, 5));
      }
    );
  };

  //取消提款
  const cancelWithdraw = (id) => {
    EVENT.Account.cancelWithdraw(id).then(() => {
      Toast.show("提款撤回成功");
      updateWithdrawRecord();
    });
  };

  // 银行卡后四位
  const getLastNumbers = (name, number) => {
    if (number !== "") {
      return name === "支付宝"
        ? getContentMask(number)
        : number.substring(number.length - 4);
    } else {
      return "";
    }
  };
  // 支付宝号码隐藏
  const getContentMask = (value) => {
    if (TEST.PHONE.test(value)) return mobileMask(value);
    return emailMask(value);
  };

  return (
    <div id={"Withdraw"}>
      <div className="withdraw_title">
        <h5>提款</h5>
      </div>
      <div className={"withdraw_main"}>
        <div className="switch_btn">
          <div>法币提现</div>
        </div>
        <div className={"withdraw_info"}>
          <div>
            {!!bankList && defaultBank !== "请选择银行卡" && (
              <SimpleSelect2
                label={"提款账户"}
                link={"账户管理"}
                goTo={"/account/bankcard"}
                options={bankList}
                onChange={(e) => {
                  setBank(e);
                }}
                onClick={(e) => {
                  console.log(e);
                  setSelectBank(e);
                }}
                defaultValue={selectBank.bankWithNum}
                type={"bank"}
              />
            )}
            <SimpleInput
              label={"提款金额"}
              link={
                <span>
                  可用余额:
                  <b>{balance}</b>
                  CNY
                </span>
              }
              placeholder={"请输入提款金额"}
              type={"number"}
              value={money}
              onChange={(e) => {
                onChangeAmount(e);
              }}
            />
            <SimpleInput
              label={"资金密码"}
              placeholder={"请输入提款密码"}
              type={"password"}
              notice={"password"}
              format={"SET_PASSWORD"}
              value={password}
              onChange={(e, format) => {
                setPassword(e);
              }}
            />
            {user.pw_w !== 0 ? (
              <div className={"forgot"}>
                <div
                  onClick={() => {
                    setForgot(true);
                  }}
                >
                  忘记提款密码
                </div>
              </div>
            ) : (
              <div className={"forgot"}>
                <div
                  onClick={() => {
                    setSet(true);
                  }}
                >
                  设置提款密码
                </div>
              </div>
            )}
            <div
              className={`confirm_btn ${!verify ? "error" : ""}`}
              onClick={() => {
                withdraw(!verify);
              }}
            >
              提款
            </div>
          </div>
          <div className={"tips"}>
            <div>
              <h6>温馨提示</h6>
              <p>* 除周五外 100元-299元提款，需联系客服申请</p>
              <p>* 首次提款最低100元，后续提款建议最低300元起</p>
              <p>* 单笔最低提款100元，最高无上限，每日限提2次</p>
              <p>
                * 提款处理时间：周一至周五09:00-21:00。到账时间以实际银行为准
              </p>
              <p>* 如充值后未交易提款，则需收取5%手续费，手续费最低15元起</p>
              <p>* 如需帮助请联系客服</p>
            </div>
          </div>
        </div>
      </div>
      <div className="withdraw_record">
        <div className={"record"}>
          <h5>近期提款记录</h5>
          <Link to={"/account/fundsDetail"} state={4}>
            查看更多 》
          </Link>
        </div>
        <ul>
          <li className="record_title">
            <div>时间</div>
            <div>提款账户</div>
            <div>数量</div>
            <div>ID</div>
            <div className={"status"}>操作/状态</div>
          </li>
          {!!list &&
            list.map((item, key) => (
              <li key={key}>
                <div>{formatDate("m-d h:i:s", { date: item.createTime })}</div>
                <div>
                  {item.chain && item.chain === "支付宝"
                    ? getContentMask(item.bankCard)
                    : item.chain +
                      " (" +
                      item.bankCard.substring(item.bankCard.length - 4) +
                      ")"}
                </div>
                <div>{item.money}</div>
                <div>{item.id}</div>
                <div className={"status"}>{statusText(item.status)}</div>
              </li>
            ))}
        </ul>
        {!!list && list.length === 0 && (
          <div className={"empty"}>
            <div>
              <img src={funds} />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </div>
      <ForgotWithdraw
        visible={forgot}
        onCancel={() => {
          setForgot(false);
        }}
        {...props}
      />
      <WithdrawPass
        visible={set}
        onCancel={() => {
          setSet(false);
        }}
        {...props}
      />
    </div>
  );
};

export default observer(Withdraw);
