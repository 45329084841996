import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";

import CsAlert from "../CsAlert";

const HomeFooter = () => {
	return (
		<div className={"HomeFoot"}>
			<CsAlert />
			<div className={"foot_info"}>
				<div className="info_main">
					<div className={"main_left"}>
						<div className="foot_nav">
							<div>
								<Link to={"/aboutUs"}>关于我们</Link>
							</div>
							<div>
								<Link to={"/school"}>致远学院</Link>
							</div>
							<div>
								<Link to={"/warning"}>安全保障</Link>
							</div>
							<div>
								<Link to={"/private"}>隐私条款</Link>
							</div>
							<div>
								<Link to={"/disclaimer"}>免责声明</Link>
							</div>
						</div>
						<div className="info">
							<p>
								本网站由香港致远金融期货有限公司负责管理，为客户提供全球金融衍生品交易服务。我们持有美国全国期货协会颁发的NFA，美国财政部FinCEN颁发的MSB等金融合法牌照，并在香港设有分公司以满足亚太地区客户快速增长的金融服务需求。
							</p>
						</div>
					</div>
					<div className={"main_right"}>
						<div className={"follow"}></div>
					</div>
				</div>
			</div>
			<div className={"foot_tips"}>
				<div className="tips_main">
					<p>
						风险警示：交易杠杆化金融产品涉及高风险，未必适合所有投资者，可能导致您损失本金。在交易前，您应完全了解保证金交易相关的一切风险，您承担的亏损风险不应超过您的承受能力。本网站内容仅作一般信息用途，并未考虑您的投资目标、财务状况及投资经验，不能被视为个人建议。如有必要，请寻求独立财务顾问意见。客户有责任确保他/她所居住国法律要求允许其使用致远品牌提供的服务。
					</p>
					<div className={"copy_right"}>
						<div>期货有风险，投资需谨慎！</div>
						<div>Copyright© 2017-2021 Zhiyuan Capital Group. All Rights Reserved.</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeFooter;
