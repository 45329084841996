import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "@/library/api";
import Storage from "@/library/storage";
import { RESTRICT } from "@/library/standard";
import Store from ".";
import FK from "@/core";
import { useNavigate } from "react-router";

export class User {
  accountInfo = {};
  allAssets = [];
  assets = {
    money: 0,
    eagle: 0,
    game: 0,
    prize: 0,
  };
  favor = [];
  isLogin = window.localStorage.getItem("isLogin") || false;
  _loop = null;
  _detailLoop = null;
  ru = "";
  eye = true;

  constructor() {
    makeAutoObservable(this);
    this.initUser();
  }
  //todo 初始化
  initUser() {
    EVENT.Account.init()
      .then((e) => {
        if (e) {
          this.updateDetail(true);
          this.updataAssets(true);
          this.updateOrderDefault();
        } else {
          this.setLogout();
        }
      })
      .catch((e) => console.log(e));
  }

  //todo 登录回调
  loginCallback(info) {
    this.isLogin = true;
    FK.Launcher.CFD.getItemDetail();
    window.localStorage.setItem("isLogin", true);
    window.localStorage.setItem("mobile", info.mobile);
    window.localStorage.setItem("email", info.email);
    window.localStorage.setItem("principal", info.account === info.phone);
    this.updateDetail();
    this.updataAssets(true);
    this.updateOrderDefault();
  }

  //todo 持续刷新用户信息
  updateDetail(val = true) {
    this.isLogin = true;
    EVENT.Account._keepUpdateDetail()
      .then(({ user }) => runInAction(() => (this.accountInfo = user)))
      .catch(({ code }) => {
        if (code === 401) {
          const navigate = useNavigate();
          navigate("/CommonLogin");
          this.setLogout();
        }
      })
      .finally(() => {
        if (val) this._detailLoop = setTimeout(() => this.updateDetail(true), 15000);
      });
  }

  //todo 持续刷新资产信息
  updataAssets(val = false) {
    EVENT.Assets.getMyAssets()
      .then(({ data }) => {
        data.map((e) => {
          e.money = RESTRICT.float(e.money, 2);
          e.game = RESTRICT.float(e.game, 2);
        });
        this.allAssets = data;
        this.currentCurrency();
      })
      .catch((err) => console.warn(err, "userStore.Assets"))
      .finally(() => {
        if (val) this._loop = setTimeout(() => this.updataAssets(true), 10000);
      });
  }

  //todo 获取当前币种资产信息
  currentCurrency(cur = "CNY") {
    this.assets = this.allAssets.filter((o) => o.currency === cur)[0];
  }

  //todo 获取自选
  getFavor() {
    if (!this.isLogin) return;
    EVENT.Account.getFavor().then(({ data }) => {
      runInAction(() => (this.favor = data));
    });
  }

  //todo 是否是自选
  isFavor(id) {
    let b;
    if(this.favor === undefined){return;}
    this.favor.findIndex((o) => o === id) === -1 ? (b = false) : (b = true);
    return b;
  }

  //todo 设置自选
  setFavor(id) {
    let del = false;
    if (this.isFavor(id)) del = true;
    return new Promise((resolve, reject) => {
      EVENT.Account.setFavor(id, del)
        .then(() => this.getFavor())
        .then(() => {
          del ? resolve("移除成功") : resolve("添加成功");
        })
        .catch((error) => {
          resolve(error.code);
        });
    });
  }

  //todo 获取代理ID
  getRu(ru) {
    if (!!ru) this.ru = ru;
  }

  //todo 显示或隐藏资产信息
  switchEyes(status) {
    this.eye = status;
  }

  //todo 获取用户止盈止损默认状态
  updateOrderDefault() {
    EVENT.Account.getOrderDefault().then(({ data }) => {
      if (data.tp === 0 && data.sl === 0) {
        data.tp = 5;
        data.sl = 0.9;
      }
      Store.Futures.getOrderSet(data);
    });
  }

  //todo 登出
  setLogout(state) {
    if (!!state) this.isLogin = state;
    if (!this.isLogin) return;
    this.isLogin = false;
    Storage.removeItem("isLogin");
    Storage.removeItem("mobile");
    Storage.removeItem("email");
    Store.Position.end();
    Store.Position.endLimit();
    clearTimeout(this._loop);
    clearTimeout(this._detailLoop);
    this._loop = null;
    this._detailLoop = null;
    this.accountInfo = {};
    this.assets = {
      money: 0,
      eagle: 0,
      game: 0,
      prize: 0,
    };
    return new Promise((resolve) => {
      const result = EVENT.Account.logout();
      resolve(result);
    });
  }
}
