import React, { useState, useEffect, useMemo } from "react";
import { Modal, Slider, Switch, notification } from "antd";
import loss from "../../../assets/images/trade/loss.svg";
import add from "../../../assets/images/trade/add.svg";
import { observer } from "mobx-react-lite";
import Store from "@/store";
import { RESTRICT } from "@/library/standard";
import "./index.scss";
import "../position/index.scss";

const TradeSetting = ({ close, ...props }) => {
  const { tp, sl, confirmClose, confirmPlace, overnight } =
    Store.Futures.orderSetting || {};
  const [confirmPlaceNew, setConfirmPlaceNew] = useState(confirmPlace);
  const [confirmCloseNew, setConfirmCloseNew] = useState(confirmClose);
  const [overnightNew, setOvernightNew] = useState(overnight);
  const tradeInfo = Store.Futures;

  const submit = () => {
    confirmPlace !== confirmPlaceNew && Store.Futures.switchOrderConfirm();
    confirmClose !== confirmCloseNew && Store.Futures.switchCloseConfirm();
    overnight !== overnightNew && Store.Futures.switchOvernight();
    Store.Futures.saveSetting()
      .then(() => {
        notification.success({ message: "保存成功" });
        Store.User.updateOrderDefault();
      })
      .catch(({ message }) => {
        notification.error({ message });
      });

    props.onCancel();
  };
  const stopLossInfo = useMemo(
    () => ({
      min: Math.abs(tradeInfo.stopLossList[0].mul(100)),
      max: Math.abs(tradeInfo.stopLossList[1].mul(100)),
    }),
    [tradeInfo.stopLossList]
  );
  const stopProfitInfo = useMemo(
    () => ({
      min: tradeInfo.stopProfitList[0].mul(100),
      max: tradeInfo.stopProfitList[1].mul(100),
    }),
    [tradeInfo.stopProfitList]
  );
  return (
    <Modal
      title={"交易设置"}
      visible={props.visible}
      className={"traderSettingModal theme_dark"}
      onCancel={props.onCancel}
      footer={<div />}
    >
      <div className={"set_title"}>
        <span>默认止盈比例</span>
        <b>{RESTRICT.float(tp.mul(100), 0)}%</b>
      </div>
      <div className={"set_box"}>
        <div className={"slider_top"}>
          <div
            onClick={() => {
              Store.Futures.updateProfitPercent(tp.mul(100).sub(1));
            }}
          >
            <img src={loss} alt="" />
          </div>
          <Slider
            className={"stopProfit stopLoss"}
            defaulatValue={tp.mul(100)}
            step={1}
            min={stopProfitInfo.min}
            value={tp.mul(100)}
            max={stopProfitInfo.max}
            onChange={(val) => Store.Futures.updateProfitPercent(val)}
          />
          <div
            onClick={() =>
              Store.Futures.updateProfitPercent(tp.mul(100).add(1))
            }
          >
            <img src={add} alt="" />
          </div>
        </div>
        <div className={"slider_"}>
          <div>5%</div>
          <div>500%</div>
        </div>
      </div>
      <div className={"set_title"}>
        <span>默认止损比例</span>
        <b>{RESTRICT.float(sl.mul(100), 0)}%</b>
      </div>
      <div className={"set_box"}>
        <div className={"slider_top"}>
          <div
            onClick={() => Store.Futures.updateLossPercent(sl.mul(100).sub(1))}
          >
            <img src={loss} alt="" />
          </div>
          <Slider
            className={"stopProfit stopLoss"}
            defaulatValue={sl.mul(100)}
            step={1}
            min={stopLossInfo.min}
            value={sl.mul(100)}
            max={stopLossInfo.max}
            onChange={(val) => Store.Futures.updateLossPercent(val)}
          />
          <div
            onClick={() => Store.Futures.updateLossPercent(sl.mul(100).add(1))}
          >
            <img src={add} alt="" />
          </div>
        </div>
        <div className={"slider_"}>
          <div>5%</div>
          <div>90%</div>
        </div>
      </div>
      <div className={"set_line"}>
        <label>下单确认</label>
        <Switch
          checked={confirmPlaceNew}
          onChange={() => setConfirmPlaceNew(!confirmPlaceNew)}
        />
      </div>
      <div className={"set_line"}>
        <label>平仓确认</label>
        <Switch
          checked={confirmCloseNew}
          onChange={() => setConfirmCloseNew(!confirmCloseNew)}
        />
      </div>
      <div className={"set_btn"}>
        <div
          onClick={() => {
            props.onCancel();
          }}
        >
          取消
        </div>
        <div
          onClick={() => {
            submit();
          }}
        >
          确认
        </div>
      </div>
    </Modal>
  );
};
export default observer(TradeSetting);
