import React, { useState, useEffect, useMemo } from "react";
import { formatDate } from "@/utils";
import dayjs from "dayjs";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import { DatePicker, message, notification, Pagination, Select } from "antd";
import { goodsImg, splitAry, statusText } from "../../components/tool";
import { isEmpty } from "@/utils";
import funds from "../../../assets/images/funds_light.png";

import EVENT from "../../../library/api";
import "./detail.scss";
const { Option } = Select;

const CryptoDetail = () => {
  const [currency, setCurrency] = useState("USDT");
  const [payment, setPayment] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filterType, setFilterType] = useState("");
  const [cryptoInfo, setCryptoInfo] = useState({ list: [], total: 0 });
  const [page, setPage] = useState(1);

  useEffect(() => {
    updateRechargeRecord({});
    getCryptoList();
    setCurrency("USDT");
  }, []);
  const disabledStartDate = useMemo(() => {
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  }, [startDate, endDate]);
  const disabledEndDate = useMemo(() => {
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  }, [startDate, endDate]);

  const getCryptoList = () => {
    EVENT.Payment.getDepositCurrency()
      .then(({ data }) => {
        setPayment(data);
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };

  const quickSearch = (type) => {
    let today = new Date();
    let tdd = String(today.getDate()).padStart(2, "0");
    let tmm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let tyyyy = today.getFullYear();

    let startDate = new Date();
    let sdd, smm, syyyy;
    switch (type) {
      case "DAY":
        startDate.setDate(startDate.getDate());
        sdd = String(startDate.getDate()).padStart(2, "0");
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      case "WEEK":
        const day = startDate.getDay() || 7;
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + 0 - day
        );
        sdd = String(startDate.getDate() + 1).padStart(2, "0");
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      case "MONTH":
        sdd = "1";
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      default:
        break;
    }

    startDate = `${syyyy}-${smm}-${sdd} ${"00"}:${"00"}:${"00"}`;
    let endDate = `${tyyyy}-${tmm}-${tdd} ${23}:${59}:${59}`;

    let tempStart = Date.parse(startDate);
    tempStart = dayjs(new Date(tempStart));
    let tempEnd = Date.parse(endDate);
    tempEnd = dayjs(new Date(tempEnd));
    updateRechargeRecord({ page: 1, startDate, endDate, currency });
    setFilterType(type);
    setStartDate(tempStart);
    setEndDate(tempEnd);
    if (page > 1) {
      setPage(1);
    }
  };

  const search = () => {
    if (!startDate) return message.warning("请选择起始时间");
    if (!endDate) return message.warning("请选择结束时间");
    const start = !!startDate && startDate.format("YYYY-MM-DD HH:mm:ss");
    const end = !!endDate && endDate.format("YYYY-MM-DD HH:mm:ss");
    updateRechargeRecord({ page: 1, startDate: start, endDate: end, currency });
    if (filterType) {
      setFilterType("");
    }
    if (page > 1) {
      setPage(1);
    }
  };
  const updateRechargeRecord = (params) => {
    const {
      startDate,
      endDate,
      page = 1,
      currency,
      currencyType = 0,
      transfer = false,
      type = 100,
    } = params;
    EVENT.History.depositWithdraw({
      page,
      rows: 999,
      createTimeGe: startDate,
      createTimeLe: endDate,
      transfer,
      currency,
      currencyType,
      type,
    })
      .then(({ data }) => {
        let o = [];
        if (!isEmpty(data)) {
          o = data.filter((item) => !!item.address);
        }
        setCryptoInfo({ list: splitAry(o, 10), total: o.length });
      })
      .catch(({ message }) => notification.error({ message }));
  };

  const onChangePage = (page) => {
    if (page - 1 >= cryptoInfo.list.length) return;
    setPage(Number(page));
    // updateRechargeRecord({ page, startDate, endDate, currency });
  };

  return (
    <div id={"Detail"} className={"cryptoDetail"}>
      <div className={"title"}>
        <div className={"dateBox"}>
          <div className={"subtitle"}>币种</div>
          <Select
            className={"select_box"}
            onChange={(e) => setCurrency(e)}
            defaultValue={
              <div className={"default"}>
                <img src={goodsImg[currency]} alt="" />
                <b>{currency}</b>
              </div>
            }
          >
            {payment.map((item, key) => (
              <Option key={key} value={item}>
                <img src={goodsImg[item]} alt="" />
                <b>{item}</b>
              </Option>
            ))}
          </Select>
        </div>
        <div className={"dateBox"}>
          <div className={"subtitle"}>日期</div>
          <div className={"dateWrapper"}>
            <DatePicker
              style={{ width: 140 }}
              defaultValue={moment(new Date())}
              disabledDate={disabledStartDate}
              format={"YYYY-MM-DD"}
              value={startDate}
              placeholder={"开始日期"}
              locale={locale}
              onChange={(val) => setStartDate(val)}
            />
            <span className={"line"}> - </span>
            <DatePicker
              style={{ width: 140 }}
              disabledDate={disabledEndDate}
              format={"YYYY-MM-DD"}
              value={endDate}
              placeholder={"结束日期"}
              locale={locale}
              onChange={(val) => setEndDate(val)}
            />
          </div>
        </div>
        <div className={"searchButtonGroup"}>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                quickSearch("DAY");
              }}
              className={`quickSearch ${filterType === "DAY" ? "active" : ""}`}
            >
              本日
            </div>
          </div>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                quickSearch("WEEK");
              }}
              className={`quickSearch ${filterType === "WEEK" ? "active" : ""}`}
            >
              本周
            </div>
          </div>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                quickSearch("MONTH");
              }}
              className={`quickSearch ${
                filterType === "MONTH" ? "active" : ""
              }`}
            >
              本月
            </div>
          </div>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                search();
              }}
              className={"searchButton"}
            >
              搜索
            </div>
          </div>
        </div>
      </div>
      <ol className={"crypto_ul"}>
        <li className={"li_title"}>
          <div>时间</div>
          <div>充值币种</div>
          <div>金额(元)</div>
          <div className={"longer"}>充币地址</div>
          <div>ID</div>
          <div>状态</div>
        </li>
        {cryptoInfo.list.length !== 0 ? (
          cryptoInfo.list.map((item, key) => (
            <li key={key}>
              <div>{formatDate("m-d h:i:s", { date: item.time })}</div>
              <div>{item.currency}</div>
              <div>{item.money}</div>
              <div className={"longer"}>{item.address}</div>
              <div>{item.id}</div>
              <div>{statusText(item.status)}</div>
            </li>
          ))
        ) : (
          <div className={"empty"}>
            <div>
              <img src={funds} />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </ol>
      <div className={"pagination"}>
        <Pagination
          onChange={(page) => {
            setPage(page);
          }}
          total={cryptoInfo.total}
          defaultPageSize={10}
          current={page}
        />
      </div>
    </div>
  );
};

export default CryptoDetail;
