import React from "react";
import "./index.scss";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { Popover } from "antd";
import downloadQR from "../../assets/images/home/download/downloadQR.png";
import download_qr from "../../assets/images/download_qrV2.png";

import section1_1 from "../../assets/images/download/top.png";
import section2_1 from "../../assets/images/download/section2-1.png";
import section2_2 from "../../assets/images/download/section2-2.svg";
import section3_2 from "../../assets/images/download/section3-2.svg";
import section3_1 from "../../assets/images/download/section3-1.png";
import section4_1 from "../../assets/images/download/section4-1.svg";
import section4_2 from "../../assets/images/download/section4-2.svg";

const Download = () => {
  const download = (
    <div className={"download_Qr"}>
      <img src={download_qr} alt="" />
    </div>
  );
  return (
    <div id={"Download"}>
      <HomeHeader />
      <div className="section1">
        <img src={section1_1} alt="" />
        <div className="main">
          <h4>致远金融</h4>
          <p>一站式金融衍生品交易平台</p>
          <div className={"btn_box"}>
            <div className={"android"} />
            <div className={"ios"} />
            <Popover content={download} placement={"bottom"}>
              <img src={downloadQR} alt="" />
            </Popover>
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="main">
          <div>
            <img className={"left"} src={section2_1} alt="" />
          </div>
          <div>
            <img className={"right"} src={section2_2} alt="" />
          </div>
        </div>
      </div>
      <div className="section3">
        <div className="main">
          <div>
            <img className={"right"} src={section3_2} alt="" />
          </div>
          <div>
            <img className={"left pc"} src={section3_1} alt="" />
          </div>
        </div>
      </div>
      <div className="section4">
        <div className="main">
          <img src={section4_1} alt="" />
          <img src={section4_2} alt="" />
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};

export default Download;
