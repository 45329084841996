import React, { useState } from 'react';
import './index.scss';
import { Select } from 'antd';
import { Link } from 'react-router-dom';

const { Option } = Select;

const SimpleSelect2 = ({ label, defaultValue, options, link, goTo, type, onChange, onClick }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
    onChange && onChange(value);
  }

  return (
    <div className="simple_select">
      <div className="select_title">
        <label>{label}</label>
        {link && <Link to={goTo}>{link}</Link>}
      </div>
      <Select
        className="select_box"
        value={selectedValue}
        onChange={handleSelectChange}
      >
        {options &&
          options.map((item, key) => (
            <Option value={item.bankWithNum} key={key} 
              onClick={() => {
                onClick && onClick(item);
              }}>
                {item.bankWithNum}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default SimpleSelect2;
