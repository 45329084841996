import React, { useState, useEffect } from "react";
import { formatDate, isEmpty } from "@/utils";
import { Pagination, notification } from "antd";
import { splitAry } from "../../components/tool";
import funds from "../../../assets/images/funds_light.png";
import EVENT from "../../../library/api";

const EagleDetail = () => {
  const [eagleInfo, setEagleInfo] = useState({ list: [], total: 0 });
  const [page, setPage] = useState(1);
  useEffect(() => {
    getRecord();
  }, []);
  const getRecord = () => {
    EVENT.History.getRecordHistory(3, 9999, "pc", page, true)
      .then(({ data }) => {
        let o = [];
        if (!isEmpty(data)) {
          o = data.filter((item) => item.assetType === 3);
        }
        setEagleInfo({ list: splitAry(o, 10), total: o.length });
      })
      .catch(({ message }) => {
        notification.error({ message });
      });
  };
  const onChangePage = (page) => {
    if (page - 1 >= eagleInfo.list.length) return;
    setPage(Number(page));
  };

  return (
    <div id={"Detail"}>
      <ol className={"eagle_ol"}>
        <li className={"li_title"}>
          <div>时间</div>
          <div>金额</div>
          <div>明细</div>
          <div>ID</div>
        </li>
        {!isEmpty(eagleInfo.list) ? (
          eagleInfo.list[page - 1].map((item, key) => {
            return (
              <li key={key}>
                <div>
                  {formatDate("m-d-y h:i:s", { date: item.createTime })}
                </div>
                <div className={item.type === 100 ? "red" : "green"}>
                  {item.type === 100 ? "+" : "-"}
                  {Number(item.money).toFixed(2)}
                </div>
                <div>{item.type === 200 ? "礼金抵扣" : "资金存入"}</div>
                <div>{item.id}</div>
              </li>
            );
          })
        ) : (
          <div className={"empty"}>
            <div>
              <img src={funds} />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </ol>
      <div className={"pagination"}>
        <Pagination
          onChange={onChangePage}
          current={page}
          defaultPageSize={10}
          total={eagleInfo.total}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default EagleDetail;
