import Launcher from "./launcher";
import Language from "./language";
import PositionClose from "./PositionCloseSet";

export default class Core {
  #launcher = null;
  #lang = null;
  #positionClose = null;

  constructor() {
    this.#lang = new Language();
    this.#launcher = new Launcher();
    this.#positionClose = new PositionClose();
  }

  get Launcher() {
    return this.#launcher;
  }

  get Lang() {
    return this.#lang;
  }
  
  get PositionClose() {
    return this.#positionClose;
  }
}
