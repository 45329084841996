import React, {Component,useState} from 'react';
import './index.scss'
import {Link} from 'react-router-dom';
import fh from '../../assets/images/school/fh.png'
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { useNavigate } from "react-router-dom";

function Page06() {
    const history = useNavigate();
    const [which, setWhich] = useState(null);
    return (
        <div className="school page3">
                <HomeHeader/>
                <div className="wrap">
                    <div className="box">
                        <div className="menu">
                            <p onClick={()=>history('/school')} className="title">致远学院</p>
                            <dl>
                                <dt>
                                    <h5>实时资讯</h5>
                                    <p><Link to="/page0">市场快讯</Link></p>
                                    <p><Link to="/page1">热点新闻</Link></p>
                                    <p><Link to="/page2">财经日历</Link></p>
                                </dt>
                                <dt>
                                    <h5>新手入门</h5>
                                    <p><Link to="/page3" t="新手入门">基础知识</Link></p>
                                    <p><Link to="/page4" t="新手入门">投资介绍</Link></p>
                                </dt>
                                <dt>
                                    <h5>高手进阶</h5>
                                    <p><Link to="/page5" t="高手进阶">基本面分析</Link></p>
                                    <p><Link to="/page6" t="高手进阶">技术面分析</Link></p>
                                    <p><Link to="/page7" t="高手进阶">综合技巧</Link></p>
                                    <p><Link to="/page8" t="高手进阶">高手心态</Link></p>
                                </dt>
                            </dl>
                        </div>
                        {
                            which===null?(
                                <div className="main">
                                    <div className="school-header">
                                        <Link to="/school">致远学院</Link> &gt; <span v-param="title">技术面分析</span>
                                    </div>
                                    <div className="school-list">
                                        <div className="school-list-body" id="投资介绍">
                                            <ul>
                                                <li><label>1</label><div onClick={()=>setWhich(0)}>理论基础</div></li>
                                                <li><label>2</label><div onClick={()=>setWhich(1)}>技术分析方法的运用</div></li>
                                                <li><label>3</label><div onClick={()=>setWhich(2)}>技术分析的要素: 价与量</div></li>
                                                <li><label>4</label><div onClick={()=>setWhich(3)}>成交量、持仓量与价格的关系</div></li>
                                            </ul>
                                        </div>
                                        <p className="back">
                                            <Link to="/school">
                                                <img src={fh} width="90px" alt="返回" title="返回"/>
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            ):(
                                which===0?(
                                    <div className="main">
                                        <div className="school-header">
                                            <Link to="/school">致远学院</Link> &gt;&nbsp;
                                            <span>高手进阶</span>
                                        </div>
                                        <div className="school-detail">
                                            <div onClick={()=>setWhich(null)} className="fh">
                                                <img src={fh} width="70px" alt="返回" title="返回"/>
                                            </div>
                                            <div className="school-detail-title">理论基础</div>
                                            <div className="school-detail-content"><p>技术分析的三个基本假定</p>        <p
                                                className="subtitle">（一）市场行为包容消化一切</p>
                                                <p>"市场行为包容消化一切"构成了技术分析的基础。除非你已经完全理解和接受这个前提条件，否则学习技术分析就毫无意义。技术分析者认为，能够影响某种商品期货价格的任何因素--基础的、政治的、心理的或任何其它方面的--实际上都反映在其价格之中。由此推论，我们必须做的事情就是研究价格变化。乍一听，这句话似乎过于武断，但是花功夫推敲推敲，确实如此。这个前提的实质含义其实就是价格变化必定反映供求关系，如果需求大于供给，价格必然上涨；如果供给过于需求，价格必然下跌。供求规律是所有经济预测方法的出发点。把它倒过来，那么，只要价格上涨，不论是因为什么具体的原因，需求一定超过供给，从经济基础上说必定看好；如果价格下跌，从经济基础上说必定看淡。归根结底，技术分析者不过是通过价格的变化间接地研究基本面。大多数技术派人士也会同意，正是某种商品的供求关系，即基本面决定了该商品的看涨或者看跌。图表本身并不能导致市场的升跌，只是简明地显示了市场上流行的乐观或悲观的心态。</p>
                                                <p>图表派通常不理会价格涨落的原因，而且在价格趋势形成的早期或者市场正处在关键转折点的时候，往往没人确切了解市场为什么会如此这般古怪地动作。恰恰是在这种至关紧要的时刻，技术分析者常常独辟蹊径，一语中的。所以，随着你市场经验日益丰富，遇到上边这种情况越多，"市场行为包容消化一切"这一句话就越发显出不可抗拒的魅力。</p>
                                                <p>顺理成章，既然影响市场价格的所有因素最终必定要通过市场价格反映出来，那么研究价格就够了。实际上，图表分析师只不过是通过研究价格图表及大量的辅助技术指标，让市场自己揭示它最可能的走势，而并不是分析师凭他的精明"征服"了市场。今后讨论的所有技术工具只不过是市场分析的辅助手段。技术派当然知道市场涨落肯定有缘故，但他们认为这些因素对于分析预测无关痛痒。</p>
                                                <p className="subtitle">（二）价格以趋势方式演变</p>
                                                <p>"趋势"概念是技术分析的核心。研究价格图表的全部意义，就是要在一个趋势发生发展的早期，及时准确地把它揭示出来，从而达到顺着趋势交易的目的。事实上，技术分析在本质上就是顺应趋势，即以判定和追随既成趋势为目的。</p>
                                                <p>从"价格以趋势方式演变"可以自然而然地推断，对于一个既成的趋势来说，下一步常常是沿着现存趋势方向继续演变，而掉头反向的可能性要小得多。这当然也是牛顿惯性定律的应用。还可以换个说法：当前趋势将一直持续到掉头反向为止。虽然这句话差不多是同语反复，但这里要强调的是：坚定不移地顺应一个既成趋势，直至有反向的征兆为止。</p>
                                                <p className="subtitle">（三）历史会重演</p>
                                                <p>技术分析和市场行为学与人类心理学有着千丝万缕的联系。比如价格形态，它们通过一些特定的价格图表形状表现出来，而这些图形表示了人们对某市场看好或看淡的心理。其实这些图形在过去的几百年里早已广为人知、并被分门别类了。既然它们在过去很管用，就不妨认为它们在未来同样有效，因为它们是以人类心理为根据的，而人类心理从来就是"江山易改本性难移"。"历史会重演"说得具体点就是，打开未来之门的钥匙隐藏在历史里，或者说将来是过去的翻版。</p>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    which===1?(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;&nbsp;
                                                <span>高手进阶</span>
                                            </div>
                                            <div className="school-detail">
                                                <div onClick={()=>setWhich(null)} className="fh">
                                                    <img src={fh} width="70px" alt="返回" title="返回"/>
                                                </div>
                                                <div className="school-detail-title">技术分析方法的运用</div>
                                                <div className="school-detail-content">
                                                    <p>在价、量历史资料基础上进行的统计、数学计算、绘制图表方法是技术分析的主要手段。从这个意义上讲，技术分析方法可以有多种。不管技术分析方法是如何产生的，人们最关心的是它的实用性，因为我们的目的是用它来预测未来价格走势，从而为投资决策服务。</p>
                                                    <p>技术分析作为一种投资分析工具，在应用时应该注意以下问题：</p>
                                                    <p>（一）技术分析应该与基本面分析结合起来使用在国内的期货市场，技术分析有较高的预测成功率。但是，在运用技术分析的同时，必须注意结合基本面分析。对于商品期货来讲，制约期货价格的根本因素是商品的供求关系，而基本面分析恰恰是从分析供求关系入手的。因此，技术分析应该与基本面分析结合起来使用。</p>
                                                    <p>（二）注意多种技术分析方法的综合研判，切忌片面地使用某一种技术分析</p>
                                                    <p>投资者应全面考虑各种技术分析方法对未来的预测，综合这些方法得到的结果，最终得出一个合理的多空双方力量对比的描述。实践证明，单独使用一种技术分析方法有相当大的局限性和盲目性。如果应用多种技术分析方法后得到同一结论，那么依据这一结论出错的机会就很小，而仅靠一种方法得到的结论出错的机会就大。为了减少自己的失误，应尽量多掌握一些技术分析方法。</p>
                                                    <p>从"价格以趋势方式演变"可以自然而然地推断，对于一个既成的趋势来说，下一步常常是沿着现存趋势方向继续演变，而掉头反向的可能性要小得多。这当然也是牛顿惯性定律的应用。还可以换个说法：当前趋势将一直持续到掉头反向为止。虽然这句话差不多是同语反复，但这里要强调的是：坚定不移地顺应一个既成趋势，直至有反向的征兆为止。</p>
                                                    <p>（三）前人和别人得出的结论要通过自己实践验证后才能放心地使用</p>
                                                    <p>由于期货市场能给人们带来巨大的收益，上百年来研究期货的人层出不穷，分析的方法各异，使用同一分析方法的风格也不同。前人和别人得到的结论是在一定的特殊条件和特定环境中得到的，随着市场环境的改变，前人和别人成功的方法自己使用时有可能失败。</p>
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        which===2?(
                                            <div className="main">
                                                <div className="school-header">
                                                    <Link to="/school">致远学院</Link> &gt;&nbsp;
                                                    <span>高手进阶</span>
                                                </div>
                                                <div className="school-detail">
                                                    <div onClick={()=>setWhich(null)} className="fh">
                                                        <img src={fh} width="70px" alt="返回" title="返回"/>
                                                    </div>
                                                    <div className="school-detail-title">技术分析的要素：价与量</div>
                                                    <div className="school-detail-content">
                                                        <p>期货价格技术分析的主要基础指标有开盘价、收盘价、最高价、最低价、成交量和持仓量。</p>
                                                        <p>（一）开盘价，开市前 5 分钟集合竞价产生的价格。</p>
                                                        <p>（二）收盘价，当日最后一笔成交价格。</p>        <p>（三）最高价，当日的最高交易价格。</p>
                                                        <p>（四）最低价，当日的最低交易价格。</p>
                                                        <p>（五）成交量，为在一定的交易时间内某种商品期货在交易所成交的合约数量。在国内期货市场，计算成交量时采用买入与卖出量两者之和。</p>
                                                        <p>（六）持仓量，指买入或卖出后尚未对冲及进行实物交割的某种商品期货合约的数量，也称未平仓合约量或空盘量。未平仓合约的买方和卖方是相等的，持仓量只是买方和卖方合计的数量。如买卖双方均为新开仓，则持仓量增加
                                                            2 个合约量；如其中一方为新开仓，另一方为平仓，则持仓量不变；如买卖双方均为平仓，持仓量减少 2
                                                            个合约量。当下次开仓数与平仓数相等时，持仓量也不变。</p>
                                                        <p>由于持仓量是从该种期货合约开始交易起，到计算该持仓量止这段时间内尚未对冲结算的合约数量，持仓量越大，该合约到期前平仓交易量和实物交割量的总和就越大，成交量也就越大。因此，分析持仓量的变化可推测资金在期货市场的流向。持仓量增加，表明资金流入期货市场；反之，则说明资金正流出期货市场。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ):(
                                            which===3?(
                                                <div className="main">
                                                    <div className="school-header">
                                                        <Link to="/school">致远学院</Link> &gt;&nbsp;
                                                        <span>高手进阶</span>
                                                    </div>
                                                    <div className="school-detail">
                                                        <div onClick={()=>setWhich(null)} className="fh">
                                                            <img src={fh} width="70px" alt="返回" title="返回"/>
                                                        </div>
                                                        <div className="school-detail-title">成交量、持仓量与价格的关系</div>
                                                        <div className="school-detail-content">
                                                            <p>成交量和持仓量的变化会对期货价格产生影响，期货价格变化也会引起成交量和持仓量的变化。因此，分析三者的变化，有利于正确预测期货价格走势。</p>
                                                            <p>（一）成交量、持仓量增加，价格上升，表示新买方正在大量收购，近期内价格还可能继续上涨。</p>
                                                            <p>（二）成交量、持仓量减少，价格上升，表示卖空者大量补货平仓，价格短期内向上，但不久将可能回落。</p>
                                                            <p>（三）成交量增加，价格上升，但持仓量减少，说明卖空者和买空者都在大量平仓，价格马上会下跌。</p>
                                                            <p>（四）成交量、持仓量增加，价格下跌，表明卖空者大量出售合约，短期内价格还可能下跌，但如抛售过度，反可能使价格上升。</p>
                                                            <p>（五）成交量、持仓量减少，价格下跌，表明大量买空者急于卖货平仓，短期内价格将继续下降。</p>
                                                            <p>（六）成交量增加、持仓量和价格下跌，表明卖空者利用买空者卖货平仓导致价格下跌之际陆续补货平仓获利，价格可能转为回升。</p>
                                                            <p>从上分析可见，在一般情况下，如果成交量、持仓量与价格同向，其价格趋势可继续维持一段时间；如两者与价格反向时，价格走势可能转向。当然，这还需结合不同的价格形态作进一步的具体分析。</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ):(
                                                <div className="main">
                                                    <div className="school-header">
                                                        <Link to="/school">致远学院</Link> &gt;&nbsp;
                                                        <span>高手进阶</span>
                                                    </div>
                                                    <div>暂无数据</div>
                                                </div>
                                            )
                                        )
                                    )
                                )
                            )
                        }

                    </div>
                </div>
                <HomeFooter/>
            </div>
    );
  }
  
  export default Page06;

