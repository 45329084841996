import React, { useEffect, useState } from "react";
import "./index.scss";
import partner from "../../assets/images/home/partner.png";

const Section4 = () => {
  return (
    <div className={"Section4"}>
      <div className="partner">
        <div className="partner_main">
          <h5>合作伙伴</h5>
          <div className={"global"}>
            <img src={partner} alt="" />
            <a
              href="https://www.cmegroup.com"
              target={"_blank"}
              className={"cme"}
            />
            <a
              href="https://www.theice.com"
              target={"_blank"}
              className={"ice"}
            />
            <a
              href="https://www.cmegroup.com"
              target={"_blank"}
              className={"ny"}
            />
            <a
              href="https://www.cmegroup.com"
              target={"_blank"}
              className={"co"}
            />
            <a href="http://www.lme.com" target={"_blank"} className={"lme"} />
            <a
              href="http://www.czce.com.cn"
              target={"_blank"}
              className={"zz"}
            />
            <a
              href="http://www.shfe.com.cn"
              target={"_blank"}
              className={"sh"}
            />
            <a
              href="http://www.dce.com.cn"
              target={"_blank"}
              className={"dl"}
            />
            <a href="http://www.ine.cn" target={"_blank"} className={"ine"} />
            <a
              href="http://www.tocom.or.jp"
              target={"_blank"}
              className={"jpx"}
            />
            <a
              href="http://www.cffex.com.cn"
              target={"_blank"}
              className={"cff"}
            />
            <a
              href="https://www.hkex.com.hk"
              target={"_blank"}
              className={"hk"}
            />
            <a
              href="https://www.euronext.com"
              target={"_blank"}
              className={"eu"}
            />
            <a
              href=" http://www.eurexchange.com"
              target={"_blank"}
              className={"ex"}
            />
            <a
              href="http://www.bursamalaysia.com"
              target={"_blank"}
              className={"mlxy"}
            />
            <a href="http://www.sgx.com" target={"_blank"} className={"sg"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
