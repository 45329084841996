import address from "./address.json";
import Storage from "@/library/storage";
import { safeValue } from "@/library/safety";

/**
 * 断言
 * @param condition
 * @param message
 */
export function assert(condition, message) {
  if (!condition) {
    if (typeof message === "object") {
      if (legal(message.message)) {
        message = message.message;
      }
    }
    throw new Error(message || "断言判断错误");
  }
}
/**
 * 数据合法性检查
 * @param {Any} val
 * @param {Boolean} [avoid] 是否检查空值
 */
export function legal(val, avoid = false) {
  if (avoid) {
    return val !== undefined && val !== null && val !== "" && val !== "null";
  }
  return val !== undefined && val !== null && val !== "null";
}

export function invariant(check, message, thing) {
  if (!check) throw new Error("数据有限性验证失败: " + message + (thing ? ` in '${thing}'` : ""));
}

export function getCloseTime(arr) {
  let o = new Date().getTime();
  return arr.find((e) => {
    return o < e;
  });
}

/**
 * 时间转换
 * @param {String} format
 * @param {Object} [options]
 * @returns {*}
 */
export function formatDate(format, options = {}) {
  let { date, isUTC } = options;
  /***新旧接口兼容 旧接口返回nunber类型 新接口返回string类型 string类型时间戳无法直接转化为时间对象 */
  date = Number(date) || date;
  /** */

  if (!format) return null;

  if (!date) {
    date = new Date();
  } else {
    date = new Date(date);
  }

  let y, m, d, h, i, s;
  if (isUTC) {
    y = date.getFullYear();
    m = completeNum(date.getUTCMonth() + 1);
    d = completeNum(date.getUTCDate());
    h = completeNum(date.getUTCHours());
    i = completeNum(date.getUTCMinutes());
    s = completeNum(date.getUTCSeconds());
  } else {
    y = date.getFullYear();
    m = completeNum(date.getMonth() + 1);
    d = completeNum(date.getDate());
    h = completeNum(date.getHours());
    i = completeNum(date.getMinutes());
    s = completeNum(date.getSeconds());
  }
  return format.replace("y", y).replace("m", m).replace("d", d).replace("h", h).replace("i", i).replace("s", s);
}

/**
 * 个位数取整
 * @returns {string}
 */
export function completeNum(num) {
  return num < 10 ? "0" + num : num;
}

export const TEST = {
  /**
   * 密码
   */
  PASSWORD: /^.{6,16}$/,

  /**
   * 注册密码
   */
  // SET_PASSWORD: /^[A-Za-z0-9!@#$%^&*?]{6,16}$/,
  SET_PASSWORD:/^.*(?=.{6,16})(?=.*\d)(?=.*[a-zA-Z])[A-Za-z0-9!@#$%^&*?].*$/,
  /**
   * 强力密码
   */
  STRONG_PASSWORD:/^.*(?=.{6,16})(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*?]).*$/,
  /**
   * 中文名
   */
  CNNAME: /^[\u4e00-\u9fa5·]{0,15}$/,
  /**
   * 昵称
   */
  NICKNAME: /^[\u4e00-\u9fa5A-Za-z0-9-_]{2,16}$/,
  /**
   * 银行卡
   */
  BANKCARD: /^[1-9]\d{15,18}$/,
  /**
   * 验证码
   */
  CAPTCHA: /^\d{4}$/,

  GLOBAL_CAPTCHA: /^\d{6}$/,

  /**
   * 手机号
   */
  PHONE: /^1[023456789]\d{9}$/,
  /**
   *身份证号
   */
  ID: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,

  GLOBAL_TEL:/^\d{5,15}$/,

  EMAIL:/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
};

export function parallel(value, string) {
  if (value === true) return string;
  if (!safeValue(value)) return "";
  return "";
}

/**
 * 获取当前平台名
 * @returns {string}
 */
export function getIdentity(len) {
  let SEED = "0Aa1Bb2Cc3Dd4Ee5Ff6Gg7Hh8Ii9Jj0Kk1Ll2Mm3Nn4Oo5Pp6Qq7Rr8Ss9Tt0Uu1Vv2Ww3Xx4Yy5Zz6789".split("");
  let SIZE = SEED.length;
  let LEN = 20;
  if (!len || typeof len !== "number") {
    len = LEN;
  }
  let uid = "";
  while (len-- > 0) {
    uid += SEED[(Math.random() * SIZE) | 0];
  }
  return uid;
}



/**
 * 获取当前平台名
 * @returns {string}
 */
export function getPlatform() {
  let ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    // isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;

  if (isAndroid) return "android";
  if (isPhone) return "ios";
  if (isTablet) return "pad";
  if (isPc) return "pc";
  return "h5";
}

/**
 * @returns {String} web-PC浏览器  h5-手机浏览器 native-web_view及原生
 */
export function getClient() {
  if (!!window.AppJs || !!window.WebViewJavascriptBridge || !!window.isSuperman) {
    return "native";
  }
  let u = navigator.userAgent;
  if (u.match(/AppleWebKit.*Mobile.*/)) {
    return "h5";
  } else {
    if (/(Android)/i.test(navigator.userAgent)) {
      return "h5";
    } else {
      return "web";
    }
  }
}

export function check_webp_feature() {
  let feature = Storage.getItem("feature");
  if (feature === null) {
    feature = "";
  } else {
    feature = feature === "true";
  }
  if (feature === "") {
    let kTestImages = {
      lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
      lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
      alpha:
        "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
      animation:
        "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
    };
    let img = new Image();
    img.onload = function () {
      feature = img.width > 0 && img.height > 0;
      Storage.setItem("feature", "true");
      // exposure("imgType", feature);
    };
    img.onerror = function () {
      feature = false;
      Storage.setItem("feature", "false");
      // exposure("imgType", feature);
    };
    img.src = "data:image/webp;base64," + kTestImages.lossless;
    return "";
  } else {
    return feature;
  }
}

/**
 * 抛错
 * @param {string} error
 */
export function throwError(error) {
  throw error;
}

/**
 * 获取查询字符串
 * @example ?t=1  返回{t:1}
 * @returns {{}}
 */
export function getSearch(url) {
  let search = {};
  let address = url || window.location.href;
  if (address.indexOf("?") !== -1) {
    [, address] = address.split("?");
    address = address.replace("%26", "&").split("&");
    for (let o of address) {
      let [key, val] = o.split("=");
      search[key] = val;
    }
  }
  return search;
}

/**
 * 设置cookie
 * @param name
 * @param value
 * @param exp
 */
export function setCookie(name, value, exp) {
  let d = new Date();
  d.setTime(d.getTime() + exp * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/`;
}
export function getCookie(name) {
  let v = window.document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}
export function removeCookie(name) {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let val = getCookie(name);
  if (val != null) document.cookie = `${name}=${val};expires=${exp.toUTCString()}`;
}

/**
 * 隐藏姓名
 * */
export function nameMask(name) {
  if (!name) return;
  return name.replace(/.(?=.)/g, "*");
}

/**
 * 隐藏电话
 * */
export function mobileMask(mobile) {
  return !!mobile ? mobile.replace(/(\d{3}).+(\d{4})/, "$1****$2") : "";
}

/**
 * 隐藏邮箱地址
 * */
export function emailMask(email) {
  if(!!email){
  	return !!email && email.replace(/(.{1}).+(.{1}@.+)/g, "$1****$2");
  }
  // return !!email && email.replace(/(.{1}).+(.{1}@.+)/g, "$1***$2");
}

/**
 * 隐藏银行卡或身份证
 * */
export function idMask(id) {
  if (!id) return;
  if (id.length === 16) {
    return id.replace(/\d{12}(\d{4})/, "**** **** **** $1");
  } else if (id.length === 17) {
    return id.replace(/\d{13}(\d{4})/, "**** **** **** $1");
  } else if (id.length === 18) {
    return id.replace(/\d{14}(\w{4})/, "**** **** **** $1");
  } else if (id.length === 19) {
    return id.replace(/\d{15}(\d{4})/, "**** **** **** $1");
  }
}

/**
 * 去除空格
 * */
export function removeAllSpace(str) {
  return str.replace(/\s+/g, "");
}

/**
 * 判断是否空数组
 * */
export function isEmptyAry(e) {
  let t;
  for (t in e) return !1;
  return !0;
}

/**
 * 根据弹出键盘弹性的调整界面高度
 * @param value
 */
export function flexResize(value) {
  if (value) {
    let body = document.body,
      html = document.documentElement;
    let height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
      window.innerHeight
    );
    document.body.style.height = `${height}px`;
  } else {
    document.body.style.height = "100%";
  }
}

export function subPoint(source, digit) {
  let ratio = Math.pow(10, digit);

  let origin = Number(source);

  let originBeiShu = Math.floor(origin * ratio);
  let originNoPoint = Math.floor(origin) * ratio;

  let pointNumber = originBeiShu - originNoPoint;
  let originPoint = pointNumber / ratio;

  return Math.floor(origin) + originPoint;
}

/**
 * 计算字符串所占的内存字节数，默认使用UTF-8的编码方式计算，也可制定为UTF-16
 * UTF-8 是一种可变长度的 Unicode 编码格式，使用一至四个字节为每个字符编码
 *
 * 000000 - 00007F(128个代码)      0zzzzzzz(00-7F)                             一个字节
 * 000080 - 0007FF(1920个代码)     110yyyyy(C0-DF) 10zzzzzz(80-BF)             两个字节
 * 000800 - 00D7FF
 * 00E000 - 00FFFF(61440个代码)    1110xxxx(E0-EF) 10yyyyyy 10zzzzzz           三个字节
 * 010000 - 10FFFF(1048576个代码)  11110www(F0-F7) 10xxxxxx 10yyyyyy 10zzzzzz  四个字节
 *
 * 注: Unicode在范围 D800-DFFF 中不存在任何字符
 * {@link http://zh.wikipedia.org/wiki/UTF-8}
 *
 * UTF-16 大部分使用两个字节编码，编码超出 65535 的使用四个字节
 * 000000 - 00FFFF  两个字节
 * 010000 - 10FFFF  四个字节
 *
 * {@link http://zh.wikipedia.org/wiki/UTF-16}
 * @param  {String} str
 * @param  {String} charset utf-8, utf-16
 * @return {Number}
 */
export function sizeof(str, charset) {
  let total = 0;
  let charCode, i, len;

  charset = charset ? charset.toLowerCase() : "";

  if (charset === "utf-16" || charset === "utf16") {
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i);

      if (charCode <= 0xffff) {
        total += 2;
      } else {
        total += 4;
      }
    }
  } else {
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i);

      if (charCode <= 0x007f) {
        total += 1;
      } else if (charCode <= 0x07ff) {
        total += 2;
      } else if (charCode <= 0xffff) {
        total += 3;
      } else {
        total += 4;
      }
    }
  }
  return total;
}

/**
 * 对选择地区部分的处理
 */
function formatData(province) {
  let data = province ? address[province] : address;
  let result = [];
  for (let key in data) {
    result.push({
      value: key,
    });
  }
  return result;
}

export function provinceData(antPicker = false) {
  if (antPicker) {
    let result = [].concat(formatData());
    result.map((item) => {
      item.label = item.value;
      return item;
    });
    return result;
  }
  return formatData();
}

/**
 * 省 / 市 / 区处理
 * @deprecated 拼写错误，心里没点儿逼数么
 * @param province
 * @param antPicker
 * @returns {*[]}
 */
export function cityeData(province, antPicker = false) {
  if (antPicker) {
    let result = [].concat(formatData(province));
    result.map((item) => {
      item.label = item.value;
      return item;
    });
    return result;
  }
  return formatData(province);
}
export function cityData(province, antPicker = false) {
  if (antPicker) {
    let result = [].concat(formatData(province));
    result.map((item) => {
      item.label = item.value;
      return item;
    });
    return result;
  }
  return formatData(province);
}
export function districtData() {
  let districtData = provinceData(true);
  districtData.forEach((item) => {
    item.children = cityData(item.value, true).map((i) => {
      return { label: i.label, value: i.value };
    });
  });
  return districtData;
}

/**
 * 将科学计数法显示的数字，强制转换成完整的数字
 * */
export function getFullNum(n) {
  const s = n + "";
  if (!s.includes("e")) {
    return n + "";
  }
  const a = s.split("e");
  const s1 = a[0].replace(".", "");
  const s2 = a[1].replace(/[+-]/, "");
  if (a[1].includes("-")) {
    return "0." + "0".repeat(s2 * 1 - 1) + s1;
  }
  return s1.padEnd(s2 * 1 + 1, "0");
}

/**
 * 百万显示
 * */
export const getTotalM = (total) => {
  if (!total) return 0;
  return Number(total).div(1000000) >= 1 ? `${Number(total).div(1000000).toFormat(0)}M` : `${total.toFormat(0)}`;
};

/**
 * 获取制定范围的随机数
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const getRandomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
export const sortNum = (a, b) => {
  return a - b;
};

// * 把数组转换成字符串（styles 使用多个类,使用空格隔开）
// * */
export function arrayToStr(arr) {
 return arr.join(" ");
}

/**
 * 判断是否空物件或數組
 * */
export function isEmpty(e) {
	if (Array.isArray(e)) {
		return e.length === 0;
	}
	let t;
	for (t in e) return false;
	return true;
}
