import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { formatDate } from "@/utils";
import { getBankIcon, withdrawStatus } from "@/utils/common";
import { Header } from "@/view/components/index";
import EVENT from "@/library/api";
import { Toast } from "antd-mobile";
import empty from "@/assets/images/empty_new.png";

function WithdrawHistory() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = () => {
    EVENT.History.getWithdrawHistory().then(({ inouts }) => setList(inouts));
  };

  const cancel = (id) => {
    EVENT.Account.cancelWithdraw(id)
      .then(() => Toast.show({ content: "取消成功", afterClose: () => getHistory() }))
      .catch(({ message }) => Toast.show(message));
  };

  return (
    <div className={styles.history}>
      <Header title="提款记录" theme />
      <main>
        {list.length > 0 ? (
          list.map((item, keys) => {
            return (
              <div className={styles.list} key={keys}>
                <div className={styles.top}>
                  <div>
                    <img src={getBankIcon(item.bankName)} alt="" />
                    <span>
                      {item.bankName}({item.bankCard})
                    </span>
                  </div>
                  <div className={styles.money}>-{item.money}</div>
                </div>
                <div className={styles.btm}>
                  {item.status === -1 ? (
                    <div className={styles.cancel} onClick={() => cancel(item.id)}>
                      取消提款
                    </div>
                  ) : (
                    <div className={styles.status}>{withdrawStatus[item.status]}</div>
                  )}

                  <div>{formatDate("y-m-d h:i:s", { date: item.time })}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.empty}>
            <img src={empty} alt="" />
            <p>暂无数据</p>
          </div>
        )}
      </main>
    </div>
  );
}

export default WithdrawHistory;
