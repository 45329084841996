import React, { useState, useEffect } from "react";
import "./index.scss";
import { Modal } from "antd";
import { SimpleInput } from "../../../components";
import { message, notification } from "antd";
import Store from "@/store";
import { Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import EVENT from "@/library/api";

const ChangeLoginPass = (props) => {
  const navigate = useNavigate();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cfmPass, setCfmPass] = useState("");

  const changeLoginPass = () => {
    if (!verify) return;
    if (!oldPass) return Toast.show("旧密码格式错误");
    if (!newPass) return Toast.show("新密码格式错误");
    if (!cfmPass) return Toast.show("确认密码格式错误");
    if (newPass !== cfmPass) return Toast.show("两次密码不一致");
    EVENT.Account.changeLoginPassword({ oldPass, newPass, cfmPass })
      .then(() => {
        Toast.show({
          content: "修改成功,请重新登录",
          afterClose: () => {
            navigate("/CommonLogin", { replace: true });
            Store.User.setLogout();
          },
        });
      })
      .catch(({ message }) => Toast.show({ content: message }));
  };

  const verify =
    oldPass.length !== 0 && newPass.length !== 0 && cfmPass.length !== 0;

  return (
    <Modal
      title={"修改登录密码"}
      visible={props.visible}
      className={"alertModal aboutPass"}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={<div />}
    >
      <SimpleInput
        label={"原密码"}
        placeholder={"请输入原密码"}
        type={"password"}
        format={"SET_PASSWORD"}
        notice={"password"}
        onChange={(e) => {
          setOldPass(e);
        }}
      />
      <SimpleInput
        label={"新密码"}
        placeholder={"请输入新密码"}
        type={"password"}
        format={"SET_PASSWORD"}
        notice={"password"}
        onChange={(e) => {
          setNewPass(e);
        }}
      />
      <SimpleInput
        label={"确认密码"}
        placeholder={"请输入确认密码"}
        type={"password"}
        format={"SET_PASSWORD"}
        notice={"password"}
        onChange={(e) => {
          setCfmPass(e);
        }}
      />

      <div
        className={`confirm_btn ${!verify && "verify"}`}
        onClick={() => changeLoginPass()}
      >
        确认
      </div>
    </Modal>
  );
};

export default observer(ChangeLoginPass);
