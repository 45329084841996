import React, { useState, useEffect } from "react";
import { getIdentity } from "@/utils";
import EVENT from "@/library/api";
import CSvg from "../../../utils/svg";
import icon_close from "../../../assets/images/close.svg";
import icon_refresh from "../../../assets/images/refresh.svg";

import "./imgtest.scss";
import { notification } from "antd";

const ImgValide = (props) => {
  const { hide, account, type, sendCodeStatus } = props;
  const [hash, setHash] = useState(getIdentity(8));
  const [imgAddress, setAddress] = useState("");
  const [input, setInput] = useState(["", "", "", ""]);
  const [input_a, setInput_a] = useState("");
  const [input_b, setInput_b] = useState("");
  const [input_c, setInput_c] = useState("");
  const [input_d, setInput_d] = useState("");

  useEffect(() => {
    setAddress(`/api/code/image.jpg?vHash=${hash}`);
  }, [hash]);

  const submit = () => {
    if (!account) return;
    let arr = [input_a, input_b, input_c, input_d];
    let lcode = arr.join("");

    EVENT.Account[account.indexOf("@") === -1 ? "sendSMS" : "sendEmail"]({
      type,
      account,
      code: lcode,
      hash,
    })
      .then(() => {
        notification.success({ message: "验证码发送成功，请注意查收！" });
        hide();
        sendCodeStatus(true);
      })
      .catch((e) => {
        setHash(getIdentity(8));
        notification.error({ message: e.message });
        setInput(["", "", "", ""]);
        setInput_a("");
        setInput_b("");
        setInput_c("");
        setInput_d("");
      });
  };

  const inputEvent = (e) => {
    const index = Number(e.target.dataset.index);
    const newInput = [...input];
    const ele = e.target;
    ele.value = ele.value.replace(/[\W]/g, "");
    newInput[index] = ele.value;
    setInput(newInput);
    if (ele.value !== "" && index !== 3) {
      ele.nextElementSibling.focus();
    }
  };

  const keydown = (e, index) => {
    const newInput = [...input];
    const ele = e.target;
    if (e.key === "Backspace") {
      if (ele.value === "" && index !== 0) {
        ele.previousElementSibling.focus();
      } else {
        newInput[index] = "";
        setInput(newInput);
      }
    } else if (e.key === "Home") {
      ele.parentElement.children[0] && ele.parentElement.children[0].focus();
    } else if (e.key === "End") {
      ele.parentElement.children[input.length - 1] &&
        ele.parentElement.children[input.length - 1].focus();
    } else if (e.key === "ArrowLeft") {
      if (ele.previousElementSibling) {
        ele.previousElementSibling.focus();
      }
    } else if (e.key === "ArrowRight") {
      if (ele.nextElementSibling) {
        ele.nextElementSibling.focus();
      }
    }
  };

  const containerStyle = {
    position: "fixed",
    display: "flex",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9,
  };

  const maskStyles = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transition: "all .3s ease-out",
    zIndex: 0,
    background: "rgba(0, 0, 0, .6)",
  };

  const containStyles = {
    background: "#fff",
    zIndex: 1,
    borderRadius: "4px",
    transition: "all .3s ease-out",
    width: "310px",
    height: "270px",
  };

  const containHeaderstyles = {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    height: "46px",
    lineHeight: "46px",
    borderBottom: "1px solid #EAEAEA",
  };

  const containerImgstyles = {
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    height: "36px",
    margin: "14px 0 17px",
  };

  const conInpstyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    padding: "15px 0",
    background: "#F5F5F5",
  };

  const inputSty = {
    height: "40px",
    width: "40px",
    border: "1px solid #8B9BB0",
    borderRadius: "4px",
    margin: "0 5px",
    background: "transparent",
    outline: "none",
    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
    textAlign: "center",
  };

  const subSty = {
    height: "40px",
    lineHeight: "40px",
    width: "200px",
    margin: "23px auto 26px",
    fontSize: "16px",
    textAlign: "center",
    background: "linear-gradient(to right, #FFCD6D, #FFB31F)",
    color: "#333333",
    borderRadius: "6px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <div style={maskStyles} onClick={() => hide()} />
      <div style={containStyles}>
        <div style={containHeaderstyles}>
          <span>请输入图形验证码</span>
          <CSvg
            style={{
              position: "absolute",
              marginRight: "15px",
              cursor: "pointer",
              right: 0,
            }}
            src={icon_close}
            onClick={() => hide()}
          />
        </div>
        <div style={containerImgstyles}>
          <img src={imgAddress} alt="" />
          <CSvg
            style={{
              position: "absolute",
              marginRight: "15px",
              cursor: "pointer",
              right: 0,
            }}
            src={icon_refresh}
            className={`imgtest-icon-refresh`}
            onClick={() => setHash(getIdentity(8))}
          />
        </div>
        <div style={conInpstyles} onPaste={(e) => {}}>
          <input
            type="text"
            maxLength={1}
            data-index={0}
            onInput={inputEvent}
            onKeyDown={keydown}
            style={inputSty}
            value={input_a}
            onChange={(e) => {
              setInput_a(e.target.value);
            }}
          />
          <input
            type="text"
            maxLength={1}
            data-index={1}
            onInput={inputEvent}
            onKeyDown={keydown}
            style={inputSty}
            value={input_b}
            onChange={(e) => {
              setInput_b(e.target.value);
            }}
          />
          <input
            type="text"
            maxLength={1}
            data-index={2}
            onInput={inputEvent}
            onKeyDown={keydown}
            style={inputSty}
            value={input_c}
            onChange={(e) => {
              setInput_c(e.target.value);
            }}
          />
          <input
            type="text"
            maxLength={1}
            data-index={3}
            onInput={inputEvent}
            onKeyDown={keydown}
            style={inputSty}
            value={input_d}
            onChange={(e) => {
              setInput_d(e.target.value);
            }}
          />
        </div>
        <div style={subSty} onClick={() => submit()}>
          提交
        </div>
      </div>
    </div>
  );
};

export default ImgValide;
