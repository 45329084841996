import React from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react-lite";
import { NavLink, useLocation } from "react-router-dom";
import Store from "@/store";
import FK from "@/core";

//图片资源
import { ReactComponent as Home } from "@/assets/svg/outlined/home.svg";
import { ReactComponent as HomeActive } from "@/assets/svg/filled/home_active.svg";
import { ReactComponent as Mine } from "@/assets/svg/outlined/mine.svg";
import { ReactComponent as MineActive } from "@/assets/svg/filled/mine_active.svg";
import { ReactComponent as Quote } from "@/assets/svg/outlined/quote.svg";
import { ReactComponent as QuoteActive } from "@/assets/svg/filled/quote_active.svg";
import { ReactComponent as Trade } from "@/assets/svg/outlined/trade.svg";
import { ReactComponent as Position } from "@/assets/svg/outlined/position.svg";
import { ReactComponent as PositionActive } from "@/assets/svg/outlined/position_active.svg";

const Footer = () => {
  const path = useLocation().pathname;
  return (
    <div className={styles.footer}>
      <NavLink to="/home">
        {path === "/home" ? <HomeActive /> : <Home />}
        <div>首页</div>
      </NavLink>
      <NavLink to="/quote/1" className={styles.small}>
        {path.indexOf("/quote") !== -1 ? <QuoteActive /> : <Quote />}
        <div>行情</div>
      </NavLink>
      <NavLink
        to={`/trade/${Store.Quote.id || FK.Launcher.CFD.index}`}
        className={styles.trade_a}
        onClick={() => Store.Quote.switchSimulate(false)}
      >
        <div className={styles.cover}>
          <Trade />
        </div>
        <div className={styles.text}>交易</div>
      </NavLink>
      <NavLink to="/position" className={styles.small} onClick={() => Store.Quote.switchSimulate(false)}>
        {path === "/position" ? <PositionActive /> : <Position />}
        <div>持仓</div>
      </NavLink>
      <NavLink to="/mine">
        {path === "/mine" ? <MineActive /> : <Mine />}
        <div>我的</div>
      </NavLink>
    </div>
  );
};

export default observer(Footer);
