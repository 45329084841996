import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./index.scss";
import Store from "@/store";

import center from "../../assets/images/home/center.svg";
import logo from "../../assets/images/home/logo.svg";
import banner1 from "../../assets/images/home/banner/mockgold.png";
import banner2024 from "../../assets/images/home/banner/2024.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { getEXImg } from "../components/tool";
import { observer } from "mobx-react-lite";

const Section1 = () => {
  if (Store.Brief.hot === null) return;
  const { hot } = Store.Brief;

  const array = Object.entries(Store.Brief.total);

  return (
    <div className={"Section1"}>
      <div className={"title"}>
        <div className="title_main">
          <div className={"ex"}>
            {hot.map((item, index) => {
              return array.map((i, k) => {
                if (item === i[0]) {
                  return (
                    <div key={index}>
                      <img src={getEXImg[index].img} />
                      <span>
                        <b>{getEXImg[index].ex}:</b>
                        <i className={i[1].isOpen ? "open" : ""}>
                          {i[1].isOpen ? "开盘中" : "休市"}
                        </i>
                      </span>
                    </div>
                  );
                }
              });
            })}
          </div>
          <Link
            to={Store.User.isLogin ? "/account/userCenter" : "/CommonLogin"}
            className={"center"}
          >
            <img src={center} />
            <span>个人中心</span>
          </Link>
        </div>
      </div>
      <div className="header">
        <div className="header_main">
          <img src={logo} alt="" />
          <div className={"nav_box"}>
            {
              <div>
                <Link to={Store.User.isLogin ? "/trade/CL" : "/commonLogin"}>
                  行情
                </Link>
              </div>
            }
            <div>
              <Link to={"/activity"}>活动专区</Link>
            </div>
            <div>
              <Link to={"/school"}>{"致远学院"}</Link>
            </div>
            <div>
              <Link to={"/cooperation/invite"}>推广合作</Link>
            </div>
            <div>
              <Link to={"/download"}>移动端下载</Link>
            </div>
            {!Store.User.isLogin && (
              <Link to={"/CommonRegister"}>免费注册</Link>
            )}
          </div>
        </div>
      </div>
      <Carousel
        showIndicators
        showStatus={false}
        showThumbs={false}
        interval={5000}
        stopOnHover={false}
        infiniteLoop
        autoPlay
      >
        <div>
          <img
            className="bannerImg"
            onClick={() => {
              this.props.history.push("/activity");
            }}
            src={banner1}
            alt=""
          />
        </div>
        <div>
          <img
            className="bannerImg"
            onClick={() => {
              this.props.history.push("/activity");
            }}
            src={banner2024}
            alt=""
          />
        </div>
      </Carousel>
    </div>
  );
};

export default observer(Section1);
