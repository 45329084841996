import React from "react";
import "./index.scss";
import section1 from "../../assets/images/home/section1.svg";
import section2 from "../../assets/images/home/section2.svg";
import terminal from "../../assets/images/home/download/terminal.png";
import iOS from "../../assets/images/home/download/IOS.png";
import android from "../../assets/images/home/download/Android.png";
import bg1 from "../../assets/images/home/section1_bg.png";
import downloadQR from "../../assets/images/home/download/downloadQR.png";
import download_qr from "../../assets/images/download_qrV2.png";
import { Popover } from "antd";

const Section3 = () => {
  const download = (
    <div className={"home_Qr"}>
      <img src={download_qr} alt="" />
    </div>
  );

  return (
    <div className={"Section3"}>
      <div className={"introduction1"}>
        <img src={bg1} alt="" className={"bg1"} />
        <img src={section1} alt="" className={"terminal"} />
      </div>
      <div className={"download"}>
        <div className="download_main">
          <img src={terminal} alt="" className={"terminal"} />
          <div className={"download_info"}>
            <h5>随时随地 全在掌控</h5>
            <p>
              我们完全免费的强大客户端旨在提升您的交易速度与效率，确保您完全掌控自己的交易体验，不论新手老手，都能随时发现投资机会、充满信心地无障碍交易全球期货市场。
            </p>
            <div>
              <img src={iOS} alt="" />
              <img src={android} alt="" />
              <Popover content={download} placement={"right"}>
                <img className={"qrCode"} src={downloadQR} alt="" />
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <div className={"introduction2"}>
        <img src={section2} alt="" className={"terminal"} />
      </div>
    </div>
  );
};

export default Section3;
