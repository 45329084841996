import service from "../network/axios";
import { getPlatform, getSearch } from "@/utils";
import { Toast } from "antd-mobile";

/**
 * 获取法币充值渠道
 * @param {*} type
 * @returns
 */
const getPaymentList = async (type = 0) => {
  const o = {
    action: "getPayList",
    switchType: 1,
    paymentWay: type,
    platform: getPlatform(),
  };
  const result = await service.post("/api/pay/recharge.htm", o);
  return result;
};
/**
 * 获取可充值币种
 * @returns
 */
const getDepositCurrency = async () => {
  //todo 获取充币 我也不知道为啥会有个Type
  const type = 1;
  const result = await service.get("/api/setting/recharge-currency", type);
  return result;
};
/**
 * 验证chain 有效性
 * @param {*} currency 币种
 */
const getChains = async (currency) => {
  const o = { currency };
  const result = await service.get("/api/setting/chains", { params: o });
  return result;
};
/**
 * 获取充值地址
 * @param {*} currency 币种
 * @param {*} chain 链
 */
const getCryptoAddress = async (currency, chain) => {
  const o = { currency, chain };
  const result = await service.get("/api/pay/recharge/getAddress", {
    params: o,
  });
  return result;
};
/**
 * 支付
 * @param {*} data url 地址  money 金额 channel 标识
 * @param {*} pc PC
 */
const fastPayment = (data, pc) => {
  const { url, moneyValue, channel } = data;
  console.log("url",url)
  if (typeof moneyValue !== "number") { Toast.show("输入充值金额"); return;}
  let o = getSearch(url);
  o.money = moneyValue;
  o.channel = channel;
  service
    .post("/api/pay/rechargeXXPay.htm", o)
    .then((result) => {
      if (pc) return `/api/vf/tdCode.gif?url=${result.redirectURL}`;
      window.location.href = result.redirectURL;
    })
    .catch(({ message }) => Toast.show(message));
};

export default {
  getPaymentList,
  getDepositCurrency,
  getChains,
  getCryptoAddress,
  fastPayment,
};
