import React, { useState, useEffect } from "react";
import "./index.scss";
import { Tabs, notification } from "antd";
import MyUser from "./myUser";
import UserTrade from "./userTrade";
import EVENT from "../../../library/api";
import { RESTRICT } from "@/library/standard";
import { useLocation } from "react-router-dom";
const { TabPane } = Tabs;

const InviteHistory = () => {
  const [subCount, setsubCount] = useState(0);
  const [subTrade, setsubTrade] = useState(0);
  const [commission, setcommission] = useState(0);
  const [fee, setfee] = useState(0);
  const location = useLocation();
  const page = location?.state || 1;

  useEffect(() => {
    updateTotalCommission();
    updateDayCommission();
    EVENT.History.updateAgentStat("CNY").then(({ data }) => {
      const { subCount, subTrade } = data;
      setsubCount(subCount);
      setsubTrade(subTrade);
    });
  }, []);

  const updateTotalCommission = () => {
    EVENT.History.updateCommissionTotal().then(({ data }) => {
      let o = 0,
        c = 0;
      for (let { currency, money, charge } of data) {
        if (money === 0) continue;
        if (currency === "CNY") {
          o += money;
          c += charge;
          continue;
        }
        const r = EVENT.Assets.getCryptoRate({ src: currency, des: "CNY" });
        if (r !== money) {
          o += r;
        }
      }
      setcommission(o);
      setfee(c);
    });
  };

  const updateDayCommission = () => {
    EVENT.History.updateDayCommission()
      .then((r) => {
        let show7 = r.data.some((item) => {
          return item.money && item.money > 0;
        });
      })
      .catch((err) => {
        notification.error({ message: err });
      });
  };

  return (
    <div id={"InviteHistory"}>
      <div className="invite_title">
        <h5>邀请记录</h5>
      </div>
      <div className={"invite_info"}>
        <div>
          <label>邀请人数</label>
          <b>{subCount}</b>
        </div>
        <div>
          <label>交易人数</label>
          <b>{subTrade}</b>
        </div>
        <div>
          <label>累计手续费</label>
          <b>{RESTRICT.float(fee, 2).toFixed(2)}</b>
        </div>
        <div>
          <label>总佣金收入</label>
          <b>{RESTRICT.float(commission, 2).toFixed(2)}</b>
        </div>
      </div>
      <div className={"my_user"}>
        <Tabs defaultActiveKey={page} destroyInactiveTabPan>
          <TabPane tab={"被邀请人"} key="1">
            <MyUser />
          </TabPane>
          <TabPane tab={"佣金记录"} key="2">
            <UserTrade />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default InviteHistory;
