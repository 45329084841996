import React, { useEffect, useState, useRef } from "react";
import "../changeLoginPass/index.scss";
import { SimpleInput } from "../../../components";
import { Modal } from "antd";
import EVENT from "@/library/api";
import { ImgValide } from "@/view/components/index";
import { Toast } from "antd-mobile";

const BindEmail = (props) => {
  const { onCancel } = props;
  const type = "BIND_EMAIL";
  const timer = useRef();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [countNumber, setCountNumber] = useState(0);
  const [countText, setCountText] = useState("获取验证码");
  const [codeShow, setCodeShow] = useState(false);

  useEffect(() => {
    if (countNumber < 0) {
      setCountText("获取验证码");
      clearInterval(timer.current);
    } else {
      countDown(countNumber);
    }
  }, [countNumber]);

  //todo 倒计时
  const countDown = () => {
    timer.current = setTimeout(() => {
      setCountNumber((time) => time - 1);
      setCountText(`${countNumber}秒后重发`);
    }, 1000);
  };

  //todo 发送后返回的状态
  const sendCodeStatus = (val) => {
    if (val) setCountNumber(120);
  };
  //todo 验证后绑定邮箱
  const bindEmail = () => {
    EVENT.Account.verifyEmail(email, code, type)
      .then(() => {
        EVENT.Account.bindEmail(email)
          .then(() => {
            Toast.show("绑定成功！");
            onCancel();
          })
          .catch(({ message }) => Toast.show(message));
      })
      .catch(({ message }) => Toast.show(message));
  };

  return (
    <>
      <Modal
        title={"绑定邮箱"}
        visible={props.visible}
        className={"alertModal aboutPass"}
        onCancel={props.onCancel}
        destroyOnClose={true}
        footer={<div />}
      >
        <SimpleInput
          label={"邮箱"}
          placeholder={"请输入邮箱地址"}
          type={"email"}
          format={"EMAIL"}
          notice={"email"}
          onChange={(e, format) => {
            setEmail(e);
          }}
        />
        <div className={"sendCode"}>
          <SimpleInput
            label={"验证码"}
            placeholder={"请输入验证码"}
            format={"GLOBAL_CAPTCHA"}
            notice={"msgCode"}
            type={"verifyCode"}
            onChange={(e, format) => {
              setCode(e);
            }}
          />
          <div className={"getCode"} onClick={() => setCodeShow(true)}>
            {countText}
          </div>
        </div>
        <div
          className={`confirm_btn ${
            (email.length < 1 || code.length < 1) && "error"
          }`}
          onClick={() => {
            bindEmail();
          }}
        >
          确认
        </div>
      </Modal>
      {codeShow && (
        <ImgValide
          type={type}
          account={email}
          sendCodeStatus={sendCodeStatus}
          hide={() => setCodeShow(false)}
        />
      )}
    </>
  );
};

export default BindEmail;
