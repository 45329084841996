import React, { useEffect, useState, useRef } from "react";
import "../changeLoginPass/index.scss";
import { Modal } from "antd";
import { SimpleInput } from "../../../components";
import Store from "@/store";
import EVENT from "@/library/api";
import { ImgValide } from "@/view/components/index";
import { Toast } from "antd-mobile";

const OneStep = (props) => {
  const { oldEmail, showCode, changeStep, countText, type } = props;
  const [code, setCode] = useState("");
  ///todo 下一步
  const nextStep = () => {
    EVENT.Account.verifyEmail(oldEmail, code, type)
      .then(() => changeStep(true))
      .catch((e) => Toast.show({ content: e.message }));
  };
  return (
    <>
      <SimpleInput label={"邮箱地址"} value={oldEmail} disable={true} />
      <div className={"sendCode"}>
        <SimpleInput
          label={"验证码"}
          placeholder={"请输入验证码"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          type={"verifyCode"}
          onChange={(e, format) => {
            setCode(e);
          }}
        />
        <div className={"getCode"} onClick={() => showCode(true)}>
          {countText}
        </div>
      </div>
      <div
        className={`confirm_btn ${code.length < 1 && "error"}`}
        onClick={() => {
          nextStep();
        }}
      >
        确认
      </div>
    </>
  );
};

const TwoStep = (props) => {
  const { type, oldEmail, showCode, countText, getNewEmail, changeStep } =
    props;
  const [account, setAccount] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    getNewEmail(account);
  }, [account]);

  const bindEmail = () => {
    EVENT.Account.verifyEmail(account, code, type)
      .then(() => {
        EVENT.Account.updateEmail(oldEmail, account)
          .then(() => {
            Toast.show({
              content: "绑定成功",
              afterClose: () => changeStep(),
            });
            Store.User.updateDetail();
          })
          .catch(({ message }) => Toast.show(message));
      })
      .catch(({ message }) => Toast.show(message));
  };

  return (
    <>
      <SimpleInput
        label={"邮箱地址"}
        placeholder={"请输入新邮箱"}
        type={"email"}
        format={"EMAIL"}
        notice={"email"}
        onChange={(e) => {
          setAccount(e);
        }}
      />
      <div className={"sendCode"}>
        <SimpleInput
          label={"验证码"}
          placeholder={"请输入验证码"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          type={"verifyCode"}
          onChange={(e, format) => {
            setCode(e);
          }}
        />
        <div className={"getCode"} onClick={() => showCode(true)}>
          {countText}
        </div>
      </div>
      <div
        className={`confirm_btn ${
          (account.length < 1 || code.length < 1) && "error"
        }`}
        onClick={() => {
          bindEmail();
        }}
      >
        确认
      </div>
    </>
  );
};

function ChangeEmail(props) {
  const [next, setNext] = useState(false);
  const type = next ? "BIND_EMAIL" : "CHANGE_EMAIL";
  const timer = useRef();
  const oldEmail = Store.User.accountInfo?.email;
  const [account, setAccount] = useState("");
  const [codeShow, setCodeShow] = useState(false);
  const [countText, setCountText] = useState("获取验证码");
  const [countNumber, setCountNumber] = useState(0);

  useEffect(() => {
    setAccount(Store.User.accountInfo.email);
    if (countNumber < 0) {
      setCountText("获取验证码");
      clearInterval(timer.current);
    } else {
      countDown(countNumber);
    }
  }, [countNumber, Store.User.accountInfo?.email]);

  const getNewEmail = (email) => setAccount(email);

  const sendCodeStatus = (val) => {
    if (val) setCountNumber(120);
  };
  //todo 倒计时
  const countDown = () => {
    timer.current = setTimeout(() => {
      setCountNumber((time) => time - 1);
      setCountText(`${countNumber}秒后重发`);
    }, 1000);
  };

  const nextStep = () => {
    setCountNumber(0);
    setNext(true);
  };

  const closeModal = () => {
    props.onCancel();
  };

  return (
    <>
      <Modal
        title={"修改邮箱"}
        visible={props.visible}
        className={"alertModal aboutPass"}
        onCancel={props.onCancel}
        afterClose={() => setNext(false)}
        destroyOnClose={true}
        footer={<div />}
      >
        {!next ? (
          <OneStep
            type={type}
            oldEmail={oldEmail}
            countText={countText}
            showCode={() => countNumber <= 0 && setCodeShow(true)}
            changeStep={nextStep}
          />
        ) : (
          <TwoStep
            type={type}
            oldEmail={oldEmail}
            getNewEmail={getNewEmail}
            countText={countText}
            showCode={() => countNumber <= 0 && setCodeShow(true)}
            changeStep={closeModal}
          />
        )}
      </Modal>
      {codeShow && (
        <ImgValide
          account={account}
          type={type}
          hide={() => setCodeShow(false)}
          sendCodeStatus={sendCodeStatus}
        />
      )}
    </>
  );
}

export default ChangeEmail;
