import { makeAutoObservable, runInAction, autorun } from "mobx";
import Store from ".";
import EVENT from "../library/api";
import { RESTRICT } from "../library/standard";

export class FuturesSPSL {
  SPSL = {};
  current = 0;
  income = 0;
  item = null;
  stopLossStepMin = 0;
  clearUpdate = null;

  constructor() {
    makeAutoObservable(this);
  }

  //～～～～～～～待优化～～～～～～～
  async getSPSLById(id) {
    if (!!id) {
      this.updateData(id);
      this.item = Store.Position.list.filter((o) => o.id === id)[0];
      //todo 止盈计算
      this.stopProfit = this.item.stopProfit;
      this.stopProfitMin = this.item.stopProfitMin;
      this.stopProfitMax = this.item.stopProfitMax;
      this.stopProfitBaseMin = +this.item.unit.mul(+this.item.opVolume);
      this.stopProfitStep = Math.abs(+this.item.stopProfitBegin).div(+this.item.unit.mul(+this.item.opVolume));
      this.stopProfitIndex = +this.item.stopProfit.div(+this.stopProfitBaseMin);
      this.stopProfitPrice = this.item.stopProfitPrice;
      this.stopProfitPriceMax = this.item.stopProfitPriceMax;
      this.stopProfitPriceMin = this.item.stopProfitPriceMin;
      //todo 止损计算
      this.stopLoss = this.item.stopLoss;
      this.stopLossMax = this.item.stopLossMax;
      this.stopLossBaseMin = -this.item.unit.mul(+this.item.opVolume);
      this.stopLossStep = Math.abs(this.item.stopLossBegin).div(this.item.unit.mul(this.item.opVolume)).toFixed(0);
      this.stopLossIndex = this.item.stopLoss.div(this.stopLossBaseMin);
      this.stopLossPrice = this.item.stopLossPrice;
      this.stopLossPriceMax = this.item.stopLossPriceMax;
      this.SPSLData();
    }
  }

  updateData(id) {
    this.clearUpdate = autorun(() => {
      if (Store.Position.list.length === 0) return;
      let obj = Store.Position.list.filter((o) => o.id === id)[0];
      if (!obj) return;
      runInAction(() => {
        this.name = obj.commodityCode;
        this.income = obj.income;
        this.current = obj.current;
        this.stopLossStepMin = -obj.stopLossStepMin;
        this.stopLossMin = obj.stopLossMin;
        this.stopLossPriceMin = obj.stopLossPriceMin;
      });
    });
  }

  SPSLData() {
    this.SPSL = {
      name: this.item.commodity,
      commodityCode: this.item.commodityCode,
      contractCode: this.item.contractCode,
      isBuy: this.item.isBuy,
      moneyType: this.item.moneyType,
      volume: this.item.volume,
      income: this.item.income,
      current: this.item.current,
      lever: this.item.lever,
      margin: this.item.margin,
      priceDigit: this.item.priceDigit,
      //止损
      stopLoss: this.stopLoss,
      stopLossMin: this.stopLossMin,
      stopLossMax: this.stopLossMax,
      //止损调整最大Step(计算)
      stopLossStep: +this.stopLossStep,
      //止损的当前Step
      stopLossIndex: this.stopLossIndex,
      stopLossStepMin: this.stopLossStepMin,
      stopLossPrice: this.stopLossPrice,
      stopLossPriceMax: this.stopLossPriceMax,
      stopLossPriceMin: this.stopLossPriceMin,
      //止盈
      stopProfit: this.stopProfit,
      stopProfitMin: this.stopProfitMin,
      stopProfitMax: this.stopProfitMax,
      //止盈调整最大Step
      stopProfitStep: this.stopProfitStep,
      //止盈的当前Step
      stopProfitIndex: this.stopProfitIndex,
      stopProfitPrice: this.stopProfitPrice,
      stopProfitPriceMax: this.stopProfitPriceMax,
      stopProfitPriceMin: this.stopProfitPriceMin,
    };
  }

  //todo 退出页面清除缓存
  clearSPSL() {
    this.SPSL = {};
  }

  //设置止损(FT)
  setStopLoss(index) {
    const { isBuy, opPrice, priceChange, priceDigit } = this.item;
    if (index < this.stopLossStepMin) index = this.stopLossStepMin;
    if (index > this.stopLossStep) index = this.stopLossStep;
    this.stopLoss = RESTRICT.float(+index * this.stopLossBaseMin, 2);
    this.stopLossIndex = index;
    if (isBuy) {
      this.stopLossPrice = RESTRICT.float(opPrice - index.mul(priceChange), priceDigit);
    } else {
      this.stopLossPrice = RESTRICT.float(opPrice + index.mul(priceChange), priceDigit);
    }
    this.SPSLData();
  }

  //设置止盈(FT)
  setStopProfit(index = 1) {
    const { isBuy, opPrice, priceChange, priceDigit } = this.item;
    if (index > this.stopProfitStep) index = this.stopProfitStep;
    if (index < 1) index = 1;
    this.stopProfit = RESTRICT.float(index.mul(+this.stopProfitBaseMin), 2);
    this.stopProfitIndex = index;
    if (isBuy) {
      this.stopProfitPrice = RESTRICT.float(opPrice + index.mul(priceChange), priceDigit);
    } else {
      this.stopProfitPrice = RESTRICT.float(opPrice - index.mul(priceChange), priceDigit);
    }
    this.SPSLData();
  }

  //设置止盈价格(CT)
  setCryptoStopProfit(profit) {
    const { isBuy, opPrice, lever, margin, priceDigit, stopProfitMax } = this.item;
    let havePoint = true;
    if (profit.toString().indexOf(".") !== -1 && profit[profit.length - 1] === "0") {
      havePoint = false;
    }
    if (profit[profit.length - 1] !== "." && profit !== "" && havePoint) {
      if (isNaN(profit)) return;
      +profit > stopProfitMax.mul(margin) ? (this.stopProfit = stopProfitMax.mul(margin)) : (this.stopProfit = +profit);
      isBuy
        ? (this.stopProfitPrice = RESTRICT.float(
            opPrice + opPrice.div(lever).mul(this.stopProfit.div(margin)),
            priceDigit
          ))
        : (this.stopProfitPrice = RESTRICT.float(
            opPrice - opPrice.div(lever).mul(this.stopProfit.div(margin)),
            priceDigit
          ));
    } else {
      this.stopProfit = profit;
    }
    this.SPSLData();
  }

  //设置止损价格(CT)
  setCryptoStopLoss(loss) {
    const { isBuy, opPrice, lever, margin, priceDigit, stopLossMax } = this.item;
    if (loss[loss.length - 1] !== "" && loss[loss.length - 1] !== "-" && loss[loss.length - 1] !== "." && loss !== "") {
      if (isNaN(loss)) return;
      +loss < stopLossMax.mul(margin) ? (this.stopLoss = stopLossMax.mul(margin)) : (this.stopLoss = +loss);
      isBuy
        ? (this.stopLossPrice = RESTRICT.float(opPrice + opPrice.div(lever).mul(this.stopLoss.div(margin)), priceDigit))
        : (this.stopLossPrice = RESTRICT.float(
            opPrice - opPrice.div(lever).mul(this.stopLoss.div(margin)),
            priceDigit
          ));
    } else {
      this.stopLoss = loss;
    }
    this.SPSLData();
  }

  //设置止盈金额(CT)
  setCryptoStopProfitPrice(val) {
    const { priceDigit, isBuy, opPrice, lever, margin } = this.item;
    let havePoint = true;
    if (val === this.stopProfitPrice) return;
    if (val.toString().indexOf(".") !== -1 && val[val.length - 1] === "0") {
      havePoint = false;
    }
    if (val[val.length - 1] !== "." && val !== "" && havePoint) {
      if (isNaN(val)) return;
      this.stopProfitPrice = RESTRICT.float(val, priceDigit);
      isBuy
        ? (this.stopProfit = RESTRICT.float(
            this.stopProfitPrice.sub(+opPrice).mul(+lever).div(+opPrice).mul(+margin),
            2
          ))
        : (this.stopProfit = RESTRICT.float(
            +opPrice.sub(this.stopProfitPrice).mul(+lever).div(+opPrice).mul(+margin),
            2
          ));
    } else {
      this.stopProfitPrice = val;
    }
    this.SPSLData();
  }

  //设置止损金额(CT)
  setCryptoStopLossPrice(val) {
    const { isBuy, opPrice, lever, margin, priceDigit } = this.item;
    let havePoint = true;
    if (val === this.stopLossPrice) return;
    if (val.toString().indexOf(".") !== -1 && val[val.length - 1] === "0") {
      havePoint = false;
    }
    if (val[val.length - 1] !== "." && val !== "" && havePoint) {
      if (isNaN(val)) return;
      this.stopLossPrice = RESTRICT.float(val, priceDigit);
      isBuy
        ? (this.stopLoss = this.stopLossPrice.sub(+opPrice).div(+opPrice).mul(+lever).mul(+margin).toFixed(2))
        : (this.stopLoss = +opPrice.sub(this.stopLossPrice).div(+opPrice).mul(+lever).mul(+margin).toFixed(2));
    } else {
      this.stopLossPrice = val;
    }
    this.SPSLData();
  }

  setSPSL() {
    return new Promise((resolve, reject) => {
      try {
        const data = {
          bettingId: this.item.id,
          tradeType: Store.Quote.simulate ? 2 : 1,
          stopProfit: +this.stopProfit / this.item.margin,
          stopLoss: +this.stopLoss / this.item.margin,
          source: "设置止盈止损",
        };
        const result = EVENT.Trade.setSPSL(data);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }
}
