import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { SimpleInput } from "../../components";
import Linkage from "../../components/linkage";
import { color, bankNumMark, chooseBankLogo } from "../../components/tool";
import { notification } from "antd";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import Store from "@/store";

const ChangeBankCard = (props) => {
  const [province, setProvince] = useState(props.editInfo.province);
  const [city, setCity] = useState(props.editInfo.city);
  const [subbranch, setSubbranch] = useState(props.editInfo.subbranch);
  const { name } = Store.User.accountInfo;

  let verify =
    province.length !== 0 && city.length !== 0 && subbranch.length !== 0;

  const submit = (verify) => {
    if (verify) return;
    EVENT.Account.changeBankInfo(province, city, subbranch, props.editInfo.id)
      .then(() => {
        notification.success({ message: "银行卡信息修改成功" });
        props.onCancel();
        props.changeAfter();
      })
      .catch((err) => {
        notification.error({ message: err });
      });
  };

  const item = props.editInfo;
  return (
    <Modal
      title={"修改银行卡"}
      visible={props.visible}
      className={"alertModal aboutPass addBankcard"}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={<div />}
    >
      <div className={`bank_box ${color(item.bank)}`}>
        <div className={"img_box"}>
          <div className={"img"}>
            <img src={chooseBankLogo(item.bank)} alt="" />
          </div>
        </div>
        <div className={"info"}>
          <div className={"bank_name"}>
            <b>{item.bank}</b>
            <p>储蓄卡</p>
          </div>
          <div className={"bank_card"}>{bankNumMark(item.cardNumber)}</div>
        </div>
      </div>
      <SimpleInput label={"开户姓名"} value={name} disable={true} />
      <Linkage
        getProvince={(e) => {
          setProvince(e);
        }}
        getCity={(e) => {
          setCity(e);
        }}
        defaultProvince={item.province}
        defaultCity={item.city}
      />
      <SimpleInput
        label={"开户支行"}
        placeholder={"请输入银行卡开户支行"}
        type={"text"}
        value={subbranch}
        maxlength={30}
        onChange={(e) => {
          setSubbranch(e);
        }}
      />
      <div
        className={`confirm_btn ${!verify && "error"}`}
        onClick={() => {
          submit(!verify);
        }}
      >
        修改
      </div>
    </Modal>
  );
};

export default observer(ChangeBankCard);
