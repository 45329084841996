import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { observer } from "mobx-react-lite";
import Store from "@/store";
import { flexResize } from "@/utils";
import { Brand } from "@/core";

import { Header } from "@/view/components/index";

function Live800() {
  const { username, userId } = Store;
  useEffect(() => {
    flexResize(false);
  }, []);

  const url = `${Brand.cusServiceUrl}&metadata={"name":"${username}","userId":"${userId}"}`;
  return (
    <div className={styles.live800}>
      <Header title={"在线客服"} theme />
      <div className={styles.main}>
        <iframe
          title="server"
          id="csIframe"
          name="csIframe"
          src={url}
          width="100%"
          frameBorder="0"
          allowtransparency="true"
          scrolling="no"
          allowFullScreen
          allow="fullscreen"
          style={{ display: "block" }}
        />
      </div>
    </div>
  );
}

export default observer(Live800);
