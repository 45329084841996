import Socket from "@/library/network/scoket";
import { makeObservable, action, observable } from "mobx";

const Protocol = {
  from: {
    3000: "beat",
    3001: "subscribe",
    3002: "unsubscribe",
    4001: "chart",
    4002: "unchart",
    5001: "depth",
    6001: "turnover",
    7001: "depthMore",
  },
  to: {
    beat: 3000,
    subscribe: 3001,
    unsubscribe: 3002,
    chart: 4001,
    unchart: 4002,
    depth: 5001,
    turnover: 6001,
    depthMore: 7001,
  },
};

class Chart {
  #resolution = "1";
  #symbols = "";
  #socket = null;

  constructor(socket) {
    this.#socket = socket;
  }

  update(o) {
    this.#socket.emit({ cmid: 4002, symbols: this.#symbols });
    this.#socket.emit({ cmid: 4001, symbols: o, r: this.#resolution });
    this.#symbols = o;
  }

  subscribe(o) {
    this.#socket.emit({ cmid: 4001, symbols: o, r: this.#resolution });
    this.#symbols = o;
  }

  unsubscribe() {
    this.#socket.emit({ cmid: 4002, symbols: this.#symbols });
    this.#symbols = "";
    this.#resolution = "1";
  }

  resolution(o) {
    if (this.#resolution !== o) {
      this.#resolution = o;
      this.#socket.emit({ cmid: 4002, symbols: this.#symbols });
      this.#socket.emit({ cmid: 4001, symbols: this.#symbols, r: o });
    }
  }

  reopen() {
    if (this.#symbols !== "") {
      this.#socket.emit({
        cmid: 4001,
        symbols: this.#symbols,
        r: this.#resolution,
      });
    }
  }
}

class Quote extends Socket {
  #chart = null;
  //用于存储当前注册的商品请求信息
  #subscribe = "";
  #live = [];
  data = null;
  chartData = null;

  constructor(props, query) {
    super(props);
    if (query) {
      this.query = query;
    }
    this.#chart = new Chart(this);
    this.open();
    makeObservable(this, {
      data: observable,
      onData: action,
      chartData: observable,
      onChartData: action,
    });
  }

  //todo 传递至Data
  onData(data) {
    this.data = data;
  }
  //todo 行情图数据
  onChartData(data) {
    this.chartData = data;
  }

  onopen() {
    if (this.try > 0) {
      this.#chart.reopen();
      if (this.#subscribe !== "")
        this.emit({ cmid: Protocol.to["subscribe"], symbols: this.#subscribe });
    }
    super.onopen();
    this.#beat();
  }

  onclose() {
    clearTimeout(this.#beatLock);
    super.onclose();
  }

  onmessage(buffer) {
    try {
      let { cmid, data } = JSON.parse(buffer.data);
      if (!data) return;
      if (+cmid === Protocol.to["beat"]) {
        this.#beatGroup[1] = +new Date();
      } else if (Protocol.from[cmid] === "subscribe") {
        //todo 消息派发
        this.onData(data);
      } else if (Protocol.from[cmid] === "chart") {
        this.onChartData(data);
      } else {
        //暂时没用到
        const o = JSON.stringify({ cmd: Protocol.from[cmid], data, code: "-" });
      }
    } catch (e) {
      console.log(e);
    }
  }

  //订阅商品数据
  subscribe(o, live) {
    if (live && !this.#live.includes(live)) this.#live.push(live);
    if (this.#subscribe !== "" && this.#subscribe === o) return;
    this.#subscribe = o;
    this.emit({ cmid: Protocol.to["subscribe"], symbols: o });
  }

  //取消商品订阅
  unsubscribe(live) {
    if (live && this.#live.includes(live)) this.#live.remove(live);
    if (this.#live.length !== 0) return;
    this.emit({ cmid: Protocol.to["unsubscribe"], symbols: this.#subscribe });
    this.#subscribe = "";
  }

  //注册行情图商品
  chartSubscribe(o) {
    this.#chart.subscribe(o);
  }

  //行情图商品切换
  chartUpdate(o) {
    this.#chart.update(o);
  }

  //注销行情图商品
  chartUnsubscribe(o) {
    this.#chart.unsubscribe(o);
  }

  #beatLock = null;
  #beatGroup = [];
  #beat() {
    this.#beatGroup[0] = +new Date();
    this.emit({ cmid: Protocol.to["beat"] });
    this.#beatLock = setTimeout(() => {
      if (this.#beatGroup[1] - this.#beatGroup[0] > 0) {
        this.#beat();
      } else {
        console.log("心跳响应终中断，正在尝试重连...");
        this.retry();
      }
    }, 12000);
  }
}

export default Quote;
