import React, { Component } from "react";
import "./rechargeTips.scss";
import rechargeTips from "../../../assets/images/rechargeTips.png";
import { message } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import { HomeHeader,HomeFooter } from "@/view/components/index";

const RechargeTips = (props) => {
  

  return (
    <div>
    <HomeHeader />
    <div className="rechargeTipsImg">
      <CopyToClipboard
        className={"copyOkex"}
        text={"https://www.okex.com/download"}
        onCopy={() => message.info("okex下载地址复制成功")}
      >
        <div className={"copy"}></div>
      </CopyToClipboard>
      <CopyToClipboard
        className={"copyHuobi"}
        text={"https://www.huobi.vc/zh-cn/download"}
        onCopy={() => message.info("火币下载地址复制成功")}
      >
        <div className={"copy"}></div>
      </CopyToClipboard>
      <img
        style={{ width: "100%" }}
        className="rechargeTipsImg"
        src={rechargeTips}
        alt="img"
      />
    </div>
    <HomeFooter />
  </div>
  );
};

export default RechargeTips;
