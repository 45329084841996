import React from "react";
import { formatDate } from "@/utils";
import { message } from "antd/lib/index";
import { Copy } from "../../components/copy/copy";
import position_bl from "../../../assets/images/position_black.png";
import { RESTRICT } from "@/library/standard";
import { notification } from "antd";
import Store from "@/store";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

const Pending = ({ array }) => {
  let data = toJS(array).sort((a, b) => b.tradeTime - a.tradeTime);

  const cancel = (id) => {
    console.log("cancel ", id);
    EVENT.Trade.cancelOrder(id)
      .then(() => {
        Store.Position.startLimit(false);
        Store.Position.startSpot(false);
        message.success("撤单请求已处理");
      })
      .catch((message) => notification.error({ message }));
  };

  return (
    <div className="position_list">
      <div className="title">
        <i />
        <div className={"pending_long"}>商品名称</div>
        <div>模式</div>
        <div>手数/杠杆</div>
        <div>挂单价</div>
        {/*<div>当前价</div>*/}
        <div>保证金</div>
        <div>止盈</div>
        <div>止损</div>
        <div className={"pending_long"}>挂单时间</div>
        <div className={"pending_long id"}>订单号</div>
        <div className={"pending_longer cancel"}>操作</div>
      </div>
      <div className="list_box">
        {!!data && data.length !== 0 ? (
          !!data &&
          data.map((item, key) => {
            let n =
              item.commodityCode.indexOf("USDT") === -1 ? "futures" : "crypto";
            return (
              <div className={"list"} key={key}>
                <i className={item.isBuy ? "redB" : "greenB"} />
                <div className={`pending_long`}>
                  {item.commodity}(
                  {n === "futures" ? item.contCode : item.commodityCode})
                </div>
                <div>{item.moneyType === 0 ? "元" : "角"}</div>
                <div>{n === "futures" ? item.volume : `${item.lever}X`}</div>
                <div>{item.price}</div>
                {/*<div>{Data._total[item.commodityCode].price}</div>*/}
                <div>{item.margin}</div>
                <div className={"red"}>
                  {n === "futures"
                    ? Math.round(item.stopProfit)
                    : RESTRICT.float(item.stopProfit, 2)}
                </div>
                <div className={"green"}>
                  {n === "futures"
                    ? Math.round(item.stopLoss)
                    : RESTRICT.float(item.stopLoss, 2)}
                </div>
                <div className={"pending_long"}>
                  {formatDate("m-d h:i:s", { date: item.tradeTime })}
                </div>
                <div className={"pending_long id"}>
                  <p>{item.id}</p>
                  <Copy text={item.id} />
                </div>
                <div className={"pending_longer"}>
                  <div
                    onClick={() => {
                      cancel(item.id);
                    }}
                  >
                    撤销
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className={"empty"}>
            <div>
              <img src={position_bl} alt="" />
              <b>暂无委托</b>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Pending);
