import React from "react";
import styles from "./style.module.scss";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Back } from "@/assets/svg/outlined/back.svg";

const Header = (props) => {
  const navigate = useNavigate();
  const { title, right, go, theme, opacity, replace, back } = props;
  return (
    <div
      className={
        theme
          ? `${styles.darkHeader} ${styles.header}`
          : opacity
          ? `${styles.opacity} ${styles.header}`
          : `${styles.header}`
      }
    >
      <div className={styles.back} onClick={() => (back ? navigate(back) : navigate(-1))}>
        <Back />
      </div>
      <div className={styles.title}>{title}</div>
      {right ? (
        <div className={styles.right}>
          {replace ? (
            <a onClick={() => navigate(go, { replace: true })}>{right}</a>
          ) : (
            <a onClick={() => navigate(go)}>{right}</a>
          )}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Header;
