import React, {useState} from 'react';
import './index.scss'
import {Link} from 'react-router-dom';
import fh from '../../assets/images/school/fh.png'
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { useNavigate } from "react-router-dom";

function Page03() {
    const history = useNavigate();
    const [which, setWhich] = useState(null);
    return (
        <div className="school page3">
        <HomeHeader/>
        <div className="wrap">
            <div className="box">
                <div className="menu">
                    <p onClick={()=>history('/school')} className="title">致远学院</p>
                    <dl>
                        <dt>
                            <h5>实时资讯</h5>
                            <p><Link to="/page0">市场快讯</Link></p>
                            <p><Link to="/page1">热点新闻</Link></p>
                            <p><Link to="/page2">财经日历</Link></p>
                        </dt>
                        <dt>
                            <h5>新手入门</h5>
                            <p><Link to="/page3" t="新手入门">基础知识</Link></p>
                            <p><Link to="/page4" t="新手入门">投资介绍</Link></p>
                        </dt>
                        <dt>
                            <h5>高手进阶</h5>
                            <p><Link to="/page5" t="高手进阶">基本面分析</Link></p>
                            <p><Link to="/page6" t="高手进阶">技术面分析</Link></p>
                            <p><Link to="/page7" t="高手进阶">综合技巧</Link></p>
                            <p><Link to="/page8" t="高手进阶">高手心态</Link></p>
                        </dt>
                    </dl>
                </div>
                {
                    which===null?(
                        <div className="main">
                            <div className="school-header">
                                <Link to="/school">致远学院</Link> &gt; <span v-param="title">基础知识</span>
                            </div>
                            <div className="school-list">
                                <div className="school-list-body" id="基础知识">
                                    <ul>
                                        <li><label>1</label><div onClick={()=>setWhich(0)}>期货概念</div></li>
                                        <li><label>2</label><div onClick={()=>setWhich(1)}>期货交易的起源</div></li>
                                        <li><label>3</label><div onClick={()=>setWhich(2)}>期货交易的基本特征</div></li>
                                        <li><label>4</label><div onClick={()=>setWhich(3)}>期货市场与证券市场</div></li>
                                    </ul>
                                </div>
                                <p className="back">
                                    <Link to="/school">
                                        <img src={fh} width="90px" alt="返回" title="返回"/>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    ):(
                        which===0?(
                            <div className="main">
                                <div className="school-header">
                                    <Link to="/school">致远学院</Link> &gt;
                                    <span>新手入门</span>
                                </div>
                                <div className="school-detail">
                                    <div onClick={()=>setWhich(null)} className="fh">
                                        <img src={fh} width="70px" alt="返回" title="返回"/>
                                    </div>
                                    <div className="school-detail-title">期货概念</div>
                                    <div className="school-detail-content">
                                        <p>期货交易是在现货交易的基础上发展起来的、通过在期货交易所买卖标准化的期货合约而进行的一种有组织的交易方式。</p>
                                        <p>在期货市场中，大部分交易者买卖的期货合约在到期前，又以对冲的形式了结。也就是说买进期货合约的人，在合约到期前又可以将期货合约卖掉；卖出期货合约的人，在合约到期前又可以买进期货合约来平仓。先买后卖或先卖后买都是允许的。一般来说，期货交易中进行实物交割的只是很少量的一部分。</p>
                                        <p>期货交易的对象并不是商品(标的物)的实体，而是商品(标的物)的标准化合约。</p>
                                        <p>期货交易的目的是为了转移价格风险或获取风险利润。</p></div>
                                </div>
                            </div>
                        ):(
                            which===1?(
                                <div className="main">
                                    <div className="school-header">
                                        <Link to="/school">致远学院</Link> &gt;
                                        <span>新手入门</span>
                                    </div>
                                    <div className="school-detail">
                                        <div onClick={()=>setWhich(null)} className="fh">
                                            <img src={fh} width="70px" alt="返回" title="返回"/>
                                        </div>
                                        <div className="school-detail-title">期货交易的起源</div>
                                        <div className="school-detail-content">
                                            <p>期货市场最早萌芽于欧洲。早在古希腊和古罗马时期，就出现过中央交易场所、大宗易货交易，以及带有期货贸易性质的交易活动。当时的罗马议会大厦广场、雅典的大交易市场就曾是这样的中心交易场所。到12世纪，这种交易方式在英、法等国的发展规模很大，专业化程度也很高。
                                                1251年，英国大宪章正式允许外国商人到英国参加季节性交易会。后来，在贸易中出现了对在途货物提前签署文件，列明商品品种、数量、价格，预交保证金购买，进而买卖文件合同的现象。1571年，英国创建了实际上第一家集中的商品市场——伦敦皇家交易所，在其原址上后来成立了伦敦国际金融期货期权交易所。其后，荷兰的阿姆斯特丹建立了第一家谷物交易所，比利时的安特卫普开设了咖啡交易所。1666年，伦敦皇家交易所毁于伦敦大火，但交易仍在当时伦敦城的几家咖啡馆中继续进行。17世纪前后，荷兰在期货交易的基础上发明了期权交易方式，在阿姆斯特丹交易中心形成了交易郁金香的期权市场。1726年，另一家商品交易所在法国巴黎诞生。</p>
                                            <p>(一) 1848年芝加哥期货交易所产生</p>        <p>(二) 1874年芝加哥商业交易所产生</p>
                                            <p>(三) 1876年伦敦金属交易所产生</p>        <p>(四) 1885年法国期货市场产生</p></div>
                                    </div>
                                </div>
                            ):(
                               which===2?(
                                    <div className="main">
                                        <div className="school-header">
                                            <Link to="/school">致远学院</Link> &gt;
                                            <span>新手入门</span>
                                        </div>
                                        <div className="school-detail">
                                            <div onClick={()=>setWhich(null)} className="fh">
                                                <img src={fh} width="70px" alt="返回" title="返回"/>
                                            </div>
                                            <div className="school-detail-title"> 期货交易的基本特征</div>
                                            <div className="school-detail-content"><p>期货交易的基本特征可以归纳为以下几个方面：</p>
                                                <p className="subtitle">（一）合约标准化</p>
                                                <p>期货交易是通过买卖期货合约进行的，而期货合约是标准化的。期货合约标准化指的是除价格外，期货合约的所有条款都是预先由期货交易所规定好的，具有标准化的特点。期货合约标准化给期货交易带来极大便利，交易双方不需对交易的具体条款进行协商，节约交易时间，减少交易纠纷。</p>
                                                <p className="subtitle">（二）交易集中化</p>
                                                <p>期货交易必须在期货交易所内进行。期货交易所实行会员制，只有会员方能进场交易。那些处在场外的广大客户若想参与期货交易，只能委托期货经纪公司代理交易。所以，期货市场是一个高度组织化的市场，并且实行严格的管理制度，期货交易最终在期货交易所内集中完成。</p>
                                                <p className="subtitle">（三）双向交易和对冲机制</p>
                                                <p>双向交易，也就是期货交易者既可以买入期货合约作为期货交易的开端（称为买入建仓），也可以卖出期货合约作为交易的开端（称为卖出建仓），也就是通常所说的“买空卖空”。与双向交易的特点相联系的还有对冲机制，在期货交易中大多数交易这并不是通过合约到期时进行实物交割来履行合约，而是通过与建仓时的交易方向相反的交易来解除履约责任。具体说就是买入建仓之后可以通过卖出相同合约的方式解除履约责任，卖出建仓后可以通过买入相同合约的方式解除履约责任。期货交易的双向交易和对冲机制的特点，吸引了大量期货投机者参与交易，因为在期货市场上，投机者有双重的获利机会，期货价格上升时，可以低买高卖来获利，价格下降时，可以通过高卖低买来获利，并且投机者可以通过对冲机制免除进行实物交割的麻烦，投机者的参与大大增加了期货市场的流动性。</p>
                                                <p className="subtitle">（四）杠杆机制</p>
                                                <p>期货交易实行保证金制度，也就是说交易者在进行期货交易时者需缴纳少量的保证金，一般为成交合约价值的5%-10%，就能完成数倍乃至数十倍的合约交易，期货交易的这种特点吸引了大量投机者参与期货交易。期货交易具有的以少量资金就可以进行较大价值额的投资的特点，被形象地称为“杠杆机制”。期货交易的杠杆机制使期货交易具有高收益高风险的特点。</p>
                                                <p className="subtitle">（五）每日无负债结算制度</p>
                                                <p>期货交易实行每日无负债结算制度，也就是在每个交易日结束后，对交易者当天的盈亏状况进行结算，在不同交易者之间根据盈亏进行资金划转，如果交易者亏损严重，保证金账户资金不足时，则要求交易者必须在下一日开市前追加保证金，以做到“每日无负债”。期货市场是一个高风险的市场，为了有效的防范风险，将因期货价格不利变动给交易者带来的风险控制在有限的幅度内，从而保证期货市场的正常运转。</p>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                   which===3?(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;
                                                <span>新手入门</span>
                                            </div>
                                            <div className="school-detail">
                                                <div onClick={()=>setWhich(null)} className="fh">
                                                    <img src={fh} width="70px" alt="返回" title="返回"/>
                                                </div>
                                                <div className="school-detail-title"> 期货市场与证券市场</div>
                                                <div className="school-detail-content">
                                                    <p>期货市场是买卖期货合约的市场，而期货合约在本质上是未来商品的代表符号，因而期货市场与商品市场有着内在的联系。但就实物商品买卖转化成合约的买卖这一点而言，期货合约在外部形态上表现为相关商品的有价证券，这一点与证券市场却有相似之处。证券市场上流通的股票、债券，可以说是股份有限公司所有权的标准化合同和债券发行者的债权债务标准化合同。人们买卖的股票、债券和期货合约，都是一种投资凭证。但是，期货市场与证券市场有以下几点重要区别。</p>
                                                    <p className="subtitle">（一）基本经济职能不同</p>
                                                    <p>证券市场的基本职能是资源配置和风险定价；期货市场的基本职能是规避风险和价格发现。</p>        <p
                                                    className="subtitle">（二）交易目的不同</p>
                                                    <p>证券交易的目的是获取利息、股息等收入和资本利得。期货交易的目的是规避现货市场价格风险或获取投机利润。</p>
                                                    <p className="subtitle">（三）市场结构不同</p>
                                                    <p>证券市场分为一级市场（发行市场）和二级市场（流通市场）；期货市场并不区分一级市场和二级市场。</p>        <p
                                                    className="subtitle">（四）保证金规定不同</p>
                                                    <p>证券一般采用现货交易方式，须交纳全额资金；期货交易只需缴纳期货合约价值一定比例的保证金。</p></div>
                                            </div>
                                        </div>
                                    ):(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;
                                                <span>新手入门</span>
                                            </div>
                                            <div>暂无数据</div>
                                        </div>
                                    )
                                )
                            )
                        )
                    )
                }

            </div>
        </div>
        <HomeFooter/>
    </div>
    );
  }
  
  export default Page03;
