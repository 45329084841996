import React, { useEffect, useState } from "react";
import csIcon from "../../../assets/images/common/cs.png";
import EVENT from "@/library/api";
import Store from "@/store";

import { formatDate, isEmpty } from "@/utils";

const MessageBox = (props) => {
  const { closeAlert } = props;
  const [msgData, setMsgData] = useState([]);
  let timer = null;
  const { unread } = Store.User.accountInfo;

  useEffect(() => {
    getMessage();

    return () => {
      clearTimeout(timer);
      timer = null;
    };
  }, []);

  //todo 获取message
  const getMessage = () => {
    EVENT.News.getMessage()
      .then(({ data }) => {
        if (isEmpty(data)) return;
        let ary = data.reverse();
        setMsgData(ary);
      })
      .finally(() => (timer = setTimeout(() => getMessage(), 5000)));
  };

  return (
    <div>
      {msgData.map((item, key) => {
        if (item.status === 3 || item.status === 4) {
          return (
            <div key={key} className={"message_li"}>
              <div className={"info"}>
                <img src={csIcon} alt="" />
                <p>{item.content}</p>
              </div>
              <div className={"time"}>
                {formatDate("y-m-d h:i", { date: item.time })}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
export default MessageBox;
