import React, { useState, useEffect, useMemo } from "react";
import { formatDate } from "@/utils";
import dayjs from "dayjs";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import { DatePicker, message, notification, Pagination, Select } from "antd";
import { splitAry, statusText } from "../../components/tool";
import funds from "../../../assets/images/funds_light.png";
import EVENT from "../../../library/api";
import "./detail.scss";

const FiatDetail = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState();
  const [filterType, setFilterType] = useState("");
  const [fiatInfo, setFiatInfo] = useState({ list: [], total: 0 });
  const [page, setPage] = useState(1);
  useEffect(() => {
    updateRechargeHistory({});
  }, []);
  const disabledStartDate = useMemo(() => {
    if (!startDate || !endDate) {
      return false;
    }
    return startDate.valueOf() > endDate.valueOf();
  }, [startDate, endDate]);
  const disabledEndDate = useMemo(() => {
    if (!endDate || !startDate) {
      return false;
    }
    return endDate.valueOf() <= startDate.valueOf();
  }, [startDate, endDate]);

  const quickSearch = (type) => {
    let today = new Date();
    let tdd = String(today.getDate()).padStart(2, "0");
    let tmm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let tyyyy = today.getFullYear();

    let startDate = new Date();
    let sdd, smm, syyyy;
    switch (type) {
      case "DAY":
        startDate.setDate(startDate.getDate());
        sdd = String(startDate.getDate()).padStart(2, "0");
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      case "WEEK":
        const day = startDate.getDay() || 7;
        startDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + 0 - day
        );
        sdd = String(startDate.getDate() + 1).padStart(2, "0");
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      case "MONTH":
        sdd = "1";
        smm = String(startDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        syyyy = startDate.getFullYear();
        break;
      default:
        break;
    }

    startDate = `${syyyy}-${smm}-${sdd} ${"00"}:${"00"}:${"00"}`;
    let endDate = `${tyyyy}-${tmm}-${tdd} ${23}:${59}:${59}`;

    let tempStart = Date.parse(startDate);
    tempStart = dayjs(new Date(tempStart));
    let tempEnd = Date.parse(endDate);
    tempEnd = dayjs(new Date(tempEnd));
    updateRechargeHistory({ page: 1, startDate, endDate });
    setFilterType(type);
    setStartDate(tempStart);
    setEndDate(tempEnd);
    if (page > 1) {
      setPage(1);
    }
  };

  const search = () => {
    if (!startDate) return message.warning("请选择起始时间");
    if (!endDate) return message.warning("请选择结束时间");
    const start = !!startDate && startDate.format("YYYY-MM-DD HH:mm:ss");
    const end = !!endDate && endDate.format("YYYY-MM-DD HH:mm:ss");
    updateRechargeHistory({ page: 1, startDate: start, endDate: end });
    if (filterType) {
      setFilterType("");
    }
    if (page > 1) {
      setPage(1);
    }
  };
  const updateRechargeHistory = (params) => {
    const { startDate, endDate, page = 1 } = params;
    EVENT.History.depositWithdraw({
      page,
      rows: 999,
      createTimeGe: startDate,
      createTimeLe: endDate,
    })
      .then(({ data, total }) => {
        let o = [];
        data.length > 0 &&
          data.forEach((item) => {
            if (item.address.length === 0 && item.status === 1) {
              o.push(item);
            }
          });
        setFiatInfo({ list: splitAry(o, 10), total: o.length });
      })
      .catch(({ message }) => notification.error({ message }));
  };

  const onChangePage = (page) => {
    if (page - 1 >= fiatInfo.list.length) return;
    setPage(Number(page));
  };

  return (
    <div id={"Detail"} className={"cryptoDetail"}>
      <div className={"title"}>
        <div className={"dateBox"}>
          <div className={"subtitle"}>日期</div>
          <div className={"dateWrapper"}>
            <DatePicker
              style={{ width: 140 }}
              defaultValue={moment(new Date())}
              disabledDate={disabledStartDate}
              format={"YYYY-MM-DD"}
              value={startDate}
              placeholder={"开始日期"}
              locale={locale}
              onChange={(val) => setStartDate(val)}
            />
            <span className={"line"}> - </span>
            <DatePicker
              style={{ width: 140 }}
              disabledDate={disabledEndDate}
              format={"YYYY-MM-DD"}
              value={endDate}
              placeholder={"结束日期"}
              locale={locale}
              onChange={(val) => setEndDate(val)}
            />
          </div>
        </div>
        <div className={"searchButtonGroup"}>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                quickSearch("DAY");
              }}
              className={`quickSearch ${filterType === "DAY" ? "active" : ""}`}
            >
              本日
            </div>
          </div>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                quickSearch("WEEK");
              }}
              className={`quickSearch ${filterType === "WEEK" ? "active" : ""}`}
            >
              本周
            </div>
          </div>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                quickSearch("MONTH");
              }}
              className={`quickSearch ${
                filterType === "MONTH" ? "active" : ""
              }`}
            >
              本月
            </div>
          </div>
          <div className={"quickSearchBox"}>
            <div
              onClick={() => {
                search();
              }}
              className={"searchButton"}
            >
              搜索
            </div>
          </div>
        </div>
      </div>

      <ol>
        <li className={"li_title"}>
          <div>时间</div>
          <div>金额(元)</div>
          <div>货币类型</div>
          <div>ID</div>
          <div className={"status"}>操作方式</div>
          <div>状态</div>
        </li>
        {!!fiatInfo.list && fiatInfo.list.length > 0 ? (
          fiatInfo.list[page - 1].map((item, key) => {
            return (
              <li key={key}>
                <div>
                  {formatDate("y-m-d h:i:s", { date: item.createTime })}
                </div>
                <div>{item.srcMoney}</div>
                <div>{item.currency}</div>
                <div>{item.id}</div>
                <div className={"status"}>
                  {item.type === 100 ? "充值存入" : "提款取出"}
                </div>
                <div>{statusText(item.status)}</div>
              </li>
            );
          })
        ) : (
          <div className={"empty"}>
            <div>
              <img src={funds} />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </ol>
      <div className={"pagination"}>
        <Pagination
          onChange={onChangePage}
          total={fiatInfo.total}
          defaultPageSize={10}
          current={page}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default FiatDetail;
