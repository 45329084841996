import React, {Component,useState} from 'react';
import './index.scss'
import {Link} from 'react-router-dom';
import fh from '../../assets/images/school/fh.png'
import { HomeHeader, HomeFooter } from "@/view/components/index";
import { useNavigate } from "react-router-dom";

function Page05() {
    const history = useNavigate();
    const [which, setWhich] = useState(null);
    return (
        <div className="school page3">
        <HomeHeader/>
        <div className="wrap">
            <div className="box">
                <div className="menu">
                    <p onClick={()=>history('/school')} className="title">致远学院</p>
                    <dl>
                        <dt>
                            <h5>实时资讯</h5>
                            <p><Link to="/page0">市场快讯</Link></p>
                            <p><Link to="/page1">热点新闻</Link></p>
                            <p><Link to="/page2">财经日历</Link></p>
                        </dt>
                        <dt>
                            <h5>新手入门</h5>
                            <p><Link to="/page3" t="新手入门">基础知识</Link></p>
                            <p><Link to="/page4" t="新手入门">投资介绍</Link></p>
                        </dt>
                        <dt>
                            <h5>高手进阶</h5>
                            <p><Link to="/page5" t="高手进阶">基本面分析</Link></p>
                            <p><Link to="/page6" t="高手进阶">技术面分析</Link></p>
                            <p><Link to="/page7" t="高手进阶">综合技巧</Link></p>
                            <p><Link to="/page8" t="高手进阶">高手心态</Link></p>
                        </dt>
                    </dl>
                </div>
                {
                    which===null?(
                        <div className="main">
                            <div className="school-header">
                                <Link to="/school">致远学院</Link> &gt; <span v-param="title">基本面分析</span>
                            </div>
                            <div className="school-list">
                                <div className="school-list-body" id="投资介绍">
                                    <ul>
                                        <li><label>1</label><div onClick={()=>setWhich(0)}>金融货币因素</div></li>
                                        <li><label>2</label><div onClick={()=>setWhich(1)}>经济波动周期</div></li>
                                        <li><label>3</label><div onClick={()=>setWhich(2)}>期货商品供给分析</div></li>
                                        <li><label>4</label><div onClick={()=>setWhich(3)}>期货商品需求分析</div></li>
                                    </ul>
                                </div>
                                <p className="back">
                                    <Link to="/school">
                                        <img src={fh} width="90px" alt="返回" title="返回"/>
                                    </Link>
                                </p>
                            </div>
                        </div>
                    ):(
                       which===0?(
                            <div className="main">
                                <div className="school-header">
                                    <Link to="/school">致远学院</Link> &gt;&nbsp;
                                    <span>高手进阶</span>
                                </div>
                                <div className="school-detail">
                                    <div onClick={()=>setWhich(null)} className="fh">
                                        <img src={fh} width="70px" alt="返回" title="返回"/>
                                    </div>
                                    <div className="school-detail-title">金融货币因素</div>
                                    <div className="school-detail-content">
                                        <p>商品期货交易与金融货币市场有着紧密的联系。利率的高低、汇率的变动都直接影响商品期货价格变动。</p>        <p
                                        className="subtitle">（一）利率</p>
                                        <p>利率调整是政府紧缩或扩张经济的宏观调控手段。利率的变化对金融衍生品交易影响较大，而对商品期货的影响较小。如 1994
                                            年开始，为了抑制通货膨胀，中国人民银行大幅度提高利率水平，提高中长期存款和国库券的保值贴补率，导致国债期货价格狂飙，1995 年 5
                                            月18 日，国债期货被国务院命令暂停交易。</p>        <p className="subtitle">（二）汇率</p>
                                        <p>期货市场是一种开放性市场，期货价格与国际市场商品价格紧密相联。国际市场商品价格比较必然涉及到各国货币的交换比值-汇率，汇率是本国货币与外国货币交换的比率。当本币贬值时，即使外国商品价格不变，但以本国货币表示的外国商品价格将上升，反之则下降，因此，汇率的高低变化必然影响相应的期货价格变化。据测算，美元对日元贬值
                                            10%，日本东京谷物交易所的进口大豆价格会相应下降
                                            10%左右。同样，如果人民币对美元贬值，那么国内大豆期货价格也会上涨。主要出口国的货币政策，如巴西在 1998
                                            年其货币雷亚尔大幅贬值，使巴西大豆的出口竞争力大幅增强，相对而言大豆供应量增加，对芝加哥大豆价格产生负面影响。</p></div>
                                </div>
                            </div>
                        ):(
                          which===1?(
                                <div className="main">
                                    <div className="school-header">
                                        <Link to="/school">致远学院</Link> &gt;&nbsp;
                                        <span>高手进阶</span>
                                    </div>
                                    <div className="school-detail">
                                        <div onClick={()=>setWhich(null)} className="fh">
                                            <img src={fh} width="70px" alt="返回" title="返回"/>
                                        </div>
                                        <div className="school-detail-title">经济波动周期</div>
                                        <div className="school-detail-content">
                                            <p>商品市场波动通常与经济波动周期紧密相关。期货价格也不例外。由于期货市场是与国际市场紧密相联的开放市场，因此，期货市场价格波动不仅受国内经济波动周期的影响，而且还受世界经济的景气状况影响。</p>
                                            <p>经济周期一般由复苏、繁荣、衰退和萧条四个阶段构成。复苏阶段开始时是前一周期的最低点，产出和价格均处于最低水平。随着经济的复苏，生产的恢复和需求的增长，价格也开始逐步回升。繁荣阶段是经济周期的高峰阶段，由于投资需求和消费需求的不断扩张超过了产出的增长，刺激价格迅速上涨到较高水平。衰退阶段出现在经济周期高峰过去后，经济开始滑坡，由于需求的萎缩，供给大大超过需求，价格迅速下跌。萧条阶段是经济周期的谷底，供给和需求均处于较低水平，价格停止下跌，处于低水平上。在整个经济周期演化过程中，价格波动略滞后于经济波动。这些是经济周期四个阶段的一般特征。</p>
                                            <p>比如，在本世纪 60 年代以前西方国家经济周期的特点是产出和价格的同向大幅波动。而 70
                                                年代初期，西方国家先后进入所谓的"滞胀"时期，经济大幅度衰退，价格却仍然猛烈上涨，经济的停滞与严重的通货膨胀并存。而 80-90
                                                年代以来的经济波动幅度大大缩小，并且价格总水平只涨不跌，衰退和萧条期下降的只是价格上涨速度而非价格的绝对水平。当然，这种只涨不跌是指价格总水平而非所有的具体商品价格，具体商品价格仍然是有升有降。进入
                                                90
                                                年代中期以后，一些新兴市场经济国家，如韩国、东南亚国家等，受到金融危机的冲击，导致一些商品的国际市场价格大幅下滑。但是，全球经济并没有陷入全面的危机之中，欧美国家经济持续向好。因此，认真观测和分析经济周期的阶段和特点，对于正确地把握期货市场价格走势具有重要意义。</p>
                                            <p>经济周期阶段可由一些主要经济指标值的高低来判断，如 GDP
                                                增长率，失业率、价格指数、汇率等。这些都是期货交易者应密切注意的。</p></div>
                                    </div>
                                </div>
                            ):(
                               which===2?(
                                    <div className="main">
                                        <div className="school-header">
                                            <Link to="/school">致远学院</Link> &gt;&nbsp;
                                            <span>高手进阶</span>
                                        </div>
                                        <div className="school-detail">
                                            <div onClick={()=>setWhich(null)} className="fh">
                                                <img src={fh} width="70px" alt="返回" title="返回"/>
                                            </div>
                                            <div className="school-detail-title">期货商品供给分析</div>
                                            <div className="school-detail-content">
                                                <p>经济学的名言是：从长期看，商品的价格最终反映的必然是供求双方力量均衡点的价格。所以，商品供求状况对商品期货价格具有重要的影响。基本因素分析法主要分析的就是供求关系。商品供求状况的变化与价格的变动是互相影响、互相制约的。商品价格与供给成反比，供给增加，价格下降；供给减少，价格上升。商品价格与需求成正比，需求增加，价格上升；需求减少，价格下降。在其他因素不变的条件下，供给和需求的任何变化，都可能影响商品价格变化，一方面，商品价格的变化受供给和需求变动的影响；另一方面，商品价格的变化又反过来对供给和需求产生影响：价格上升，供给增加，需求减少；价格下降，供给减少，需求增加。这种供求与价格互相影响、互为因果的关系，使商品供求分析更加复杂化，即不仅要考虑供求变动对价格的影响，还要考虑价格变化对供求的反作用。</p>
                                                <p className="subtitle">（一）期初库存量</p>
                                                <p>期初库存量是指上年度或上季度积存下来可供社会继续消费的商品实物量。根据存货所有者身份的不同，可以分为生产供应者存货、经营商存货和政府储备。前两种存货可根据价格变化随时上市供给，可视为市场商品可供量的实际组成部分。而政府储备的目的在于为全社会整体利益而储备，不会因一般的价格变动而轻易投放市场。但当市场供给出现严重短缺，价格猛涨时，政府可能动用它来平抑物价，则将对市场供给产生重要影响。</p>
                                                <p className="subtitle">（二）本期产量</p>
                                                <p>本期产量是指本年度或本季度的商品生产量。它是市场商品供给量的主体，其影响因素也甚为复杂。从短期看，它主要受生产能力的制约，资源和自然条件、生产成本及政府政策的影响。不同商品生产量的影响因素可能相差很大，必须对具体商品生产量的影响因素进行具体的分析，以便能较为准确地把握其可能的变动。</p>
                                                <p className="subtitle">（三）本期进口量</p>
                                                <p>本期进口量是对国内生产量的补充，通常会随着国内市场供求平衡状况的变化而变化。同时，进口量还会受到国际国内市场价格差、汇率、国家进出口政策以及国际政治因素的影响而变化。</p>
                                            </div>
                                        </div>
                                    </div>
                                ):(
                                    which===3?(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;&nbsp;
                                                <span>高手进阶</span>
                                            </div>
                                            <div className="school-detail">
                                                <div onClick={()=>setWhich(null)} className="fh">
                                                    <img src={fh} width="70px" alt="返回" title="返回"/>
                                                </div>
                                                <div className="school-detail-title">期货商品需求分析</div>
                                                <div className="school-detail-content">
                                                    <p>商品市场的需求量是指在一定时间、地点和价格条件下买方愿意购买并有能力购买的某种商品数量。它通常由国内消费量、出口量和期末库存量等三部分组成。</p>
                                                    <p className="subtitle">（一）国内消费量</p>
                                                    <p>国内消费量主要受消费者的收入水平或购买能力、消费者人数、消费结构变化、商品新用途发现、替代品的价格及获取的方便程度等因素的影响，这些因素变化对期货商品需求及价格的影响往往大于对现货市场的影响。</p>
                                                    <p className="subtitle">（二）国际市场需求分析</p>
                                                    <p>稳定的进口量虽然量值大但对国际市场价格影响甚小，不稳定的进口量虽然量值小，但对国际市场价格影响很大。出口量是本国生产和加工的商品销往国外市场的数量，它是影响国内需求总量的重要因素之一。分析其变化应综合考虑影响出口的各种因素的变化情况，如国际、国内市场供求状况，内销和外销价格比，本国出口政策和进口国进口政策变化，关税和汇率变化等。例如，我国是玉米出口国之一，玉米出口量是影响玉米期货价格的重要因素。</p>
                                                    <p className="subtitle">（三）期末结存量</p>
                                                    <p>期末结存量具有双重的作用，一方面，它是商品需求的组成部分，是正常的社会再生产的必要条件；另一方面，它又在一定程度上起着平衡短期供求的作用。当本期商品供不应求时，期末结存将会减少；反之就会增加。因此，分析本期期末存量的实际变动情况，即可从商品实物运动的角度看出本期商品的供求状况及其对下期商品供求状况和价格的影响。</p>
                                                </div>
                                            </div>
                                        </div>
                                    ):(
                                        <div className="main">
                                            <div className="school-header">
                                                <Link to="/school">致远学院</Link> &gt;&nbsp;
                                                <span>高手进阶</span>
                                            </div>
                                            <div>暂无数据</div>
                                        </div>
                                    )
                                )
                            )
                        )
                    )
                }

            </div>
        </div>
        <HomeFooter/>
    </div>
    );
  }
  
  export default Page05;
