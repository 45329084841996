import React from "react";
import ReactDOM from "react-dom/client";
import { notification, message } from "antd";
import "@/style/init.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import FK from "./core/index";
import Store from "./store";
import { getSearch } from "./utils";
// 設置彈窗訊息
message.config({
	top: 200,
	duration: 1.5,
	maxCount: 3,
});
// 設置通知訊息
notification.config({
	placement: "topRight",
	top: 100,
	duration: 2,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);
FK.Launcher.start()
	.then(() => {
		Store.User.initUser();
		Store.Brief.setHot(["CL", "GC", "NQ", "DAX", "NK", "HSI", "IF"]);
		Store.Brief.setHotV2(["CL", "GC", "HG", "HSI"]);
		Store.User.getRu(getSearch(window.location.href).ru);
		Store.User.getFrom(getSearch(window.location.href).f);
	})
	.catch((err) => console.warn(err));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
