import React, { useState } from "react";
import { formatDate } from "@/utils";
import SPSLSet from "./spsl";
import { Modal, notification } from "antd";

import { Copy } from "../../components/copy/copy";
import position_bl from "../../../assets/images/position_black.png";
import { RESTRICT } from "@/library/standard";
import Store from "@/store";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { SPSL } from "./leverSpslV2";
import { customModal } from "@/view/components/Modal";

const Position = ({ array }) => {
  const [volumeHide, setVolumeHide] = useState(false);
  const [leverHide, setLeverHide] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [id, setId] = useState(false);
  const simulate = Store.Quote.simulate;

  let data = toJS(array).sort((a, b) => b.tradeTime - a.tradeTime);

  const sell = (id) => {
    Store.Position.closePosition(id)
      .then(() => {
        notification.success({ message: `订单平仓成功` });
        Store.Position.start(false);
        Store.Wallet.updateWallet();
      })
      .catch(({ message }) => notification.error({ message }));
  };

  const setting = (id) => {
    Store.FuturesSPSL.getSPSLById(id);
    customModal(<SPSL id={id} />, false);
  };

  return (
    <div className="position_list">
      <div className="title">
        <i />
        <div className={"position_long"}>商品名称</div>
        <div>模式</div>
        <div>手数/杠杆</div>
        <div>保证金</div>
        <div>开仓价</div>
        {/*<div>当前价</div>*/}
        <div>止盈</div>
        <div>止损</div>
        <div>盈亏(元)</div>
        <div className={"deferDays"}>过夜天数</div>
        <div className={"position_short"}>开仓时间</div>
        <div className={"id position_longer"}>订单号</div>
        <div className={"position_longer"}>操作</div>
      </div>
      <div className="list_box">
        {!!data && data.length !== 0 ? (
          !!data &&
          data.map((item, key) => {
            let n =
              item.commodityCode.indexOf("USDT") === -1 ? "futures" : "crypto";
            return (
              <div className={"list"} key={key}>
                <i className={item.isBuy ? "redB" : "greenB"} />
                <div className={`position_long`}>
                  {item.commodity}(
                  {n === "futures" ? item.contCode : item.commodityCode})
                </div>
                <div>{item.moneyType === 0 ? "元" : "角"}</div>
                <div>{n === "futures" ? item.volume : `${item.lever}X`}</div>
                <div>{item.margin}</div>
                <div>{item.opPrice}</div>
                {/*<div>{item.current}</div>*/}
                <div className={"red"}>{item.stopProfit}</div>
                <div className={"green"}>{item.stopLoss}</div>
                <div className={item.income > 0 ? "red" : "green"}>
                  {item.income.toFixed(2)}
                </div>
                <div className={"deferDays"}>
                  {simulate ? "--" : item.defer ? item.deferDays : "--"}
                </div>
                <div className={"position_short"}>
                  {formatDate("m-d h:i:s", { date: item.tradeTime })}
                </div>
                <div className={"id position_longer"}>
                  <p>{item.id}</p>
                  <Copy text={item.id} />
                </div>
                <div className={"btn_box position_longer"}>
                  <div
                    onClick={() => {
                      if (item.lever === 1) {
                        Store.FuturesSPSL.getSPSLById(item.id);
                        setVolumeHide(true);
                        setId(item.id);
                      } else {
                        Store.FuturesSPSL.getSPSLById(item.id);
                        setLeverHide(true);
                        setting(item.id);
                        setId(item.id);
                      }
                    }}
                  >
                    设置
                  </div>
                  {
                    <div
                      className={item.tradeStatus === 12 ? "closeBtn" : ""}
                      onClick={() => {
                        if (
                          item.commodityZones !== "futures" &&
                          Store.Futures.orderSetting.confirmClose
                        ) {
                          setConfirmClose(true);
                          setId(item.id);
                        } else {
                          sell(item.id);
                        }
                      }}
                    >
                      平仓
                    </div>
                  }
                </div>
              </div>
            );
          })
        ) : (
          <div className={"empty"}>
            <div>
              <img src={position_bl} alt="" />
              <b>暂无持仓</b>
            </div>
          </div>
        )}
      </div>
      <Modal
        title={"温馨提示"}
        visible={confirmClose}
        className={"theme_dark"}
        onCancel={() => setConfirmClose(false)}
        footer={<div />}
      >
        <div className={"confirm_info"}>确定是否平仓</div>
        <div className={"btn_box"}>
          <div onClick={() => setConfirmClose(false)}>取消</div>
          <div
            className={"confirm"}
            onClick={() => {
              sell(id);
              setConfirmClose(false);
            }}
          >
            确定
          </div>
        </div>
      </Modal>
      <SPSLSet
        visible={volumeHide}
        onCancel={() => {
          setVolumeHide(false);
        }}
        id={id}
      />
    </div>
  );
};

export default observer(Position);
