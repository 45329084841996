import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { notification, message } from "antd";

// lib
import EVENT from "@/library/api";
// components
import { SimpleInput } from "../../components";
// style
import styles from "./index.module.scss";

export default function TwoStep(props) {
  const navigate = useNavigate();
  const { token } = props;
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [isError, setIsError] = useState(true);

  useEffect(() => {
    if (password.length > 0 && password == confirmPwd) {
      setIsError(false);
    }
  }, [password, confirmPwd]);

  const submit = () => {
    if (isError) {
      return;
    }
    if (password !== confirmPwd) {
      return message.warn("两次输入密码不一致");
    }

    EVENT.Account.retrieveLoginPass({
      newPass: password,
      cfmPass: confirmPwd,
      token: token,
    })
      .then(() => {
        notification.success({
          message: "密码重置成功,请重新登录",
          duration: 2.5,
          onClose: () => {
            navigate("/CommonLogin");
          },
        });
      })
      .catch((message) => notification.error({ message }));
  };
  return (
    <div className={styles.form}>
      <SimpleInput
        label="新密码"
        placeholder="请输入新密码"
        type={"password"}
        format={"SET_WITHDRAWPASSWORD"}
        notice={"withdrawpassword"}
        value={password}
        simple={true}
        force={true}
        onChange={(password, format) => {
          setPassword(password);
        }}
      />
      <SimpleInput
        label="确认密码"
        placeholder="请再次输入新密码"
        type={"password"}
        format={"SET_WITHDRAWPASSWORD"}
        notice={"withdrawpassword"}
        value={confirmPwd}
        simple={true}
        force={true}
        onChange={(password, format) => {
          setConfirmPwd(password);
        }}
      />
      <div
        className={isError ? styles.confirmBtnErr : styles.confirmBtn}
        onClick={submit}
      >
        确定
      </div>
    </div>
  );
}
