import React from 'react';
import { ReactSVG } from 'react-svg'
import "./svg.scss"

const CSvg = (props) => {
    const {svgClassName, className, onClick, src, item} = props;

    const override = [ svgClassName || null];
    const compile = [ className || null];

    if (item) {
        return (
            <div className={`C-Svg ${compile.join(' ')}`}>
                {
                    src && <img {...props} className={override.join(' ')} onClick={onClick} src={src} alt={''} />
                }
            </div>
        );
    } else {
        return (
            <ReactSVG {...props} onClick={onClick} src={src} svgClassName={override.join(' ')} className={`C-Svg ${compile.join(' ')}`}/>
        );
    }
}

export default CSvg;
