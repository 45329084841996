import React, { useState, useEffect } from "react";
import { formatDate } from "@/utils";
import { Pagination, notification } from "antd";
import funds from "../../../assets/images/funds_light.png";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";

const LuckyDetail = (props) => {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);

  useEffect(() => {
    getLucky(page);
  }, []);

  useEffect(() => {
    getLucky(page);
  }, [page]);

  const getLucky = (page) => {
    EVENT.History.getRecordHistory(4, 10, "pc", page)
      .then(({ data, page, total }) => {
        setList(data);
        total = Number(total);
        setTotal(total);
      })
      .catch((err) => {
        notification.error({ message: err });
      });
  };

  return (
    <div id={"Detail"}>
      <ol className={"eagle_ol"}>
        <li className={"li_title"}>
          <div>时间</div>
          <div>金额</div>
          <div>明细</div>
          <div>ID</div>
        </li>
        {!!list && list.length !== 0 ? (
          list.length !== 0 &&
          list.map((item, key) => {
            return (
              <li key={key}>
                <div>
                  {formatDate("m-d-y h:i:s", { date: item.createTime })}
                </div>
                <div className={item.type === 300 ? "red" : "green"}>
                  {item.type === 300 ? "+" : "-"}
                  {Number(item.money).toFixed(2)}
                </div>
                <div>{item.explain}</div>
                <div>{item.id}</div>
              </li>
            );
          })
        ) : (
          <div className={"empty"}>
            <div>
              <img src={funds} alt=""/>
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </ol>
      <div className={"pagination"}>
        <Pagination
          onChange={(page) => {
            setPage(page);
          }}
          current={page}
          total={total}
        />
      </div>
    </div>
  );
};

export default observer(LuckyDetail);
