import React, { useState, useEffect } from "react";
import { SimpleInput } from "../../components";
import { Link } from "react-router-dom";
import { paymentImg } from "../../components/tool";
import { message } from "antd";
import { getSearch, getRandomNum, sortNum } from "@/utils";
import { formatDate } from "@/utils/index";
import { statusText } from "../../components/tool";
import funds from "../../../assets/images/funds_light.png";
import { Toast } from "antd-mobile";
import EVENT from "../../../library/api";

const money = [300, 500, 1000, 2000, 5000, 10000, 20000];

const Fiat = (props) => {
  const { list, bal } = props;

  const [name, setName] = useState("");
  const [needName, setNeed] = useState(false);
  const [needConfirm, setNeedConfirm] = useState(false);
  const [confirmContent, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [descr, setDescr] = useState("");

  const [max, setMax] = useState();
  const [min, setMin] = useState();
  const [payName, setPayName] = useState();
  const [moneyList, setMoneyList] = useState([]);
  const [load, setLoad] = useState(false);
  const [payList, setPayList] = useState([]);
  const [moneyValue, setMoneyValue] = useState("");
  const [config, setConfig] = useState(null);
  const [listIndex, setListIndex] = useState("");

  useEffect(() => {
    if (payList?.length === 0) return payList[{ name: "暂无充值渠道" }];
  }, [payList]);

  useEffect(() => {
    getPayList();
  }, []);

  //获取列表
  const getPayList = async () => {
    try {
      message.success("加载中...");
      EVENT.Payment.getPaymentList().then((list) => {
        setPayList(list.payList);
        setUrl(list.payList[0].url);
        setListIndex(0);
      });
    } catch (error) {
      Toast.show(error);
    }
  };

  useEffect(() => {
    setMin(getSearch(url).min);
    setMax(getSearch(url).max);
    createRandomNumber(getSearch(url).min, getSearch(url).max);
    !!getSearch(url).type && getSearch(url).type.indexOf("NAME") !== -1
      ? setNeed(true)
      : setNeed(false);
    !!getSearch(url).confirm
      ? setContent(getSearch(url).confirm)
      : setContent("");
  }, [url]);

  //todo 生产随机数
  const createRandomNumber = (min, max) => {
    let a = [];
    for (let i = 0; i < 8; i++) {
      a.push(getRandomNum(min, max));
    }
    setMoneyList(a.sort(sortNum));
  };

  //todo 判断是否能发起充值
  const pay = () => {
    if (needName && !payName) {
      Toast.show("请输入付款人姓名");
      return;
    }
    if (typeof moneyValue !== "number") {
      Toast.show("请输入金额");
      return;
    }
    confirmContent !== "" && moneyValue < 100
      ? setNeedConfirm(true)
      : deposit();
  };

  //todo 充值
  const deposit = () => {
    if (typeof moneyValue !== "number") {
      Toast.show("输入充值金额");
      return;
    }
    const o = {
      url,
      moneyValue,
      channel: "ALIPAY_WAP",
    };
    if (needName) o.accountName = payName;
    setLoad(true);
    EVENT.Payment.fastPayment(o)
      .then((url) => {
        message.success("加载中...");
        window.location.href = url;
        setLoad(false);
      })
      .catch((message) => {
        Toast.show(message);
        setLoad(false);
      });
  };

  //todo 匹配名字
  const matchName = (name) => {
    if (name.indexOf("小荷包") !== -1) return "银行转账";
    if (name.indexOf("支付宝") !== -1) return "支付宝转账";
    if (name.indexOf("人民币") !== -1) return "银行转账";
    if (name.indexOf("钉钉") !== -1 || name.indexOf("釘釘") !== -1)
      return "支付宝转账";
    if (name.indexOf("淘宝") !== -1) return "银行转账";
    if (name.indexOf("QQ") !== -1) return "银行转账";
    return "银行转账";
  };

  return (
    <>
      <div className={"fiat_recharge"}>
        <div className={"fiat_balance"}>
          <p>账户余额</p>
          <div>
            <b>
              {bal} <span>CNY</span>
            </b>
          </div>
        </div>
        <div className={"pay_method"}>
          <label>支付方式</label>
          <div className="pay_box">
            {payList?.length > 0 &&
              payList.map((item, key) => {
                return (
                  <div
                    className={`pay ${listIndex === key ? "active" : ""}`}
                    key={key}
                    onClick={() => {
                      setListIndex(key);
                      setConfig(item);
                      setUrl(item.url);
                    }}
                  >
                    <img src={paymentImg(matchName(item.name))} alt="" />
                    <div>
                      <b>{item.name}</b>
                      <p>{item.descr}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {needName && (
          <div className={"pay_accountname"}>
            <SimpleInput
              label={"付款人姓名"}
              placeholder={"请输入付款人姓名"}
              type={"text"}
              maxLength={20}
              value={payName}
              onChange={(e) => {
                setPayName(e);
              }}
            />
          </div>
        )}
        <div className={"pay_money"}>
          <SimpleInput
            label={"充值金额"}
            placeholder={"请输入充值金额"}
            type={"number"}
            maxLength={8}
            value={moneyValue}
            onChange={(e) => {
              setMoneyValue(e);
              config.param.money.value = e;
            }}
          />
          {money.map((item) => {
            return (
              <div
                className={`money ${moneyValue === item ? "active" : ""}`}
                onClick={() => {
                  setMoneyValue(item);
                  if (config !== null) {
                    config.param.money.value = item;
                  }
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
        <div className={"submit_box"}>
          <div className={"pay_btn"}>
            <p>
              应付金额：
              <b>{moneyValue === "" ? 0 : moneyValue} CNY</b>
            </p>
            <div
              className={`confirm_btn ${moneyValue.length === 0 && "error"}`}
              onClick={() => {
                pay();
              }}
            >
              确定
            </div>
          </div>
          <div className={"tips"}>
            <div>
              <h6>温馨提示</h6>
              <p>· 申请金额与转账金额必须一致，否则无法到账。</p>
              <p>· 使用支付宝转账，请在转账时间内完成，否则不会自动到账。</p>
              <p>· 第三方收款账户不定期更新，充值前需获取最新收款信息。</p>
              <p>· 操作过程中如有问题，请联系客服。</p>
            </div>
          </div>
        </div>
      </div>
      <div className="recent_record">
        <div className={"record"}>
          <h5>近期充值记录</h5>
          <Link to={{ pathname: "/account/fundsDetail" }}>查看更多 》</Link>
        </div>
        <ul>
          <li className={"record_title"}>
            <div>时间</div>
            <div>金额(元)</div>
            <div>ID</div>
            <div>状态</div>
          </li>
          {!!list &&
            list.map((item, key) => {
              if (key < 5) {
                return (
                  <li key={key}>
                    <div>
                      {formatDate("m-d h:i:s", {
                        date: item.createTime,
                      })}
                    </div>
                    <div>{item.srcMoney}</div>
                    <div>{item.id}</div>
                    <div>{statusText(item.status)}</div>
                  </li>
                );
              }
            })}
        </ul>
        {!!list && list.length === 0 && (
          <div className={"empty"}>
            <div>
              <img src={funds} />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Fiat;
