import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Slider, notification } from "antd";

// lib
import { RESTRICT } from "@/library/standard";
//utils
import { arrayToStr } from "@/utils";
import { goodsImg } from "@/view/components/tool";
// store
import Store from "@/store";

// assets
import { ReactComponent as Close } from "@assets/images/common/x.svg";
// style
import styles from "./index.module.scss";
import CSvg from "@/utils/svg";
import add from "../../../assets/images/trade/add.svg";
import minus from "../../../assets/images/trade/loss.svg";

export const SPSL = observer(({ close, id }) => {
  const { list = [] } = Store.Position;
  const {
    commodityCode,
    isBuy,
    stopProfit,
    stopProfitMax = 0,
    stopProfitMin = 0,
    stopProfitPrice,
    stopProfitPriceMin,
    stopProfitPriceMax,
    stopLoss,
    stopLossMin = 0,
    stopLossMax = 0,
    stopLossPrice,
    stopLossPriceMax,
    stopLossPriceMin,
    margin,
    priceDigit,
  } = Store.FuturesSPSL.SPSL;
  const [switchType, setSwitchType] = useState(0);
  const [profit, setProfit] = useState(stopProfit);
  const [loss, setLoss] = useState(stopLoss);

  const [profitPrice, setProfitPrice] = useState(stopProfitPrice);
  const [lossPrice, setLossPrice] = useState(stopLossPrice);

  useEffect(() => {
    Store.FuturesSPSL.setCryptoStopProfit(profit);
  }, [profit]);

  useEffect(() => {
    Store.FuturesSPSL.setCryptoStopLoss(loss);
  }, [loss]);
  useEffect(() => {
    Store.FuturesSPSL.setCryptoStopProfitPrice(profitPrice);
  }, [profitPrice]);

  useEffect(() => {
    Store.FuturesSPSL.setCryptoStopLossPrice(lossPrice);
  }, [lossPrice]);
  const min = useMemo(() => 1 / Math.pow(10, priceDigit), [priceDigit]);
  const orderInfo = useMemo(
    () => list.find((item) => item.id === id),
    [list, id]
  );
  if (Object.keys(Store.FuturesSPSL.SPSL).length === 0) return;

  // 取消或關閉
  const handleClose = () => {
    Store.FuturesSPSL.clearUpdate();
    Store.FuturesSPSL.clearSPSL();
    close();
  };
  // 止盈金額輸入
  const validStopProfit = (e) => {
    let val = e.target.value;
    if (isNaN(val)) return;
    if (val.indexOf(".") !== -1) {
      if (val.split(".")[1].length > 2) return;
    }
    console.log("止盈金額輸入 === ", val);
    setProfit(val);
  };
  // 止損金額輸入
  const validStopLoss = (e) => {
    let val = e.target.value;
    if (val.indexOf(".") !== -1) {
      if (val.split(".")[1].length > 2) return;
    }
    if (val < stopLossMax.mul(margin)) {
      setLoss(+stopLossMax.mul(margin));
      return;
    }
    console.log("止損金額輸入 ", stopLossMax.mul(margin));
    if (stopLossMin.toFixed(1) > 0) {
      if (+val > +stopLossMin) {
        val = stopLossMin.toFixed(1);
      }
    }
    setLoss(val);
  };
  // 止盈金額加減按鈕
  const updateProfit = (isAdd) => {
    if (isAdd) {
      if (+profit + 1 > stopProfitMax.mul(margin))
        return setProfit(stopProfitMax.mul(margin));
      if (+profit + 1 < stopProfitMin.mul(margin))
        return setProfit(stopProfitMin.mul(margin));
      setProfit(+profit + 1);
    } else {
      if (+profit - 1 < stopProfitMin.mul(margin))
        return setProfit(stopProfitMin.mul(margin));
      setProfit(+profit - 1);
    }
  };
  // 止損金額加減按鈕
  const updateLoss = (isAdd) => {
    if (isAdd) {
      if (+loss - 1 < stopLossMax.mul(margin)) {
        return setLoss(stopLossMax.mul(margin));
      }
      setLoss(+loss - 1);
    } else {
      if (+loss + 1 > +stopLossMin) {
        return setLoss(stopLossMin.toFixed(0));
      }
      setLoss(+loss + 1);
    }
  };
  // 止盈價格輸入
  const validProfitPrice = (e) => {
    let val = e.target.value;
    if (isNaN(val)) return;
    console.log("止盈價格輸入");
    if (+val > +stopProfitPriceMax) return setProfitPrice(stopProfitPriceMax);
    if (val.indexOf(".") !== -1) {
      if (val.split(".")[1].length > +priceDigit) return;
      if (val.split(".")[0].length > 5) return;
    } else {
      if (val.split(".")[0].length > 5) return;
    }
    setProfitPrice(val);
  };
  // 止損價格輸入
  const validLossPrice = (e) => {
    let val = e.target.value;
    if (isNaN(val)) return;
    if (isBuy) {
      if (+val > +stopLossPriceMin) return setLossPrice(stopLossPriceMin);
    } else {
      if (+val > +stopLossPriceMax) return setLossPrice(stopLossPriceMax);
    }
    if (val.indexOf(".") !== -1) {
      if (val.split(".")[0].length > 5) return;
      if (val.split(".")[1].length > +priceDigit) return;
    } else {
      if (val.length > 5) return;
    }
    setLossPrice(val);
  };
  // 止盈價格加減按鈕
  const updateProfitPrice = (isAdd) => {
    if (isAdd) {
      if (isBuy) {
        if (
          +stopProfitPrice.add(min).toFixed(priceDigit) >= +stopProfitPriceMax
        )
          return setProfitPrice(stopProfitPriceMax);
        if (
          +stopProfitPrice.add(min).toFixed(priceDigit) <= +stopProfitPriceMin
        )
          return setProfitPrice(stopProfitPriceMin);
      } else {
        if (
          +stopProfitPrice.add(min).toFixed(priceDigit) <= +stopProfitPriceMin
        )
          return setProfitPrice(stopProfitPriceMin);
      }
      if (+stopProfitPrice.add(min).toFixed(priceDigit) > +stopProfitPriceMax)
        return;
      setProfitPrice(stopProfitPrice.add(min).toFixed(priceDigit));
    } else {
      if (+stopProfitPrice.sub(min).toFixed(priceDigit) < +stopProfitPriceMin)
        return;
      setProfitPrice(stopProfitPrice.sub(min).toFixed(priceDigit));
    }
  };
  // 止損價格加減按鈕
  const updateLossPrice = (isAdd) => {
    if (isAdd) {
      if (isBuy) {
        if (+stopLossPrice.add(min).toFixed(priceDigit) <= stopLossPriceMax)
          return setLossPrice(stopLossPriceMax);
        if (+stopLossPrice.add(min).toFixed(priceDigit) >= +stopLossPriceMin)
          return setLossPrice(stopLossPriceMin);
      } else {
        if (+stopLossPrice.add(min).toFixed(priceDigit) <= +stopLossPriceMin)
          return setLossPrice(stopLossPriceMin);
        if (+stopLossPrice.add(min).toFixed(priceDigit) >= +stopLossPriceMax)
          return setLossPrice(stopLossPriceMax);
      }
      setLossPrice((+stopLossPrice.add(min)).toFixed(priceDigit));
    } else {
      if (isBuy) {
        if (+stopLossPrice.sub(min).toFixed(priceDigit) <= +stopLossPriceMax)
          return setLossPrice(stopLossPriceMax);
      } else {
        if (+stopLossPrice.sub(min).toFixed(priceDigit) <= +stopLossPriceMin)
          return setLossPrice(stopLossPriceMin);
      }
      setLossPrice((+stopLossPrice.sub(min)).toFixed(priceDigit));
    }
  };

  const submit = () => {
    Store.FuturesSPSL.setSPSL()
      .then(() => {
        notification.success({ message: "设置止盈止损成功" });
        Store.Position.start(false);
        handleClose();
      })
      .catch(({ message }) => {
        notification.error({ message });
        handleClose();
      });
  };

  return (
    <div className={styles.spslModal}>
      <div className={styles.close} onClick={handleClose}>
        <Close />
      </div>
      <div className={arrayToStr([styles.oneLine, styles.switchBtn])}>
        <div
          className={switchType === 0 ? styles.active : ""}
          onClick={() => setSwitchType(0)}
        >
          按金额
        </div>
        <div
          className={switchType === 1 ? styles.active : ""}
          onClick={() => setSwitchType(1)}
        >
          按价格
        </div>
      </div>
      <div className={styles.oneLine}>
        <div>
          <img src={goodsImg[commodityCode]} />
          {commodityCode.includes("USDT")
            ? commodityCode.replace("USDT", "/USDT")
            : commodityCode}
        </div>
        <b className={orderInfo.income > 0 ? "red" : "green"}>
          {RESTRICT.float(orderInfo.income, 2)}
          <span> 元</span>
        </b>
      </div>
      <div className={styles.twoLine}>
        <label>预计盈利</label>
        <div className={styles.inputBox}>
          <input
            //type="number"
            min={switchType === 0 ? stopProfitMin : stopProfitPriceMin}
            max={switchType === 0 ? stopProfitMax : stopProfitPriceMax}
            placeholder={
              switchType === 0
                ? `${Number(stopProfitMin).mul(margin)} ~ ${Number(
                    stopProfitMax
                  ).mul(margin)}`
                : `${stopProfitPriceMin} ~ ${stopProfitPriceMax}`
            }
            value={switchType === 0 ? stopProfit : stopProfitPrice}
            onChange={switchType === 0 ? validStopProfit : validProfitPrice}
            onBlur={(e) => {
              const value = e.target.value;
              if (switchType === 0) {
                if (+value >= stopProfitMax.mul(margin)) {
                  setProfit(stopProfitMax.mul(margin));
                  console.log("这里是大于的情况", stopProfitMax.mul(margin));
                }
                if (+value <= stopProfitMin.mul(margin)) {
                  setProfit(stopProfitMin.mul(margin));
                }
                if (isNaN(value)) {
                  setProfit(stopProfitMin.mul(margin));
                }
                console.log("溢出后 --- ", profit);
              } else {
                if (isBuy) {
                  if (+value >= +stopProfitPriceMax)
                    return setProfitPrice(stopProfitPriceMax);
                  if (+value <= +stopProfitPriceMin)
                    return setProfitPrice(stopProfitPriceMin);
                } else {
                  if (+value <= +stopProfitPriceMin)
                    return setProfitPrice(stopProfitPriceMin);
                }
              }
            }}
          />
          <div className={styles.controller}>
            <div
              className={styles.btn}
              onClick={() =>
                switchType === 0 ? updateProfit(true) : updateProfitPrice(true)
              }
            >
              <CSvg className={"add"} svgClassName={"btn"} src={add} />
            </div>
            <div
              className={styles.btn}
              onClick={() =>
                switchType === 0
                  ? updateProfit(false)
                  : updateProfitPrice(false)
              }
            >
              <CSvg className={"minus"} svgClassName={"btn"} src={minus} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <span>{switchType === 0 ? "止盈价" : "止盈金额"}：</span>
            <b>{switchType === 0 ? stopProfitPrice : stopProfit}</b>
          </div>
          <div>
            <span>盈利百分比：</span>
            <b>{stopProfit.div(margin).mul(100).toFixed(2)}%</b>
          </div>
        </div>
      </div>
      <div className={styles.twoLine}>
        <label>预计亏损</label>
        <div className={styles.inputBox}>
          <input
            min={
              switchType === 0
                ? +stopLossMax?.mul(margin)
                : isBuy
                ? stopLossPriceMax
                : stopLossPriceMin
            }
            max={
              switchType === 0
                ? +stopLossMin?.mul(margin)
                : isBuy
                ? stopLossPriceMin
                : stopLossPriceMax
            }
            placeholder={
              switchType === 0
                ? `${stopLossMin.toFixed(0)} ~ ${+stopLossMax
                    .mul(margin)
                    .toFixed(0)}`
                : isBuy
                ? `${stopLossPriceMax} ~ ${stopLossPriceMin}`
                : `${stopLossPriceMin} ~ ${stopLossPriceMax}`
            }
            value={
              switchType === 0
                ? Math.abs(loss) === stopLoss
                  ? loss
                  : stopLoss
                : stopLossPrice
            }
            onChange={switchType === 0 ? validStopLoss : validLossPrice}
            onBlur={(e) => {
              const value = e.target.value;
              if (switchType === 0) {
                if (+value > +stopLossMin) {
                  setLoss(stopLossMin);
                  return;
                }
                if (value === "" || +value < +stopLossMax.mul(margin)) {
                  setLoss(+stopLossMax.mul(margin));
                  return;
                }
                if (isNaN(value)) {
                  setLoss(stopLossMin);
                }
              } else {
                if (isBuy) {
                  if (+value <= +stopLossPriceMax)
                    return setLossPrice(stopLossPriceMax);
                  if (+value >= +stopLossPriceMin)
                    return setLossPrice(stopLossPriceMin);
                } else {
                  console.log("value =>", value, stopLossPriceMin);
                  if (+value <= +stopLossPriceMin)
                    return setLossPrice(stopLossPriceMin);
                }
              }
            }}
          />
          <div className={styles.controller}>
            <div
              className={styles.btn}
              onClick={() =>
                switchType === 0 ? updateLoss(true) : updateLossPrice(true)
              }
            >
              <CSvg className={"add"} svgClassName={"btn"} src={add} />
            </div>
            <div
              className={styles.btn}
              onClick={() =>
                switchType === 0 ? updateLoss(false) : updateLossPrice(false)
              }
            >
              <CSvg className={"minus"} svgClassName={"btn"} src={minus} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <span>{switchType === 0 ? "止损价" : "止损金额"}：</span>
            <b>
              {switchType === 0
                ? stopLossPrice
                : Math.abs(loss) === stopLoss
                ? loss
                : stopLoss}
            </b>
          </div>
          <div>
            <span>亏损百分比：</span>
            <b>{Math.abs(stopLoss).div(margin).mul(100).toFixed(2)}%</b>
          </div>
        </div>
      </div>
      <div className={styles.btnBox}>
        <div onClick={handleClose}>取消</div>
        <div className={styles.confirm} onClick={submit}>
          确定
        </div>
      </div>
    </div>
  );
});
