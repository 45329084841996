import React, { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import { SimpleInput } from "../../components";
import Store from "@/store";
import EVENT from "@/library/api";
import { ImgValide } from "@/view/components/index";
import { Toast } from "antd-mobile";

const ForgotWithdraw = (props) => {
  const { handleModal } = props;
  const type = "CHANGE_WITHDRAW";
  const timer = useRef();
  const { mobile, email } = Store.User?.accountInfo;
  const [code, setCode] = useState();
  const [codeShow, setCodeShow] = useState();
  const [account, setAccount] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cfmPass, setCfmPass] = useState("");
  const [countText, setCountText] = useState("获取验证码");
  const [countNumber, setCountNumber] = useState(0);
  const [accountType, setAccountType] = useState("mobile");

  useEffect(() => {
    !!mobile ? setAccountType("mobile") : setAccountType("email");
  }, [mobile]);

  useEffect(() => {
    if (countNumber < 0) {
      setCountText("获取验证码");
      clearInterval(timer.current);
    } else {
      timer.current = setTimeout(() => {
        setCountNumber((time) => time - 1);
        setCountText(`${countNumber}秒后重发`);
      }, 1000);
    }
    accountType === "mobile" ? setAccount(mobile) : setAccount(email);
  }, [countNumber, accountType]);

  //todo 获取验证码发送状态
  const sendCodeStatus = (val) => {
    if (val) setCountNumber(120);
  };

  //todo 修改资金密码
  const changeFundsPass = () => {
    if (newPass !== cfmPass) return Toast.show("两次密码不一致");
    EVENT.Account[account.indexOf("@") === -1 ? "verifySMS" : "verifyEmail"](
      account,
      code,
      type
    )
      .then(() => {
        EVENT.Account.retrieveFundsPass({
          account,
          password: newPass,
        })
          .then(() => {
            Toast.show({
              content: "资金密码修改成功",
              afterClose: () => props.onCancel(),
            });
          })
          .catch((message) => Toast.show(message));
      })
      .catch(({ message }) => Toast.show(message));
  };

  return (
    <>
      <Modal
        title={"忘记提款密码"}
        visible={props.visible}
        className={"alertModal aboutPass"}
        onCancel={props.onCancel}
        destroyOnClose={true}
        // afterClose={() => {
        //   clearTimeout(this.timer);
        //   this.setState({ count: `获取验证码`, countNumber: 90 });
        // }}
        footer={<div />}
      >
        <SimpleInput
          label={"新密码"}
          placeholder={"请输入新密码"}
          type={"password"}
          format={"SET_PASSWORD"}
          notice={"password"}
          onChange={(e, format) => {
            setNewPass(e);
          }}
        />
        <SimpleInput
          label={"确认密码"}
          placeholder={"请确认密码"}
          type={"password"}
          format={"SET_PASSWORD"}
          notice={"password"}
          onChange={(e, format) => {
            setCfmPass(e);
          }}
        />
        <div className={"sendCode"}>
          <SimpleInput
            label={"验证码"}
            placeholder={
              accountType === "mobile" ? "请输入短信验证码" : "请输入邮箱验证码"
            }
            format={"GLOBAL_CAPTCHA"}
            notice={"msgCode"}
            type={"verifyCode"}
            onChange={(e, format) => {
              setCode(e);
            }}
          />
          <div className={"getCode"} onClick={() => setCodeShow(true)}>
            {countText}
          </div>
        </div>
        {!!mobile &&
          !!email &&
          !!email &&
          email.indexOf("@") !== -1 &&
          (accountType === "mobile" ? (
            <div className={"switch_verify"}>
              <div
                onClick={() =>
                  accountType === "mobile"
                    ? setAccountType("email")
                    : setAccountType("mobile")
                }
              >
                使用邮箱验证
              </div>
            </div>
          ) : (
            <div className={"switch_verify"}>
              <div
                onClick={() =>
                  accountType === "mobile"
                    ? setAccountType("email")
                    : setAccountType("mobile")
                }
              >
                使用手机验证
              </div>
            </div>
          ))}
        <div
          className={`confirm_btn ${
            (newPass == "" || cfmPass == "" || code == "") && "error"
          }`}
          onClick={() => {
            changeFundsPass();
          }}
        >
          确认
        </div>
      </Modal>
      {codeShow && (
        <ImgValide
          type={type}
          account={account}
          sendCodeStatus={sendCodeStatus}
          hide={() => setCodeShow(false)}
        />
      )}
    </>
  );
};

export default ForgotWithdraw;
