import React from 'react';
import './index.scss';
import {Tabs} from 'antd';
import FiatDetail from './fiatDetail';
import CryptoDetail from './cryptoDetail';
import WithdrawDetail from './withdrawDetail';
import TradeDetail from './tradeDetail';
import EagleDetail from './eagleDetail';
import LuckyDetail from './luckyDetail';
import { observer } from "mobx-react-lite";
import {useLocation} from 'react-router-dom';
const {TabPane} = Tabs;

const FundDetail = () => {
    const location = useLocation();
    const page = location?.state || 1
      
    return(
        <div id={'FundsDetail'}>
        <div className="funds_title">
            <h5>资金明细</h5>
        </div>
        <div className="funds_main">
            <Tabs defaultActiveKey={`${page}`} destroyInactiveTabPane>
                <TabPane tab="充值记录" key="1">
                   <FiatDetail/>
                </TabPane>
                <TabPane tab="充币记录" key="2">
                    <CryptoDetail/>
                </TabPane>
                <TabPane tab="交易记录" key="3">
                   <TradeDetail/>
                </TabPane>
                <TabPane tab="提款记录" key="4">
                    <WithdrawDetail/>
                </TabPane>
                <TabPane tab="礼金记录" key="5">
                    <EagleDetail/>
                </TabPane>
                <TabPane tab="积分记录" key="6">
                   <LuckyDetail/>
                </TabPane>
            </Tabs>
        </div>
    </div>
    )
  
  }
  
  export default observer(FundDetail)
