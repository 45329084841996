import { makeAutoObservable, runInAction } from "mobx";
import EVENT from "../library/api";

export class Game {
  rankList = [];
  myRank = {};

  commodity = [];
  id = [];
  history = [];
  trendList = [];

  constructor() {
    makeAutoObservable(this);
  }

  initial() {
    this.getRanking();
    this.getCommodity();
  }

  getGameHistory() {
    EVENT.Game.getGameHistory().then((result) => runInAction(() => (this.history = result.data)));
  }

  //todo 获取排行榜
  async getRanking() {
    const { rankList, myRank } = await EVENT.Game.getGameRaking();
    let rl = rankList ? rankList : [];
    let res = rl.concat([
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
      "虚位以待",
    ]);
    res = res.slice(0, 10);

    runInAction(() => {
      this.myRank = myRank;
      this.rankList = res;
    });
  }

  //todo 获取可竞猜商品信息
  async getCommodity() {
    const { data } = await EVENT.Game.getCommodity();
    runInAction(() => {
      data.forEach(({ code }) => {
        this.commodity.push(code);
      });
      this.commodity = this.commodity.unique();
      this.id = this.commodity[0];
    });
    this.getIssue();
  }

  async getIssue() {
    const {
      thisIssue,
      lastIssue,
      poolAwardTime,
      pool: { eagle, monday, sunday },
      nextIssue: { presellStartTime },
    } = await EVENT.Game.getIssue(this.id, 5);
    runInAction(() => {
      this.issue = thisIssue;
      this.last = lastIssue;
      this.monday = monday.time || monday;
      this.sunday = sunday.time || sunday;
      this.awardTime = poolAwardTime.time || poolAwardTime;
      this.nextTime = presellStartTime.time || presellStartTime;
      this.award = eagle;
    });
  }

  countdown() {
    if (this.nextTime !== undefined) {
      return parseInt(this.nextTime.sub(new Date().getTime()).div(1000));
    }
  }
  //todo 获取今日趋势
  getTodayTrend() {
    const o = {
      type: 2,
      commodityCode: this.issue.commodityCode,
      quizDate: this.issue.quizDate,
      interval: 5,
    };
    EVENT.Game.getTodayTrend(o).then(({ data }) => {
      let newList = data.filter((item) => {
        return item.result !== -1;
      });
      newList = newList.matrix(5);
      runInAction(() => (this.trendList = newList));
    });
  }

  //todo 切换商品Id
  switchCommodity(id) {
    this.id = id;
    this.getIssue();
  }

  //todo reload
  renew() {
    this.getIssue();
  }

  //todo 下单
  order(amount, isUp) {
    const o = {
      quizCode: this.issue.quizCode,
      currency: "XDC",
      price: this.issue.price,
      volume: parseInt(amount / this.issue.price),
      buy: isUp,
      type: 2,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const result = await EVENT.Game.order(o);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }
}
