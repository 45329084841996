import React, { useState } from "react";
import { Modal } from "antd";
import "../changeLoginPass/index.scss";
import { SimpleInput } from "../../../components";
import Store from "@/store";
import { Toast } from "antd-mobile";
import { observer } from "mobx-react-lite";
import EVENT from "@/library/api";

const WithdrawPass = (props) => {
  const { handleModal } = props
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cfmPass, setCfmPass] = useState("");
  const [have, setHave] = useState(Store.User.accountInfo?.pw_w === 0 ? false : true)

  const withdrawPass = () => {
    if (!verify) return
    EVENT.Account.withdrawPassword({ oldPass, newPass, cfmPass })
      .then(({ message }) => {
        Toast.show({
          content: message, afterClose: () =>
            handleModal()
        });
        props.onCancel()
        Store.User.updateDetail();
      })
      .catch(({ message }) => Toast.show(message));
  };

  const verify =
    oldPass.length > 0 &&
    newPass.length > 0 &&
    cfmPass.length > 0;

  return (
    <Modal
      title={have ? "修改提款密码" : "设置提款密码"}
      visible={props.visible}
      className={"alertModal aboutPass"}
      onCancel={props.onCancel}
      destroyOnClose={true}
      footer={<div />}
    >
      <SimpleInput
        label={have ? "原资金密码" : "登录密码"}
        placeholder={have ? "请输入原资金密码" : "请输入登录密码"}
        format={"SET_PASSWORD"}
        notice={"password"}
        type={"password"}
        onChange={(e, format) => {
          setOldPass(e);
        }}
      />
      <SimpleInput
        label={"资金密码"}
        placeholder={"请输入资金密码"}
        format={"SET_PASSWORD"}
        notice={"password"}
        type={"password"}
        onChange={(e, format) => {
          setNewPass(e);
        }}
      />
      <SimpleInput
        label={"确认资金密码"}
        placeholder={"请输入确认密码"}
        format={"SET_PASSWORD"}
        notice={"password"}
        type={"password"}
        onChange={(e, format) => {
          setCfmPass(e);
        }}
      />

      {have && (
        <div className={"switch_verify"}>
          <div
            onClick={() => {
              props.forgotPass();
              props.onCancel();
            }}
          >
            忘记密码
          </div>
        </div>
      )}
      <div
        className={`confirm_btn ${!verify && "verify"}`}
        onClick={() => withdrawPass()}
      >
        确认
      </div>
    </Modal>
  );
};

export default observer(WithdrawPass);

