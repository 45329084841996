import React, { useState, useEffect } from "react";
import "./index.scss";
import AddBankCardV1 from "./addBankcard";
import ChangeBankcard from "./changeBankcard";
import { notification } from "antd";
import { chooseBankLogo, color, bankNumMark } from "../../components/tool";
import position from "../../../assets/images/position_light.png";
import Store from "@/store";
import { idMask } from "@/utils";
import { observer } from "mobx-react-lite";
import EVENT from "../../../library/api";
import { useLocation } from "react-router-dom";
import { toJS } from "mobx";

const BankCard = (props) => {
  const location = useLocation();
  const { identityNumberValid } = Store.User.accountInfo;
  const [idVerify, setIdVerify] = useState("");
  const [bankList, setBankList] = useState("");
  const [addBankcard, setAddBankcard] = useState(false);
  const [changeBankcard, setChangeBankcard] = useState(false);
  const [editCard, setEditCard] = useState("");

  useEffect(() => {
    getUserInfo();
  }, [Store.User.accountInfo]);

  useEffect(() => {
    getAllBankcard();
    getUserInfo();
    if (location?.state === "unbind") {
      addBankcardVerify();
    }
  }, []);

  const getUserInfo = () => {
    setIdVerify(identityNumberValid);
  };

  //获取银行卡
  const getAllBankcard = () => {
    EVENT.Account.getAllBankCard()
      .then((result) => {
        // 不展示支付宝
        let cards = result.bankCards.filter((x) => x.bank !== "支付宝");
        setBankList(cards);
      })
      .catch((err) => {
        notification.error({ message: err });
      });
  };

  //设置默认
  const setDefault = (id) => {
    EVENT.Account.setDefaultBankCard(id)
      .then(() => {
        notification.success({ message: "默认银行卡设置成功" });
        getAllBankcard();
      })
      .catch((err) => {
        // notification.error({message:err.message})
      });
  };

  // 删除银行卡
  const deleteBankcard = (id) => {
    EVENT.Account.deleteBankCard(id).then(() => {
      Store.User.updateDetail();
      notification.success({ message: "删除成功" });
      getAllBankcard();
    });
  };

  //绑卡验证
  const addBankcardVerify = () => {
    if (!toJS(Store.User.accountInfo.identityNumberValid))
      return notification.error({ message: "请先实名认证" });
    setAddBankcard(true);
  };

  //修改银行卡
  const editBankcard = (item) => {
    setChangeBankcard(true);
    setEditCard(item);
  };

  const cardNumber = (val) => {
    return "**** **** **** " + val.slice(-4);
  };

  return (
    <div id={"Bankcard"}>
      <div className="bankcard_title">
        <h5>银行卡管理</h5>
        <div
          onClick={() => {
            addBankcardVerify();
          }}
        >
          添加银行卡
        </div>
      </div>
      <div className={"bank_main"}>
        <div className="switch_btn">
          <div>我的银行卡</div>
        </div>
        <div className={"bankcard_list"}>
          {bankList?.length > 0 ? (
            bankList.map((item, key) => {
              return (
                <div className={`bank_box ${color(item.bank)}`} key={key}>
                  <div className={"img_box"}>
                    <div className={"img"}>
                      <img src={chooseBankLogo(item.bank)} alt="" />
                    </div>
                  </div>
                  <div className={"info"}>
                    <div className={"bank_name"}>
                      <b>{item.bank}</b>
                      <p>储蓄卡</p>
                    </div>
                    <div className={"bank_card"}>
                      {cardNumber(item.cardNumber)}
                    </div>
                    <div className={"btn_box"}>
                      <div
                        className={"btn"}
                        onClick={() => {
                          editBankcard(item);
                        }}
                      >
                        编辑
                      </div>
                      <div className={"right"}>
                        {item.def === 0 && (
                          <div
                            className={"btn"}
                            onClick={() => {
                              setDefault(item.id);
                            }}
                          >
                            设为默认
                          </div>
                        )}
                        <div
                          className={"btn"}
                          onClick={() => {
                            deleteBankcard(item.id);
                          }}
                        >
                          删除
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.def === 1 && <div className="default" />}
                </div>
              );
            })
          ) : (
            <div className={"empty"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: "1.5rem",
                  alignItems: "center",
                }}
              >
                <img
                  src={position}
                  alt=""
                  style={{ width: "80px", height: "100px" }}
                />
                <b>暂无银行卡</b>
              </div>
            </div>
          )}
        </div>
      </div>
      <AddBankCardV1
        visible={addBankcard}
        onCancel={() => {
          setAddBankcard(false);
        }}
        bindAfter={() => {
          getAllBankcard();
        }}
      />

      {changeBankcard && (
        <ChangeBankcard
          visible={changeBankcard}
          editInfo={editCard}
          onCancel={() => {
            setChangeBankcard(false);
          }}
          changeAfter={() => {
            getAllBankcard();
          }}
        />
      )}
    </div>
  );
};

export default observer(BankCard);
