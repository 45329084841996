import { assert, legal } from "@/utils";
import Storage from "../library/storage";

//todo 多语言
export default class Lang {
  #default = "zh-CN";
  #key = "_FK_lang";
  #language = null;
  #file = {};
  #cache = {};
  #match = [];
  #support = {};
  #zendesk = {
    "en-US": "en-us",
  };
  #chart = {
    "en-US": "en",
  };

  constructor() {
    Storage.getItem(this.#key)
      .then((lang) => {
        this.#language = lang ? lang : this.#default;
      })
      .catch((err) => {
        this.#language = this.#default;
      });
  }

  setFile(file, support, async = true) {
    try {
      assert(legal(file), "语言包文件错误");
      assert(legal(support), "缺少语言支持列表");
      this.#match = Object.entries(support);
      this.#support = support;
      if (this.#support[this.#language] === undefined) {
        this.#language = this.#match[0][0];
        Storage.setItem(this.#key, this.#language).catch((err) => console.warn(err));
      }
      for (let [ln, pack] of Object.entries(file)) {
        if (async) {
          this.#file[ln] = pack;
        } else {
          this.#cache[ln] = pack;
        }
      }
      this.#loadFile();
    } catch (err) {
      console.error(err);
    }
  }

  setLanguage(language) {
    if (this.#support[language] === undefined) return;
    if (this.#language === language) return;
    this.#language = language;
    Storage.setItem(this.#key, this.#language).catch((err) => console.warn(err));
    if (window.zE) {
      window.zE("webWidget", "setLocale", this.zendesk);
    }
    // exposure('language_changed');
    this.#loadFile();
  }

  #callback = null;

  setCallback(callback) {
    this.#callback = callback;
  }

  #loadFile() {
    if (this.#cache[this.#language] === undefined) {
      this.#file
        [this.#language]()
        .then((obj) => {
          this.#cache[this.#language] = obj;
          let root = document.createElement("root");
          root.className = this.#language;
          this.#render();
        })
        .catch((err) => {
          console.warn(err);
          setTimeout(() => this.#loadFile(), 2000);
        });
    }
  }

  #render() {
    if (this.#callback !== null) this.#callback();
    // exposure('loaded');
  }

  get language() {
    return this.#language;
  }

  get zendesk() {
    return this.#zendesk[this.#language] || this.#zendesk["en-US"];
  }

  get chart() {
    return this.#chart[this.#language] || this.#chart["en-US"];
  }
}
