import React, { useEffect, useState } from "react";
import "./index.scss";
import Fiat from "./fiat";
import Crypto from "./crypto";
import Store from "@/store";
import { useLocation } from "react-router-dom";
import EVENT from "../../../library/api";

const Recharge = () => {
  const location = useLocation();
  const [type, setType] = useState(0);
  const [list_a, setList_a] = useState([]);

  const { money } = Store.User.assets;

  useEffect(() => {
    if (location.state === 2) {
      setType(1);
    }
  }, []);

  const updateRechargeRecord = () => {
    EVENT.History.depositWithdraw({ type: 100, currencyType: 0 }).then(
      ({ data }) => {
        setList_a(data);
      }
    );
  };

  useEffect(() => {
    updateRechargeRecord();
  }, []);

  return (
    <div className={"Recharge"}>
      <div className="recharge_title">
        <h5>充值</h5>
      </div>
      <div className={"recharge_main"}>
        <div className="switch_btn">
          <div
            onClick={() => {
              setType(0);
            }}
            className={type === 0 ? "active" : ""}
          >
            数字货币
          </div>
          <div
            onClick={() => {
              setType(1);
            }}
            className={type === 1 ? "active" : ""}
          >
            法币
          </div>
        </div>
        {type === 0 && <Crypto />}
        {type === 1 && (
          <Fiat
            bal={money}
            update={() => {
              updateRechargeRecord({});
            }}
            list={list_a}
          />
        )}
      </div>
    </div>
  );
};

export default Recharge;
