import React, { useEffect, useState } from "react";
import "./index.scss";
import { formatDate } from "@/utils";
import EVENT from "@/library/api";

const LoginHistory = () => {
  const [loginList, setLoginList] = useState([]);
  useEffect(() => {
    EVENT.History.getLoginHistory().then(({ data }) => {
      let temp = data;

      if (temp.length === 0) return;
      temp.forEach((element) => {
        element.date = formatDate("m-d-y h:i:s", { date: element.time });
      });
      setLoginList(temp);
    });
  }, []);
  return (
    <div className={"login_history"}>
      <h5>账户活动</h5>
      <div className="history_main">
        <div className="title">登录历史</div>
        <div className={"history_list"}>
          <div className={"list_title"}>
            <div className={"country"}>国家/地区</div>
            <div className={"system"}>系统&浏览器</div>
            <div className={"ip"}>IP地址</div>
            <div className={"time"}>时间</div>
          </div>
          {loginList.map((item, key) => {
            return (
              <div className={"list"} key={key}>
                <div className={"country"}>{item.region}</div>
                <div className={"system"}>
                  <p>{item.agent}</p>
                </div>
                <div className={"ip"}>{item.ip}</div>
                <div className={"time"}>
                  {formatDate("m-d-y h:i:s", { date: item.time })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LoginHistory;
