import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import { Copy } from "../../components/copy/copy";
import { Select, message } from "antd";
import { goodsImg } from "../../components/tool";
import { RESTRICT } from "@/library/standard";
import { formatDate } from "@/utils/index";
import { Link } from "react-router-dom";
import { statusText } from "../../components/tool";
import funds from "../../../assets/images/funds_light.png";
import { useNavigate } from "react-router";
import EVENT from "../../../library/api";
import Store from "@/store";
import { observer } from "mobx-react-lite";
const { Option } = Select;

const Crypto = () => {
  const { money } = Store.User.assets;
  const [dataSource, setDataSource] = React.useState([]);

  const [chainsName, setChainsName] = React.useState("");
  const [rechargeCur, setRechargeCur] = React.useState([]);
  const [localCoin, setLocalCoin] = React.useState("USDT");
  const [chainList, setChainList] = React.useState([]);
  const [localAddress, setLocalAddress] = React.useState("");
  const [rate2, setRate2] = useState("");

  const [rate, setRate] = useState();
  const [min, setMin] = useState(2);

  const navigate = useNavigate();

  useEffect(() => {
    getRate(localCoin);
    EVENT.Payment.getDepositCurrency().then((data) => {
      setRechargeCur(data.data);
    });
  }, []);

  useEffect(() => {
    EVENT.Payment.getChains(localCoin).then((data) => {
      setChainList(data.data);
      setChainsName(data.data[0].chain);

      EVENT.Payment.getCryptoAddress(localCoin, data.data[0].chain).then(
        ({ data }) => {
          setLocalAddress(data.address);
        }
      );
    });
  }, [localCoin]);

  useEffect(() => {
    if (chainsName == "") return;
    message.success("加载中...");

    EVENT.Payment.getCryptoAddress(localCoin, chainsName).then((data) => {
      message.destroy();
      setLocalAddress(data.data.address);
    });
  }, [chainsName]);

  useEffect(() => {
    EVENT.History.depositWithdraw({ type: 100, currencyType: 1 }).then(
      ({ data }) => {
        setDataSource(data);
      }
    );
  }, []);

  useEffect(() => {}, []);

  //todo 获取汇率
  const getRate = (currency) => {
    EVENT.Assets.getCryptoRate({ src: "CNY", des: currency }).then(
      ({ rate }) => {
        setRate(rate);
      }
    );

    EVENT.Assets.getCryptoRate({ src: "USDT", des: "CNY" }).then(({ rate }) => {
      setRate2(rate);
    });
  };

  return (
    <>
      <div className={"crypto_recharge"}>
        <div className={"info_right"}>
          <div className={"select_box"}>
            <div className={"select_coin"}>
              <label>
                <span>选择币种</span>
                <span
                  className="rechargeTips"
                  onClick={() => {
                    navigate("/rechargeTips");
                  }}
                >
                  充币教程
                </span>
              </label>
              <Select
                className={"select_box"}
                defaultValue={
                  <div className={"default"}>
                    <img src={goodsImg[localCoin]} alt="" />
                    <b>{localCoin}</b>
                  </div>
                }
                onChange={(e) => setLocalCoin(e)}
              >
                {!!rechargeCur &&
                  rechargeCur.map((item, key) => {
                    return (
                      <Option key={key} value={item}>
                        <img src={goodsImg[item]} alt="" />
                        <b>{item}</b>
                      </Option>
                    );
                  })}
              </Select>
            </div>
            <div className={"balance"}>
              <label>可用资产:</label> <b>{money}</b> 元{" "}
              <span>
                ≈ {RESTRICT.float(money.mul(rate), 2)} {localCoin}
              </span>
            </div>
          </div>
          <div className={"tips"}>
            <h6>温馨提示</h6>
            <ul>
              <li>
                · 最新参考汇率：1 {localCoin} ≈ {rate2} CNY。
              </li>
              <li>
                · 单笔充值不得低于 {min} {localCoin}，小于此金额将不会成功。
              </li>
              <li className={"tipsImportant"} style={{ color: "#F78C1C" }}>
                ·
                每次充币需在此页面获取充币地址，不按提示操作充值旧地址的资金无法被找回，且平台将无法协助挽回
              </li>
              <li>
                · 请勿向上述地址充值任何非 {localCoin}
                资产，否则资产将不可找回。
              </li>
            </ul>
          </div>
        </div>
        <div className={"info_left"}>
          <div className={"link"}>
            <label>链名称</label>
            <div className={"link_name"}>
              {!!chainList &&
                chainList.map((item, key) => {
                  return (
                    <div
                      className={chainsName === item.chain ? "active" : ""}
                      key={key}
                      onClick={() => {
                        setChainsName(item.chain);
                      }}
                    >
                      {item.chain}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="QR">
            {!!localAddress && <QRCode value={localAddress} />}
          </div>
          {!!localAddress && (
            <div className={"address"}>
              <div>
                <b>{localAddress}</b> <Copy text={localAddress} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="recent_record">
        <div className={"record"}>
          <h5>近期充值记录</h5>
          <Link to={{ pathname: "/account/fundsDetail" }} state={2}>
            查看更多 》
          </Link>
        </div>
        <ul className={"crypto_ul"}>
          <li className={"record_title"}>
            <div>时间</div>
            <div>充值币种</div>
            <div>金额(元)</div>
            <div className={"longer"}>充币地址</div>
            <div>ID</div>
            <div>状态</div>
          </li>
          {!!dataSource &&
            dataSource.map((item, key) => {
              if (key < 5) {
                return (
                  <li key={key}>
                    <div>
                      {formatDate("m-d h:i:s", { date: item.createTime })}
                    </div>
                    <div>{item.currency}</div>
                    <div>{item.money}</div>
                    <div className={"longer"}>{item.address}</div>
                    <div>{item.id}</div>
                    <div>{statusText(item.status)}</div>
                  </li>
                );
              }
            })}
        </ul>
        {!!dataSource && dataSource.length === 0 && (
          <div className={"empty"}>
            <div>
              <img src={funds} alt="" />
              <b>暂无记录</b>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default observer(Crypto);
