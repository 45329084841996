import React from "react";
import { useNavigate } from "react-router-dom";
import "./agreement.scss";
import { HomeHeader,HomeFooter } from "@/view/components/index";


const Agreement = () => {
  const history = useNavigate();

  return (
    <div className="agreement">
    <HomeHeader />
    <div class="page-agree">
      <div class="head">
        <span
          className="return"
          onClick={() => {
            history(-1);
          }}
        >
          返回
        </span>
        <span>用户协议</span>
      </div>
      <div class="wrap">
        <div class="title">前言</div>
        <p>
          1．期货交易涉及潜在的利益与损失，并不能保证绝对的盈利或亏损。若买卖情况不利，损失有可能超过最初投入的金额。期货交易中，价位波动受多种全球性的因素影响。这些因素大多是难以预计的，期货行情的剧烈波动可能导致投资者无法结算失利的买卖。
        </p>
        <p>
          2．请在签署本协议前，小心阅读整份协议。注意：通过网上开户者勾选“我已认真阅读并同意以上协议”所产生的法律效力与亲身签署协议所产生的法律效力相同。香港致远期货有限公司(以下简称「致远金融」)会及时上载最新修订之协议内容，客户有必要自行查阅以保障知情权。
        </p>
        <div class="title">风险披露声明</div>
        <p>
          本项简要声明并不披露关于期货(包括内盘和外盘，统称为“期货”)的所有风险及其他重要方面。鉴于有关风险，客户在参与交易之前，应该了解交易性质及将会面临的风险程度。期货交易并不适合于普罗大众。客户必须根据客户的投资经验、目的、财力和承受风险能力等相关情形仔细考虑金银交易是否适合客户本人。客户在开户及开始交易前应该咨询法律及其他专业意见。
        </p>
        <div class="title">期货交易</div>
        <div class="subtitle">1．保证金交易的风险</div>
        <p>
          期货交易伴随很高风险。相对于期货价格而言，初始保证金的金额可能比较小，这样交易就会被杠杆化。即使市场上出现比较小的变动，也会对客户已经或将要存入的资金产生相对大的影响：对客户也许有利，也许不利。客户可能需要为了保持头寸不被结算而需要承受客户存入本公司的初始保证金及任何追加资金上的损失。如果市场变动对客户不利或导致保证金水平下降，客户有可能无法及时追加保证金来维持头寸而在亏损的情况下被清算，客户将必须对由此造成的亏损自行负责。
        </p>
        <div class="subtitle">2．降低风险的指令或策略</div>
        <p>
          下达某些旨在将损失/利益限制在特定金额的指令(例如“止损”指令，或者“止赢”指令)有可能并不见效或没有执行。
        </p>
        <div class="subtitle">3．交易设施</div>
        <p>
          大部分电子交易的设施是通过以计算机为基础的系统来进行交易指令的传递、执行、匹配、登记和交易清算。与所有的设施和系统一样，他们容易受到临时故障的影响。客户收回某些损失的能力可能受限于：系统提供者、市场、清算所及/或公司设定的有限度责任。这些有限度责任可能各有不同。
        </p>
        <div class="subtitle">4．电子交易</div>
        <p>
          有别于公开喊价买卖的交易运作，电子交易会令客户有机会遇到由于计算机的硬件、软件及互联网传输的失误，而导致客户的指令处于未被执行或完全不会执行的问题，客户必须了解有关风险并不能将第三方风险转嫁至致远金融。
        </p>
        <div class="subtitle">5．佣金及其他费用</div>
        <p>
          在客户开始交易之前，客户应该了解清楚所有佣金、收费及其他应付的费用。这些收费将会影响到客户的凈利润(如有)或增加客户的损失。
        </p>
        <div class="subtitle">6．暂停或限制交易与定价的关系</div>
        <p>
          市场状况(例如流动性)及、或某些市场的运作条例(例如由于价格限制或休市造成的任何期货暂停交易)，有可能增加损失的风险，因为完成交易或平清、对冲头寸已经变得很困难或不可能。再者，相关资产与期货的正常价格关系可能不复存在，缺乏相关资产的参考价格，使“公平”价格难以判断。
        </p>
        <div class="subtitle">7．存放的现金与财物</div>
        <p>
          客户必须熟悉各种有关“为进行当地或外地交易存放的金钱与财物”有哪些保障，特别是在公司资不抵债或破产的时候。客户可收回现金与财物的程度，是受制于特定法例或当地的规则。在有些司法辖区，当清债出现亏空时，特地标明为客户所有的财物将与现金一起被按比例加以分配。
        </p>
        <div class="subtitle">8．不可抗力的风险</div>
        <p>
          客户须知如果交易活动被不可抗力干扰时，客户的订单可能无法执行，或在相对通常情况不足的条件下被执行。
        </p>
        <p>不可抗力的情况包括但不限于：</p>
        <p>（1）行情来源被暂时关闭、损坏或其他人和情况下关闭停止运作；</p>
        <p>（2）在相应交易市场上，期货价格有异常变化或丧失流动性；</p>
        <p>
          （3）宏观经济数据报告的发表，或其他政治经济等方面的信息公布，对期货价格有显着影响；
        </p>
        <p>
          （4）因硬件、软件的损坏，导致电子交易系统不能正常运作，香港致远期货有限公司不承担任何责任；
        </p>
        <p>（5）由于网络供货商的失误导致互联网连接失败；</p>
        <p>（6）国家行政部门的决定和命令；</p>
        <p>（7）电讯系统的瘫痪；</p>
        <div class="title">客户告鉴</div>
        <p>本协议具有法律约束力，请仔细审阅。</p>
        <p>
          本法律合约乃由香港致远期货有限公司(以下简称「致远金融」)；一家在香港特别行政区法律下成立的有限公司，其承继人或转让人，与本合约的合约方共同订立(下称“客户”)。关于在致远金融开立账户以便通过增加杠杆进行期货交易。客户确认已了解下述有关杠杆交易的因素，以及为客户提供的风险披露声明。
        </p>
        <p>
          （1）杠杆交易仅适合于专业机构或投资经验丰富的人士，其财力可以承受也许远超过保证金或存款金额的损失而并不干扰其正常生活。
        </p>
        <p>
          （2）尽管许多以计算机为基础的系统提供报价和实际价格，这两者可能因为市场的缺乏流动性而有所差异。许多电子交易设施是以计算机为基础的系统来支持进行下单、执行、匹配。客户确认任何由电子交易服务系统所提供的信息是由数据来源方(第三方)按照“现况”或“当时可供使用”的基础上提供的，客户需承担因依据有关信息所涉及的风险。致远金融不会确认、保证或担保该等信息的实时性、次序、准确性、充分性、连续性或完整性。同时，致远金融不会就该等信息作出任何明示或暗示的保证(包括但不限于该等信息的商用性或就某项用途的适用性)。
        </p>
        <p>
          （3）无人能保证客户的交易对手的信誉。致远金融将会尽力只与具备良好声誉的机构和清算所进行交易。此外，有可能出现这样的情况：即交易流动性的降低造成期货交易停止，从而妨碍平清不利的头寸，由此可能带来相当的财政损失。致远金融不会就这些损失承担任何责任。
        </p>
        <p>
          （4）客户确认购买或卖出期货包括进行交割，每次交易都进账到客户在本公司开立的交易账户。
        </p>
        <p>
          （5）致远金融的保证金政策，以及执行交易的机构/清算所的政策可能要求客户提供追加资金以便维持其保证金账户，客户有义务满足这类保证金要求。否则将可能带来头寸的清盘及相应的损失。致远金融保留拒绝接受订单的权利或提供市场对冲。
        </p>
        <p>
          （6）在某一电子交易系统的交易可能不仅与银行同业市场的交易不同，也可能不同于在其他公司的电子系统进行的交易。如果客户在某一电子平台从事交易，客户将面临与该系统相关的风险，包括硬件和软件的故障。系统故障可能造成客户的订单难以按照客户的指示执行或根本不能执行。客户需承担因而引致的一切损失。
        </p>
        <p>
          （7）倘若客户将其账户的管理权或交易权交予第三者 (下称“介绍人”)
          ，不论是以自主权或非自主权的基础上，致远金融将不对客户作出的选择负责或对此作出任何推荐。致远金融不对有关交易介绍人作出任何声明或保证；致远金融不对因为交易介绍人的行为而对客户产生的损失负责；致远金融不对交易介绍人的运作方式作出任何间接或直接的支持或批准。如果客户授权客户介绍人管理其账户，客户需自行承担风险。
          倘若客户未获本公司同意将交易授权或对其账户的管理交予介绍人，致远金融保留对有关账户所有或部份相关的交易进行取消的权利，而不需事先通知客户。
        </p>
        <p>
          （8）所有客户必须意识到任何“回报”均非保证。此外，除非有与本公司记录相符的文字记录，致远金融不对任何致远金融丶其雇员及/或关联人作出的声称或保证负责。
        </p>
        <div class="title">免责声明</div>
        <div class="subtitle">1．国际互联网故障</div>
        <p>
          由于致远金融没有控制信号数据的能力，信号数据经由互联网传送的稳定性，是需要依赖客户设备的结构或连接的可靠性，致远金融不会对互联网上交易中出现的通讯故障丶失真或延迟负责。
        </p>
        <div class="subtitle">2．市场风险和网上交易</div>
        <p>
          期货交易涉及相当大的风险，并非适合每个人。请参照客户协议书了解风险的详细介绍。不论网上交易多么方便或有效率，这并不降低期货交易的风险。
        </p>
        <div class="subtitle">3．密码保护</div>
        <p>
          客户明白并确认客户的交易密码须绝对保密，客户是交易密码的唯一授权用户。同时，客户同意就任何利用其交易密码而向致远金融发出的交易指令承担全部责任(不论是否由客户授权并经致远金融批准)。交易商不须对客户因执行交易指令而引致的损失负责。若客户将交易密码透漏给第三者，不论密码透漏是蓄意的、非故意的或错误的，客户必须对所有交易、损失、费用及支出负责，而致远金融无须为任何经核实密码后执行的交易所引致的损失、费用及支出负责。
        </p>
        <div class="subtitle">4．报价错误</div>
        <p>
          当某些报价或成交价发生错误时，致远金融将不为此错误所导致的账户余额错误负责。这些错误包括但不限于：交易员的错误报价、非国际市场价之报价，或是任何报价错误(例如：硬件，软件或网络之问题，或是第三者所提供之错误数据)。致远金融不需为错误所导致的账户余额负责。下单时需预留足够的时间让系统执行订单和计算所需的保证金。若订单的执行价格或订单的设定和市场价格过于接近，将可能会触发其他订单（不论是哪种订单类型）或发出保证金提示。致远金融不会对由于系统没有足够时间执行订单或进行运算所产生的保证金提示、账户结余或账户仓位负责。上文不得视作内容全部，一旦发生报价或执行错误，致远金融保留作出任何更正或调整的权力，任何有关报价与成交错误之争执只能由致远金融完全自主决定解决。若因此使致远金融有任何损失丶损害或责任，客户同意予以赔偿使致远金融使其不受损害。
        </p>
        <div class="subtitle">5．套戥</div>
        <p>
          互联网丶联机延误及报价上的误差有时会造成在致远金融交易平台上显示的报价无法准确地反映实时市场价格。"套戥"及"切汇"，或因网络联机的延误而利用差价获利的行为，并不能存在于期货交易市场中。致远金融期货不允许客户在本公司的交易平台上进行此等套戥行为。依靠因价格滞后带来的套戥机会所进行的交易有可能会被撤销。致远金融期货保留权利对涉及上述交易的账户进行必要的修改和调整。致远金融可全权酌情决定要求交易员进行干预或核淮所有下单以及或终止有关客户的账户。致远金融可完全自主解决因套戥或操控价格而产生的纠纷。致远金融冻结客户取款的权利直至以上的问题能够解决。在此陈述的任何行动或决议并不免除或损害致远金融对客户和其职员拥有之权利或赔偿。
        </p>
        <div class="subtitle">6．价格丶订单执行及平台操控</div>
        <p>
          致远金融严禁任何账户以任何形式对其价格丶执行及平台进行操控(包括但不限于客户未经致远金融批准进行的第三者交易)。若致远金融怀疑任何账户从事操控，致远金融保留对账户进行调查及复核的权利，并从涉嫌账户中扣除由相关活动所赚取的盈利款项。致远金融保留对相关账户进行必要更正或调整的权利。对于涉嫌从事操控的账户，致远金融可全权酌情决定，要求交易员进行干预丶对下单进行核准以及或终止有关客户的账户。对于由套戥以及或操控所产生的任何纠纷，由致远金融完全自主解决。致远金融可酌情决定向任何相关监管机构或执法机构报告有关事件，而不会就事件报告通知相关客户。此处所陈述的任何行动或决议并不免除或损害致远金融对客户和其职员拥有之权利或赔偿，所有均为明确保留的权利或赔偿。
        </p>
        <div class="subtitle">7．破产披露</div>
        <p>
          客户与致远金融进行的交易并不是在交易所进行。一旦致远金融期货破产，客户向致远金融期货追回有关存入资金或在交易中赚取的利益，可能不会得到优先偿还权。没有优先偿还权，客户就是无抵押债权人，会在偿付那些优先索偿后才与其他债权人获得补偿。
        </p>
        <div class="title">介绍人披露</div>
        <p>
          致远金融并不监管介绍人的活动，不对介绍人作出的任何声明承担责任。致远金融和介绍人相互完全独立。致远金融和介绍人直接的协议并不建立合资企业或合伙企业关系。介绍人不是致远金融的代理人或职员。
        </p>
        <p>
          （1）客户了解并同意如果客户在致远金融的账户是由介绍人引荐而来，介绍人可能获得客户的个人资料及其他有关客户在致远金融账户交易活动的数据。客户了解并同意，如果客户在致远金融的账户是经介绍人引荐而来，介绍人不得以客户的致远金融账户从事交易。除非客户通过书面协议授权该代理人代表客户进行交易。
        </p>
        <p>
          （2）因为期货交易的风险因素很高，只有真正的“风险”资金可以用于这类交易。如果客户并没有闲置资金可供损失，客户不应在期货市场上交易。
        </p>
        <p>
          （3）客户理解介绍人或很多出售第三者的交易系统丶课程丶程序丶研究或建议可能不受政府机构监管。
        </p>
        <p>
          （4）如果客户以前被告知或相信使用任何第三者的交易系统，课程丶程序丶或由介绍人或其他第三者提供的研究或建议会带来交易盈利，客户在此确认、同意和理解所有期货交易，包括通过任何第三者的交易系统丶程序丶或由介绍人或其他第三者提供的研究或建议进行的交易涉及很大的损失风险。此外，客户在此确认、同意和理解所有期货交易，包括通过任何第三者的交易系统丶课程丶程序丶或由介绍人或其他第三者提供的研究或建议进行的交易并不保证带来盈利，亦不一定可以避免风险或限制风险。
        </p>
        <p>
          （5）如果介绍人或其他任何第三者向客户提供期货交易的信息或建议，致远金融绝不对客户使用该信息或建议带来的损失负责。
        </p>
        <p>
          （6）客户确认致远金融及与其相关的任何人士不会就客户账户未来的盈亏作出任何许诺。客户明白期货交易有很大风险。
        </p>
        <p>
          （7）致远金融会在新客户开立账户时提供风险披露信息，客户必须仔细阅读这些信息，不得倚赖任何源出别处的相反意向信息。客户在本平台进行交易将被视为已阅读及理解致远金融的风险声明。
        </p>
        <p>
          （8）对于客户已经或将会从介绍人或其他任何非致远金融雇员获得的信息或建议，致远金融均不能控制，也不会支持或担保其关于期货交易的准确性或完备性。
        </p>
        <p>
          （9）致远金融不支持或担保介绍人所提供之服务。由于介绍人不是致远金融的职员或代理人，所以客户有责任在享用其服务前验证和严格评估该介绍人。
        </p>
        <p>
          （10）客户声明及保证其并非美国公民/居民（作为缴税或其他目的），亦并非以其他方式就任何目的而言与美国有所关联，亦并非以代表任何美国公民/居民/实体的代理人身份行事。倘若上述之声明及保证有任何改变，客户承诺在该改变时立即以书面通知本公司。
        </p>
        <div class="title">客户协议</div>
        <p>
          致远金融同意客户开立持有一个交易账户，并可能通过或透过客户的致远金融账户向客户提供有关买卖期货品种（如上文客户告鉴里所界定）。
        </p>
        <div class="subtitle">1．条款与标题</div>
        <p>
          "致远金融"一词包括香港致远期货有限公司，其分部，继承人和转让人。"客户"一词乃指订立本协议的一方(或多方)。"协议书"一词包括所有客户在任何时候为维持其致远金融账户而订立的其他协议或给予的授权。本协议的段落标题是为查考便利而加入，并不限制或影响段落条文的应用与意义。
        </p>
        <div class="subtitle">2．约束效力</div>
        <p>
          本协议（包括风险披露声明丶客户告鉴丶免责声明丶本客户协议以及开户申请表）将持续有效，并涵盖客户任何时候在致远金融开立或重新开立的所有账户，不论任何致远金融或其他继承人丶转让人或关联机构的人事变动。如果发生合并丶兼并或其他变动，本协议(包括任何授权)将适应致远金融或其他继承人或转让人的利益，并对客户及其/或其遗产继承人丶委托人丶管理人丶法定代表丶继承人和转让人具人约束力。客户只有在确认本协议之后才可在致远金融进行交易，并同意客户与此交易有关的权利或义务受本协议条款的管辖。
        </p>
        <div class="subtitle">3．协议接受</div>
        <p>
          仅当致远金融确认及批核后，本协议方可被视作已为致远金融所接受或成为客户与致远金融期货之间具有约束力的合同。
        </p>
        <div class="subtitle">4．政府、对手机构及银行间系统规条</div>
        <p>
          所有本协议下的交易均受辖于执行交易的对手机构或其他银行间市场（及其清算组织，如适用）的宪章丶细则丶条例丶规定丶习惯丶用法丶裁决和解释，并执行所有适用的香港法律。如果此后通过的任何法令，或任何政府机构通过的任何条规，对致远金融产生约束力、影响或冲突到本协议的任何条款，受到影响的条款将视作被有关法令丶条规变更或替代，而其他条款及变更后的条款将继续完全有效。客户确认本协议下的所有交易受辖于前述监管要求。
        </p>
        <div class="subtitle">5．交叉交易的同意</div>
        <p>
          客户在此确认并同意下述情况有可能出现：即与致远金融相关的某一营业人员丶董事丶关联机构丶关联人丶雇员丶银行或银行雇员丶交易商及致远金融本身可能是客户账户进行的交易的对手经纪人或委托人。客户在此同意进行上述交易，仅有的限制是有关执行买卖订单的银行丶机构丶交易所或交易委员会的任何可能的条例或规定，或其他监管机构的任何可能的限制与条件。
        </p>
        <div class="subtitle">6．账户清偿与欠款偿付</div>
        <p>如发生下列情形：</p>
        <p>（1）客户死亡或司法宣定无能力；</p>
        <p>
          （2）客户申请破产，或选派托管人，或客户自动地或被动地进行任何破产或类似的诉讼；
        </p>
        <p>（3）扣押客户在致远金融开设的任何账户；</p>
        <p>
          （4）保证金不足，或致远金融确定任何用于保护客户交易账户的担保品不足以担保该账户，不论当时的市场报价如何；
        </p>
        <p>（5）客户未能向致远金融提供任何根据本协议要求的信息；</p>
        <p>
          （6）任何其他致远金融应当采取保护措施的情况或变化，致远金融有全权酌情决定采取以下某种或多种行动：
        </p>
        <p>
          （一）用致远金融代为客户保存或控制的资金或财产来抵偿客户直接或因提供担保而对致远金融负有的债务；
        </p>
        <p>（二）买卖任何为客户持有的期货头寸；</p>
        <p>
          （三）取消任何或所有未完成订单，或其他任何以客户名义作出的承诺。
        </p>
        <p>采取任何上述行动可能并不以下列为条件：</p>
        <p>
          （1）即要求客户提供保证金或追加保证金，或事先将买卖决定通知客户丶客户的个人代表丶继承人丶委托人或转让人等丶且不论涉及的所有权利益是否为客户独有或与他人合有。
        </p>
        <p>
          （2）为了建立致远金融判断认为有益于保护或降低客户已有头寸的差价或同价对敲，致远金融有权平清客户的多头或空头头寸。
        </p>
        <p>
          （3）根据致远金融的判断及酌情权，在此所述的买卖行为可以通过任何银行间或其他经常进行业务的交易市场进行，或公开拍卖私下出售，致远金融可以购买全部或部分而不受赎回权的限制。
        </p>
        <p>
          （4）一经致远金融要求，客户将在任何时候对其账户的欠款负责，且当其账户被致远金融或其自己全部或部分平仓之时丶在任何时候均对其剩余欠款负责。如果根据本授权进行的平仓所实现的资金不足以支付客户向致远金融所欠的债务，一经要求，客户将立即支付欠款丶所有未偿还债务丶以及相应利息(计算方式如下：在主要银行提供的优惠利率的基础上再加3%或法律规定的最高利率，选择较低的一项)，以及所有托收费用，包括律师费丶证人费丶差旅费等。如果致远金融因为客户的账户支付了除追收欠款费用以外的其他费用，客户亦同意支付该类费用。
        </p>
        <div class="subtitle">7．风险承担</div>
        <p>
          客户明白投资于杠杆或杠杆式交易是投机性的，涉及高度风险，只适合能够承担超过其保证金存款损失风险的人士。客户理解由于期货交易通常要求的保证金较低，期货商品的价格变动可能带来相当大的损失，该损失可能超过客户的投资和保证金存款。客户保证其愿意且能够在财务上或其他方面承担期货交易的风险，客户同意不会就因遵循致远金融或其雇员丶代理人或代表人作出的交易推荐或建议，而造成的交易损失追究致远金融的责任。客户认识到不可能保证期货交易会获得盈利或不受损失。客户承认其未从致远金融；或其任何代表人；或介绍人；或其他客户处获得盈利保证，并且未根据任何上述保证来订立本协议。
        </p>
        <div class="subtitle">8．香港致远期货有限公司的责任</div>
        <p>
          致远金融将不负责因传输或通讯设施故障丶电力短路或任何其他致远金融所不能控制或预计的原因带来的指令传输的延迟。致远金融将仅对直接因为致远金融期货的过失丶蓄意过错或欺诈造成的行为负责。由致远金融按本协议雇用的任何介绍人或其他参与人的过失所引起的损失，致远金融将不负责。
        </p>
        <div class="subtitle">9．通讯联络</div>
        <p>
          报告丶报表，通知及其它通讯可能送达至客户的电子邮件或申请表上客户的地址。所有如此送出的通讯联络，不论是邮寄丶电报或其他方式，一旦投入有关邮政机构，或经发送机构收受，即被认定已由致远金融期货发出，且被认定已送达客户本人，不论客户是否实际收到。
        </p>
        <div class="subtitle">10．保证金存款和取款安排</div>
        <p>
          客户同意当致远金融作出追补资金的要求时，迅速以致远金融所要求的转款方式满足所有保证金要求，而任何汇款及转款过程涉及的费用，包括银行手续费丶汇率差价及所有有关费用均由客户负责。客户完全理解致远金融需要时间去处理有关客户之存款，客户很可能不可以实时使用刚存入的保证金，更可能不可以作为追加保证金，客户同意承担一切由于未能及时满足追加保证金要求而面临的强制平仓之损失，其损失可以超过客户最初投入的初始保证金。此时客户交易账户中的金额可能为负数，但是致远金融有权不追讨客户上述部分的负数金额。致远金融可能在任何时候根据本协议的规定清算客户的账户，即使致远金融不行使该项权利，并不代表其放弃了该权利。任何致远金融过去的保证金要求均不妨碍致远金融不需通知而提高上述保证金要求。
        </p>
        <p>
          客户完全理解致远金融需要时间去处理有关客户之取款，客户很可能不可以实时收到所提取的金额。客户同意不追究致远金融一切由于未能及时满足自身取款要求所引起的责任。客户确认一旦下达取款要求，致远金融将实时从客户账户结余中扣除该款额。
        </p>
        <p>
          客户必须确保其所指定的往来银行账户属于客户本人，致远金融均不接受任何第三者(包括但不限于介绍人)存取款。客户提交存款证明并确认为第三者存款者，致远金融完全拥有权利对客户账户进行以下处理:
        </p>
        <p>
          实时涷结客户账户进行调查，禁止其账户进行一切交易。并依据酌情权决定向任何相关监管机构或执法机构报告有关事件;
        </p>
        <div class="subtitle">11．协议修改</div>
        <p>
          客户理解丶确认并同意致远金融期货可能不时修订本协议之条款，致远金融期货会把这些修改或变更在本公司网站上进行公布。客户应定时浏览有关条款之修改并同意受此约束，客户理解和同意致远金融期货不会为其另行发放新的客户协议书。而不论客户之前是否已经在网站上签署或亲身领取了客户协议书，所有旧有的条款将被视作被新的条款变更或替代，而其他条款及变更后的条款将继续完全有效。
          任何协议双方的交往过程，或因致远金融期货或其他代理人在任何情况或一系列情况下未能坚持其协议项下的权利均不可间接解释为权利的放弃或更改。任何口头协议或指示均不可被承认或执行。
        </p>
        <div class="subtitle">12．终止</div>
        <p>
          本协议将始于有效直至终止，而客户可在任何时候终止协议，只要届时客户没有持有未平仓，不对致远金融负有任何债务，且致远金融办事处实际收到终止通知；或任何时候致远金融向客户传递终止通知，由发出通知那日收市时开始生效，条件是如此终止将不影响任何之前签订的交易且不解除任何一方此协定项下的任何义务，或解除客户引起任何欠款的责任。如客户在长时期内没有交易及/或存款,
          有关帐户将被视作为不活跃账户。介时会将有关账户设为休眠状态,
          而不另行通知。详情请与在线客服查询。
        </p>
        <div class="subtitle">13．赔偿</div>
        <p>
          客户同意，如果因为客户未能完全与及时地履行其承诺或因其声明或保证并不属实或正确，而给致远金融带来了任何债务丶损失丶损害丶成本或费用，包括律师费，客户将对此向致远金融，其有关机构丶雇员丶代理人丶继承人及转让人予以赔偿并使之不受损害及损失。客户同时同意立即支付给致远金融在执行本协议任何条文时带来的损害丶成本与费用，包括律师费。此外，假如损失来自：
        </p>
        <p>（1）客户的行为：客户或授权人的行动或他们的遗漏；</p>
        <p>
          （2
          ）伪造签名：所有账户或本协议有关文件上的伪造签名或未获授权的签名；
        </p>
        <p>
          （3）故障：系统故障丶设备故障或系统中断或系统脱供（不论是客户或是网络传输的设备）;
        </p>
        <p>（4）延迟：在实施任何指示时发生之延迟丶故障或错误；</p>
        <p>
          （5）资料：从客户收到的不正确或不全的指示，致远金融期货均不会负任何责任或赔偿损失。
        </p>
        <div class="subtitle">14．交易推荐</div>
        <p>
          （1）任何由致远金融或其他任何公司内部人员向客户提供的市场推荐和信息并不构成一项购买/出售期货商品；
        </p>
        <p>
          （2
          ）此类推荐和信息，尽管基于致远金融认为可靠的数据源，有可能完全基于某一经纪人的意见，故这类信息可能并不完备或未经确认；
        </p>
        <div class="subtitle">15．客户声明与保证</div>
        <p>（1）客户头脑健全丶到达法定年龄，具有法律能力；</p>
        <p>（2 ）仅客户本人享有对客户账户的利益；</p>
        <p>
          （3）客户在此保证不论此后任何相反的裁决，除了i.所述，客户有足够能力进行期货交易；
        </p>
        <p>
          （4）客户目前不受雇于任何交易所丶任何交易所持有绝大部分资本的公司丶任何交易所的成员及或任何在交易所注册的公司丶任何银行丶信托机构或保险公司，一旦客户接受上述雇用，客户必须立即以书面形式通知致远金融；
        </p>
        <p>
          （5）所有在此协议开户申请表部份提供的信息均至本日期止真实丶正确和完备，客户将迅速通知致远金融任何讯息变化；
        </p>
        <p>
          （6）客户应完全遵守当地有关法例，包括遵守该等地区或司法管辖区内须遵守之任何其他手续而取得政府或其他方面之同意，以及因为使用本平台进行交易而需要支付当地任何相关税项丶关税及其他金额。客户在本平台进行交易，将被视为该客户向致远金融声明及保证已遵守当地法律及规定。如果客户对情况有疑问，务请向专业顾问查询。
        </p>
        <div class="subtitle">16．财务讯息</div>
        <p>
          （1）客户声明并保证向致远金融披露的财务讯息准确地表达了客户目前的财务情况。
        </p>
        <p>
          （2）客户进一步声明并保证在确定其凈值时，资产与负债已被仔细计算，并将负债从资产中扣减来确定客户在财务讯息中提供的凈值。
        </p>
        <p>
          （3）客户声明并保证在确定资产价值时，客户包括了现金及或现金等值品，和可以流通证券，自有房产(不包括主要住宅)，人寿保险的现金价值及其它有价资产。
        </p>
        <p>
          （4）客户声明并保证在确定负债时，客户包括了应付银行的本票(担保或非现担保)，应付亲属的本票，应付房产抵押贷款，(不包括基本住所)及其它债券。
        </p>
        <p>
          （5）客户声明并保证在确定其流动资产时，客户仅包括能迅速(一天时间以内)变现的资产。
        </p>
        <p>
          （6）客户声明并保证其已非常仔细地考虑了客户资产中可为风险资本的部分。
        </p>
        <p>
          （7）客户保证及声明风险资本是指如此金额的资金，即客户愿意将其投入风险之中，且即使损失也不会对客户的生活方式带来任何改变。
        </p>
        <div class="subtitle">17．不保证盈利或限制损失</div>
        <p>
          客户保证及声明其未有与客户的介绍人或任何致远金融雇员或代理人就其致远金融期货账户的交易签订任何单独协议，包括任何保证其账户盈利或限制损失的协议，客户同意其有责任以书面形式立即告知致远金融任何此类协议。此外，客户同意如果任何人士作出的任何有关交易账户的声明有异于客户从致远金融获得的表述，客户同意以书面形式提请致远金融的注意。如果因客户未能及时将有争议的交易告知致远金融而造成其损失，客户同意赔偿致远金融以使其不受损害。本条款下的通知需要送往致远金融的办事处。
        </p>
        <div class="subtitle">18．信贷报告</div>
        <p>
          客户授权致远金融，或代理人以致远金融期货的名义，调查客户的信用状况并为此联系致远金融认为与证实客户资料有关的合适的（所有）银行丶金融机构和信用机构。客户进一步授权致远金融期货调查其目前和过去的投资活动，并为此联系致远金融期货认为合适的期货交易商、交易所、经纪人/交易商、银行、及法务信息中心。如果客户以书面形式向致远金融作出请求，客户可被允许复印上述记录，费用完全由客户承担。
        </p>
        <div class="subtitle">19．录音</div>
        <p>
          致远金融有权在有或没有使用自动声音警告装置的情况下用电子仪器纪录客户与致远金融期货或其代理的电话谈话，而此纪录及此记录的复制版本可用作任何用途，包括核证客户之指令。此纪录的产权绝对归致远金融期货独有。客户同意，倘若出现纠纷，任何一方可以使用此类录音或此类录音的复制版本作出证据。客户同时承认致远金融无责任长期保留该记录及该纪录的复制版本，而致远金融有绝对酌情权厘定该纪录及该纪录的复制版本的保留期限。如果发生任何不可抗力事件或非致远金融所能控制的情况包括但不限于盗窃、火灾、天灾、设备之技术性失灵等而导致致远金融遗失或不再管有该纪录及
          /
          或该纪录的复制版本，致远金融概不就客户的期货合约所涉及的任何损失或支出负上任何责任或义务。
        </p>
        <div class="subtitle">20．司法管辖区及司法管辖地的同意</div>
        <p>
          凡因本合同或与本合同有关的争议丶争执或索偿丶违约终止或合同无效等均应通过仲裁解决。仲裁在发生仲裁那日开始按目前有效的联合国国际贸易法委员会的仲裁规则进行。指定仲裁员的机构是香港国际仲裁中心,指定地点是位于香港的香港国际仲裁中心。仲裁由香港国际仲裁中心按照开始仲裁时有效的香港国际仲裁中心的仲裁程序规则进行。仲裁程序以英语进行。
        </p>
        <div class="subtitle">21．权利转让</div>
        <p>
          根据此项协议，致远金融可授权其全部或部份的权利或义务予任何人，而无需经过客户的事先同意或批准。
        </p>
        <div class="subtitle">22．高风险投资</div>
        <p>
          除这项协议所载的标准风险披露外，客户应注意以保证金为基础的期货
          期货交易是金融市场上最具风险的投资方式之一，且仅适合于有经验的投资者和机构。在致远金融开立的账户允许客户以很高的杠杆比率进行期货交易。鉴于存在损失全部投资的可能性，在期货交易市场进行投机的资金必须是风险资本金，其损失将不会对客户个人或机构的财务状况产生太大影响。
          如果过去客户只曾投资于低风险的投资工具，客户可能需要在正式买卖之前学习期货交易。客户需要认识到假如在交易期货时市场走势并不如客户所预料时，客户有可能损失所有存放在致远金融作为初始保证金的资金。如果客户希望继续客户的投资，客户必须确认客户的资金是纯风险资本金，这些资金的损失并不会危害到客户的生活方式或损害客户的未来退休计划。此外，客户完全明白期货投资的性质和风险，客户在投资时承受的损失不会影响到第三者。
        </p>
        <div class="subtitle">23．数据确认</div>
        <p>
          若客户的开户申请表上的数据有任何更改，客户有责任立即通知致远金融有关的更改。
        </p>
        <div class="subtitle">24．私隐政策</div>
        <p>
          致远金融私隐政策是根据个人资料（私隐）条例保护客户权益，便利客户开设和维持期货户口，提供融资和金融顾问服务。致远金融忠诚地为客户所提供的个人资料，作出保密的监控。除了得到法例批准，致远金融绝对不会把任何非公开性的数据给予任何个别人士。
        </p>
        <p>
          当客户在致远金融开立或维持一个交易账户时，所提供的个人资料，只会作公司的内部商业用途，例如评估客户在财务上的需要，处理客户的交易以及其他要求，提供有关产品与服务，提供一般交易上的服务及按监管程序需要确认客户身份。
        </p>
        <p>致远金融需要客户提供以便运作的数据报括：</p>
        <p>
          （1）有关致远金融的账户申请表格以及其他表格上所要求提供的个人资料，例如姓名、地址、出生日期、身份证号码、职业、资产以及收入数据等；
        </p>
        <p>（2）有关客户在致远金融以及其附属公司的事务数据；</p>
        <p>（3）有关客户调查报告公司的资料；</p>
        <p>（4）有关核实客户身份的资料，例如政府文文件, 护照或驾驶执照。</p>
        <p>
          致远金融只会把客户所提供的数据，有限地让服务客户的雇员查阅，以便提供相关的客户服务和产品介绍。这些程序上的要求，都是为了保障客户的非公开性数据不会受到公开，保护客户的私隐。致远金融绝不会把客户的姓名和个人资料，销售或租借与任何人士。
        </p>
        <div class="subtitle">25．有关Cookies</div>
        <p>
          Cookies是在客户硬盘上的一个追踪设备，能追踪及储存客户使用网上服务的有关数据。致远金融可能会在客户的计算机设定及存取致远金融Cookies，以协助致远金融了解哪些广告和推销吸引客户浏览致远金融期货的网站。致远金融及其分支部门可能会于致远金融的产品和服务中使用Cookies来追踪客户在致远金融网站的浏览，收集得来和共享的资料是不具姓名及无法被个别辨识的。
        </p>
        <p>
          致远金融采用 Secure Socket Layer
          (SSL)编成密码技术来保护客户所提供的数据。这种技术能保障客户的数据于传送往致远金融途中免受他人拦截及盗取。致远金融致力确保网站是安全及符合业界标准，并且使用其他数据保障工具，例如：防火墙丶认证系统（密码和个人身份证号码等）和操控机制来控制未获授权的系统进入和存取数据。
        </p>
        <p>
          致远金融提供的一些金融产品和服务，可能需要和第三方服务提供商以及一些不隶属致远金融的推广公司共享上述的个人资料，第三方服务提供商包括以合约形式代表致远金融提供服务的公司，例如提供短信服务的公司，维护及开发数据处理的软件公司等。这些第三方公司的保密守则与致远金融不尽相同，如果是由于第三方公司的任何原因导致客户数据泄露，致远金融概不就客户因此所涉及的任何损失或支出负上任何责任或义务。
        </p>
        <p>
          另外，若因法律的要求允许致远金融向监管机构披露个人资料。例如：如有必要遵从法庭的传令或其他官方要求，或为保障致远金融的权益和财产，致远金融需要和监管机构或法律执行机构合作时可以披露个人资料。向不隶属致远金融的第三者披露非公开的个人资料前会先通知客户有关私隐政策，会给予客户足够时间退出参与数据披露。公布新类别的个人资料前丶新类别的非个人资料前丶向新的第三者（不隶属致远金融期货）披露数据前，致远金融会向客户提供修订的私隐政策和新的退出参与通知。所有致远金融雇员执行政策时会受到合理的监管确保遵守法则。
        </p>
        <div class="subtitle">26．仲裁协议</div>
        <p>
          任何客户与致远金融之间有关客户的争端，将根据本协议的仲裁方式解决。任何由该仲裁作出的裁决将作为最终裁决并具有约束力，任何拥有司法管辖权的法院均可依法执行。
        </p>
        <div class="subtitle">27．管辖法律及司法管辖权</div>
        <p>
          本协议及协议方的相应的权利与义务受致远金融所处的香港特别行政区法律的管辖，并据此解释与执行。据此并没有和法律抵触而干预或妨碍条文之应用。
        </p>
        <p>（一）充值</p>
        <p>
          （1）客户可根据本公司网站上提供之充值方式为客户之交易账户存入资金。
        </p>
        <p>
          （2）除非本公司核实确认收到客户之充值资金，否则本公司将无法为客户办理充值。
        </p>
        <p>（二）取款</p>
        <p>
          （1）所有由客户提交之证明文件和复印件均默认是真实有效，为避免争议，致远金融不接受客户修改已绑定的个人资料，致远金融保留最终确认修改的权利。
        </p>
        <p>（2）致远金融将于收到取款申请起2小时内处理并将该笔款项汇出。</p>
        <p>
          （3）鉴于第三方原因，银行处理款项速度非致远金融可控制及预计的。因此客户很可能无法在2小时内收到所提取的金额。请参阅客户协议第13条保证金存款及取款安排。
        </p>
        <p>
          （4）无论是处于开市抑或休市时间，若客户交易账户中仍有未平仓订单，为了降低客户交易订单被强行平仓的风险，客户在取款时不可全额取款，必须保证交易平台中“可用保证金”一栏的金额不等于零。
        </p>
        <p>（三）汇率</p>
        <p>
          所有非美元存款，均会被兑换成美元。客户如欲以人民币取款，致远金融将会按照当日银联汇率将其美元兑换成其指定货币。致远金融有权根据市场情况，在无需预先通知客户的情况下，更改上述汇率。
        </p>
        <p>（四）交易编码</p>
        <p>（1）建仓/平仓交易编码和客户之订单号是唯一的丶不可重复的。</p>
        <p>
          （2）客户如需取消接收平仓交易编码，可向致远金融提出申请。所有的申请均需以下述条件为前提：客户的交易账户中所有未平仓之交易订单均已平仓；
        </p>
        <p>（五）销户</p>
        <p>
          倘若客户需要关闭账户，请向致远金融的雇员提出申请，客户需确保账户内并未持有任何未平仓头寸，致远金融将在收到申请的24小时内予以处理。
        </p>
        <p>（六）互联网风险披露声明</p>
        <p>
          由于互联网之间的信号丶其接收或线路丶其设备/系统之设定或其连接系统之可靠性，均并非致远金融控制范围以内，故致远金融将不可能就通过互联网交易时出现的通讯故障丶失实或延误而负责。期货交易具有相当大的风险，并非适合每个人。不论网上交易如何方便丶快捷，亦不可能减低有关交易时所带来的风险。客户确认期货交易的现货价格乃因机构而异，并且于分秒间亦会随时出现变化，原因包括(但不限于)数据传送上存在时差，故有时甚至不能根据所公布的价格进行交易。因此，客户同意接受致远金融不时提供予客户的价格，乃当时所能取得之最佳价格。
        </p>
        <p>（七）网上开户</p>
        <p>
          如客户于致远金融网站之开户申请表中勾选“我已认真阅读并同意以上三个协议”，与书面形式的客户协议书具有同等法律效力。
        </p>
        <p>（八）财务数据和信息</p>
        <p>
          客户同意：在未经致远金融书面同意前，不会将透过电子交易服务获取的财务数据或其他信息在其日常业务中使用，亦不会向他人分发、复制、出版或散播该财务数据或其他信息。若客户违反本条款，客户将须承担引致致远金融的一切损失及损害。
        </p>
        <p>（九）交易平台维护</p>
        <p>
          致远金融认为在有需要的情况下，可以暂停提供部份或全部电子交易服务，以进行定期或紧急维修；在此等情形下，致远金融应在暂停服务前采取合理措施通知客户。客户明白及接纳致远金融可随时行使其绝对酌情权决定而无须给予客户预先通知暂停、禁止、限制或终止客户使用或进入致远金融的电子交易服务系统而进行任何交易。而有关情况将不影响双方在暂停、禁止、限制或终止进入致远金融的电子交易服务系统或客户结束其电子账户前所享有的权利及/或承担的义务。致远金融绝对不需要对客户存放于该账户内的按金保证或任何款项支付利息。
        </p>
        <p>（十）第三方代理交易风险</p>
        <p>
          致远金融为确保客户利益，所有客户账户禁止未经致远金融同意下授权第三者操作客户的账户。如本公司发现或怀疑客户为未经本公司同意下授权第三者操作的账户，本公司完全有权取消该账户内的所有交易。由于以上行为可能已违反香港反洗黑钱及相关法例，致远金融完全有权取消该等账户，并收取不少于账户内余额百分之10作为行政费后将账户内余数款项全数退回账户持有人。
        </p>
        <p>（十一）买卖差价</p>
        <p>
          所有买卖差价将按市场情况而调整改变，在少数情况下（包括但不限于公布数据、突发消息、巿场成交薄弱等），扩大买卖差价属正常。
        </p>
        <div class="title">声明本人特此声明</div>
        <p>（1）本开户申请表所载的数据和声明均为真实、完整和正确；</p>
        <p>（2）本人会对最终所发出的指令承担所有的责任；</p>
        <p>
          （3）本人乃准备收取交易之商业/经济利益和/或承担商业/经济风险的人。
        </p>
        <p>
          本人特此确认并同意，除非香港致远期货有限公司收到本人的更改通知，否则香港致远期货有限公司就一切目的而言可完全依赖上述数据和声明。
        </p>
        <p>本人认知及确认：</p>
        <p>
          （1）本人已经细阅香港致远期货有限公司客户协议书及客户协议书所列有关风险披露声明及香港致远期货有限公司已请本人阅读上述风险披露声明、提出问题及寻求独立的意见（如本人有此意愿）。
        </p>
        <p>
          （2）本人同意受香港致远期货有限公司客户协议书之条款及条件约束并明白及确认香港致远期货有限公司可不时修订该协议之条款及条件。本人确认，香港致远期货有限公司并不提供任何有关投资、税务或法律的意见或建议。
        </p>
        <div class="title">重要通告</div>
        <p>
          致远金融强烈要求客户将登录交易平台的数据保密及稳妥收藏。这些登录数据是由致远金融直接传递给客户。因此，只有账户持有人有权拥有该等登录数据并进入交易平台进行交易买卖。致远金融强烈建议客户在第一次登录交易平台时更改登录密码及妥善收藏，因为所有在客户交易账户的买卖活动，客户均需负责。当客户怀疑登录数据被第三者取得，客户应实时更改密码或通知本公司更改登录密码。同时，致远金融严禁员工向客户索取登录密码及进入客户交易账户进行任何活动。客户如对任何索取登录密码的人士有疑问，请与本公司联络。
        </p>
      </div>
    </div>
    <HomeFooter />
  </div>
  );
};

export default Agreement;
