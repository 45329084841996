import React, { useState, useEffect } from "react";
import { Pagination, DatePicker, message, notification } from "antd";
import moment from "moment";
import funds from "../../../assets/images/funds_light.png";
import locale from "antd/es/date-picker/locale/zh_CN";
import Store from "@/store";
import EVENT from "../../../library/api";
import { observer } from "mobx-react-lite";
import { RESTRICT } from "@/library/standard";
import { formatDate } from "@/utils";

const UserTrade = () => {
	const [data, setdata] = useState([]);

	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);

	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [subName, setsubName] = useState("");

	useEffect(() => {
		update({});
	}, []);

	//todo 获取充币记录
	const update = ({ page = 1, rows = 10, biz = 1, startDate, endDate }) => {
		EVENT.History.updateCommission(page, rows, biz, startDate, endDate)
			.then(({ data, page, total }) => {
				setdata(data);
				setPage(page);
				setTotal(total);
			})
			.catch((err) => {
				notification.error({ message: err });
			});
	};

	//todo 搜索
	const search = () => {
		if (!startDate) return message.warn("请选择起始时间");
		if (!endDate) return message.warn("请选择结束日期");

		let t1 = startDate ? startDate.format("YYYY-MM-DD HH:mm:ss") : undefined;
		let t2 = endDate ? endDate.format("YYYY-MM-DD HH:mm:ss") : undefined;
		setPage(0);

		update({
			page: 1,
			rows: 10,
			biz: 1,
			startDate: t1,
			endDate: t2,
		});
	};

	const onChangePage = (page) => {
		let t1 = startDate ? startDate.format("YYYY-MM-DD HH:mm:ss") : undefined;
		let t2 = endDate ? endDate.format("YYYY-MM-DD HH:mm:ss") : undefined;

		setPage(page);
		update({
			page: page,
			rows: 10,
			biz: 1,
			startDate: t1,
			endDate: t2,
		});
	};

	const onChangeDate = (field, value) => {
		if (field === "startDate") {
			setStartDate(value);
		} else {
			setEndDate(value);
		}
	};

	const onStartChange = (value) => {
		onChangeDate("startDate", value);
	};

	const onEndChange = (value) => {
		onChangeDate("endDate", value);
	};

	const disabledStartDate = (startDate) => {
		if (!startDate || !endDate) {
			return false;
		}
		return startDate.valueOf() > endDate.valueOf();
	};

	const disabledEndDate = (endDate) => {
		if (!endDate || !startDate) {
			return false;
		}
		return endDate.valueOf() <= startDate.valueOf();
	};

	//todo 导出列表
	const getExcel = () => {
		if (!startDate && !!endDate) return message.warn("请选择起始时间");
		if (!endDate && !!startDate) return message.warn("请选择结束时间");
		let sDate, eDate;
		if (startDate instanceof moment) {
			sDate = startDate.format("YYYY-MM-DD HH:mm:ss");
		}

		if (endDate instanceof moment) {
			eDate = endDate.format("YYYY-MM-DD HH:mm:ss");
		}
		window.location.href = `${window.location.origin}/api/mine/agent/commission-export${
			!!sDate && !!eDate ? `?createTimeGe=${sDate}&createTimeLe=${eDate}` : ""
		}`;
	};

	return (
		<div className={"user_trade"}>
			<div className="title">
				<div className={"title_section"}>
					<label>日期</label>
					<div className={"dateWrapper"}>
						<DatePicker
							style={{ width: 140 }}
							disabledDate={disabledStartDate}
							format={"YYYY-MM-DD"}
							value={startDate}
							placeholder={"开始日期"}
							locale={locale}
							onChange={onStartChange}
						/>
						<span className={"line"}> - </span>
						<DatePicker
							style={{ width: 140 }}
							disabledDate={disabledEndDate}
							format={"YYYY-MM-DD"}
							value={endDate}
							placeholder={"结束日期"}
							locale={locale}
							onChange={onEndChange}
						/>
					</div>
				</div>
				<div
					className={"search"}
					onClick={() => {
						search();
					}}
				>
					搜索
				</div>
				<div
					className={"excel"}
					onClick={() => {
						getExcel();
					}}
				>
					导出
				</div>
			</div>
			<ol>
				<li className={"user_title"}>
					<div>邀请用户</div>
					<div>交易手续费(元)</div>
					<div>佣金收入(元)</div>
					<div>交易ID</div>
					<div>时间</div>
				</li>
				{!!data && data.length !== 0 ? (
					data.map((item, key) => (
						<li key={key}>
							<div>{item.subName}</div>
							<div>{RESTRICT.float(item.charge, 2)}</div>
							<div>{RESTRICT.float(item.money, 2)}</div>
							<div>{item.tradeId}</div>
							<div>{formatDate("m-d h:i:s", { date: item.createTime })}</div>
						</li>
					))
				) : (
					<div className={"empty"}>
						<div>
							<img src={funds} alt="" />
							<b>暂无记录</b>
						</div>
					</div>
				)}
			</ol>
			<div className={"pagination"}>
				<Pagination
					onChange={(page) => {
						onChangePage(page);
					}}
					defaultPageSize={10}
					current={page}
					total={total}
				/>
			</div>
		</div>
	);
};

export default UserTrade;
