import ICBC from "@/assets/images/bankIcon/icbc.png";
import CMB from "@/assets/images/bankIcon/cmb.png";
import CCB from "@/assets/images/bankIcon/ccb.png";
import ABC from "@/assets/images/bankIcon/abc.png";
import BOC from "@/assets/images/bankIcon/boc.png";
import COMM from "@/assets/images/bankIcon/comm.png";
import CMBC from "@/assets/images/bankIcon/cmbc.png";
import SPDB from "@assets/images/bankIcon/spdb.png";
import CITIC from "@/assets/images/bankIcon/citic.png";
import GDB from "@/assets/images/bankIcon/gdb.png";
import SZPAB from "@/assets/images/bankIcon/szpab.png";
import CIB from "@/assets/images/bankIcon/cib.png";
import HXB from "@/assets/images/bankIcon/hxb.png";
import CEB from "@/assets/images/bankIcon/ceb.png";
import PSBC from "@/assets/images/bankIcon/psbc.png";
import ZXYH from "@/assets/images/bankBg/ZXYH.png";
import GFYH from "@/assets/images/bankBg/GFYH.png";
import GSYH from "@/assets/images/bankBg/GSYH.png";
import HXYH from "@/assets/images/bankBg/HXYH.png";
import JSYH from "@/assets/images/bankBg/JSYH.png";
import JTYH from "@/assets/images/bankBg/JTYH.png";
import MSYH from "@/assets/images/bankBg/MSYH.png";
import NYYH from "@/assets/images/bankBg/NYYH.png";
import PAYH from "@/assets/images/bankBg/PAYH.png";
import PFYH from "@/assets/images/bankBg/PFYH.png";
import XYYH from "@/assets/images/bankBg/XYYH.png";
import YZCX from "@/assets/images/bankBg/YZCX.png";
import ZGYH from "@/assets/images/bankBg/ZGYH.png";
import ZSYH from "@/assets/images/bankBg/ZSYH.png";
import GDYH from "@/assets/images/bankBg/GDYH.png";
import CARDBG from "@/assets/images/bankBg/card-bg.png";
import BSCBG from "@/assets/images/bankBg/bsc-bg.png";
import RCB from "@/assets/images/bankIcon/RCB.svg";
import BCS from "@/assets/images/bankIcon/BCS.svg";
import CB from "@/assets/images/bankIcon/CB.svg";

//todo 银行卡列表
export const bank = [
  [
    { label: "中国工商银行(ICBC)", value: "工商银行" },
    { label: "中国建设银行(CCB)", value: "建设银行" },
    { label: "中国农业银行(ABC)", value: "农业银行" },
    { label: "招商银行(CMB)", value: "招商银行" },
    { label: "中国银行(BOC)", value: "中国银行" },
    { label: "交通银行(BCM)", value: "交通银行" },
    { label: "中国邮政储蓄银行(PSBC)", value: "邮政储蓄" },
    { label: "中国民生银行(CMBC)", value: "民生银行" },
    { label: "上海浦东发展银行(SPDB)", value: "浦发银行" },
    { label: "兴业银行(CIB)", value: "兴业银行" },
    { label: "华夏银行(HB)", value: "华夏银行" },
    { label: "中国光大银行(CEB)", value: "光大银行" },
    { label: "广东发展银行(GDB)", value: "广发银行" },
    { label: "中信银行(CITIC)", value: "中信银行" },
    { label: "平安银行(SZPAB)", value: "平安银行" },
    { label: "长沙银行(BCS)", value: "长沙银行" },
  ],
];

//todo 获取银行图标
export const getBankIcon = (name) => {
  switch (name) {
    case "工商银行":
      return ICBC;
    case "招商银行":
      return CMB;
    case "建设银行":
      return CCB;
    case "农业银行":
      return ABC;
    case "中国银行":
      return BOC;
    case "交通银行":
      return COMM;
    case "民生银行":
      return CMBC;
    case "浦发银行":
      return SPDB;
    case "中信银行":
      return CITIC;
    case "广发银行":
      return GDB;
    case "平安银行":
      return SZPAB;
    case "兴业银行":
      return CIB;
    case "华夏银行":
      return HXB;
    case "光大银行":
      return CEB;
    case "邮政储蓄":
      return PSBC;
    case "农商银行":
      return RCB;
    case "长沙银行":
      return BCS;
    case "商业银行":
      return CB;
    default:
      return "";
  }
};

//todo 获取银行背景图
export const getBankBg = (name) => {
  switch (name) {
    case "工商银行":
      return GSYH;
    case "招商银行":
      return ZSYH;
    case "建设银行":
      return JSYH;
    case "农业银行":
      return NYYH;
    case "中国银行":
      return ZGYH;
    case "交通银行":
      return JTYH;
    case "民生银行":
      return MSYH;
    case "浦发银行":
      return PFYH;
    case "中信银行":
      return ZXYH;
    case "广发银行":
      return GFYH;
    case "平安银行":
      return PAYH;
    case "兴业银行":
      return XYYH;
    case "华夏银行":
      return HXYH;
    case "光大银行":
      return GDYH;
    case "邮政储蓄":
      return YZCX;
    case "长沙银行":
      return BSCBG;
    default:
      return CARDBG;
  }
};

export const withdrawStatus = [
  "待处理",
  "处理成功",
  "处理失败",
  "已取消",
  "处理中",
  "汇款中",
  "已退款",
];
