import React, { useState, useEffect } from "react";
import "./index.scss";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import ChangeLoginPass from "./changeLoginPass";
import WithdrawPass from "./withdrawPass";
import BindPhone from "./bindPhone";
import ChangePhone from "./changePhone";
import BindEmail from "./bindEmail";
import ChangeEmail from "./changeEmail";
import ForgetWithdraw from "../withdraw/forgotWithdraw";
import { isEmptyAry, mobileMask, emailMask, formatDate } from "@/utils";
import { safeValue } from "../../../library/safety";

import { SimpleInput } from "../../components";
import { Modal, message } from "antd";
import { Toast } from "antd-mobile";

import userIcon from "../../../assets/images/userCenter/userIcon.svg";
import edit from "../../../assets/images/userCenter/edit.svg";

import EVENT from "../../../library/api";
import Store from "@/store";

import CheckIn from "./checkIn/checkIn";
import { customModal } from "@/view/components/Modal";
import checkIcon from "../../../assets/images/common/checkIn.svg";

const UserCenter = (props) => {
  const [changeLogin, setchangeLogin] = useState(false);
  const [setWithdraw, setsetWithdraw] = useState(false);
  const [bindPhone, setbindPhone] = useState(false);
  const [changePhone, setchangePhone] = useState(false);
  const [bindEmail, setbindEmail] = useState(false);
  const [changeEmail, setchangeEmail] = useState(false);
  const [forgotWithdraw, setforgotWithdraw] = useState(false);
  const [user, setuser] = useState(Store.User.accountInfo);
  const [loginHistory, setloginHistory] = useState({});
  const [editUsername, seteditUsername] = useState(false);
  const [nickname, setnickname] = useState("");
  const isLogin = Store.User.isLogin;
  const { usernameNo } = Store.User.accountInfo;

  useEffect(() => {
    setuser(Store.User.accountInfo);
  }, [Store.User.accountInfo]);

  useEffect(() => {
    getLoginHistory();
  }, []);

  const handleChangeNickName = (e) => {
    setnickname(e);
  };

  //todo 获取最新的登录IP信息
  const getLoginHistory = () => {
    EVENT.History.getLoginHistory(1, 1).then(({ data }) => {
      setloginHistory(data !== undefined ? data : {});
    });
  };

  //todo 判断用户安全等级
  const calculate = () => {
    let lv = 1;
    if (safeValue(user.phone, true) && safeValue(user.email, true)) {
      lv += 1;
    }
    if (user.pw_w > 0) {
      lv += 1;
    }
    if (user.pw_w === 3) {
      lv += 1;
    }
    if (user.pw_l === 3) {
      lv += 1;
    }
    return lv;
  };

  //todo 用户安全等级级别
  const getLevel = (lv) => {
    if (lv <= 2) {
      return "低";
    } else if (lv > 2 && lv <= 4) {
      return "中";
    } else if (lv > 4) {
      return "高";
    }
  };

  const lv = calculate();
  const level = getLevel(lv);

  const showCheckInModal = () => {
    customModal(<CheckIn />, false);
  };

  return (
    <div className={"user_center"}>
      <div className={"center_title"}>
        <h5>个人中心</h5>
        <div className={"user_box"}>
          <div className={"user_info"}>
            <img src={userIcon} alt="" />
            <div className={"info"}>
              <div className={"name"}>
                <b>{isLogin ? user.username : ""}</b>
                {usernameNo === 0 ? (
                  <img
                    src={edit}
                    alt=""
                    className={"editname"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      seteditUsername(true);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className={"id"}>
                <div className={"phone"}>
                  {!isEmptyAry(user) && user.phone.length === 0
                    ? emailMask(user.email)
                    : mobileMask(user.phone)}
                </div>
                <div>
                  <label>UID:</label>
                  {user.userId}
                </div>
              </div>
            </div>
          </div>
          <div className={"login_info"}>
            <span />
            <div>
              <p>
                上次登录时间:
                {formatDate("y-m-d h:i:s", {
                  date: !!loginHistory[0] && loginHistory[0].time,
                })}
              </p>
              <p>IP: {!!loginHistory[0] && loginHistory[0].ip}</p>
              <Link to={"/account/loginHistory"}>查看登录历史记录</Link>
            </div>
          </div>
          <div className={"checkin"} onClick={showCheckInModal}>
            <img src={checkIcon} />
            <span>签到</span>
          </div>
        </div>
      </div>
      <div className={"security_level"}>
        <b>安全级别</b>
        <div className="line">
          <div
            className={lv <= 2 ? "red_bg" : lv <= 4 ? "yellow_bg" : "green_bg"}
          />
          <div
            className={
              lv <= 2 ? "" : lv > 2 && lv <= 4 ? "yellow_bg" : "green_bg"
            }
          />
          <div className={lv > 4 ? "green_bg" : ""} />
        </div>
        <div
          className={`level ${
            lv <= 2 ? "red_bg" : lv <= 4 ? "yellow_bg" : "green_bg"
          }`}
        >
          {level}
        </div>
        <p>建议您启动全部安全设置，以保障账户及资金安全</p>
      </div>
      <div className={"user_nav"}>
        <div className={"nav_name"}>
          <b>登录密码</b>
          <p>在登录个人账号时使用的密码</p>
        </div>
        <div
          className={"btn"}
          onClick={() => {
            setchangeLogin(true);
          }}
        >
          修改
        </div>
      </div>
      <div className={"user_nav"}>
        <div className={"nav_name"}>
          <b>资金密码</b>
          <p>用于申请提款时的安全认证</p>
        </div>
        <div
          className={"btn"}
          onClick={() => {
            setsetWithdraw(true);
          }}
        >
          {user.pw_w === 0 ? "设置" : "修改"}
        </div>
      </div>
      <div className={"user_nav"}>
        <div className={"nav_name"}>
          <b>手机验证</b>
          <p>用于提现，修改密码，安全设置时用以收取验证短信</p>
        </div>
        <div
          className={"btn"}
          onClick={() => {
            user.phone === "" ? setbindPhone(true) : setchangePhone(true);
          }}
        >
          {user.phone === "" ? "绑定" : "修改"}
        </div>
      </div>
      <div className={"user_nav"}>
        <div className={"nav_name"}>
          <b>邮箱验证</b>
          <p>用于登录，找回密码，修改账户安全信息，提币等操作</p>
        </div>
        <div
          className={"btn"}
          onClick={() => {
            user.email === "" ? setbindEmail(true) : setchangeEmail(true);
          }}
        >
          {user.email === "" ? "绑定" : "修改"}
        </div>
      </div>

      {/* 修改登录密码 */}
      {changeLogin && (
        <ChangeLoginPass
          visible={changeLogin}
          onCancel={() => {
            setchangeLogin(false);
          }}
          {...props}
        />
      )}
      {/*设置和修改资金密码*/}
      {setWithdraw && (
        <WithdrawPass
          visible={setWithdraw}
          onCancel={() => {
            setsetWithdraw(false);
          }}
          handleModal={() => setsetWithdraw(false)}
          forgotPass={() => {
            setforgotWithdraw(true);
          }}
          {...props}
        />
      )}

      {/*绑定手机*/}
      {bindPhone && (
        <BindPhone
          visible={bindPhone}
          onCancel={() => {
            setbindPhone(false);
          }}
          handleModal={() => setbindPhone(false)}
          {...props}
        />
      )}
      {/*修改手机*/}
      {changePhone && (
        <ChangePhone
          visible={changePhone}
          onCancel={() => {
            setchangePhone(false);
          }}
          {...props}
        />
      )}
      {/*绑定邮箱*/}
      {bindEmail && (
        <BindEmail
          visible={bindEmail}
          onCancel={() => {
            setbindEmail(false);
          }}
          {...props}
        />
      )}
      {/*修改邮箱*/}
      {changeEmail && (
        <ChangeEmail
          visible={changeEmail}
          onCancel={() => {
            setchangeEmail(false);
          }}
          handleModal={() => setchangeEmail(false)}
          {...props}
        />
      )}
      {/*忘记提款密码*/}
      {forgotWithdraw && (
        <ForgetWithdraw
          visible={forgotWithdraw}
          onCancel={() => {
            setforgotWithdraw(false);
          }}
          handleModal={() => setforgotWithdraw(false)}
          {...props}
        />
      )}
      {editUsername && (
        <Modal
          title={"修改昵称"}
          visible={editUsername}
          className={"alertModal aboutPass"}
          destroyOnClose={true}
          onCancel={() => {
            seteditUsername(false);
            setnickname("");
          }}
          footer={<div />}
        >
          <SimpleInput
            label={"昵称"}
            placeholder={"请输入新昵称"}
            type={"text"}
            maxLength={16}
            value={nickname}
            onChange={handleChangeNickName}
          />
          <div
            className={`confirm_btn ${!nickname && "error"}`}
            onClick={() => {
              if (!nickname) return;
              EVENT.Account.updateNickname(nickname)
                .then(() => {
                  Store.User.updateDetail();
                  message.success("昵称修改成功");
                  seteditUsername(false);
                  setnickname("");
                })
                .catch((err) => Toast.show(err.message));
            }}
          >
            确认
          </div>
        </Modal>
      )}
    </div>
  );
};

export default observer(UserCenter);
