import React, { useEffect } from "react";
import { HomeHeader, HomeFooter } from "@/view/components/index";
import security from "@assets/images/bot/security-banner.jpg";

// style
import styles from "./index.module.scss";

export default function Warning(props) {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth", // 若要平滑滾動效果，可以設置為 'smooth'
		});
	}, []);
	return (
		<div className={styles.warningWrapper}>
			<HomeHeader />
			<main>
				<div className={styles.banner}>
					<img src={security} />
				</div>
				<div className={styles.body}>
					<div className={styles.security01} />
					<div className={styles.security02} />
					<div className={styles.security03} />
					<div className={styles.security04} />
					<div className={styles.security05} />
				</div>
			</main>
			<HomeFooter />
		</div>
	);
}
