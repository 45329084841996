import React, { Component,useEffect, useState } from "react";
import "./index.scss";

import { HomeHeader,HomeFooter } from "@/view/components/index";
import { Link } from "react-router-dom";
import knowledge from "../../assets/images/school/school-knowledge.png";
import intro from "../../assets/images/school/school-intro.png";
import basic from "../../assets/images/school/school-basic.png";
import technology from "../../assets/images/school/school-technology.png";
import comprehensive from "../../assets/images/school/school-comprehensive.png";
import mind from "../../assets/images/school/school-mind.png";

function School() {
  return (
    <div className="school">
    <HomeHeader />
    <div className="wrap">
      <div className="box">
        <div className="menu">
          <p onClick={()=>this.props.history.push('/school')} className="title">致远学院</p>
          <dl>
            <dt>
              <h5>实时资讯</h5>

              <p>                   
                <Link to="/page0">市场快讯</Link>
              </p>

              <p>
                <Link to="/page1">热点新闻</Link>
              </p>
              <p>
                <Link to="/page2">财经日历</Link>
              </p>
            </dt>
            <dt>
              <h5>新手入门</h5>
              <p>
                <Link to="/page3" t="新手入门">
                  基础知识
                </Link>
              </p>
              <p>
                <Link to="/page4" t="新手入门">
                  投资介绍
                </Link>
              </p>
            </dt>
            <dt>
              <h5>高手进阶</h5>
              <p>
                <Link to="/page5" t="高手进阶">
                  基本面分析
                </Link>
              </p>
              <p>
                <Link to="/page6" t="高手进阶">
                  技术面分析
                </Link>
              </p>
              <p>
                <Link to="/page7" t="高手进阶">
                  综合技巧
                </Link>
              </p>
              <p>
                <Link to="/page8" t="高手进阶">
                  高手心态
                </Link>
              </p>
            </dt>
          </dl>
        </div>
        <div className="main">
          <div className="school-header">
            <Link to="/school">致远学院</Link> &gt; <span>首页</span>
          </div>
          <div className="school-index">
            <div className="school-index-body">
              <div className="process">
                <div className="title">平台特点</div>
                <div className="step">
                  <div className="item arrow">
                    <h5>01</h5>
                    <span>注册账户</span>
                  </div>
                  <div className="item arrow">
                    <h5>02</h5>
                    <span>热点新闻</span>
                  </div>
                  <div className="item">
                    <h5>03</h5>
                    <span>财经日历</span>
                  </div>
                </div>
              </div>
              <div className="beginner">
                <div className="title">新手入门</div>
                <div className="column">
                  <div className="item itemleft">
                    <div className="subtitle">
                      <label>基础知识</label>
                      <Link to="/page3">更多&gt;&gt;</Link>
                    </div>
                    <div className="cell">
                      <ol>
                        <li>
                          <Link to="#school-detail?s=11">期货概念</Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=12">
                            期货交易的起源
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=13">
                            期货交易的基本特征
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=14">
                            期货市场与证券市场
                          </Link>
                        </li>
                      </ol>
                      <img src={knowledge} />
                    </div>
                  </div>
                  <div className="item itemright">
                    <div className="subtitle">
                      <label>投资介绍</label>
                      <Link to="/page4">更多&gt;&gt;</Link>
                    </div>
                    <div className="cell">
                      <ol>
                        <li>
                          <Link to="#school-detail?s=21">期货投机交易</Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=22">
                            期货套利的方法
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=23">
                            期货交易的风险
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=24">
                            投资者如何控制风险
                          </Link>
                        </li>
                      </ol>
                      <img src={intro} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="beginner">
                <div className="title">高手进阶</div>
                <div className="column">
                  <div className="item itemleft">
                    <div className="subtitle">
                      <label>基本面分析</label>
                      <Link to="/page5">更多&gt;&gt;</Link>
                    </div>
                    <div className="cell">
                      <ol>
                        <li>
                          <Link to="#school-detail?s=31">金融货币因素</Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=32">经济波动周期</Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=33">
                            期货商品供给分析
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=34">
                            期货商品需求分析
                          </Link>
                        </li>
                      </ol>
                      <img src={basic} />
                    </div>
                  </div>
                  <div className="item itemright">
                    <div className="subtitle">
                      <label>技术面分析</label>
                      <Link to="/page6">更多&gt;&gt;</Link>
                    </div>
                    <div className="cell">
                      <ol>
                        <li>
                          <Link to="#school-detail?s=41">理论基础</Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=42">
                            技术分析方法的运用
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=43">
                            技术分析的要素：价与量
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=44">
                            成交量、持仓量与价格的关系
                          </Link>
                        </li>
                      </ol>
                      <img src={technology} />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="item itemleft">
                    <div className="subtitle">
                      <label>综合技巧</label>
                      <Link to="/page7">更多&gt;&gt;</Link>
                    </div>
                    <div className="cell">
                      <ol>
                        <li>
                          <Link to="#school-detail?s=51">消除假突破</Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=52">
                            高手的均线交易
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=53">
                            入市时间的选择
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=54">
                            建立波段交易系统
                          </Link>
                        </li>
                      </ol>
                      <img src={comprehensive} />
                    </div>
                  </div>
                  <div className="item itemright">
                    <div className="subtitle">
                      <label>高手心态</label>
                      <Link to="/page8">更多&gt;&gt;</Link>
                    </div>
                    <div className="cell">
                      <ol>
                        <li>
                          <Link to="#school-detail?s=61">
                            交易要剖析自己
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=62">
                            主观交易心法修炼
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=63">
                          如何验证自己的交易逻辑
                          </Link>
                        </li>
                        <li>
                          <Link to="#school-detail?s=64">
                            全球顶尖交易员的投资大揭密
                          </Link>
                        </li>
                      </ol>
                      <img src={mind} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeFooter />
  </div>
  );
}

export default School;
