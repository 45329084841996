import { observer } from "mobx-react-lite";
import React from "react";
import "./index.scss";

const Rule = ({ rule,data,orderType }) => {
  let futures =
    !!data.code && (data.code.indexOf("USDT") === -1 ? true : false);
	return (
    <div className={`rule ${orderType === 1 ? "short" : "high"}`}>
        <h5>商品信息</h5>
        {!!rule && (
          <>
            <div>
              <label>合约名称</label>
              <span>{rule.name}</span>
            </div>
            <div>
              <label>商品编号</label>
              <span>{rule.code}</span>
            </div>
            {futures && (
              <div>
                <label>交易所</label>
                <span>{rule.exchange}</span>
              </div>
            )}
            <div>
              <label>货币单位</label>
              <span>{rule.currency}</span>
            </div>
            <div>
              <label>合约类型</label>
              <span>{rule.type}</span>
            </div>
            {futures ? (
              <>
                {" "}
                <div>
                  <label>合约规模</label>
                  <span>{rule.unit}</span>
                </div>
                <div>
                  <label>合约价值</label>
                  <span>{rule.volatility}</span>
                </div>
                <div>
                  <label>最小变动单位</label>
                  <span>{rule.volatilityIncome}</span>
                </div>
              </>
            ) : (
              <>
                <div>
                  <label>最低保证金</label>
                  <span>{rule.min}</span>
                </div>
                <div>
                  <label>最高保证金</label>
                  <span>{rule.max}</span>
                </div>
                <div>
                  <label>杠杆范围</label>
                  <span>{rule.lever}</span>
                </div>
                <div>
                  <label>手续费</label>
                  <span>{rule.charge}</span>
                </div>
                <div>
                  <label>过夜费</label>
                  <span>保证金*杠杆*0.08%</span>
                </div>
                <div>
                  <label>过夜费收取</label>
                  <span>每日05:55:00</span>
                </div>
              </>
            )}
            <div>
              <label>买入时间</label>
              <span dangerouslySetInnerHTML={{ __html: rule.buyTimeAM }} />
            </div>
            {!!rule.buyTimePM && (
              <div>
                <label />
                <span dangerouslySetInnerHTML={{ __html: rule.buyTimePM }} />
              </div>
            )}
            {!!rule.buyTimeAMWinter && (
              <div>
                <label />
                <span
                  dangerouslySetInnerHTML={{ __html: rule.buyTimeAMWinter }}
                />
              </div>
            )}
            <div>
              <label>卖出时间</label>
              <span dangerouslySetInnerHTML={{ __html: rule.sellTimeAM }} />
            </div>
            {!!rule.sellTimePM && (
              <div>
                <label />
                <span dangerouslySetInnerHTML={{ __html: rule.sellTimePM }} />
              </div>
            )}
            {!!rule.sellTimeAMWinter && (
              <div>
                <label />
                <span
                  dangerouslySetInnerHTML={{ __html: rule.sellTimeAMWinter }}
                />
              </div>
            )}
            <div>
              <label>清仓时间</label>
              <span dangerouslySetInnerHTML={{ __html: rule.clearTime }} />
            </div>
            {!!rule.clearTimeWinter && (
              <div>
                <label />
                <span
                  dangerouslySetInnerHTML={{ __html: rule.clearTimeWinter }}
                />
              </div>
            )}
            {!futures && (
              <>
                <div>
                  <label>发行时间</label>
                  <span>{rule.publicTime}</span>
                </div>
                <div>
                  <label>发行总量</label>
                  <span>{rule.publicTotal}</span>
                </div>
                <div>
                  <label>白皮书</label>
                  <span className={"search"}>{rule.shu}</span>
                </div>
                <div>
                  <label>官方网站</label>
                  <span>{rule.publicWeb}</span>
                </div>
                <div>
                  <label>区块链查询</label>
                  <span className={"search"}>{rule.publicSearch}</span>
                </div>
                <div className={"production"}>
                  <b>介绍</b>
                  <span>{rule.dec}</span>
                </div>
              </>
            )}
          </>
        )}
      </div>
   );
 };
 
 export default observer(Rule);
