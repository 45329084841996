import React, { useState, useMemo, useEffect, useRef } from "react";
import { notification, message } from "antd";

// lib
import EVENT from "@/library/api";
// components
import { ImgValide, SimpleInput } from "../../components";
// style
import styles from "./index.module.scss";

const MobileForm = (props) => {
  const { account, setAccount, formatVerify, setFormatVerify } = props;
  return (
    <SimpleInput
      label="手机号码"
      placeholder="请输入手机号码"
      type={"tel"}
      format={"PHONE"}
      notice={"tel"}
      value={account}
      onChange={(phone, format) => {
        if (format !== formatVerify.phone) {
          setFormatVerify((props) => ({ ...props, phone: format }));
        }
        setAccount(phone);
      }}
    />
  );
};
const EmailForm = (props) => {
  const { account, setAccount, formatVerify, setFormatVerify } = props;
  return (
    <SimpleInput
      label="电子邮箱"
      placeholder="请输入邮箱地址"
      type={"email"}
      format={"EMAIL"}
      notice={"email"}
      value={account}
      onChange={(email, format) => {
        setAccount(email);
        if (format !== formatVerify.email) {
          setFormatVerify((props) => ({ ...props, email: format }));
        }
      }}
    />
  );
};

export default function OneStep(props) {
  const { type, next } = props;
  const [account, setAccount] = useState("");
  const [msgCode, setMsgCode] = useState("");
  const [codeShow, setCodeShow] = useState(false);
  const [countTime, setCountTime] = useState(null);
  const [isSend, setIsSend] = useState(false);
  const [countText, setCountText] = useState("获取验证码");
  const [formatVerify, setFormatVerify] = useState({
    phone: false,
    email: false,
    code: false,
  });
  const [isError, setIsError] = useState(true);
  const timer = useRef();

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = null;
    };
  }, []);

  useEffect(() => {
    setAccount("");
  }, [type]);

  useEffect(() => {
    if (countTime === null) {
      return;
    }
    if (countTime === 0) {
      message.warning("验证码错误或已过期");
      setCountText("获取验证码");
      setIsSend(false);
      setCountTime(null);
      clearTimeout(timer.current);
    } else {
      timer.current = setTimeout(() => {
        setCountTime((time) => time - 1);
        setCountText(`${countTime}秒后重发`);
        setIsSend(true);
      }, 1000);
    }
  }, [countTime]);

  useEffect(() => {
    if (account.length > 0 && msgCode.length > 0) {
      setIsError(false);
    }
  }, [account, msgCode]);

  // 顯示圖片驗證碼
  const showCode = () => {
    if (!account) {
      return message.warning("请输入账户");
    }
    if (type === "MOBILE" ? account.length < 8 : !account.includes("@")) {
      return message.warning("账号格式不正确");
    }
    if (isSend) return;
    setCodeShow(true);
  };
  // 驗證驗證碼
  const changeLoginPass = () => {
    if (isError) {
      return;
    }
    EVENT.Account[type === "MOBILE" ? "verifySMS" : "verifyEmail"](
      account,
      msgCode,
      "FORGOT_PASSWORD"
    )
      .then(() => nextStep())
      .catch(({ message }) => notification.error({ message }));
  };
  const nextStep = () => {
    EVENT.Account.securityVerify(account)
      .then(({ token }) => next(token))
      .catch(({ message }) => notification.error({ message }));
  };
  return (
    <div className={styles.form}>
      {type === "MOBILE" ? (
        <MobileForm
          account={account}
          setAccount={setAccount}
          formatVerify={formatVerify}
          setFormatVerify={setFormatVerify}
        />
      ) : (
        <EmailForm
          account={account}
          setAccount={setAccount}
          formatVerify={formatVerify}
          setFormatVerify={setFormatVerify}
        />
      )}
      <div className={styles.sendCode}>
        <SimpleInput
          customClass={styles.simpleInputV2}
          label={type === "MOBILE" ? "短信验证码" : "邮箱验证码"}
          placeholder={
            type === "MOBILE" ? "请输入手机验证码" : "请输入邮箱验证码"
          }
          type={"verifyCode"}
          format={"GLOBAL_CAPTCHA"}
          notice={"msgCode"}
          value={msgCode}
          onChange={(code, format) => {
            if (format !== formatVerify.code) {
              setFormatVerify((props) => ({ ...props, code: format }));
            }
            setMsgCode(code);
          }}
        />
        <div className={styles.getCode} onClick={showCode}>
          {countText}
        </div>
      </div>
      <div
        className={isError ? styles.confirmBtnErr : styles.confirmBtn}
        onClick={changeLoginPass}
      >
        下一步
      </div>
      {codeShow && (
        <ImgValide
          type="FORGOT_PASSWORD"
          account={account}
          sendCodeStatus={() => setCountTime(90)}
          hide={() => setCodeShow(false)}
        />
      )}
    </div>
  );
}
