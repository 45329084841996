class Storage {
  #store = window.localStorage;
  #sign = `|-FK-|`;

  //todo 缓存
  storage(store) {
    this.#store = store;
  }

  setItem(key, value, expires = 0) {
    return new Promise(async (resolve, reject) => {
      try {
        value = `${value}${this.#sign}${expires}${this.#sign}${Date.now()}`;
        if (this.#store.setItem instanceof Promise) {
          await this.#store.setItem(key, value);
        } else {
          this.#store.setItem(key, value);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  getItem(key) {
    return new Promise(async (resolve, reject) => {
      try {
        let o;
        if (this.#store.getItem instanceof Promise) {
          o = await this.#store.getItem(key);
        } else {
          o = this.#store.getItem(key);
        }
        if (o === null) return resolve(o);
        let [val, expires, data] = o.split(this.#sign);
        if (Date.now() - data >= expires && expires !== 0) val = null;
        resolve(val);
      } catch (e) {
        reject(e);
      }
    });
  }

  removeItem(key) {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.#store.removeItem instanceof Promise) {
          await this.#store.removeItem(key);
        } else {
          this.#store.removeItem(key);
        }
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new Storage();
